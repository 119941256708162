import React from "react";
import { Table } from "../../../layout";

const Payments = ({ payments }) => {
  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={() => <> </>}
        tblHead={[
          { id: "status", label: "Status" },
          { id: "amt", label: "Amount" },
          { id: "subsTitle", label: "Subs. Titlte" },
          { id: "userName", label: "Paid User" },
          { id: "date", label: "Date" },
          { id: "stripePaymentId", label: "Payment Id" },
        ]}
        tblData={payments.map((j) => {
          return {
            ...j,
            amt: <span>$ {j.amount}</span>,
            subsTitle: j.subs && j.subs.title,
            userName: j.user && j.user.fullName,
            statusName: j.status === 1 ? "Completed" : "Cancelled",
            status: (
              <div className="p-5">
                {j.status === 1 ? (
                  <span className="success-color bg-successXS status">
                    Completed
                  </span>
                ) : (
                  <span className="error-color bg-errorSM status">
                    Cancelled
                  </span>
                )}
              </div>
            ),
          };
        })}
      />
    </>
  );
};

export default Payments;
