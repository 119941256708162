import { Grid } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Button, Input, InputPass } from "../../controls";
import { Model, UseForm } from "../../hooks";
import { Form } from "../../layout";
import { PassValidation } from "../../utils";
import * as actions from "../../api/actions/actions";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { useState } from "react";

const Signup = (props) => {
  const { _post, _getByObj } = props;
  const { UserMdl } = Model();
  const navigate = useNavigate();
  const { values, errors, setErrors, handleInpChg, handleInpPass, passValid } =
    UseForm(UserMdl);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : "Full Name is required.";
    temp.email =
      values.email !== ""
        ? /^$|.+@.+..+/.test(values.email)
          ? ""
          : "Email is not valid."
        : "Email is required.";
    temp.hoaName = values.hoaName !== "" ? "" : "Hoa Name is required.";
    temp.orgName =
      values.orgName !== "" ? "" : "Organization name is required.";
    temp.password =
      values.password !== ""
        ? passValid.passValid
          ? ""
          : " "
        : "Password is required.";
    temp.confirmPass =
      values.confirmPass !== ""
        ? values.confirmPass === values.password
          ? ""
          : "Password and Confirm password not match"
        : "Confirm Password is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // Create Action
      if (!values.id) {
        const onComplete = (res) => {
          setLoading(false);
          if (res.status === 201) {
            // Assign Trial Subscription Plan
            var subsParam = JSON.parse(localStorage.getItem("subsParam"));
            _getByObj(
              endpoints.Subs + controller.CreateSubs,
              ToFormData({
                id: subsParam.pkgId,
                boolVal: subsParam.isYearlyPay,
              }),
              actions.ActionType.Subs,
              (res) => {
                if (res.status === 201) {
                  localStorage.removeItem("subsParam");
                  navigate("/my_account/home");
                } else console.log("Warning: ", res.status, res.msg);
              }
            );
          } else if (res.status === 409) {
            setErrors({
              ...errors,
              email: res.msg,
            });
          }
        };
        _post(
          endpoints.User + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Auth,
          onComplete
        );
      }
    }
  };

  return (
    <div className="d-flex j-center">
      <div>
        <div className="tx-center mb-20">
          <img
            className="img-fluid"
            style={{
              backgroundColor: "rgba(255 255 255 / 50%)",
              border: "3px double var(--primary-color)",
              borderRadius: "50%",
              padding: "5px",
              width: "70px",
              height: "70px",
            }}
            alt="user"
            src={process.env.PUBLIC_URL + "/assets/user.png"}
          />
          <h2 className="h mt-10 tx-upp">Register</h2>
        </div>
        <div className="p-20">
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Input
                  label="Full Name"
                  name="fullName"
                  onChange={handleInpChg}
                  value={values.fullName}
                  error={errors.fullName}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Email"
                  name="email"
                  onChange={handleInpChg}
                  value={values.email}
                  error={errors.email}
                  fullWidth
                  inputProps={{ maxLength: 300 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="HOA Name"
                  name="hoaName"
                  onChange={handleInpChg}
                  value={values.hoaName}
                  error={errors.hoaName}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Organization Name"
                  name="orgName"
                  onChange={handleInpChg}
                  value={values.orgName}
                  error={errors.orgName}
                  fullWidth
                  inputProps={{ maxLength: 300 }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputPass
                  label="Password"
                  name="password"
                  value={values.password}
                  onChange={handleInpPass}
                  error={errors.password}
                  className="input-primary w-100pr"
                />
                <PassValidation {...{ password: values.password, passValid }} />
              </Grid>
              <Grid item xs={12}>
                <InputPass
                  label="Confirm Password"
                  name="confirmPass"
                  value={values.confirmPass}
                  onChange={(e) => handleInpPass(e, true)}
                  error={errors.confirmPass}
                  className="input-primary w-100pr"
                />
              </Grid>

              <Grid item xs={12} className="tx-center">
                <Button
                  loading={loading}
                  size="large"
                  className="fs-18 w-100pr"
                  type="submit"
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _data: state.storeData.data,
});

const mapActionToProps = {
  _post: actions.post,
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(Signup);
