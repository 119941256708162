import { CloseRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { Table } from "../../../layout";
import { DropLi } from "../../../hooks";

const VeweInvoices = (props) => {
  const { setShowForm, invs } = props;
  const { InvTypes } = DropLi();
  const handleClose = () => {
    setShowForm(false);
  };
  const HeaderComp = () => (
    <div className="d-flex a-center">
      <IconButton onClick={handleClose}>
        <CloseRounded />
      </IconButton>
    </div>
  );

  return (
    <div className="p-15">
      <Table
        shadow={false}
        ActionComp={() => <></>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "issueDate", label: "Issue Date" },
          { id: "dueDate", label: "DUE Date" },
          { id: "invType", label: "Invoice Type" },
          { id: "title", label: "Charge Title" },
          { id: "invAmount", label: "Invoice Amount" },
        ]}
        tblData={invs.map((j) => {
          var type = InvTypes.find((x) => j.inv.invType === x.id);
          return {
            issueDate: j.inv.issueDate,
            dueDate: j.inv.dueDate,
            invType: type && type.name,
            title: j.inv.title,
            invAmount: "$" + j.inv.amount,
          };
        })}
      />
    </div>
  );
};

export default VeweInvoices;
