import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Slide } from "@mui/material";
import React, { useState } from "react";
import "./Carousel.scss";

const Carousel = (props) => {
  const { children, arrowInside = false, ...other } = props;
  const [activeIn, setActiveIn] = useState(0);
  const [animDirection, setAnimDirection] = useState("right");

  const handleDot = (ind) => {
    setAnimDirection("up");
    setActiveIn(ind);
  };

  const handleNext = () => {
    setAnimDirection("left");
    setActiveIn(children.length > activeIn + 1 ? activeIn + 1 : 0);
  };
  const handlePrev = () => {
    setAnimDirection("right");
    setActiveIn(activeIn > 0 ? activeIn - 1 : children.length - 1);
  };

  return (
    <div {...other} className="pos-relative">
      <div className="d-flex">
        <div className="container-body d-flex j-center">
          {/* Body */}
          {children.length > 0
            ? children.map((el, i) => (
                <Slide
                  className={i !== activeIn ? "d-none" : "d-flex"}
                  key={i}
                  in={i === activeIn}
                  direction={animDirection}
                  timeout={700}
                >
                  {el}
                </Slide>
              ))
            : children}

          {/* Next Button */}
          <div
            className={`d-flex j-between btns ${
              !arrowInside && "arrow-inside"
            }`}
          >
            <button className="btn prevBtn" onClick={handlePrev}>
              <ArrowBackIosNewRounded className="fs-18" />
            </button>
            <button className="btn nextBtn" onClick={handleNext}>
              <ArrowForwardIosRounded className="fs-18" />
            </button>
          </div>
        </div>
      </div>
      {/* Dots */}
      <div className="d-flex j-center">
        <div className={`${arrowInside ? "dot-inside" : "mt-40"}`}>
          <div className="d-flex">
            {Array.from({ length: children.length }).map((item, i) => (
              <div key={i}>
                <div
                  onClick={() => handleDot(i)}
                  className={`dot ${i === activeIn && "active"}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
