import { ClearRounded, EditRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { controller, endpoints } from "../../../api/actions/api";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import AddEditNote from "./AddEditNote";
import * as actions from "../../../api/actions/actions";

const VendorNote = (props) => {
  const { tblShadow = true, vendor, _getById, _delete } = props;
  const [notes, setNotes] = useState([]);
  const [updData, setUpdData] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [showForm, setShowForm] = useState(false);

  const handleAddEdit = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  useEffect(() => {
    if (vendor.id) {
      _getById(
        endpoints.VendorNote + controller.GetAllByVendor,
        vendor.id,
        null,
        (res) => setNotes([...res.result])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  const handleDlt = (id) => {
    if (id) {
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "Are you sure!",
        subTitle: "You want to delete this note?",
        onConfirm: () => {
          const onComplete = (res) => {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setNotes((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else {
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
            }
          };
          _delete(endpoints.VendorNote, id, null, onComplete);
        },
      });
    }
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="Edit Note">
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete Note">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAddEdit()}>
        Add Note
      </Button>
    </div>
  );
  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        shadow={tblShadow}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "note", label: "Note" },
          { id: "createDate", label: "Added Date" },
        ]}
        tblData={notes}
      />

      {/* Note Form */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditNote
            {...{ setShowForm, alert, setAlert, vendor, setNotes, updData }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(VendorNote);
