import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputMultFile,
  Select,
} from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import { NestedFormData, RmvEmptyProp } from "../../../hooks/Method";

const AddEditReq = (props) => {
  const {
    _getById,
    rcdType,
    setList,
    unit,
    _post,
    _put,
    _hoa,
    _authUser,
    setShowForm,
    setAlert,
    updData,
  } = props;
  const { ReqMdl } = Model();
  const { ReqType, VioType } = DropLi();
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    handleInpDate,
    resetFormControls,
  } = UseForm(ReqMdl);

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200 &&
        setUnits([
          ...res.result.map((j) => {
            var defOwner = j.unitOwners[0] && j.unitOwners[0].owner;
            return {
              id: j.id,
              label: `${j.title} ${defOwner ? `(${defOwner.fullName})` : ""} `,
            };
          }),
        ]);
    };
    _hoa &&
      _getById(endpoints.Unit + controller.GetByHoa, _hoa.id, null, onComplete);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    // Set Update Value
    if (units.length > 0) {
      if (updData) {
        setValues((x) => ({ ...x, ...updData, files: [] }));
        setFiles([...updData.reqFiles]);
      }
      unit && setValues((x) => ({ ...x, unitId: unit.id }));
    }
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData, units, unit]);

  const validateForm = () => {
    let temp = {};
    temp.type = values.type !== "" ? "" : "Request type is required.";
    temp.unitId = values.unitId !== "" ? "" : "Unit is required.";
    temp.title = values.title !== "" ? "" : "Title is required.";
    temp.msg = values.msg !== "" ? "" : "Message number is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          updData
            ? setList((x) => [
                ...x.map((j) => (j.id === res.result.id ? res.result : j)),
              ])
            : setList((x) => [res.result, ...x]);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `${updData ? "Updated" : "Added"} Successfully.`,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        values.createUserId = _authUser.id;
        values.rcdType = rcdType;
        _post(
          endpoints.Request + controller.Post,
          NestedFormData(RmvEmptyProp(values), "files"),
          null,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.Request + controller.Put,
          NestedFormData(RmvEmptyProp(values), "files"),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate className="p-15">
      <Grid container spacing={3}>
        <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
          <h2 className="h fw-5 mb-5 tx-upp">
            {updData ? "Update" : "Create a"}{" "}
            {rcdType === 1 ? "Request" : "Violation"}
          </h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-60" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputAuto
            label="Unit"
            name="unitId"
            value={values.unitId}
            error={errors.unitId}
            disabled={unit ? true : false}
            onChange={handleInpChg}
            options={units}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            label={`${rcdType === 1 ? "Request" : "Violation"} Type`}
            name="type"
            value={values.type}
            error={errors.type}
            onChange={handleInpChg}
            options={rcdType === 1 ? ReqType : VioType}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={rcdType === 1 ? "d-none" : "d-block"}
        >
          <InputDate
            label="Resolution Deadline"
            name="resDeadline"
            value={values.resDeadline}
            error={errors.resDeadline}
            className="w-100pr"
            onChange={(date) =>
              handleInpDate("resDeadline", new Date(date).toLocaleDateString())
            }
          />
        </Grid>
        <Grid item xs={12} md={rcdType === 2 ? 6 : 12}>
          <Input
            label="Title"
            name="title"
            value={values.title}
            error={errors.title}
            inputProps={{ maxLength: 200 }}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            multiline
            rows={5}
            label="Message"
            name="msg"
            value={values.msg}
            error={errors.msg}
            onChange={handleInpChg}
            inputProps={{ maxLength: 500 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputMultFile
            label="Upload File"
            name="files"
            maxSize={6}
            src={files}
            values={values.files}
            onChange={handleFile}
            rmv={(file) => {
              setValues((x) => ({
                ...x,
                files: x.files.filter(
                  (j) => j.lastModified !== file.lastModified
                ),
                reqFiles: x.reqFiles.filter((j) => j.id !== file.id),
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="text" size="large" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button size="large" type="submit" loading={loading} fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditReq);
