import {
  AccountBoxRounded,
  CorporateFareRounded,
  HomeWorkRounded,
  StarRounded,
} from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import { controller, endpoints } from "../../api/actions/api";

const Statusbar = (props) => {
  const { _getAll, rating = "" } = props;
  const [units, setUnits] = useState();
  const [owners, setOwners] = useState();
  const [org, setOrg] = useState();

  useEffect(() => {
    _getAll(endpoints.Unit + controller.Total, null, (res) =>
      setUnits(res.result)
    );
    _getAll(endpoints.Owner + controller.Total, null, (res) =>
      setOwners(res.result)
    );
    _getAll(endpoints.Org + controller.Total, null, (res) =>
      setOrg(res.result)
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3} className="pb-50">
      <Grid item xs={12} md={3}>
        <Paper className="paper p-25" data-aos="fade-up">
          <div className="d-flex a-end mb-10">
            <StarRounded className="fs-35 warning-color mr-15" />
            <Typography variant="h5" className="dark-color fw-5 fs-26">
              {rating} / 5
            </Typography>
          </div>
          <span className="tx-sm">Customer recommended</span>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper className="paper p-25" data-aos="fade-up">
          <div className="d-flex a-end mb-10">
            <HomeWorkRounded className="fs-35 primary-color mr-15" />
            <Typography variant="h5" className="dark-color fw-5 fs-26">
              {org}
            </Typography>
          </div>
          <span className="tx-sm">HOAs have chosen Strata-Management</span>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper className="paper p-25" data-aos="fade-up">
          <div className="d-flex a-end mb-10">
            <CorporateFareRounded className="fs-35 primary-color mr-15" />
            <Typography variant="h5" className="dark-color fw-5 fs-26">
              {units}
            </Typography>
          </div>
          <span className="tx-sm">Units Managed</span>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper className="paper p-25" data-aos="fade-up">
          <div className="d-flex a-end mb-10">
            <AccountBoxRounded className="fs-35 primary-color mr-15" />
            <Typography variant="h5" className="dark-color fw-5 fs-26">
              {owners}
            </Typography>
          </div>
          <span className="tx-sm">Unit Owners</span>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _getAll: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Statusbar);
