import {
  ClearRounded,
  EditRounded,
  LandscapeRounded,
  PaidRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import AddEditPay from "../Transactions/Payable/AddEditPay";

const Vendors = (props) => {
  const { _getById, _set, _defHoa, _delete } = props;
  const navigate = useNavigate();
  const [vendor, setVendor] = useState();
  const [payVendor, setPayVendor] = useState(false);
  const [vendors, setVendors] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_defHoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setVendors(res.result);
      };
      _getById(
        endpoints.Vendor + controller.GetByHoa,
        _defHoa.id,
        actions.ActionType.List,
        onComplete
      );
    }
    return () => {
      _set();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_defHoa]);

  const handleAddEdit = (id) => {
    navigate(`vendor_form/${id || ""}`);
  };
  const handleView = (id) => {
    navigate(`view_vendor/${id}`);
  };
  const handlePay = (data) => {
    setVendor(data);
    setPayVendor(true);
  };

  const handleDlt = (id) => {
    if (id) {
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "Are you sure!",
        subTitle: "You want to delete this vendor?",
        onConfirm: () => {
          const onComplete = (res) => {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setVendors((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else {
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
            }
          };
          _delete(endpoints.Vendor, id, null, onComplete);
        },
      });
    }
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Details">
        <IconButton
          onClick={() => handleView(props.id)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Pay Vendor">
        <IconButton
          onClick={() => handlePay(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <PaidRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>

      <Tooltip arrow title="Edit">
        <IconButton
          onClick={() => handleAddEdit(props.id)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <Box sx={{ mb: { xs: "10px", md: "0px" } }}>
      <Button
        variant="outlined"
        onClick={() => handleAddEdit(null)}
        className="mr-10"
      >
        Add Vendor
      </Button>
    </Box>
  );
  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "logo", label: "Logo" },
          { id: "name", label: "Vendor Name" },
          { id: "accNumber", label: "Account Number" },
          { id: "vendorEin", label: "Vendor EIN" },
          { id: "phone", label: "Phone Number" },
        ]}
        tblData={vendors.map((obj) => {
          return {
            ...obj,
            logo: (
              <Avatar
                src={obj.logo}
                alt="logo"
                className="bg-primary"
                sx={{ width: 36, height: 36 }}
              >
                {!obj.logo && <LandscapeRounded />}
              </Avatar>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Add Edit Form */}
      {payVendor && (
        <Dialog show={payVendor} onClose={false} maxWidth="md">
          <AddEditPay {...{ setShowForm: setPayVendor, setAlert, vendor }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _set: actions.set,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Vendors);
