import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { useEffect } from "react";
import { controller, endpoints } from "../../../../api/actions/api";
import { Table } from "../../../../layout";

const CreditDebit = (props) => {
  const { unitId, _getById } = props;
  const [credits, setCredits] = useState([]);

  useEffect(() => {
    unitId &&
      _getById(
        endpoints.Unit + controller.GetCredits,
        unitId,
        null,
        (res) => res.status === 200 && setCredits(res.result)
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={() => (
          <>
            <span className="gray-color ">
              Total:&nbsp;&nbsp;&nbsp; $&nbsp;
              {credits
                .map((j) => j.balance)
                .reduce((x, y) => x + y, 0)
                .toLocaleString()}
            </span>
          </>
        )}
        tblHead={[
          { id: "bal", label: "Balance ($)" },
          { id: "type", label: "Type" },
          { id: "invName", label: "Invoice" },
          { id: "addDate", label: "Date" },
          { id: "note", label: "Note" },
        ]}
        tblData={credits.map((j) => {
          return {
            ...j,
            txnType:
              j.txnType === 1
                ? "Cash In"
                : j.txnType === 2
                ? "Cash Out"
                : "Early Payment Discount",
            bal: (
              <span className={j.balance > 0 ? "gray-color" : "error-color"}>
                {j.balance}
              </span>
            ),
            type:
              j.txnType === 1 ? (
                <span className="bg-successXS success-color status">
                  Cash In
                </span>
              ) : j.txnType === 2 ? (
                <span className="bg-errorSM error-color status">Cash Out</span>
              ) : (
                <span className="bg-successXS success-color status">
                  Early Payment Discount
                </span>
              ),
            invName: j.inv && j.inv.title,
          };
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(CreditDebit);
