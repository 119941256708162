import { Button, Divider, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { InputDate, Select } from "../../../controls";
import { subDays } from "date-fns";
import { DropLi } from "../../../hooks";
import moment from "moment";

const FilterBudget = (props) => {
  const { isBuildBudget, setSortDate, sortDate, bdgType, setBdgType } = props;
  const { CatType } = DropLi();
  const navigate = useNavigate();

  const handleInpChange = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  const handleCat = () => {
    navigate("/my_account/categories");
  };

  const handleBuildBudget = () => {
    navigate("/my_account/budgets/build_budget");
  };

  const handleBdg = (e) => {
    setBdgType(e.target.value);
  };

  return (
    <Paper>
      <div className="d-flex j-between flex-wrap p-15 a-center">
        <Box sx={{ mb: { md: "0px", xs: "15px" } }}>
          <h2 className="border-left h fw-5 fs-20 gray-color tx-upp">
            Budgets
          </h2>
        </Box>
        <div>
          {!isBuildBudget && (
            <Button
              onClick={handleBuildBudget}
              variant="outlined"
              className="mr-10"
            >
              Build Budgets
            </Button>
          )}
          <Button onClick={handleCat} variant="outlined">
            Manage Categories
          </Button>
        </div>
      </div>
      <Divider />
      <div className="d-flex a-center flex-wrap">
        <div className="p-15 d-flex a-center ">
          <InputDate
            variant="outlined"
            sx={{ maxWidth: "150px" }}
            size="small"
            name="fromDate"
            value={sortDate.fromDate}
            views={["day", "month", "year"]}
            openTo="day"
            minDate={new Date("01/01/2000")}
            maxDate={subDays(new Date(sortDate.toDate), 1)}
            onChange={(date) =>
              handleInpChange("fromDate", new Date(date).toLocaleDateString())
            }
          />
          <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
          <InputDate
            variant="outlined"
            sx={{ maxWidth: "150px" }}
            size="small"
            name="toDate"
            value={sortDate.toDate}
            minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
            views={["day", "month", "year"]}
            openTo="day"
            onChange={(date) =>
              handleInpChange("toDate", new Date(date).toLocaleDateString())
            }
          />
        </div>
        {isBuildBudget && (
          <Box
            sx={{
              ml: { xs: "0px", md: "15px" },
              padding: { xs: "15px ", md: "0px" },
            }}
          >
            <Select
              label="Budget Type"
              name="unitId"
              options={CatType}
              value={bdgType}
              onChange={handleBdg}
              size="small"
              variant="outlined"
              sx={{ width: "250px" }}
            />
          </Box>
        )}
      </div>
    </Paper>
  );
};

export default FilterBudget;
