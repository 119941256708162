import {
  AttachFileRounded,
  DeleteForeverRounded,
  DownloadRounded,
  DriveFileRenameOutlineRounded,
  InsertDriveFileRounded,
  SearchRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import {
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { Button, Input } from "../../../controls";
import { Alert, Dialog, MenuPopup } from "../../../layout";
import Rename from "./Rename";
import UplFileForm from "./UplFileForm";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useEffect } from "react";
import { controller, endpoints } from "../../../api/actions/api";

const Doc = (props) => {
  const { vendor, unit, _getByObj, _delete, _hoa } = props;
  const [actionEl, setActionEl] = useState(null);
  const [showRename, setShowRename] = useState(false);
  const [showUplFlie, setShowUplFile] = useState(false);
  const [updData, setUpdData] = useState();
  const [filteredDoc, setFilteredDoc] = useState([]);
  const [selVal, setSelVal] = useState();
  const [docs, setDocs] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    docs && setFilteredDoc([...docs]);
  }, [docs]);

  useEffect(() => {
    var param = vendor
      ? { id: vendor.id, value: "vendor" }
      : unit
      ? { id: unit.id, value: "unit" }
      : { id: _hoa.id, value: "global" };

    param &&
      _getByObj(
        endpoints.Document + controller.GetAllById,
        param,
        null,
        (res) => setDocs([...res.result])
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor, unit, _hoa]);

  useEffect(() => {
    !actionEl && setSelVal();
  }, [actionEl]);

  const handleAction = (e, val) => {
    setSelVal(val);
    setActionEl(e.currentTarget);
  };

  const handleViewLink = () => {
    window.open(selVal.fileSrc, "_blank", "noopener,noreferrer");
  };

  const handleShowRename = () => {
    setUpdData(selVal);
    setShowRename(true);
  };
  const handleUplFile = () => {
    setShowUplFile(true);
  };

  const handleDlt = () => {
    if (selVal && selVal.id) {
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "Are you sure!",
        subTitle: "You want to delete this doc?",
        onConfirm: () => {
          const onComplete = (res) => {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setDocs((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else {
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
            }
          };
          _delete(endpoints.Document, selVal.id, null, onComplete);
        },
      });
    }
  };

  const handleSerach = (e) => {
    setFilteredDoc([
      ...docs.filter((j) =>
        Object.keys(j).some((x) =>
          String(j[x]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      ),
    ]);
  };

  const Action = () => (
    <MenuPopup anchorEl={actionEl} setAnchorEl={setActionEl} id="action-menu">
      <MenuItem onClick={handleViewLink}>
        <ListItemIcon>
          <VisibilityRounded fontSize="small" />
        </ListItemIcon>
        View File
      </MenuItem>
      <MenuItem onClick={handleShowRename}>
        <ListItemIcon>
          <DriveFileRenameOutlineRounded fontSize="small" />
        </ListItemIcon>
        Rename
      </MenuItem>
      <MenuItem>
        <a
          href={selVal && selVal.fileSrc}
          download
          className="d-flex a-center tx-und-none dark-color"
        >
          <ListItemIcon>
            <DownloadRounded fontSize="small" />
          </ListItemIcon>
          Download
        </a>
      </MenuItem>
      <MenuItem onClick={handleDlt}>
        <ListItemIcon>
          <DeleteForeverRounded fontSize="small" />
        </ListItemIcon>
        Delete
      </MenuItem>
    </MenuPopup>
  );

  return (
    <Paper>
      <div className="p-10 d-flex j-between flex-wrap">
        <Input
          sx={{
            width: { xs: "100%", lg: "280px" },
            mb: { xs: "10px", lg: "0px" },
          }}
          size="small"
          placeholder="Search"
          variant="outlined"
          onChange={handleSerach}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={handleUplFile}
          variant="outlined"
          size="large"
          sx={{ width: { xs: "100%", lg: "180px" } }}
          startIcon={<AttachFileRounded />}
        >
          Upload Files
        </Button>
      </div>
      <Divider />
      <div className="p-10 mt-20">
        <List sx={{ maxHeight: "500px", overflow: "auto" }}>
          {filteredDoc.map((item, i) => (
            <ListItem key={i} disablePadding className="hove-primary">
              <ListItemButton
                className="br-9"
                onClick={(e) => handleAction(e, item)}
                aria-controls="action-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <InsertDriveFileRounded />
                </ListItemIcon>
                <ListItemText
                  primary={item.fileName}
                  secondary={`Date: ${item.createDate} || File Type: ${
                    item.fileType === 1
                      ? "Vendor"
                      : item.fileType === 2
                      ? "Unit"
                      : "Global"
                  }`}
                />
              </ListItemButton>
            </ListItem>
          ))}

          {/* Action  */}
          <Action />
          {/* Alert  */}
          <Alert alert={alert} setAlert={setAlert} />
          {/* Rename Window */}
          <Dialog show={showRename} onClose={false} maxWidth="md">
            <Rename
              {...{
                setShowForm: setShowRename,
                setDocs,
                setAlert,
                updData,
              }}
            />
          </Dialog>

          {/* Upload File Form */}
          {showUplFlie && (
            <Dialog show={showUplFlie} onClose={false} maxWidth="md">
              <UplFileForm
                {...{
                  setShowForm: setShowUplFile,
                  setAlert,
                  alert,
                  setDocs,
                  vendorId: vendor && vendor.id,
                  unitId: unit && unit.id,
                  makePrivate: false,
                }}
              />
            </Dialog>
          )}
        </List>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Doc);
