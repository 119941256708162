import { AddRounded, SearchRounded } from "@mui/icons-material";
import { Box, InputAdornment, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "../../../controls";
import { CSVLink } from "react-csv";
import { DropLi } from "../../../hooks";
import { Dialog } from "../../../layout";
import MultUnitUpl from "./MultUnitUpl";

const UnitHeader = (props) => {
  const {
    setFilterUnits,
    filterUnits,
    units,
    _authUser,
    _hoa,
    setAlert,
    alert,
  } = props;
  const [search, setSearch] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [showCsvUpl, setShowCsvUpl] = useState(false);
  const navigate = useNavigate();
  const { Countries } = DropLi();

  useEffect(() => {
    setCsvHeader([
      { key: "id", label: "Id" },
      { key: "title", label: "Title" },
      { key: "owner", label: "Owner" },
      { key: "city", label: "City" },
      { key: "state", label: "State" },
      { key: "zip", label: "ZIP" },
      { key: "address1", label: "Address 1" },
      { key: "address2", label: "Address 2" },
      { key: "country", label: "Country" },
      { key: "createDate", label: "Create Date" },
      { key: "updateDate", label: "Update Date" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    setCsvData([
      ...units.map((j) => {
        var contactOwner = j.unitOwners.find((x) => x.isContactOwner);
        var country = Countries.find((x) => x.id === j.country);
        return {
          ...j,
          owner: contactOwner && contactOwner.owner.fullName,
          country: country && country.name,
        };
      }),
    ]);
    return () => {
      setCsvData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  const hanldeSearch = (e) => {
    setSearch(e.target.value);
    setFilterUnits([
      ...units.filter((j) =>
        Object.keys(j).some((x) =>
          String(j[x]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      ),
    ]);
  };

  const handleAddUnit = () => {
    navigate("unit_form");
  };

  const handleImportCsv = () => {
    _hoa.country || _hoa.city || _hoa.address1
      ? setShowCsvUpl(true)
      : setAlert({
          isOpen: true,
          type: "warning",
          title: "Missing Addresses",
          subTitle: "Please first fulfill HOA's addresses.",
        });
  };

  return (
    <>
      <Paper className="border-none mt-15">
        <div className="d-flex j-between flex-wrap a-center">
          <Box
            sx={{
              mb: { xs: "10px", md: "0px" },
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Input
              placeholder="Search"
              name="search"
              variant="outlined"
              size="small"
              value={search}
              onChange={hanldeSearch}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
            <div className="mt-10">
              <span className="fs-14 tx-upp gray-color">
                Total Unit: {filterUnits.length}
              </span>
            </div>
          </Box>
          {_authUser && _authUser.role !== 1 && (
            <Box
              className="d-flex a-center g-10"
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button variant="text" onClick={handleImportCsv}>
                Import Excel
              </Button>
              <Box
                className="d-flex"
                sx={{
                  width: { xs: "100%", md: "auto" },
                }}
              >
                <CSVLink
                  data={csvData}
                  headers={csvHeader}
                  filename={`Unit (${new Date().toDateString()}).csv`}
                  target="_blank"
                  className="w-100pr tx-upp tx-und-none primary-color bg-primarySM p-10 fs-14 br-7 tx-center"
                >
                  Export CSV
                </CSVLink>
              </Box>

              <Button
                variant="outlined"
                onClick={handleAddUnit}
                startIcon={<AddRounded />}
                sx={{ width: { xs: "100%", md: "auto" } }}
              >
                Add Units
              </Button>
            </Box>
          )}
        </div>
      </Paper>

      {/* Upload File Form */}
      {showCsvUpl && (
        <Dialog show={showCsvUpl} onClose={false} maxWidth="md">
          <MultUnitUpl
            {...{
              setShowForm: setShowCsvUpl,
              setAlert,
              alert,
              setFilterUnits,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default UnitHeader;
