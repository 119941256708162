import { Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import * as actions from "../../api/actions/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import { endpoints } from "../../api/actions/api";
import { useState } from "react";
import { no_img } from "../../assets";
import { Link } from "react-router-dom";

const About = (props) => {
  const { _get } = props;
  const [values, setValues] = useState({});

  useEffect(() => {
    _get(endpoints.Feature, null, (res) => {
      res.result && setValues((j) => ({ ...j, ...res.result }));
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl" className="pt-90 pb-90">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={5}>
          <div data-aos="fade-up-right">
            <h2 className="h h2 border-left tx-upp fw-5">{values.title}</h2>
            <p className="p">{values.subtitle}</p>
          </div>
          <div className="tx-center mt-50" data-aos="fade-up-right">
            <img
              className="img-fluid"
              style={{ width: "80%" }}
              src={values.img || no_img}
              alt="app-featurs"
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Grid container spacing={3}>
            {values.featureItems &&
              values.featureItems.map((item, i) => (
                <Grid item xs={12} md={6} key={i}>
                  <Link
                    to={item.link}
                    className="tx-und-none"
                    rel="noreferrer"
                    target={item.link && "_blank"}
                  >
                    <div data-aos="fade-up-left">
                      <Paper
                        className="p-20 d-flex a-center border-right"
                        elevation={2}
                      >
                        <img
                          alt="feature"
                          className="img-fluid mr-15 "
                          width="80px"
                          src={item.img || no_img}
                        />
                        <div>
                          <Typography
                            variant="h5"
                            className="fw-5 dark-color fs-22 mb-10"
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className="fw-4 gray-color"
                          >
                            {item.subtitle}
                          </Typography>
                        </div>
                      </Paper>
                    </div>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(About);
