import { ArrowBackRounded } from "@mui/icons-material";
import { Grid, IconButton, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { no_img } from "../../../assets";

const OrgDtlComp = ({ org }) => {
  const navigate = useNavigate();
  return (
    <Paper className="p-10">
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-20">
          <div className="d-flex g-10 a-center ">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackRounded />
            </IconButton>

            <span className="fs-20 gray-color fw-5 tx-upp">
              Organization Details
            </span>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <img
            src={org.logo || no_img}
            alt="Logo"
            className="h-40 w-auto obj-contain"
          />
        </Grid>
        <Grid item xs={12} md={4} className="d-grid g-5">
          <span className="tx-xs tx-upp mb-5 gray-color">
            Organization Name:
          </span>
          <span className="fs-20">{org.orgName}</span>
          <span className="gray-color fs-12">
            Since: {new Date(org.createDate).toDateString()}
          </span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid g-5">
          <span className="tx-xs tx-upp mb-5">Contact:</span>
          <a className="link fs-14" href={`mailto:${org.email}`}>
            {org.email}
          </a>
          <a
            className="dark-color hove-primary tx-und-none fs-14"
            href={`tel:${org.phone}`}
          >
            {org.phone}
          </a>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrgDtlComp;
