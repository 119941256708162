import { Box } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { useEffect } from "react";

const RevForm = (props) => {
  const { setShowForm, _put, setAlert, paymentDtl, setPays } = props;
  const { PaymentMdl } = Model();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(PaymentMdl);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let temp = {};
    temp.revNote = values.revNote ? "" : "Reverse note is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    paymentDtl && setValues({ ...paymentDtl });
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDtl]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200) {
          if (setPays && res.result.id)
            setPays((x) => [
              ...x.map((j) => (j.id === res.result.id ? res.result : j)),
            ]);
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
        } else {
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "error",
            title: "Something is Wrong",
            subTitle: res.msg,
          });
        }
      };
      _put(
        endpoints.Payment + controller.RevPayment,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box className="p-15" sx={{ minWidth: { xs: "280px", md: "380px" } }}>
        <Form onSubmit={handleSubmit} noValidate>
          <div className="tx-center mb-30">
            <h2 className="h fw-5 mb-5 fs-20 tx-upp gray-color">
              Reverse Payment
            </h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-40" />
            </div>
          </div>
          <div className="mb-15">
            <Input
              label="Reverse Note"
              name="revNote"
              value={values.revNote}
              error={errors.revNote}
              inputProps={{ maxLength: 500 }}
              multiline
              rows={3}
              onChange={handleInpChg}
              fullWidth
            />
          </div>

          <Box className="d-flex">
            <Button
              onClick={handleClose}
              fullWidth
              className="mr-10"
              variant="text"
            >
              Close
            </Button>
            <Button fullWidth type="submit" loading={loading}>
              Submit
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(RevForm);
