import {
  CancelRounded,
  CheckCircleRounded,
  ClearRounded,
  EditRounded,
} from "@mui/icons-material";
import { Avatar, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../layout";
import { Button } from "../../../controls";

const SubsPkgs = (props) => {
  const { _getAll, _delete, _admin } = props;
  const [pkgs, setPkgs] = useState([]);
  const { setLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setPkgs([...res.result]);
    };
    _getAll(endpoints.SubsPkg, undefined, onComplete);
    return () => {
      setPkgs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEdit = (id) => {
    navigate(`subs_pkg_form/${id || ""}`);
  };

  const handleDlt = (id) => {
    if (id) {
      if (id === _admin.id)
        setAlert({
          isOpen: true,
          type: "warning",
          title: "Warning",
          subTitle:
            "You can't delete this user. Because this is the logged in user right now.",
        });
      else
        setAlert({
          isOpen: true,
          type: "confirmation",
          title: "Are you sure!",
          subTitle: "You want to delete this package?",
          onConfirm: () => {
            const onComplete = (res) => {
              if (res.status === 200) {
                setAlert({
                  isOpen: true,
                  type: "success",
                  title: "Deleted Successfully",
                  subTitle: res.msg,
                });
                setPkgs((x) => [...x.filter((j) => j.id !== res.result.id)]);
              } else {
                setAlert({
                  isOpen: true,
                  type: "warning",
                  title: "Warning",
                  subTitle: res.msg,
                });
              }
            };
            _delete(endpoints.SubsPkg, id, null, onComplete);
          },
        });
    }
  };

  return (
    <>
      <div className="mb-20">
        <Button
          variant="text"
          onClick={() => handleAddEdit(null)}
          className="mr-10"
          size="large"
        >
          Add Package
        </Button>
      </div>

      <Grid container spacing={4}>
        {pkgs.map((pkg, i) => (
          <Grid key={i} item xs={12} md={6} lg={4}>
            <Paper>
              {/* Titlte */}
              <div className="p-30 d-grid g-15">
                <div className="tx-center">
                  <h1 className="h fs-26 fw-5 tx-upp mb-10">{pkg.title}</h1>
                  <div className="d-flex j-center">
                    <div className="border-bottom w-40" />
                  </div>
                </div>
                <span className="p">{pkg.note}</span>
              </div>

              {/* Price  */}
              <div className="d-flex j-between mb-40 mt-30">
                <Avatar
                  sx={{ width: 56, height: 56, borderRadius: "15px" }}
                  className="ml-20"
                  src={pkg.photo}
                  alt="subscription"
                />
                <div
                  className="d-flex j-center"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    boxShadow: "var(--shadow-primary)",
                    minWidth: "50%",
                    padding: "10px",
                    borderRadius: "30px 0px 0px 30px",
                  }}
                >
                  <span className="fw-5 white-color mr-5">$</span>
                  <div className="d-flex a-end white-color">
                    <span style={{ lineHeight: 0.9 }} className="fs-40">
                      {pkg.price}
                    </span>
                    <span className="fw-3"> / month</span>
                  </div>
                </div>
              </div>
              <div className="p-30">
                <div className="d-flex a-center mb-15">
                  {!pkg.unlimitedBranch && pkg.branch === 0 ? (
                    <CancelRounded className="mr-15 error-color" />
                  ) : (
                    <CheckCircleRounded className="mr-15 success-color" />
                  )}
                  <span className="gray-color">
                    <span className="fs-16">Number of Branch:&nbsp;&nbsp;</span>
                    <span className="fw-5 fs-18">
                      {pkg.unlimitedBranch ? "Unlimited" : pkg.branch}
                    </span>
                  </span>
                </div>
                <div className="d-flex a-center mb-15">
                  {!pkg.unlimitedUnit && pkg.unit === 0 ? (
                    <CancelRounded className="mr-15 error-color" />
                  ) : (
                    <CheckCircleRounded className="mr-15 success-color" />
                  )}
                  <span className="gray-color">
                    <span className="fs-16">Number of Unit:&nbsp;&nbsp;</span>
                    <span className="fw-5 fs-18">
                      {pkg.unlimitedUnit ? "Unlimited" : pkg.unit}
                    </span>
                  </span>
                </div>
                <div className="d-flex a-center mb-15">
                  {!pkg.unlimitedOwner && pkg.owner === 0 ? (
                    <CancelRounded className="mr-15 error-color" />
                  ) : (
                    <CheckCircleRounded className="mr-15 success-color" />
                  )}
                  <span className="gray-color">
                    <span className="fs-16">Number of Owner:&nbsp;&nbsp;</span>
                    <span className="fw-5 fs-18">
                      {pkg.unlimitedOwner ? "Unlimited" : pkg.owner}
                    </span>
                  </span>
                </div>
                <div className="d-flex a-center mb-15">
                  {!pkg.unlimitedVendor && pkg.vendor === 0 ? (
                    <CancelRounded className="mr-15 error-color" />
                  ) : (
                    <CheckCircleRounded className="mr-15 success-color" />
                  )}
                  <span className="gray-color">
                    <span className="fs-16">Number of Vendor:&nbsp;&nbsp;</span>
                    <span className="fw-5 fs-18">
                      {pkg.unlimitedVendor ? "Unlimited" : pkg.vendor}
                    </span>
                  </span>
                </div>
                <div className="d-flex a-center mb-15">
                  {!pkg.unlimitedUser && pkg.user === 0 ? (
                    <CancelRounded className="mr-15 error-color" />
                  ) : (
                    <CheckCircleRounded className="mr-15 success-color" />
                  )}
                  <span className="gray-color">
                    <span className="fs-16">Number of User:&nbsp;&nbsp;</span>
                    <span className="fw-5 fs-18">
                      {pkg.unlimitedUser ? "Unlimited" : pkg.user}
                    </span>
                  </span>
                </div>

                <div className="tx-center mt-50 d-flex g-15 j-center">
                  <Tooltip arrow title="Edit">
                    <IconButton
                      onClick={() => handleAddEdit(pkg.id)}
                      size="large"
                      color="primary"
                    >
                      <EditRounded />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Delete">
                    <IconButton
                      onClick={() => handleDlt(pkg.id)}
                      size="large"
                      color="error"
                    >
                      <ClearRounded />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _getAll: actions.get,
  _set: actions.set,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(SubsPkgs);
