import React, { useContext, useEffect, useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { AppContext } from "../../../App";
import RepNav from "./RepNav";
import { Table } from "../../../layout";
import moment from "moment";
import { TableCell } from "@mui/material";
import { DropLi } from "../../../hooks";

const tblHeader = () => (
  <div className="d-flex a-center flex-wrap">
    <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
      Receipt Details
    </h2>
  </div>
);

const ExpByCat = (props) => {
  const { _getById, _hoa } = props;
  const { setLoader } = useContext(AppContext);
  const { PayMethods } = DropLi();
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [payments, setPayments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });

  useEffect(() => {
    setCsvHeader([
      { key: "payDate", label: "Payment Date" },
      { key: "title", label: "Charge Title" },
      { key: "catName", label: "Category" },
      { key: "method", label: "Payment Method" },
      { key: "unitName", label: "Unit" },
      { key: "payor", label: "Payor" },
      { key: "amt", label: "Payment Amount" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    setTblData([
      ...filteredData.map((j) => {
        var payType = PayMethods.find((x) => x.id === j.method);
        return {
          ...j,
          title: j.inv && j.inv.title,
          catName: j.inv.category && j.inv.category.name,
          bankName: j.inv.bankAcc && j.inv.bankAcc.accName,
          method: payType && payType.label,
          unitName: j.unit && j.unit.title,
          payor: j.owner ? j.owner.fullName : "All Owner",
          amt: `$ ${j.amount.toLocaleString()}`,
        };
      }),
    ]);
    return () => {
      setTblData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useEffect(() => {
    if (_hoa.id) {
      _getById(
        endpoints.Payment + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 && setPayments([...res.result]);
          setLoader(false);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    payments &&
      setFilteredData([
        ...payments.filter(
          (j) =>
            moment(j.payDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.payDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY") &&
            j
        ),
      ]);

    return () => {
      setFilteredData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments, sortDate]);

  return (
    <>
      <div className="mt-20 mb-10">
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "Receipt Details",
            showTitle: false,
          }}
        />
      </div>
      <div>
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={tblHeader}
          tblHead={[
            { id: "payDate", label: "Payment Date" },
            { id: "title", label: "Charge Title" },
            { id: "catName", label: "Category" },
            { id: "bankName", label: "Bank Account" },
            { id: "method", label: "Payment Method" },
            { id: "unitName", label: "Unit" },
            { id: "payor", label: "Payor" },
            { id: "amt", label: "Payment Amount" },
          ]}
          tblData={tblData}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-16">
                Total Payments
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className="gray-color fw-6 fs-16">
                $&nbsp;
                {tblData
                  .map((j) => j.amount)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(ExpByCat);
