import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const RevChart = (props) => {
  const { catInvs } = props;
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (catInvs) {
      setSeries([
        {
          name: "Amount",
          data: catInvs.map((j) =>
            j.invs
              .map((j) => j.inv && j.inv.invPayments)
              .flat()
              .map((j) => j.paidAmt)
              .reduce((x, y) => x + y, 0)
          ),
        },
      ]);
    }
    return () => {
      setSeries();
    };
  }, [catInvs]);

  return (
    <ReactApexChart
      options={{
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: catInvs.map((j) => j.catName),
        },
        yaxis: {
          title: {
            style: {
              color: "var(--gray-color)",
              fontFamily: "roboto",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
      }}
      series={series}
      type="bar"
      width="100%"
      height="290"
    />
  );
};

export default RevChart;
