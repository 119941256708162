import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../controls";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { controller, endpoints } from "../../../api/actions/api";
import { ToFormData } from "../../../hooks/Method";
import { useNavigate } from "react-router-dom";

const Rename = (props) => {
  const { setShowForm, updData, _post, setDocs, setAlert } = props;
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    updData && setValue(updData.fileName);
  }, [updData]);

  const handleInpChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          if (setDocs && res.result.id) {
            setDocs((x) => [
              ...x.map((j) => (j.id === res.result.id ? res.result : j)),
            ]);
          }
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Renamed Successfully.`,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Something is Wrong",
            subTitle: "Please refresh the page and try again.",
            onClick: () => navigate("/my_account/vendors/"),
          });
      };

      _post(
        endpoints.Document + controller.RenameDoc,
        ToFormData({
          id: updData.id,
          value: value,
        }),
        null,
        onComplete
      );
    } else setError("This field is required");
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Box className="p-15" sx={{ minWidth: { xs: "280px", md: "380px" } }}>
      <div className="tx-center mb-30">
        <h2 className="h fw-5 mb-5 fs-20 tx-upp gray-color">Rename</h2>
        <div className="pos-relative d-flex j-center">
          <div className="border-bottom w-40" />
        </div>
      </div>
      <div className="mb-15">
        <Input
          label="Rename"
          name="rename"
          value={value}
          error={error}
          onChange={handleInpChange}
          fullWidth
        />
      </div>

      <Box className="d-flex">
        <Button
          onClick={handleClose}
          fullWidth
          className="mr-10"
          variant="text"
        >
          Close
        </Button>
        <Button fullWidth loading={loading} onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(Rename);
