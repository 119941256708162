import { VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { no_img } from "../../../assets";
import { Select } from "../../../controls";
import { DropLi } from "../../../hooks";
import { PkgStatusComp } from "../Subs/Subs";

const Orgs = (props) => {
  const { _getAll, today = false, title = "Organizations" } = props;
  const { OrgPayStatus, SubsStatus } = DropLi();
  const [orgs, setOrgs] = useState([]);
  const naviage = useNavigate();
  const { setLoader } = useContext(AppContext);
  const [subInvs, setSubInvs] = useState([]);
  const [fltPayStatus, setFltPayStatus] = useState(1);
  const [fltOrgStatus, setFltOrgStatus] = useState(0);
  const [fltOrgs, setFltOrgs] = useState([]);
  const [subs, setSubs] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setOrgs([...res.result]);
    };
    today
      ? _getAll(
          endpoints.Org + controller.GetTodayRegOrg,
          undefined,
          onComplete
        )
      : _getAll(endpoints.Org + controller.GetAll, undefined, onComplete);

    _getAll(endpoints.SubsInv + controller.GetAll, null, (res) =>
      setSubInvs([...res.result])
    );

    _getAll(endpoints.Subs + controller.GetAll, null, (res) =>
      setSubs([...res.result])
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setOrgs([
      ...orgs.map((x) => {
        var orgSubs = subs.filter((j) => j.orgId === x.id);
        var selSub = orgSubs.find((j) => j.isActive) || subs[subs.length - 1];

        var invs = subInvs.filter((j) => j.subs && j.subs.orgId === x.id);
        var dueAmt = invs
          .filter((j) => j.status === 1)
          .map((j) => j.amount)
          .reduce((x, y) => x + y, 0);
        var paidAmt = invs
          .filter((j) => j.status === 2)
          .map((j) => j.amount)
          .reduce((x, y) => x + y, 0);

        return {
          ...x,
          invs: [...invs],
          accStatus: dueAmt ? 3 : 2,
          due: dueAmt,
          paid: paidAmt,
          sub: selSub,
        };
      }),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subInvs]);

  useEffect(() => {
    var filtaredOrgs = orgs.filter(
      (j) => j.accStatus === fltPayStatus || fltPayStatus === 1
    );
    filtaredOrgs = filtaredOrgs.filter(
      (j) => (j.sub && j.sub.subsPeriod === fltOrgStatus) || fltOrgStatus === 0
    );
    setFltOrgs([...filtaredOrgs]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fltPayStatus, fltOrgStatus, orgs]);

  const handleView = (id) => {
    naviage(`/admin_panel/view_org/${id}`);
  };

  const HeaderCom = () => (
    <div className="d-flex a-center flex-wrap w-100pr g-10">
      <div className="mr-20">
        <span className="border-left fw-4 fs-20 gray-color tx-upp">
          {title}
        </span>
      </div>

      <Select
        label="Account Status"
        size="small"
        variant="outlined"
        value={fltPayStatus}
        onChange={(e) => setFltPayStatus(e.target.value)}
        options={OrgPayStatus}
        sx={{ width: { md: "250px", xs: "100%" } }}
      />

      <Select
        label="Payment Status"
        size="small"
        variant="outlined"
        value={fltOrgStatus}
        onChange={(e) => setFltOrgStatus(e.target.value)}
        options={SubsStatus}
        sx={{ width: { md: "250px", xs: "100%" } }}
      />
    </div>
  );

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View">
        <IconButton
          onClick={() => handleView(props.id)}
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-22 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderCom}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "orgLogo", label: "Logo" },
          { id: "orgName", label: "Org. Name" },
          { id: "email", label: "Email" },
          { id: "amount", label: "Due" },
          { id: "since", label: "Since" },
        ]}
        tblData={fltOrgs.map((obj) => {
          return {
            ...obj,
            orgIdSearch: `Id ${obj.id}`,
            status: (
              <div className="d-grid g-10">
                {obj.sub && (
                  <div>
                    {!obj.sub.isActive ? (
                      <span
                        className="fs-12 fw-5 bg-grayXS white-gray br-8 tx-upp"
                        style={{ padding: "5px 10px 5px 10px" }}
                      >
                        Deactivated
                      </span>
                    ) : (
                      <div>
                        <PkgStatusComp status={obj.sub.subsPeriod} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
            orgLogo: (
              <img
                src={obj.logo || no_img}
                alt="Logo"
                className="h-35 w-auto obj-contain"
              />
            ),
            amount: (
              <div>
                <span className="fs-14">Due:</span>
                {obj.due ? (
                  <span className="error-color"> $ {obj.due}</span>
                ) : (
                  " NULL"
                )}
                &nbsp;&nbsp;|&nbsp;&nbsp;<span className="fs-14">Spend:</span>
                <span className="fw-5"> $ {obj.paid}</span>
              </div>
            ),
            since: new Date(obj.createDate).toDateString(),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _getAll: actions.get,
  _set: actions.set,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Orgs);
