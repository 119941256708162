import React, { Fragment, useEffect } from "react";
import { Alert, Form, Tabs } from "../../../layout";
import { Divider, Grid, Paper } from "@mui/material";
import { Model, UseForm } from "../../../hooks";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import {
  BGNestedObjToFromData,
  RandomCode,
  RmvEmptyProp,
} from "../../../hooks/Method";
import { Button, Input, InputImg } from "../../../controls";
import { AddRounded } from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "../../../App";

const LPForm = (props) => {
  const { _put } = props;
  const { LandingPgMdl } = Model();
  const { landingPg, setLandingPg } = useContext(AppContext);
  const { values, setValues, errors, setErrors, handleInpChg, handleFile } =
    UseForm(LandingPgMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setValues((j) => ({ ...j, ...landingPg }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPg]);

  const validateForm = () => {
    let temp = {};
    temp.siteName = values.siteName !== "" ? "" : "Site name is required.";
    temp.title = values.title !== "" ? "" : "Title is required.";
    temp.subtitle = values.subtitle !== "" ? "" : "Subtitle is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "You have successfully updated the landing page data.",
          });
          res.result && setLandingPg({ ...res.result });
        } else if (res.status === 409)
          setErrors((x) => ({ ...x, email: res.msg }));
        else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };

      values.id &&
        _put(
          endpoints.LP + controller.Put,
          BGNestedObjToFromData(RmvEmptyProp({ ...values, admin: {} })),
          null,
          onComplete
        );
    }
  };

  const addSocialMedia = () => {
    setValues((j) => ({
      ...j,
      socialMedias: [
        ...j.socialMedias,
        {
          id: 0,
          key: RandomCode(10),
          title: "",
          link: "",
          icon: "",
          iconFile: "",
        },
      ],
    }));
  };

  const handleRmvItem = (ind) => {
    setValues((j) => ({
      ...j,
      socialMedias: [...j.socialMedias.filter((item, i) => i !== ind)],
    }));
  };

  const handleMediaChg = (e, ind) => {
    const { value, name } = e.target;
    setValues((j) => ({
      ...j,
      socialMedias: [
        ...j.socialMedias.map((item, i) =>
          i === ind ? { ...item, [name]: value } : item
        ),
      ],
    }));
  };

  const handleIcon = (e, ind) => {
    const { value } = e;
    setValues((j) => ({
      ...j,
      socialMedias: [
        ...j.socialMedias.map((x, i) =>
          i === ind ? { ...x, iconFile: value } : x
        ),
      ],
    }));
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-15 pl-15 pr-15">
            <div>
              <span className="fs-22 fw-5 tx-upp border-left gray-color">
                Landing Page
              </span>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                Save Changes
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-15 pr-15 pb-15">
            <Tabs
              className="mt-30"
              header={[
                { label: "Header", icon: "" },
                { label: "Footer", icon: "" },
                { label: "Social Media", icon: "" },
              ]}
            >
              {/* Header */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Input
                    label="Site Name"
                    name="siteName"
                    value={values.siteName}
                    error={errors.siteName}
                    required={true}
                    onChange={handleInpChg}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Input
                    label="Title"
                    name="title"
                    value={values.title}
                    error={errors.title}
                    required={true}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 300 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    multiline
                    rows={3}
                    label="Subtitle"
                    name="subtitle"
                    value={values.subtitle}
                    error={errors.subtitle}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 500 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputImg
                    label="logo"
                    name="logoFile"
                    src={values.logo}
                    value={values.logoFile}
                    rmv={() => {
                      setValues({ ...values, logoFile: "", logo: "" });
                    }}
                    onChange={handleFile}
                    error={errors.logoFile}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputImg
                    label="img"
                    name="imgFile"
                    src={values.img}
                    value={values.imgFile}
                    rmv={() => {
                      setValues({ ...values, imgFile: "", img: "" });
                    }}
                    onChange={handleFile}
                    error={errors.imgFile}
                  />
                </Grid>
              </Grid>

              {/* Footer */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 200 }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    label="Phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 200 }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    label="Fax"
                    name="fax"
                    value={values.fax}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 200 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Address"
                    name="address"
                    value={values.address}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 600 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    multiline
                    rows={3}
                    label="Description"
                    name="desc"
                    value={values.desc}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 600 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputImg
                    label="Footer Logo"
                    name="footerLogoFile"
                    src={values.footerLogo}
                    value={values.footerLogoFile}
                    rmv={() => {
                      setValues({
                        ...values,
                        footerLogoFile: "",
                        footerLogo: "",
                      });
                    }}
                    onChange={handleFile}
                    error={errors.imgFile}
                  />
                </Grid>
              </Grid>

              {/* Social Media */}
              <Grid container spacing={3}>
                {values.socialMedias &&
                  values.socialMedias.map((item, ind) => {
                    return (
                      <Fragment key={ind}>
                        <Grid item xs={12} md={2}>
                          <InputImg
                            label="Icon"
                            name={`icon${ind}`}
                            onChange={(e) => handleIcon(e, ind)}
                            src={item.icon}
                            value={item.iconFile}
                            rmv={() => {
                              setValues((j) => ({
                                ...j,
                                socialMedias: [
                                  ...j.socialMedias.map((x, i) =>
                                    i === ind
                                      ? { ...x, icon: "", iconFile: "" }
                                      : x
                                  ),
                                ],
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <div className="d-grid g-15">
                            <Input
                              label="Title"
                              name="title"
                              value={item.title}
                              inputProps={{ maxLength: 50 }}
                              onChange={(e) => handleMediaChg(e, ind)}
                              fullWidth
                            />
                            <Input
                              label="Link"
                              name="link"
                              value={item.link}
                              onChange={(e) => handleMediaChg(e, ind)}
                              inputProps={{ maxLength: 1000 }}
                              fullWidth
                            />
                            <div className="tx-right">
                              <Button
                                onClick={() => handleRmvItem(ind)}
                                variant="text"
                                size="small"
                                sx={{ minWidth: "100px" }}
                                className="bg-errorSM error-color"
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      </Fragment>
                    );
                  })}
                <Grid item xs={12}>
                  <Button
                    onClick={() => addSocialMedia()}
                    variant="text"
                    size="large"
                    sx={{ minWidth: "120px" }}
                    startIcon={<AddRounded />}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(LPForm);
