import { Paper } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { InputDate } from "../../../controls";

const options = {
  colors: ["var(--primary-color)", "var(--grayXS-color)"],
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      borderRadius: 0,
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },

  noData: {
    text: "Loading...",
  },
  xaxis: {
    categories: moment.monthsShort(),
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    labels: {
      formatter: (val) => "$" + val.toLocaleString(),
      style: {
        fontSize: "14px",
      },
    },
  },
};

const YearlyRev = (props) => {
  const { payments = [], subsInvs = [] } = props;
  const [date, setDate] = useState(new Date());
  const [series, setSeries] = useState([]);

  const handleInpChange = (newVal) => {
    setDate(newVal);
  };

  useEffect(() => {
    if (date) {
      var fullYear = date && new Date(date).getFullYear();
      var dateLi = [
        `01/${fullYear}`,
        `02/${fullYear}`,
        `03/${fullYear}`,
        `04/${fullYear}`,
        `05/${fullYear}`,
        `06/${fullYear}`,
        `07/${fullYear}`,
        `08/${fullYear}`,
        `09/${fullYear}`,
        `10/${fullYear}`,
        `11/${fullYear}`,
        `12/${fullYear}`,
      ];

      var dueAmt = [];
      var paidAmt = [];
      dateLi.forEach((date) => {
        dueAmt.push(
          subsInvs
            .filter(
              (j) =>
                moment(j.dueDate, "MM/DD/YYYY").format("MM/YYYY") === date &&
                j.status === 1
            )
            .map((j) => j.amount)
            .reduce((a, b) => a + b, 0)
        );
        paidAmt.push(
          payments
            .filter(
              (j) => moment(j.date, "MM/DD/YYYY").format("MM/YYYY") === date
            )
            .map((j) => j.amount)
            .reduce((a, b) => a + b, 0)
        );
      });
      setSeries([
        { name: "Paid", data: paidAmt },
        { name: "Due", data: dueAmt },
      ]);
    }

    return () => {
      setSeries([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, payments, subsInvs]);

  return (
    <Paper>
      <div className="p-10 d-flex j-between a-center">
        <div>
          <span className="border-left fw-4 fs-20 gray-color tx-upp">
            Payment Status
          </span>
        </div>

        <div>
          <InputDate
            variant="outlined"
            sx={{ maxWidth: "100px" }}
            size="small"
            value={date}
            openTo="year"
            views={["year"]}
            onChange={handleInpChange}
          />
        </div>
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="300"
      />
    </Paper>
  );
};

export default YearlyRev;
