import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputMultFile,
} from "../../../../controls";
import { Model, UseForm } from "../../../../hooks";
import { Form } from "../../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { controller, endpoints } from "../../../../api/actions/api";
import {
  MaxLength,
  NestedFormData,
  RmvEmptyProp,
} from "../../../../hooks/Method";
import { useNavigate } from "react-router-dom";

const AddEditPay = (props) => {
  const {
    _post,
    _put,
    _authUser,
    _hoa,
    _getById,
    setShowForm,
    updData,
    setAps,
    setAlert,
    vendor,
  } = props;
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [cats, setCats] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [files, setFiles] = useState([]);
  const { APMdl } = Model();
  const navigate = useNavigate();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    handleInpDate,
  } = UseForm(APMdl);

  useEffect(() => {
    vendor && setValues((x) => ({ ...x, vendorId: vendor.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  useEffect(() => {
    if (updData && banks.length > 0 && cats.length > 0) {
      setValues((x) => ({
        ...x,
        ...updData,
        files: [],
        units: updData.arapUnits && updData.arapUnits.map((j) => j.unitId),
      }));
      setFiles([...updData.apFiles]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData, cats]);

  useEffect(() => {
    if (_hoa.id) {
      _getById(
        endpoints.Vendor + controller.GetByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setVendors([
            ...res.result.map((j) => {
              return {
                id: j.id,
                label: j.name,
              };
            }),
          ])
      );
      _getById(
        endpoints.BankAcc + controller.GetByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setBanks([
            ...res.result.map((j) => {
              return {
                id: j.id,
                label: j.accName,
              };
            }),
          ])
      );
      _getById(
        endpoints.HoaCat + controller.GetByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setCats([
            ...res.result
              .filter((j) => j.catType === 2)
              .map((j) => ({ ...j, label: j.name })),
          ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa.id]);

  const validateForm = () => {
    let temp = {};
    temp.dueDate = values.dueDate !== "" ? "" : "Due date is required.";
    temp.amount = values.amount !== "" ? "" : "Amount is required.";
    temp.categoryId = values.categoryId !== "" ? "" : "Category is required.";
    temp.bankAccId = values.bankAccId !== "" ? "" : "Bank account is required.";
    temp.vendorId = values.vendorId !== "" ? "" : "Vendor is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          if (setAps && res.result.id) {
            updData
              ? setAps((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setAps((x) => [res.result, ...x]);
          }
          setShowForm && setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "You have successfully added.",
            onClick: () =>
              _authUser && _authUser.role !== 1
                ? navigate("/my_account/payables")
                : navigate("/my_account"),
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        values.userId = _authUser.id;
        _post(
          endpoints.AP + controller.Post,
          NestedFormData(RmvEmptyProp(values), "files"),
          null,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.AP + controller.Put,
          NestedFormData(RmvEmptyProp(values), "files"),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate className="p-15">
      <div className="mb-20 d-flex flex-wrap g-10 j-between a-center">
        <div>
          <span className="fs-22 mb-5 fw-4 gray-color tx-upp border-left">
            Pay Vendor
          </span>
        </div>

        <div className="d-flex g-10">
          {setShowForm && (
            <div>
              <Button variant="text" onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </div>
          )}
          <div>
            <Button type="submit" loading={loading}>
              Submit
            </Button>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputDate
            label="Due Date"
            name="dueDate"
            value={values.dueDate}
            error={errors.dueDate}
            className="w-100pr"
            views={["day", "month", "year"]}
            minDate={new Date()}
            onChange={(date) =>
              handleInpDate("dueDate", new Date(date).toLocaleDateString())
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputAuto
            label="Vendor"
            name="vendorId"
            disabled={vendor ? true : false}
            value={values.vendorId}
            error={errors.vendorId}
            onChange={handleInpChg}
            options={vendors}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Amount"
            name="amount"
            placeholder="$ 0.00"
            onInput={(e) => (e.target.value = MaxLength(e.target.value, 18))}
            value={values.amount}
            error={errors.amount}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputAuto
            label="Bank Account"
            name="bankAccId"
            value={values.bankAccId}
            error={errors.bankAccId}
            onChange={handleInpChg}
            options={banks}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Memo"
            name="memo"
            value={values.memo}
            error={errors.memo}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputAuto
            label="Category"
            name="categoryId"
            value={values.categoryId}
            error={errors.categoryId}
            onChange={handleInpChg}
            options={cats}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            multiline
            rows={2}
            label="Description"
            name="descr"
            value={values.descr}
            error={errors.descr}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputMultFile
            label="Upload File"
            name="files"
            maxSize={6}
            src={files}
            values={values.files}
            onChange={handleFile}
            rmv={(file) => {
              setValues((x) => ({
                ...x,
                files: x.files.filter(
                  (j) => j.lastModified !== file.lastModified
                ),
                apFiles: x.apFiles.filter((j) => j.id !== file.id),
              }));
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditPay);
