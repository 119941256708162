import { Container, Grid } from "@mui/material";
import React, { useContext } from "react";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import InputRate from "../../../controls/InputRate";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";

const AddEditHoaCat = (props) => {
  const { _post } = props;
  const { ReviewMdl } = Model();
  const navigate = useNavigate();
  const { setOrg } = useContext(AppContext);
  const { values, errors, setErrors, handleInpChg } = UseForm(ReviewMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const validateForm = () => {
    let temp = {};
    temp.rating = values.rating ? "" : "Rating is required.";
    temp.desc = values.desc !== "" ? "" : "Review name is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
            onClick: () => {
              navigate("/my_account/home");
              res.result && res.result.id && setOrg(res.result);
            },
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        _post(
          endpoints.Review + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <>
      <Container maxWidth="sm" className="bs-secondary br-8">
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3} className="pb-20">
            <Grid item xs={12} className="tx-center p-20 mt-30 w-100pr">
              <h2 className="h fw-5 mb-5 tx-upp">Review 🎉</h2>
              <div className="pos-relative d-flex j-center">
                <div className="border-bottom w-60" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <InputRate
                name="rating"
                value={parseInt(values.rating)}
                error={errors.rating}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Review"
                name="desc"
                value={values.desc}
                error={errors.desc}
                onChange={handleInpChg}
                fullWidth
                required={true}
                multiline
                rows={5}
                inputProps={{ maxLength: 1500 }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                variant="text"
                size="large"
                onClick={handleClose}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button loading={loading} size="large" type="submit" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Container>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditHoaCat);
