import { api } from "./api";

export const ActionType = {
  Data: "Data",
  List: "List",
  AddItem: "AddItem",
  UpdateItem: "UpdateItem",
  Delete: "Delete",
  RmvList: "RmvList",
  ClearLi: "ClearLi",
  ClearData: "ClearData",
  Check: "Check",
  Auth: "Auth",
  AuthOwner: "AuthOwner",
  AuthAdmin: "AuthAdmin",
  OwnerUnits: "OwnerUnits",
  SelUnit: "SelUnit",
  DefHoa: "DefHoa",
  Subs: "Subs",
  Hoa: "Hoa",
  RmvHoa: "RmvHoa",
};

export const get =
  (endpoint, type = ActionType.List, onComplete) =>
  (dispatch) => {
    api(endpoint)
      .get()
      .then((res) => {
        dispatch({
          type: type,
          payload: res.data,
        });
        onComplete && onComplete(res.data);
      })
      .catch((err) => {
        console.error("actions: ", err);
        onComplete && onComplete(undefined, err);
      });
  };

export const set =
  (dataType = [], type = ActionType.ClearLi) =>
  (dispatch) => {
    dispatch({
      type: type,
      payload: dataType,
    });
  };

export const getById =
  (endpoint, id, type = null, onComplete) =>
  (dispatch) => {
    api(endpoint)
      .getById(id)
      .then((res) => {
        dispatch({
          type: type,
          payload: res.data,
        });
        onComplete && onComplete(res.data);
      })
      .catch((err) => {
        console.error("actions: ", err);
        onComplete && onComplete(undefined, err);
      });
  };

export const getByObj =
  (endpoint, obj, type = ActionType.Data, onComplete) =>
  (dispatch) => {
    api(endpoint)
      .getByObj(obj)
      .then((res) => {
        dispatch({
          type: type,
          payload: res.data,
        });
        onComplete && onComplete(res.data);
      })
      .catch((err) => {
        console.error("actions: ", err);
        onComplete && onComplete(undefined, err);
      });
  };

export const post =
  (endpoint, data, type = ActionType.Data, onComplete) =>
  (dispatch) => {
    api(endpoint)
      .post(data)
      .then((res) => {
        dispatch({
          type: type,
          payload: res.data,
        });
        onComplete && onComplete(res.data);
      })
      .catch((err) => {
        console.error("actions: ", err);
        onComplete && onComplete(undefined, err);
      });
  };

export const put =
  (endpoint, data, type = ActionType.Data, onComplete) =>
  (dispatch) => {
    api(endpoint)
      .put(data)
      .then((res) => {
        dispatch({
          type: type,
          payload: res.data,
        });
        onComplete && onComplete(res.data);
      })
      .catch((err) => {
        console.error("actions: ", err);
        onComplete && onComplete(undefined, err);
      });
  };

export const Delete =
  (endpoint, id, type = ActionType.DELETE, onComplete) =>
  (dispatch) => {
    api(endpoint)
      .delete(id)
      .then((res) => {
        dispatch({
          type: type,
          payload: res.data,
        });
        onComplete && onComplete(res.data);
      })
      .catch((err) => {
        console.error("actions: ", err);
        onComplete && onComplete(undefined, err);
      });
  };
