import { Divider, Grid } from "@mui/material";
import React from "react";
import { Button } from "../../../controls";

const ViewEmail = (props) => {
  const { setShowForm, viewData } = props;
  const handleClose = () => {
    setShowForm(false);
  };
  return (
    <>
      {viewData ? (
        <Grid container spacing={3} className="p-20">
          <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
            <h2 className="h fw-5 mb-5">View Email</h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-60" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Sent Date:</span>
            <span className="tx-md ">{viewData.sentDate}</span>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Owner Name:</span>
            <span className="tx-md ">{viewData.owner.fullName}</span>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Sent Email:</span>
            <span className="tx-md ">{viewData.email}</span>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Sent Email:</span>
            <span className="tx-md">
              <a className="link" href={`tel:${viewData.owner.phone}`}>
                {viewData.owner.phone}
              </a>
            </span>
          </Grid>
          <Grid item xs={12} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Subject:</span>
            <span className="tx-md ">{viewData.subject}</span>
          </Grid>
          <Grid item xs={12}>
            <span className="tx-xs tx-upp mb-5">Message:</span>
            <Divider />
            <p className="p pre-wrap">{viewData.msg}</p>
            <Divider />
          </Grid>
          <Grid item xs={12} className="tx-center">
            <Button
              onClick={handleClose}
              sx={{ minWidth: "150px" }}
              size="large"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default ViewEmail;
