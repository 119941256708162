import { LandscapeRounded } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React from "react";
import { Table } from "../../../../layout";

const Vendors = (props) => {
  const { vendors = [] } = props;

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={() => <></>}
        tblHead={[
          { id: "logo", label: "Logo" },
          { id: "name", label: "Vendor Name" },
          { id: "accNumber", label: "Account Number" },
          { id: "vendorEin", label: "Vendor EIN" },
          { id: "phone", label: "Phone Number" },
        ]}
        tblData={vendors.map((obj) => {
          return {
            ...obj,
            logo: (
              <Avatar
                src={obj.logo}
                alt="logo"
                className="bg-primary"
                sx={{ width: 36, height: 36 }}
              >
                {!obj.logo && <LandscapeRounded />}
              </Avatar>
            ),
          };
        })}
      />
    </>
  );
};

export default Vendors;
