import React from "react";
import { Dashboard } from "../../layout";
import { Header, Home, NavMenu, Subs } from "./";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import SubsForm from "./SubsPkg/SubsPkgForm";
import UserForm from "./Users/UserForm";
import Users from "./Users/Users";
import SubsPkgs from "./SubsPkg/SubsPkgs";
import SubsPkgForm from "./SubsPkg/SubsPkgForm";
import SubsSettings from "./Master/SubsSettings";
import Orgs from "./Org/Orgs";
import ViewOrg from "./Org/ViewOrg";
import Pages from "./SitePages/Pages";
import PageForm from "./SitePages/PageForm";
import LPForm from "./LandingPg/LPForm";
import Features from "./Features/Features";
import Reviews from "./Review/Reviews";
import { useContext } from "react";
import { AppContext } from "../../App";

const AdminPanelRoute = () => {
  const { landingPg } = useContext(AppContext);

  return (
    <Dashboard
      menu={<NavMenu />}
      header={<Header />}
      photo={landingPg.logo || ""}
      title={landingPg.orgName || ""}
    >
      <Routes>
        <Route path="/" element={<Navigate to="home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/orgs" element={<Orgs />} />
        <Route path="/view_org/:id" element={<ViewOrg />} />
        <Route path="/subs_pkg" element={<SubsPkgs />} />
        <Route path="/subscriptions" element={<Subs />} />
        <Route path="/subs_settings" element={<SubsSettings />} />
        <Route path="/subs_pkg/subs_pkg_form">
          <Route path=":id" element={<SubsPkgForm />} />
          <Route path="" element={<SubsPkgForm />} />
          <Route path="trial" element={<SubsPkgForm isTrial={true} />} />
        </Route>

        <Route path="/users" element={<Users />} />
        <Route path="/users/user_form">
          <Route path=":id" element={<UserForm />} />
          <Route path="" element={<UserForm />} />
        </Route>

        <Route path="/subscriptions/subscription_form">
          <Route path=":id" element={<SubsForm />} />
          <Route path="" element={<SubsForm />} />
        </Route>

        <Route path="/pages" element={<Pages />} />
        <Route path="/pages/page_form">
          <Route path=":id" element={<PageForm />} />
          <Route path="" element={<PageForm />} />
        </Route>
        <Route path="/landing_pg" element={<LPForm />} />
        <Route path="/features" element={<Features />} />
        <Route path="/reviews" element={<Reviews />} />
      </Routes>
    </Dashboard>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActiontoProps = {
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActiontoProps)(AdminPanelRoute);
