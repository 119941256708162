import React from "react";
import { Alert, AlertTitle } from "@mui/material";

const SubsWarning = ({ type = "warning", title, msg, Action = <></> }) => {
  return (
    <div>
      <Alert severity={type} sx={{ border: "none" }} action={Action}>
        <AlertTitle className="tx-upp">{title}</AlertTitle>
        {msg}
      </Alert>
    </div>
  );
};

export default SubsWarning;
