import { TableCell } from "@mui/material";
import React, { useContext } from "react";
import { Table } from "../../../layout";
import { connect } from "react-redux";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import moment from "moment";
import RepNav from "./RepNav";

const APRep = (props) => {
  const { _getById, _hoa } = props;
  const [payments, setPayments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [filteredPay, setFilteredPay] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    setCsvHeader([
      { label: "Vendor", key: "vendor" },
      { label: "Current Due", key: "current" },
      { label: "01 to 30 Days", key: "last30Days" },
      { label: "31 to 60 Days", key: "last31to60Days" },
      { label: "61 to 90 Days	", key: "last61to90Days" },
      { label: "Over 90 Days", key: "more90Days" },
      { label: "Total Due", key: "total" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    payments &&
      setFilteredPay([
        ...payments.filter(
          (j) =>
            moment(j.dueDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.dueDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);

    return () => {
      setFilteredPay([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments, sortDate]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setPayments([...res.result.filter((j) => !j.isComplete)]);
      };
      _getById(endpoints.AP + controller.GetByHoa, _hoa.id, null, onComplete);
      _getById(
        endpoints.Vendor + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setVendors([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    if (filteredPay && vendors) {
      setTblData(
        vendors.map((vendor) => {
          var current = filteredPay
            .filter(
              (j) =>
                j.vendorId === vendor.id &&
                moment(j.dueDate, "MM/DD/YYYY").format("MM/YYYY") ===
                  moment().format("MM/YYYY")
            )
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);

          var last30Days = filteredPay
            .filter(
              (j) =>
                j.vendorId === vendor.id &&
                moment(j.dueDate, "MM/DD/YYYY").format("MM/YYYY") ===
                  moment().subtract(1, "months").format("MM/YYYY")
            )
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          var last31to60Days = filteredPay
            .filter(
              (j) =>
                j.vendorId === vendor.id &&
                moment(j.dueDate, "MM/DD/YYYY").format("MM/YYYY") ===
                  moment().subtract(2, "months").format("MM/YYYY")
            )
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          var last61to90Days = filteredPay
            .filter(
              (j) =>
                j.vendorId === vendor.id &&
                moment(j.dueDate, "MM/DD/YYYY").format("MM/YYYY") ===
                  moment().subtract(3, "months").format("MM/YYYY")
            )
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          var more90Days = filteredPay
            .filter(
              (j) =>
                j.vendorId === vendor.id &&
                moment().subtract(4, "months") >=
                  moment(j.dueDate, "MM/DD/YYYY")
            )
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          var total =
            last30Days + last31to60Days + last61to90Days + more90Days + current;
          return {
            vendor: vendor.name,
            current,
            last30Days,
            last31to60Days,
            last61to90Days,
            more90Days,
            total,
          };
        })
      );
    }
  }, [filteredPay, vendors]);

  const HeaderComp = () => (
    <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
      Payable Aging
    </h2>
  );
  return (
    <>
      <div className="mt-20 mb-10">
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "PAYABLE AGING",
            showTitle: false,
          }}
        />
      </div>
      <div className="mt-15">
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={HeaderComp}
          tblHead={[
            { id: "vendor", label: "Vendor" },
            { id: "current", label: "Current Due" },
            { id: "last30Days", label: "01 to 30 Days" },
            { id: "last31to60Days", label: "31 to 60 Days" },
            { id: "last61to90Days", label: "61 to 90 Days" },
            { id: "more90Days", label: "Over 90 Days" },
            { id: "total", label: "Total Due" },
          ]}
          tblData={tblData.map((j) => {
            return {
              ...j,
              total: (
                <span className="gray-color fw-6 fs-italic">
                  ${j.total.toLocaleString()}
                </span>
              ),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.current)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.last30Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.last31to60Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.last61to90Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.more90Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.total)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(APRep);
