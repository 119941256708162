import {
  ClearRounded,
  EditRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import AddEditReq from "./AddEditReq";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { DropLi } from "../../../hooks";
import { useNavigate } from "react-router-dom";

const Violations = (props) => {
  const { _getByObj, _hoa, _delete, unit } = props;
  const { VioType } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const navigate = useNavigate();
  const [updData, setUpdData] = useState();
  const [vios, setVios] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setVios([...res.result]);
    };
    if (unit && unit.id) {
      _getByObj(
        endpoints.Request + controller.GetAllByUnit,
        { id: unit.id, value: "2" },
        null,
        onComplete
      );
    } else if (_hoa.id) {
      setLoader(true);
      _getByObj(
        endpoints.Request + controller.GetByHoa,
        { id: _hoa.id, value: "2" },
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa, unit]);

  const handleView = (val) => {
    navigate(`/my_account/view_request/${val.id}/true`);
  };

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this violation report?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setVios((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Request, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="btn-error"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {props.isComplete === 2 && (
        <>
          <Tooltip arrow title="Edit Record">
            <IconButton
              onClick={() => handleAddEdit(props)}
              size="small"
              color="primary"
              className="mr-5"
            >
              <EditRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Delete Record">
            <IconButton
              onClick={() => handleDlt(props.id)}
              size="small"
              color="error"
            >
              <ClearRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );

  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAddEdit()}>
        Create Violation
      </Button>
    </div>
  );
  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "unitName", label: "Unit" },
          { id: "reqType", label: "Request Type" },
          { id: "resDeadline", label: "Resolution Deadline" },
          { id: "cmpltDate", label: "Complete Date" },
        ]}
        tblData={vios.map((req, i) => {
          var type = VioType.find((j) => j.id === req.type);
          return {
            ...req,
            unitName: req.unit && req.unit.title,
            reqType: type && type.label,
            status:
              req.isComplete === 2 ? (
                <span className="bg-warningT warning-color status">
                  Pending
                </span>
              ) : (
                <span className="bg-primarySM primary-color status">
                  Complete
                </span>
              ),
          };
        })}
      />

      {/* Add Requests */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditReq
            {...{
              setShowForm: setShowForm,
              setAlert,
              setList: setVios,
              unit,
              updData,
              rcdType: 2,
            }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Violations);
