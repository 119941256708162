import {
  CrisisAlertRounded,
  PaymentsRounded,
  ReceiptRounded,
  TouchAppRounded,
} from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Widgets = (props) => {
  const { totalReq, totalVio, totalInv, totalPay } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper className="p-20">
          <Link to="/my_account/violation" className="tx-und-none">
            <div className="d-flex a-center">
              <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
                <CrisisAlertRounded className="fs-30" />
              </div>
              <div className="ml-15">
                <h2 className="h fs-22 fw-6">{totalVio}</h2>
                <span className="tx-sm gray-color">Violations</span>
              </div>
            </div>
          </Link>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Paper className="p-20">
          <Link to="/my_account/requests" className="tx-und-none">
            <div className="d-flex a-center">
              <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
                <TouchAppRounded className="fs-30" />
              </div>
              <div className="ml-15">
                <h2 className="h fs-22 fw-6">{totalReq}</h2>
                <span className="tx-sm gray-color">Request</span>
              </div>
            </div>
          </Link>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper className="p-20">
          <Link to="/my_account/invoices" className="tx-und-none">
            <div className="d-flex a-center">
              <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
                <ReceiptRounded className="fs-30" />
              </div>
              <div className="ml-15">
                <h2 className="h fs-22 fw-6">{totalInv}</h2>
                <span className="tx-sm gray-color">Unpaid Invoices</span>
              </div>
            </div>
          </Link>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper className="p-20">
          <Link to="/my_account/payables" className="tx-und-none">
            <div className="d-flex a-center">
              <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
                <PaymentsRounded className="fs-30" />
              </div>
              <div className="ml-15">
                <h2 className="h fs-22 fw-6">{totalPay}</h2>
                <span className="tx-sm gray-color">Pending Payables</span>
              </div>
            </div>
          </Link>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Widgets;
