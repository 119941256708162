import {
  AdminPanelSettingsRounded,
  Logout,
  MailOutlineRounded,
  RoomPreferencesRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  IconButton,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import { MenuPopup } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { controller, endpoints } from "../../../api/actions/api";
import "./Header.scss";
import { OwnerContext } from "../OwnerPortalRoute";

const Header = (props) => {
  const { _get, _getById, _authOwner } = props;
  const [profileEl, setProfileEl] = useState(null);
  const { notices } = useContext(OwnerContext);
  const { setLoader } = useContext(AppContext);
  const [unseenNtcs, setUnseenNtcs] = useState(0);
  const navigate = useNavigate();

  const handleProfile = (e) => {
    setProfileEl(e.currentTarget);
  };

  const handleLogout = () => {
    setLoader(true);
    const onComplete = (res) => {
      navigate("/");
      setLoader(false);
    };
    _get(
      endpoints.AuthOwner + controller.Logout,
      actions.ActionType.AuthOwner,
      onComplete
    );
  };

  useEffect(() => {
    if (_authOwner.id !== undefined) {
      _getById(
        endpoints.Unit + controller.GetAllByOwner,
        _authOwner.id,
        actions.ActionType.OwnerUnits
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authOwner]);

  useEffect(() => {
    notices && setUnseenNtcs(notices.filter((j) => !j.isSeen).length);
    return () => {};
  }, [notices]);

  return (
    <div className="header_container">
      <div className="d-flex a-center j-between">
        <div>
          <IconButton
            onClick={handleProfile}
            size="small"
            aria-controls="account-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <Avatar
              alt="profile-pic"
              src={_authOwner.photo}
              className="bg-inherit"
            />
          </IconButton>
          <MenuPopup
            anchorEl={profileEl}
            setAnchorEl={setProfileEl}
            id="account-menu"
          >
            <MenuItem onClick={() => navigate(`my_account/${_authOwner.id}`)}>
              <ListItemIcon>
                <AdminPanelSettingsRounded fontSize="small" />
              </ListItemIcon>
              My Account
            </MenuItem>
            {_authOwner && _authOwner.role === 3 && (
              <MenuItem onClick={() => navigate("organization-settings")}>
                <ListItemIcon>
                  <RoomPreferencesRounded fontSize="small" />
                </ListItemIcon>
                Organization
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </MenuPopup>
        </div>
        <div>
          <Link to="/owner_portal/home">
            <img
              src={process.env.PUBLIC_URL + "/assets/logo-white.png"}
              alt="site-logo"
              id="site_logo"
            />
          </Link>
        </div>
        <div className="pr-10">
          <Badge badgeContent={unseenNtcs} color="white">
            <IconButton
              size="small"
              color="white"
              onClick={() => navigate("/owner_portal/notices")}
            >
              <MailOutlineRounded />
            </IconButton>
          </Badge>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authOwner: state.storeData.authOwner,
  _ownerUnits: state.storeData.ownerUnits,
  _hoas: state.storeData.hoas,
  _selUnit: state.storeData.selUnit,
});

const mapActiontoProps = {
  _get: actions.get,
  _set: actions.set,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActiontoProps)(Header);
