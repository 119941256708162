import {
  ClearRounded,
  EditRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import { useNavigate } from "react-router-dom";
import ViewOwner from "./ViewOwner";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";

const Owners = (props) => {
  const { _getById, _hoa, _delete, _authUser } = props;
  const [owners, setOwners] = useState([]);
  const [viewDtls, setViewDtls] = useState(false);
  const [viewData, setViewData] = useState();
  const { setLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setOwners(res.result);
      };
      _getById(
        endpoints.Owner + controller.GetByHoa,
        _hoa.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  const handleAddEdit = (id) => {
    navigate(`owner_form/${id || ""}`);
  };
  const handleView = (data) => {
    setViewData(data);
    setViewDtls(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete this owner?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setOwners((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Owner, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Details">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {_authUser && _authUser.role !== 1 && (
        <>
          <Tooltip arrow title="Edit">
            <IconButton
              onClick={() => handleAddEdit(props.id)}
              size="small"
              color="primary"
              className="mr-5"
            >
              <EditRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Delete">
            <IconButton
              onClick={() => handleDlt(props.id)}
              size="small"
              color="error"
            >
              <ClearRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAddEdit()}>
        Add Owner
      </Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() =>
          _authUser && _authUser.role !== 1 ? <HeaderComp /> : <> </>
        }
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "fullName", label: "Full Name" },
          { id: "phone", label: "Phone" },
          { id: "email", label: "Email" },
          { id: "lastLogin", label: "Last Login" },
        ]}
        tblData={owners.map((owner) => {
          return {
            ...owner,
            status:
              owner.isActive === 1 ? (
                <span className="success-color bg-successXS status">
                  Active
                </span>
              ) : (
                <span className="error-color bg-errorSM status">Inactive</span>
              ),
          };
        })}
      />

      {/* View Details */}
      <Dialog show={viewDtls} onClose={false} maxWidth="md">
        <ViewOwner {...{ setShowForm: setViewDtls, viewData }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _set: actions.set,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Owners);
