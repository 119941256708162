import { VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../../../layout";
import ViewOwner from "../../../../dashboard/Owner/ViewOwner";

const Owners = (props) => {
  const { unit } = props;
  const [viewDtls, setViewDtls] = useState();
  const [showDtls, setShowDtls] = useState(false);
  const [owners, setOwners] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    unit && setOwners([...unit.unitOwners]);

    return () => {
      setOwners([]);
    };
  }, [unit]);

  const handleView = (props) => {
    setViewDtls(props.owner);
    setShowDtls(true);
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Details">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );

  const Header = () => (
    <div className="mb-5">
      <h2 className="h fs-18 fw-5 tx-upp gray-color">Owners of this Unit</h2>
    </div>
  );

  return (
    <>
      <Table
        shadow={false}
        ActionComp={ActionComp}
        HeaderComp={Header}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "name", label: "Owner" },
          { id: "email", label: "Email" },
        ]}
        tblData={owners.map((owner) => {
          return {
            ...owner,
            status:
              owner.owner.isActive === 1 ? (
                <span className="success-color bg-successXS status">
                  Active
                </span>
              ) : (
                <span className="error-color bg-errorSM status">Inactive</span>
              ),
            name: owner.owner.fullName,
            email: (
              <a className="link" href={`mailto:${owner.owner.email}`}>
                {owner.owner.email}
              </a>
            ),
          };
        })}
      />

      {/* View Details */}
      <Dialog show={showDtls} onClose={false} maxWidth="md">
        <ViewOwner {...{ setShowForm: setShowDtls, viewData: viewDtls }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Owners;
