import { Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { Model, UseForm } from "../../../hooks";
import { controller, endpoints } from "../../../api/actions/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { Alert, Form } from "../../../layout";
import { Button, Input } from "../../../controls";
import * as actions from "../../../api/actions/actions";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";

const SubsSettings = (props) => {
  const { _put, _get } = props;
  const { SubsSettingsMdl } = Model();
  const navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(SubsSettingsMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const validateForm = () => {
    let temp = {};
    temp.yearlyPayDis =
      values.yearlyPayDis !== "" ? "" : "Discount is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // Create Action
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200)
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
      };
      _put(
        endpoints.Master + controller.PutSubsSettings,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200
        ? setValues({ ...res.result })
        : setAlert({
            isOpen: true,
            type: "error",
            title: "Opps!",
            subTitle: "Something is Wrong. Refresh the page and try again.",
            onClick: () => navigate("/my_account/"),
          });
    };
    _get(endpoints.Master + controller.GetSubsSettings, null, onComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container maxWidth="sm">
        <Paper className="p-15">
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="tx-center p-20 mb-10 w-100pr">
                <h2 className="fs-22 fw-5 mb-5 tx-upp gray-color">
                  Subscription Settings
                </h2>
                <div className="border-bottom w-60 m-auto" />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Yearly Pay Discount"
                  name="yearlyPayDis"
                  onChange={handleInpChg}
                  value={values.yearlyPayDis}
                  error={errors.yearlyPayDis}
                  fullWidth
                  onInput={(e) =>
                    (e.target.value = MaxLength(e.target.value, 5))
                  }
                />
              </Grid>

              <Grid item xs={12} className="tx-right mt-10">
                <Button loading={loading} type="submit">
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Container>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(SubsSettings);
