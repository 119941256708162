import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "../../../../layout";
import { Button, Input } from "../../../../controls";
import { Model, UseForm } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { controller, endpoints } from "../../../../api/actions/api";
import { ToFormData } from "../../../../hooks/Method";
import { SendRounded } from "@mui/icons-material";

const SendFrgtCode = (props) => {
  const { _getByObj, forgotVal, setForgotVal, setActiveStep } = props;
  const { ForgotMdl } = Model();
  const navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(ForgotMdl);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let temp = {};
    temp.email = values.email !== "" ? "" : "Email is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      //Got to the next step
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        // Set User Email to the forgot model.
        res.result !== undefined &&
          setValues({ ...values, email: res.result.email });
        if (res !== undefined && res.status === 200) {
          setActiveStep(1);
        } else if (res !== undefined && res.status === 204) {
          setErrors({
            ...errors,
            email: res.msg,
          });
        }
      };
      values.forgotStep = 1;
      _getByObj(
        endpoints.AuthAdmin + controller.Forgot,
        ToFormData(values),
        actions.ActionType.Data,
        onComplete
      );
    }
  };

  useEffect(() => {
    setValues(forgotVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setForgotVal({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Form noValidate onSubmit={submitForm}>
      <Grid container className="p-15 pt-0" spacing={3}>
        <Grid item xs={12} className="tx-center mt-20 pb-30">
          <h2 className="fs-24 fw-5 tx-upp h gray-color">Forgot Password</h2>
          <div className="pos-relative d-flex j-center mt-5">
            <div className="border-bottom w-60" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInpChg}
            error={errors.email}
            className="input-primary w-100pr"
          />
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex g-10 mt-20">
            <Button
              onClick={() => navigate("/owner_portal")}
              size="large"
              variant="text"
              className="w-100pr br-20"
            >
              Back
            </Button>
            <Button
              loading={loading}
              type="submit"
              size="large"
              className="w-100pr br-20"
              endIcon={<SendRounded />}
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(SendFrgtCode);
