import {
  EditRounded,
  ForwardToInbox,
  PaymentsRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { CircularProgress, Divider, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Snackbar, Table } from "../../../layout";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { DropLi } from "../../../hooks";
import moment from "moment";
import AddEditPay from "../Payment/AddEditPay";
import DateFilter from "./DateFilter";

const Invoices = (props) => {
  const { _getById, _hoa, _authUser } = props;
  const navigate = useNavigate();
  const [invs, setInvs] = useState([]);
  const [fltInvs, setFltInvs] = useState([]);
  const { InvTypes, InvPeriod } = DropLi();
  const { setLoader } = useContext(AppContext);
  const [showPayment, setShowPayment] = useState(false);
  const [invDtls, setInvDtls] = useState(false);
  const [tblData, setTblData] = useState([]);

  var date = new Date();
  const [sortDate, setSortDate] = useState({
    fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
    toDate: new Date(),
  });
  const [snackbar, setSnackbar] = useState({
    show: false,
    type: "success",
    msg: "",
  });
  const [loading, setLoading] = useState({
    id: 0,
    show: false,
  });
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setFltInvs([
      ...invs.filter(
        (j) =>
          moment(j.createDate, "MM/DD/YYYY") >=
            moment(sortDate.fromDate, "MM/DD/YYYY") &&
          moment(j.createDate, "MM/DD/YYYY") <=
            moment(sortDate.toDate, "MM/DD/YYYY")
      ),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invs, sortDate]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setInvs(res.result);
      };
      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    setTblData([
      ...fltInvs.map((inv) => {
        const dueAmt = inv.invPayments
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toLocaleString();

        const lastPayment = inv.invPayments
          .sort((a, b) =>
            a.paidDate
              ? b.paidDate
                ? a.paidDate.localeCompare(b.paidDate)
                : -1
              : 1
          )
          .slice(-1)
          .pop();
        const firstUnpaid = inv.invPayments.find((j) => j.status === 1);
        const chargeType = InvTypes.find((j) => j.id === inv.invType);
        const invPeriodName =
          inv.invPlan && InvPeriod.find((j) => j.id === inv.invPlan.periodic);
        const firstOwner =
          inv.unit.unitOwners[0] && inv.unit.unitOwners[0].owner;
        return {
          ...inv,
          lastPayment,
          status:
            inv.invType === 3 ? (
              <div>
                <span className="bg-errorSM error-color status">Deactive</span>
              </div>
            ) : lastPayment && lastPayment.status === 2 ? (
              "Paid"
            ) : 0 < firstUnpaid &&
              moment().diff(
                moment(firstUnpaid.dueDate, "MM/DD/YYYY"),
                "days"
              ) ? (
              "Overdue"
            ) : lastPayment &&
              (lastPayment.status === 1 || lastPayment.status === 3) ? (
              "Due"
            ) : (
              "Not started yet"
            ),
          chargeType:
            inv.invType === 3 ? (
              <div className="fs-12">
                <span className="tx-upp fw-5 error-color">
                  Deactive Note:&nbsp;
                </span>
                <span className="">{inv.deactiveNote}</span>
              </div>
            ) : (
              <span className="dark-color">
                {chargeType && chargeType.label}
              </span>
            ),
          amt:
            inv.invType !== 3 ? (
              <div className="d-grid a-center w-fit">
                <span className="fs-12">Due: ${dueAmt}</span>
                <Divider />
                <span className="fs-12">
                  Inv Amt: ${inv.amount.toLocaleString()}
                </span>
              </div>
            ) : (
              <span>${inv.amount}</span>
            ),
          unitName: (
            <div className="d-grid">
              <span>{inv.unit.title}</span>
              <span className="fs-12 ">
                {firstOwner && firstOwner.fullName}
              </span>
            </div>
          ),
          invTerm: invPeriodName && (
            <div className="d-grid">
              <span>{inv.invPlan.name}</span>
              <span className="fs-12 ">
                {inv.invPlan.duration} {invPeriodName.label}
              </span>
            </div>
          ),
          dueDate: firstUnpaid
            ? firstUnpaid.dueDate
            : lastPayment && lastPayment.dueDate,
        };
      }),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fltInvs]);

  const handleAddEdit = (id) => {
    navigate(`invoice_form/${id || ""}`);
  };
  const handleView = (data) => {
    navigate(`view_invoice_details/${data.id}`);
  };

  const handleSendEmail = (id) => {
    setLoading({
      id: id,
      show: true,
    });
    const onComplete = (res) => {
      if (res.status === 200) {
        setSnackbar({
          show: true,
          msg: res.msg,
        });
      } else {
        setSnackbar({
          show: true,
          type: "error",
          msg: res.msg,
        });
      }
      setLoading({
        id: 0,
        show: false,
      });
    };
    _getById(endpoints.Invoice + controller.SendEmail, id, null, onComplete);
  };

  const handlePayment = (data) => {
    setInvDtls(data);
    setShowPayment(true);
  };

  const ActionComp = (props) => (
    <div className="d-flex a-center">
      <Tooltip arrow title="View Details">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {props.id === loading.id && loading.show ? (
        <>
          <CircularProgress color="primary" size={12} className="m-10" />
          <span className="gray-color fs-14">Sending...</span>
        </>
      ) : (
        <>
          {_authUser && _authUser.role !== 1 && props.invType !== 3 && (
            <>
              <Tooltip arrow title="Send email to this owners.">
                <span>
                  <IconButton
                    onClick={() => handleSendEmail(props.id)}
                    disabled={!props.sendEmail}
                    size="small"
                    color="primary"
                    className="mr-5"
                  >
                    <ForwardToInbox className="fs-18 m-2" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip arrow title="Edit">
                <IconButton
                  onClick={() => handleAddEdit(props.id)}
                  size="small"
                  color="primary"
                  className="mr-5"
                >
                  <EditRounded className="fs-18 m-2" />
                </IconButton>
              </Tooltip>
            </>
          )}

          {props.lastPayment &&
            props.lastPayment.status !== 2 &&
            props.invType !== 3 && (
              <Tooltip arrow title="Add Cash">
                <IconButton
                  onClick={() => handlePayment(props)}
                  size="small"
                  color="primary"
                  className="mr-5"
                >
                  <PaymentsRounded className="fs-18 m-2" />
                </IconButton>
              </Tooltip>
            )}
        </>
      )}
    </div>
  );

  const HeaderComp = () => (
    <div className="d-flex g-10">
      <div>
        <Button variant="outlined" size="large" onClick={() => handleAddEdit()}>
          Add Invoice
        </Button>
      </div>

      <DateFilter {...{ sortDate, setSortDate }} />
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() =>
          _authUser && _authUser.role !== 1 ? <HeaderComp /> : <></>
        }
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "id", label: "Invoice Id" },
          { id: "chargeType", label: "Invoice Type" },
          { id: "title", label: "Charge Title" },
          { id: "amt", label: "Amount" },
          { id: "unitName", label: "Units" },
          { id: "invTerm", label: "Invoice Term" },
          { id: "dueDate", label: "Due Date" },
        ]}
        tblData={tblData}
      />

      {showPayment && (
        <Dialog show={showPayment} onClose={false} maxWidth="md">
          <AddEditPay {...{ setShowForm: setShowPayment, invDtls, setAlert }} />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      <Snackbar {...{ snackbar, setSnackbar }} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Invoices);
