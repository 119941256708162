import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../../controls";
import { Alert, Table } from "../../../layout";
import DefHoa from "./DefHoa";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { useNavigate } from "react-router-dom";

const Hoas = (props) => {
  const { _hoas, _get, _type, _delete } = props;
  const navigate = useNavigate();
  const { setLoader } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    _get(endpoints.Hoa + controller.GetAll, actions.ActionType.List, () =>
      setLoader(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEdit = (id) => {
    navigate(`hoa_form/${id || ""}`);
  };

  const handleDlt = (val) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete this HOA?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Hoa, val.id, actions.ActionType.RmvHoa, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="Edit">
        <IconButton
          onClick={() => handleAddEdit(props.id)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete">
        <IconButton onClick={() => handleDlt(props)} size="small" color="error">
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );

  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAddEdit()}>
        Add Hoa
      </Button>
    </div>
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Table
            ActionComp={ActionComp}
            HeaderComp={HeaderComp}
            tblHead={[
              { id: "actions", label: "Actions" },
              { id: "hoaName", label: "Hoa Name" },
              { id: "email", label: "Organization Email" },
              { id: "phone", label: "Phone" },
              { id: "createDate", label: "Create Date" },
            ]}
            tblData={_type === "hoas" ? _hoas : []}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <DefHoa />
        </Grid>
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _type: state.storeData.type,
  _hoas: state.storeData.hoas,
});

const mapActionToProps = {
  _get: actions.get,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Hoas);
