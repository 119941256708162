import React, { useContext, useEffect, useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { AppContext } from "../../../App";
import RepNav from "./RepNav";
import { Table } from "../../../layout";
import moment from "moment";
import { DropLi } from "../../../hooks";
import { TableCell } from "@mui/material";

const tblHeader = () => (
  <div className="d-flex a-center flex-wrap">
    <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
      Vendor Overview
    </h2>
  </div>
);

const VendorOverview = (props) => {
  const { _getById, _hoa } = props;
  const { setLoader } = useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });

  const { Countries } = DropLi();

  useEffect(() => {
    setCsvHeader([
      { key: "name", label: "Vendor Name" },
      { key: "accNumber", label: "Account Number" },
      { key: "vendorEin", label: "TAX" },
      { key: "fullAddress", label: "Full Address" },
      { key: "fullCont", label: "Full Contact Info" },
      { key: "totalChecks", label: "Total Checks" },
      { key: "totalAmt", label: "Total Amount" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);
  useEffect(() => {
    setTblData([
      ...filteredData.map((j) => {
        var country = Countries.find((x) => x.id === j.country);
        var payments = j.vendorPayments.filter((x) => x.isComplete);
        var totalAmt = payments.map((j) => j.amount).reduce((x, y) => x + y, 0);
        return {
          ...j,
          totalChecks: payments.length,
          amt: totalAmt,
          totalAmt: `$ ${totalAmt.toLocaleString()}`,
          name: `${j.name} (${j.nickname})`,
          fullAddress: `${j.address1}, ${j.address2}, ${j.city}, ${j.state}, ${
            j.zip
          }, ${country && country.name}`,
          fullCont: `${j.contactName}, ${j.contactEamil}, ${j.phone}`,
        };
      }),
    ]);
    return () => {
      setTblData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res.status === 200) {
          setVendors(res.result);
          setFilteredData(res.result);
        }
      };
      _getById(
        endpoints.Vendor + controller.GetByHoa,
        _hoa.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    vendors &&
      setFilteredData([
        ...vendors.filter(
          (j) =>
            moment(j.createDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.createDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);

    return () => {
      setFilteredData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors, sortDate]);

  return (
    <>
      <div className="mt-20 mb-10">
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "Vendor Overview",
            showTitle: false,
          }}
        />
      </div>
      <div>
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={tblHeader}
          tblHead={[
            { id: "name", label: "Vendor Name" },
            { id: "accNumber", label: "Account Number" },
            { id: "vendorEin", label: "TAX" },
            { id: "fullAddress", label: "Full Address" },
            { id: "fullCont", label: "Full Contact Info" },
            { id: "totalChecks", label: "Total Checks" },
            { id: "totalAmt", label: "Total Amount" },
          ]}
          tblData={tblData.map((j) => {
            var country = Countries.find((x) => x.id === j.country);
            return {
              ...j,
              fullAddress: (
                <>
                  <span className="fs-14">{j.address1},</span>
                  <br />
                  {j.address2 && (
                    <>
                      <span className="fs-14">{j.address2},</span>
                      <br />
                    </>
                  )}
                  <span className="fs-14">
                    {`${j.city} ${j.state} ${j.zip}`},
                  </span>
                  <br />
                  <span className="fs-14">{country.name}</span>
                  <br />
                </>
              ),
              fullCont: (
                <>
                  <span className="fs-14">{j.contactName},</span>
                  <br />
                  <span className="fs-14">{j.contactEamil},</span>
                  <br />
                  <span className="fs-14">{j.phone}</span>
                  <br />
                </>
              ),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total Vendor Expense
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $&nbsp;
                {tblData
                  .map((j) => j.amt)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(VendorOverview);
