import { VisibilityRounded } from "@mui/icons-material";
import { Divider, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "../../../layout";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { DropLi } from "../../../hooks";
import moment from "moment";

const Invoices = (props) => {
  const { _getById, _authOwner } = props;
  const navigate = useNavigate();
  const [invs, setInvs] = useState([]);
  const { InvTypes, InvPeriod } = DropLi();
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    if (_authOwner.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setInvs(res.result);
      };
      _getById(
        endpoints.Invoice + controller.GetAllByOwner,
        _authOwner.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authOwner]);

  const handleView = (data) => {
    navigate(`view_invoice_details/${data.id}`);
  };

  const ActionComp = (props) => (
    <div className="d-flex a-center">
      <Tooltip arrow title="View Details">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <div className="p-15">
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => <></>}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "id", label: "Invoice Id" },
          { id: "chargeType", label: "Invoice Type" },
          { id: "title", label: "Charge Title" },
          { id: "amt", label: "Amount" },
          { id: "unitName", label: "Units" },
          { id: "invTerm", label: "Invoice Term" },
          { id: "dueDate", label: "Due Date" },
        ]}
        tblData={invs.map((inv) => {
          const dueAmt = inv.invPayments
            .map(
              (j) =>
                j.amount -
                j.paidAmt +
                j.invLateFees
                  .map((x) => x.amount - x.paidAmt)
                  .reduce((x, y) => x + y, 0)
            )
            .reduce((x, y) => x + y, 0)
            .toLocaleString();

          const lastPayment = inv.invPayments
            .sort((a, b) =>
              a.paidDate
                ? b.paidDate
                  ? a.paidDate.localeCompare(b.paidDate)
                  : -1
                : 1
            )
            .slice(-1)
            .pop();
          const firstUnpaid = inv.invPayments.find((j) => j.status === 1);
          const chargeType = InvTypes.find((j) => j.id === inv.invType);
          const invPeriodName =
            inv.invPlan && InvPeriod.find((j) => j.id === inv.invPlan.periodic);
          const firstOwner =
            inv.unit.unitOwners[0] && inv.unit.unitOwners[0].owner;
          return {
            ...inv,
            lastPayment,
            status:
              inv.invType !== 3 &&
              (lastPayment && lastPayment.status === 2
                ? "Paid"
                : 0 < firstUnpaid &&
                  moment().diff(
                    moment(firstUnpaid.dueDate, "MM/DD/YYYY"),
                    "days"
                  )
                ? "Overdue"
                : lastPayment &&
                  (lastPayment.status === 1 || lastPayment.status === 3)
                ? "Due"
                : "Not started yet"),
            chargeType: chargeType && (
              <span
                className={inv.invType === 3 ? "error-color" : "dark-color"}
              >
                {chargeType.label}
              </span>
            ),
            amt:
              inv.invType !== 3 ? (
                <div className="d-grid a-center w-fit">
                  <span className="fs-12">Due: ${dueAmt}</span>
                  <Divider />
                  <span className="fs-12">
                    Inv Amt: ${inv.amount.toLocaleString()}
                  </span>
                </div>
              ) : (
                <span className="error-color">-${inv.amount}</span>
              ),
            unitName: (
              <div className="d-grid">
                <span>{inv.unit.title}</span>
                <span className="fs-12 ">
                  {firstOwner && firstOwner.fullName}
                </span>
              </div>
            ),
            invTerm: invPeriodName && (
              <div className="d-grid">
                <span>{inv.invPlan.name}</span>
                <span className="fs-12 ">
                  {inv.invPlan.duration} {invPeriodName.label}
                </span>
              </div>
            ),
            dueDate: firstUnpaid
              ? firstUnpaid.dueDate
              : lastPayment && lastPayment.dueDate,
          };
        })}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authOwner: state.storeData.authOwner,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Invoices);
