import React, { useState } from "react";
import AddEditPay from "./AddEditPay";
import { Alert } from "../../../../layout";
import { Paper } from "@mui/material";

const PayPage = () => {
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  return (
    <Paper>
      <AddEditPay {...{ setAlert }} />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </Paper>
  );
};

export default PayPage;
