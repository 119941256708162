import { Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../controls";
import { useEffect } from "react";
import { DropLi } from "../../../hooks";

const ViewPayment = (props) => {
  const { setShowForm, viewData } = props;
  const { PayMethods } = DropLi();
  const [payMethod, setPayMethod] = useState();

  useEffect(() => {
    if (viewData) {
      var _payMethod = PayMethods.find((j) => j.id === viewData.method);
      _payMethod && setPayMethod(_payMethod.label);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  const handleClose = () => {
    setShowForm(false);
  };

  return viewData ? (
    <Grid container spacing={3} className="p-20 dark-color">
      <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
        <h2 className="h fw-5 mb-5 tx-upp">View Payment</h2>
        <div className="pos-relative d-flex j-center">
          <div className="border-bottom w-60" />
        </div>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid a-center">
        <span className="tx-xs tx-upp mb-5 ">Invoice Id:</span>
        <span>{viewData.inv.id}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid a-center">
        <span className="tx-xs tx-upp mb-5 ">Reference:</span>
        <span>{viewData.refe}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid a-center">
        <span className="tx-xs tx-upp mb-5 ">Invoice Title:</span>
        <span>{viewData.inv.title}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid a-center">
        <span className="tx-xs tx-upp mb-5 ">Unit:</span>
        <span>{viewData.unit.title}</span>
      </Grid>

      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Pay Date:</span>
        <span>{viewData.payDate}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Create Date:</span>
        <span>{viewData.createDate}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Amount:</span>
        <span>{viewData.amt}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Payment Method:</span>
        <span>{payMethod}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Send Email Receipt:</span>
        <span>{viewData.emailRecept ? "YES" : "NO"}</span>
      </Grid>
      <Grid item xs={12} className="d-grid">
        <span className="tx-xs tx-upp mb-5 gray-color ">View File:</span>
        {viewData.paymentFiles.map((file, i) => (
          <a
            key={i}
            href={file.fileSrc}
            className="mb-10 link fs-14"
            target="_blank"
            rel="noreferrer"
          >
            {file.name}
          </a>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Note:</span>
        <span className="pre-wrap">{viewData.note}</span>
      </Grid>
      <Grid item xs={12} className="tx-center mt-30">
        <Button size="large" onClick={handleClose}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  ) : (
    "Loading..."
  );
};

export default ViewPayment;
