export default function DropLi() {
  const Gender = [
    { id: 1, label: "Male" },
    { id: 2, label: "Female" },
    { id: 3, label: "Other" },
  ];

  const InvTypes = [
    { id: 1, label: "One Time Charge" },
    { id: 2, label: "Recurring Charge" },
  ];

  const InvPeriod = [
    { id: 1, label: "Days" },
    { id: 2, label: "Week" },
    { id: 3, label: "Month" },
    { id: 4, label: "Year" },
  ];

  const TxnType = [
    { id: 1, label: "Receivable" },
    { id: 2, label: "Payable" },
  ];

  const OrgPayStatus = [
    { id: 1, label: "All Org." },
    { id: 2, label: "Paid" },
    { id: 3, label: "Due" },
  ];

  const PageType = [
    { id: 1, label: "Terms & Condition" },
    { id: 2, label: "Privacy Policy" },
    { id: 3, label: "Show on Footer" },
    { id: 4, label: "Other" },
  ];

  const BankTxnType = [
    { id: 1, label: "Cash In" },
    { id: 2, label: "Cash Out" },
  ];

  const PayType = [
    { id: 1, label: "Credit Balance" },
    { id: 2, label: "Payment Received" },
  ];
  const CatType = [
    { id: 1, label: "Revenue" },
    { id: 2, label: "Expense" },
  ];

  const RevenueType = [
    { id: 1, label: "Assessments" },
    { id: 2, label: "Insurance" },
    { id: 3, label: "Late Fees" },
    { id: 4, label: "Transfer to Savings" },
    { id: 5, label: "Water Reimbursement" },
  ];

  const AccStatus = [
    { id: 1, label: "Active" },
    { id: 2, label: "Inactive" },
  ];

  const RecuringType = [
    { id: 1, label: "Year" },
    { id: 2, label: "6 Months" },
    { id: 3, label: "3 Months" },
    { id: 4, label: "Month" },
    { id: 5, label: "Week" },
  ];

  const SubsStatus = [
    { id: 0, label: "All" },
    { id: 1, label: "Running Trial" },
    { id: 2, label: "Finished Trial" },
    { id: 3, label: "Plan Activated" },
    { id: 4, label: "Plan Deactivated" },
    { id: 5, label: "On Hold" },
    { id: 6, label: "Pending For Activation" },
  ];

  const Roles = [
    { id: 1, label: "User" },
    { id: 2, label: "Manager" },
    { id: 3, label: "Admin" },
  ];

  const ReqType = [
    { id: 1, label: "Construction Permit" },
    { id: 2, label: "Visitor Pass" },
    { id: 3, label: "A Parking Permit" },
    { id: 4, label: "Beach Access" },
    { id: 5, label: "Repair / Maintenance" },
    { id: 6, label: "Loss or Damage" },
    { id: 7, label: "Security Concerns" },
    { id: 8, label: "Account Concerns" },
    { id: 9, label: "Request a Meeting" },
    { id: 10, label: "Volunteer Services" },
  ];

  const VioType = [
    { id: 1, label: "Barking Dogs" },
    { id: 2, label: "Water Waste" },
    { id: 3, label: "Air Pollution" },
    { id: 4, label: "Insect Problems" },
    { id: 5, label: "Parking a Moving Van on the Street" },
    { id: 6, label: "Vegetation on a Private Property Blocking Views" },
    { id: 7, label: "Trees and Vegetation on Public Property" },
    { id: 8, label: "Construction Site Complaints" },
  ];

  const Status = [
    { id: 1, label: "Pending" },
    { id: 2, label: "Complete" },
  ];

  const PeriodicType = [
    { id: 1, label: "Today " },
    { id: 2, label: "This Week" },
    { id: 3, label: "This Month" },
    { id: 4, label: "Last Month" },
    { id: 5, label: "This Year" },
    { id: 6, label: "Last Year" },
  ];

  const PayMethods = [
    { id: 1, label: "Offline (Cheque)" },
    { id: 2, label: "Offline (Cash)" },
    { id: 3, label: "Credit" },
    { id: 4, label: "EFT" },
  ];

  const Countries = [
    { label: "Afghanistan", id: "AF" },
    { label: "Åland Islands", id: "AX" },
    { label: "Albania", id: "AL" },
    { label: "Algeria", id: "DZ" },
    { label: "American Samoa", id: "AS" },
    { label: "AndorrA", id: "AD" },
    { label: "Angola", id: "AO" },
    { label: "Anguilla", id: "AI" },
    { label: "Antarctica", id: "AQ" },
    { label: "Antigua and Barbuda", id: "AG" },
    { label: "Argentina", id: "AR" },
    { label: "Armenia", id: "AM" },
    { label: "Aruba", id: "AW" },
    { label: "Australia", id: "AU" },
    { label: "Austria", id: "AT" },
    { label: "Azerbaijan", id: "AZ" },
    { label: "Bahamas", id: "BS" },
    { label: "Bahrain", id: "BH" },
    { label: "Bangladesh", id: "BD" },
    { label: "Barbados", id: "BB" },
    { label: "Belarus", id: "BY" },
    { label: "Belgium", id: "BE" },
    { label: "Belize", id: "BZ" },
    { label: "Benin", id: "BJ" },
    { label: "Bermuda", id: "BM" },
    { label: "Bhutan", id: "BT" },
    { label: "Bolivia (Plurinational State of)", id: "BO" },
    { label: "Bonaire, Sint Eustatius and Saba", id: "BQ" },
    { label: "Bosnia and Herzegovina", id: "BA" },
    { label: "Botswana", id: "BW" },
    { label: "Bouvet Island", id: "BV" },
    { label: "Brazil", id: "BR" },
    { label: "British Indian Ocean Territory", id: "IO" },
    { label: "Brunei Darussalam", id: "BN" },
    { label: "Bulgaria", id: "BG" },
    { label: "Burkina Faso", id: "BF" },
    { label: "Burundi", id: "BI" },
    { label: "Cambodia", id: "KH" },
    { label: "Cameroon", id: "CM" },
    { label: "Canada", id: "CA" },
    { label: "Cape Verde", id: "CV" },
    { label: "Cayman Islands", id: "KY" },
    { label: "Central African Republic", id: "CF" },
    { label: "Chad", id: "TD" },
    { label: "Chile", id: "CL" },
    { label: "China", id: "CN" },
    { label: "Christmas Island", id: "CX" },
    { label: "Cocos (Keeling) Islands", id: "CC" },
    { label: "Colombia", id: "CO" },
    { label: "Comoros", id: "KM" },
    { label: "Congo", id: "CG" },
    { label: "Congo, The Democratic Republic of the", id: "CD" },
    { label: "Cook Islands", id: "CK" },
    { label: "Costa Rica", id: "CR" },
    { label: "Curaçao", id: "CW" },
    { label: "Cote D'Ivoire", id: "CI" },
    { label: "Croatia", id: "HR" },
    { label: "Cuba", id: "CU" },
    { label: "Cyprus", id: "CY" },
    { label: "Czech Republic", id: "CZ" },
    { label: "Denmark", id: "DK" },
    { label: "Djibouti", id: "DJ" },
    { label: "Dominica", id: "DM" },
    { label: "Dominican Republic", id: "DO" },
    { label: "Ecuador", id: "EC" },
    { label: "Egypt", id: "EG" },
    { label: "El Salvador", id: "SV" },
    { label: "Equatorial Guinea", id: "GQ" },
    { label: "Eritrea", id: "ER" },
    { label: "Estonia", id: "EE" },
    { label: "Ethiopia", id: "ET" },
    { label: "Falkland Islands (Malvinas)", id: "FK" },
    { label: "Faroe Islands", id: "FO" },
    { label: "Fiji", id: "FJ" },
    { label: "Finland", id: "FI" },
    { label: "France", id: "FR" },
    { label: "French Guiana", id: "GF" },
    { label: "French Polynesia", id: "PF" },
    { label: "French Southern Territories", id: "TF" },
    { label: "Gabon", id: "GA" },
    { label: "Gambia", id: "GM" },
    { label: "Georgia", id: "GE" },
    { label: "Germany", id: "DE" },
    { label: "Ghana", id: "GH" },
    { label: "Gibraltar", id: "GI" },
    { label: "Greece", id: "GR" },
    { label: "Greenland", id: "GL" },
    { label: "Grenada", id: "GD" },
    { label: "Guadeloupe", id: "GP" },
    { label: "Guam", id: "GU" },
    { label: "Guatemala", id: "GT" },
    { label: "Guernsey", id: "GG" },
    { label: "Guinea", id: "GN" },
    { label: "Guinea-Bissau", id: "GW" },
    { label: "Guyana", id: "GY" },
    { label: "Haiti", id: "HT" },
    { label: "Heard Island and Mcdonald Islands", id: "HM" },
    { label: "Holy See (Vatican City State)", id: "VA" },
    { label: "Honduras", id: "HN" },
    { label: "Hong Kong", id: "HK" },
    { label: "Hungary", id: "HU" },
    { label: "Iceland", id: "IS" },
    { label: "India", id: "IN" },
    { label: "Indonesia", id: "ID" },
    { label: "Iran, Islamic Republic Of", id: "IR" },
    { label: "Iraq", id: "IQ" },
    { label: "Ireland", id: "IE" },
    { label: "Isle of Man", id: "IM" },
    { label: "Israel", id: "IL" },
    { label: "Italy", id: "IT" },
    { label: "Jamaica", id: "JM" },
    { label: "Japan", id: "JP" },
    { label: "Jersey", id: "JE" },
    { label: "Jordan", id: "JO" },
    { label: "Kazakhstan", id: "KZ" },
    { label: "Kenya", id: "KE" },
    { label: "Kiribati", id: "KI" },
    { label: "Korea, Democratic People'S Republic of", id: "KP" },
    { label: "Korea, Republic of", id: "KR" },
    { label: "Kuwait", id: "KW" },
    { label: "Kyrgyzstan", id: "KG" },
    { label: "Lao People'S Democratic Republic", id: "LA" },
    { label: "Latvia", id: "LV" },
    { label: "Lebanon", id: "LB" },
    { label: "Lesotho", id: "LS" },
    { label: "Liberia", id: "LR" },
    { label: "Libyan Arab Jamahiriya", id: "LY" },
    { label: "Liechtenstein", id: "LI" },
    { label: "Lithuania", id: "LT" },
    { label: "Luxembourg", id: "LU" },
    { label: "Macao", id: "MO" },
    { label: "Macedonia, The Former Yugoslav Republic of", id: "MK" },
    { label: "Madagascar", id: "MG" },
    { label: "Malawi", id: "MW" },
    { label: "Malaysia", id: "MY" },
    { label: "Maldives", id: "MV" },
    { label: "Mali", id: "ML" },
    { label: "Malta", id: "MT" },
    { label: "Marshall Islands", id: "MH" },
    { label: "Martinique", id: "MQ" },
    { label: "Mauritania", id: "MR" },
    { label: "Mauritius", id: "MU" },
    { label: "Mayotte", id: "YT" },
    { label: "Mexico", id: "MX" },
    { label: "Micronesia, Federated States of", id: "FM" },
    { label: "Moldova, Republic of", id: "MD" },
    { label: "Monaco", id: "MC" },
    { label: "Mongolia", id: "MN" },
    { label: "Montserrat", id: "MS" },
    { label: "Morocco", id: "MA" },
    { label: "Mozambique", id: "MZ" },
    { label: "Myanmar", id: "MM" },
    { label: "Namibia", id: "NA" },
    { label: "Nauru", id: "NR" },
    { label: "Nepal", id: "NP" },
    { label: "Netherlands", id: "NL" },
    { label: "New Caledonia", id: "NC" },
    { label: "New Zealand", id: "NZ" },
    { label: "Nicaragua", id: "NI" },
    { label: "Niger", id: "NE" },
    { label: "Nigeria", id: "NG" },
    { label: "Niue", id: "NU" },
    { label: "Norfolk Island", id: "NF" },
    { label: "Northern Mariana Islands", id: "MP" },
    { label: "Norway", id: "NO" },
    { label: "Oman", id: "OM" },
    { label: "Pakistan", id: "PK" },
    { label: "Palau", id: "PW" },
    { label: "Palestinian Territory, Occupied", id: "PS" },
    { label: "Panama", id: "PA" },
    { label: "Papua New Guinea", id: "PG" },
    { label: "Paraguay", id: "PY" },
    { label: "Peru", id: "PE" },
    { label: "Philippines", id: "PH" },
    { label: "Pitcairn", id: "PN" },
    { label: "Poland", id: "PL" },
    { label: "Portugal", id: "PT" },
    { label: "Puerto Rico", id: "PR" },
    { label: "Qatar", id: "QA" },
    { label: "Reunion", id: "RE" },
    { label: "Romania", id: "RO" },
    { label: "Russian Federation", id: "RU" },
    { label: "RWANDA", id: "RW" },
    { label: "Saint Helena", id: "SH" },
    { label: "Saint Kitts and Nevis", id: "KN" },
    { label: "Saint Lucia", id: "LC" },
    { label: "Saint Pierre and Miquelon", id: "PM" },
    { label: "Saint Vincent and the Grenadines", id: "VC" },
    { label: "Samoa", id: "WS" },
    { label: "San Marino", id: "SM" },
    { label: "Sao Tome and Principe", id: "ST" },
    { label: "Saudi Arabia", id: "SA" },
    { label: "Senegal", id: "SN" },
    { label: "Serbia and Montenegro", id: "CS" },
    { label: "Seychelles", id: "SC" },
    { label: "Sierra Leone", id: "SL" },
    { label: "Singapore", id: "SG" },
    { label: "Slovakia", id: "SK" },
    { label: "Slovenia", id: "SI" },
    { label: "Solomon Islands", id: "SB" },
    { label: "Somalia", id: "SO" },
    { label: "South Africa", id: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", id: "GS" },
    { label: "Spain", id: "ES" },
    { label: "Sri Lanka", id: "LK" },
    { label: "Sudan", id: "SD" },
    { label: "Suriname", id: "SR" },
    { label: "Svalbard and Jan Mayen", id: "SJ" },
    { label: "Swaziland", id: "SZ" },
    { label: "Sweden", id: "SE" },
    { label: "Switzerland", id: "CH" },
    { label: "Syrian Arab Republic", id: "SY" },
    { label: "Taiwan, Province of China", id: "TW" },
    { label: "Tajikistan", id: "TJ" },
    { label: "Tanzania, United Republic of", id: "TZ" },
    { label: "Thailand", id: "TH" },
    { label: "Timor-Leste", id: "TL" },
    { label: "Togo", id: "TG" },
    { label: "Tokelau", id: "TK" },
    { label: "Tonga", id: "TO" },
    { label: "Trinidad and Tobago", id: "TT" },
    { label: "Tunisia", id: "TN" },
    { label: "Turkey", id: "TR" },
    { label: "Turkmenistan", id: "TM" },
    { label: "Turks and Caicos Islands", id: "TC" },
    { label: "Tuvalu", id: "TV" },
    { label: "Uganda", id: "UG" },
    { label: "Ukraine", id: "UA" },
    { label: "United Arab Emirates", id: "AE" },
    { label: "United Kingdom", id: "GB" },
    { label: "United States", id: "US" },
    { label: "United States Minor Outlying Islands", id: "UM" },
    { label: "Uruguay", id: "UY" },
    { label: "Uzbekistan", id: "UZ" },
    { label: "Vanuatu", id: "VU" },
    { label: "Venezuela", id: "VE" },
    { label: "Viet Nam", id: "VN" },
    { label: "Virgin Islands, British", id: "VG" },
    { label: "Virgin Islands, U.S.", id: "VI" },
    { label: "Wallis and Futuna", id: "WF" },
    { label: "Western Sahara", id: "EH" },
    { label: "Yemen", id: "YE" },
    { label: "Zambia", id: "ZM" },
    { label: "Zimbabwe", id: "ZW" },
  ];

  return {
    PeriodicType,
    InvPeriod,
    Gender,
    Countries,
    PayMethods,
    ReqType,
    Status,
    VioType,
    Roles,
    AccStatus,
    RevenueType,
    RecuringType,
    TxnType,
    CatType,
    InvTypes,
    PayType,
    BankTxnType,
    PageType,
    OrgPayStatus,
    SubsStatus,
  };
}
