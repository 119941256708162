import { Paper } from "@mui/material";
import React from "react";
import { Carousel } from "../../../../../layout";

const PropertyImgs = ({ data }) => {
  return (
    <Paper>
      <Carousel arrowInside={true}>
        {data.map((item, i) => (
          <div className="d-flex " key={i}>
            <img className="img-fluid br-10" alt="propert-img" src={item} />
          </div>
        ))}
      </Carousel>
    </Paper>
  );
};

export default PropertyImgs;
