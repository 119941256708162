import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputAuto, InputImg } from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const AddEditVendor = (props) => {
  const { _getById, _post, _put, _defHoa, _authUser } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const { Countries } = DropLi();
  const { VendorMdl } = Model();
  const [hideZip, setHideZip] = useState(true);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    resetFormControls,
  } = UseForm(VendorMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200 &&
        setValues({ ...values, ...RmvEmptyProp(res.result) });
    };
    id && _getById(endpoints.Vendor + controller.GetById, id, null, onComplete);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : "Vendor name is required.";
    temp.address1 = values.address1 !== "" ? "" : "Address line is required.";
    temp.city = values.city !== "" ? "" : "City is required.";
    temp.country = values.country !== "" ? "" : "Country is required.";
    temp.state = values.state !== "" ? "" : "State is required.";
    if (hideZip) temp.zip = values.zip !== "" ? "" : "Zip is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Vendor ${id ? "Update" : "Added"} Successfully.`,
            onClick: () =>
              _authUser && _authUser.role !== 1
                ? navigate("/my_account/vendors")
                : navigate("/my_account/"),
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.hoaId = _defHoa.id;
        _post(
          endpoints.Vendor + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.Vendor + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    if (values.country === "JM") {
      setHideZip(false);
      setValues((x) => ({ ...x, zip: "" }));
    } else setHideZip(true);
    return () => {
      setHideZip(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country]);

  return (
    <>
      <Paper className="p-20">
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="mb-10 d-flex j-between a-center">
                <div>
                  <h2 className="h tx-md fw-5 mb-5 tx-upp border-left">
                    {id ? "Edit" : "Add"} Vendor
                  </h2>
                </div>

                <div>
                  <Button type="submit" loading={loading}>
                    {id ? "Save Changes" : "Submit"}{" "}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex a-center pl-5">
                <span
                  className="gray-color tx-upp"
                  style={{ minWidth: "190px" }}
                >
                  General Information
                </span>
                <div className="bs-secondary w-100pr" />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Input
                label="Vendor Name"
                name="name"
                value={values.name}
                error={errors.name}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Nickname"
                name="nickname"
                value={values.nickname}
                error={errors.nickname}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Account Number"
                name="accNumber"
                value={values.accNumber}
                error={errors.accNumber}
                onChange={handleInpChg}
                type="number"
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 25))
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Vendor EIN"
                name="vendorEin"
                value={values.vendorEin}
                error={errors.vendorEin}
                type="number"
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 25))
                }
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputAuto
                label="Country"
                name="country"
                value={values.country}
                error={errors.country}
                onChange={handleInpChg}
                className="mr-5"
                options={Countries}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="City"
                name="city"
                value={values.city}
                error={errors.city}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={!hideZip ? "Parish" : "State"}
                name="state"
                value={values.state}
                error={errors.state}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            {hideZip && (
              <Grid item xs={12} md={6}>
                <Input
                  label="ZIP"
                  name="zip"
                  value={values.zip}
                  error={errors.zip}
                  onChange={handleInpChg}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Input
                label="Address Line 1"
                name="address1"
                value={values.address1}
                error={errors.address1}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Address Line 2"
                name="address2"
                value={values.address2}
                error={errors.address2}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex a-center pl-5 mt-30">
                <span
                  className="gray-color tx-upp"
                  style={{ minWidth: "190px" }}
                >
                  Additional Details
                </span>
                <div className="bs-secondary w-100pr" />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Contact Name"
                name="contactName"
                value={values.contactName}
                error={errors.contactName}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Contact Eamil"
                name="contactEamil"
                value={values.contactEamil}
                error={errors.contactEamil}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Vendor Website"
                name="website"
                value={values.website}
                error={errors.website}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Phone Number"
                name="phone"
                value={values.phone}
                error={errors.phone}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Fax Number"
                name="fax"
                value={values.fax}
                error={errors.fax}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputImg
                label="Attach Logo"
                subtitle="Recomended Logo Size (80 x 80 px)"
                name="logoFile"
                src={values.logo}
                rmv={() => {
                  setValues({ ...values, logoFile: "", logo: "" });
                }}
                onChange={handleFile}
                error={errors.logoFile}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _defHoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditVendor);
