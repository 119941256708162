import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Table } from "../../../layout";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { DropLi } from "../../../hooks";

const Pages = (props) => {
  const { _getAll, _delete } = props;
  const { PageType } = DropLi();
  const navigate = useNavigate();
  const [pages, setPages] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setPages([...res.result]);
    };
    _getAll(endpoints.Page, undefined, onComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEdit = (id) => {
    navigate(`page_form/${id || ""}`);
  };

  const handleDlt = (id) => {
    if (id) {
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "Are you sure!",
        subTitle: "You want to delete this page",
        onConfirm: () => {
          const onComplete = (res) => {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setPages((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else {
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
            }
          };
          _delete(endpoints.Page, id, null, onComplete);
        },
      });
    }
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="Edit">
        <IconButton
          onClick={() => handleAddEdit(props.id)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <Box sx={{ mb: { xs: "10px", md: "0px" } }}>
      <Button
        variant="outlined"
        onClick={() => handleAddEdit(null)}
        className="mr-10"
      >
        Create Page
      </Button>
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "pgType", label: "Page Type" },
          { id: "btnName", label: "Button Name" },
          { id: "title", label: "Title" },
          { id: "createDate", label: "Create Date" },
          { id: "updateDate", label: "Update Date" },
          { id: "url", label: "Page URL" },
        ]}
        tblData={pages.map((obj) => {
          var pgType = PageType.find((j) => j.id === obj.type);
          return {
            ...obj,
            pgType: pgType && pgType.label,
            url: `${
              window.location.protocol + "//" + window.location.host
            }/page/${obj.id}`,
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _getAll: actions.get,
  _set: actions.set,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Pages);
