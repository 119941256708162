import {
  AccountCircleRounded,
  AdminPanelSettingsRounded,
} from "@mui/icons-material";
import { Container, Grid, ListItemIcon, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../controls";
import Statusbar from "./Statusbar";
import { MenuPopup } from "../../layout";
import { useContext } from "react";
import { AppContext } from "../../App";
import { no_img } from "../../assets";

const Header = ({ rating }) => {
  const navigate = useNavigate();
  const { landingPg } = useContext(AppContext);
  const [accountEl, setAccountEl] = useState(null);
  const handleAccEl = (e) => {
    setAccountEl(e.currentTarget);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/header-bg.jpg)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {landingPg && landingPg.id ? (
        <>
          <Container maxWidth="xl" className="h-100vh">
            {/* <--- Header ----> */}
            <header
              className="d-flex j-between"
              style={{ padding: "20px 0px 20px 0px" }}
            >
              <Link to="/">
                <img
                  src={landingPg.logo || no_img}
                  className="img-fluid header-logo"
                  alt="site-logo"
                />
              </Link>

              <div>
                <Button
                  className="br-20 fs-16"
                  variant="outlined"
                  sx={{
                    padding: "3px 10px 3px 10px",
                  }}
                  onClick={handleAccEl}
                  startIcon={<AccountCircleRounded />}
                >
                  My Accounts
                </Button>
                <MenuPopup
                  anchorEl={accountEl}
                  setAnchorEl={setAccountEl}
                  id="account-menu"
                  className="mt-5"
                >
                  <MenuItem
                    className="tx-upp"
                    onClick={() => navigate("my_account")}
                  >
                    <ListItemIcon>
                      <AdminPanelSettingsRounded fontSize="small" />
                    </ListItemIcon>
                    Manager Portal
                  </MenuItem>
                  <MenuItem
                    className="tx-upp"
                    onClick={() => navigate("owner_portal")}
                  >
                    <ListItemIcon>
                      <AccountCircleRounded fontSize="small" />
                    </ListItemIcon>
                    Owner Portal
                  </MenuItem>
                </MenuPopup>
              </div>
            </header>

            {/* <--- Header Body ----> */}

            <Grid
              container
              spacing={2}
              sx={{
                height: "70%",
                alignItems: "center",
                display: "flex",
                paddingTop: { lg: "0px", xs: "60px" },
              }}
            >
              <Grid item xs={12} md={6}>
                <Box data-aos="zoom-out">
                  <h1 className="h h1">{landingPg.title}</h1>
                  <p className="p mb-30">{landingPg.subtitle}</p>
                  <Button
                    className="br-20"
                    sx={{ fontSize: "16px", padding: "10px 15px 10px 15px" }}
                  >
                    <a
                      href="/#subscriptions"
                      className="tx-und-none white-color"
                    >
                      Get Free Trial
                    </a>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: { xs: "50px", md: "0px" } }}>
                <div data-aos="zoom-out">
                  <img
                    className="img-fluid animated"
                    alt="app-img"
                    src={landingPg.img || no_img}
                  />
                </div>
              </Grid>
            </Grid>

            {/* <--- Statusbar ----> */}
            <Box sx={{ display: { md: "block", xs: "none" } }}>
              <Statusbar {...{ rating }} />
            </Box>
          </Container>

          <Box sx={{ display: { md: "none", xs: "block" }, padding: "20px" }}>
            <Statusbar {...{ rating }} />
          </Box>
        </>
      ) : (
        <div className="h-100vh"></div>
      )}
    </Box>
  );
};

export default Header;
