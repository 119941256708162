import { Box } from "@mui/system";
import React from "react";
import { InputDate } from "../../../controls";
import { subDays } from "date-fns";
import moment from "moment";

const RevNav = ({ sortDate, setSortDate }) => {
  const handleInpChange = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  return (
    <div className="d-flex j-between flex-wrap a-center mt-20">
      <Box sx={{ mb: { md: "0px", xs: "15px" } }}>
        <h2 className="border-left h fw-5 fs-20 gray-color tx-upp">
          Revenue Report
        </h2>
      </Box>

      <div className="d-flex a-center">
        <InputDate
          variant="outlined"
          sx={{ maxWidth: "150px" }}
          size="small"
          name="fromDate"
          value={sortDate.fromDate}
          views={["day", "month", "year"]}
          openTo="day"
          minDate={new Date("01/01/2000")}
          maxDate={subDays(new Date(sortDate.toDate), 1)}
          onChange={(date) =>
            handleInpChange("fromDate", new Date(date).toLocaleDateString())
          }
        />
        <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
        <InputDate
          variant="outlined"
          sx={{ maxWidth: "150px" }}
          size="small"
          name="toDate"
          value={sortDate.toDate}
          minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
          views={["day", "month", "year"]}
          openTo="day"
          onChange={(date) =>
            handleInpChange("toDate", new Date(date).toLocaleDateString())
          }
        />
      </div>
    </div>
  );
};

export default RevNav;
