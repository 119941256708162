import React, { useState } from "react";
import FilterBudget from "./FilterBudget";
import { useEffect } from "react";
import moment from "moment";
import { Snackbar } from "../../../layout";
import BudgetForm from "./BudgetForm";

const BuildBudgets = () => {
  const [headerDate, setHeaderDate] = useState([{ year: 2023, months: [] }]);
  const [bdgType, setBdgType] = useState(1);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/1/${new Date().getFullYear()}`,
  });
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });

  useEffect(() => {
    if (sortDate) {
      var startDate = moment(sortDate.fromDate, "MM/DD/YYYY");
      var endDate = moment(sortDate.toDate, "MM/DD/YYYY");

      if (startDate < endDate) {
        var date = startDate.startOf("month");
        var betweenMonths = [];
        var prevYear = "";
        while (date < endDate.endOf("month")) {
          if (prevYear === date.format("YYYY")) {
            betweenMonths = betweenMonths.map((j) =>
              j.year === date.format("YYYY")
                ? {
                    ...j,
                    months: [
                      ...j.months,
                      { id: date.format("MM"), name: date.format("MMM") },
                    ],
                  }
                : j
            );
          } else {
            betweenMonths.push({
              year: date.format("YYYY"),
              months: [{ id: date.format("MM"), name: date.format("MMM") }],
            });
          }
          prevYear = date.format("YYYY");
          date.add(1, "month");
        }
        setHeaderDate(betweenMonths);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDate]);

  return (
    <>
      <div className="mb-30">
        <FilterBudget
          {...{
            bdgType,
            setBdgType,
            isBuildBudget: true,
            setSortDate,
            sortDate,
          }}
        />
      </div>
      {bdgType === 1 ? (
        <BudgetForm {...{ headerDate, title: "Revenue", budgetType: 1 }} />
      ) : (
        <BudgetForm {...{ headerDate, title: "Expense", budgetType: 2 }} />
      )}

      <Snackbar {...{ snackbar, setSnackbar }} />
    </>
  );
};

export default BuildBudgets;
