import React, { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Box, List as MuiList, IconButton, Drawer } from "@mui/material";
import { WinDims } from "../../hooks/Method";
import { MenuRounded } from "@mui/icons-material";
import "./Dashboard.scss";
import { no_img } from "../../assets";
import { AppContext } from "../../App";

const List = styled(MuiList)({
  "&": {
    margin: "5px",
  },
  "& .MuiListItemButton-root": {
    borderRadius: "10px",
    marginBottom: "0px",
    "&, & .MuiListItemIcon-root": {
      color: "var(--gray-color)",
      minWidth: "40px",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "var(--primaryXS-color)",
    "&, & .MuiListItemIcon-root": {
      color: "var(--primary-color)",
    },
    "& .MuiListItemText-root .MuiTypography-root": {
      fontWeight: "500",
      color: "var(--primary-color)",
    },
  },
});

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  top: "inherit",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Dashboard(props) {
  const { menu, header, children, photo = "", showNav = true } = props;
  const { openSidebar, setOpenSidebar } = useContext(AppContext);
  const minWidth = 950;
  const windowDims = WinDims().width;

  const toggleDrawer = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    showNav && setOpenSidebar(windowDims > minWidth ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDims]);

  return (
    <div style={{ display: "flex" }}>
      {/* --- Navbar --- */}
      {header && (
        <AppBar open={openSidebar} className="appbar">
          <div className="appbar-container">
            <div className="appbar-inner-div">
              <div className="ml-10">
                {showNav && (
                  <IconButton onClick={toggleDrawer} size="small">
                    <MenuRounded />
                  </IconButton>
                )}
              </div>
              {showNav && header}
            </div>
          </div>
        </AppBar>
      )}
      {/* --- Menu --- */}
      {menu && (
        <Drawer
          open={openSidebar}
          onClose={() => setOpenSidebar(!openSidebar)}
          variant="persistent"
          sx={{
            "& .MuiDrawer-paper": {
              display: openSidebar ? "block" : "none",
              width: "280px",
              position: { md: "relative", xs: "" },
              backgroundColor: "inherit",
              border: "none",
              boxShadow: "none",
              borderRadius: "0px",
            },
          }}
        >
          <div className="sidebar">
            <div className="sidebar-container">
              <div className="sidebar-card">
                <img
                  alt="profile-pic"
                  src={photo || no_img}
                  className="h-40 obj-cover"
                />
              </div>

              <List
                sx={{
                  height: "calc(100vh - 160px)",
                  overflow: "auto",
                  p: "30px 5px 10px 5px",
                }}
              >
                {menu}
              </List>
            </div>
          </div>
        </Drawer>
      )}

      {/* --- Body --- */}
      <Box
        component="main"
        className="bg-inherit p-20 pt-90 w-100pr mt-70"
        sx={{
          overflow: "auto",
          height: "calc(100vh - 110px)",
        }}
      >
        {children}
      </Box>
    </div>
  );
}
