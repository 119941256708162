export default function Model() {
  const LoginMdl = () => ({
    email: "",
    password: "",
  });
  const DeactiveInvMdl = () => ({
    invoiceId: "",
    note: "",
  });
  const LandingPgMdl = () => ({
    id: 0,
    siteName: "",
    title: "",
    subtitle: "",

    desc: "",
    address: "",
    phone: "",
    email: "",
    fax: "",

    socialMedias: [],

    logo: "",
    logoFile: "",
    img: "",
    imgFile: "",
    footerLogo: "",
    footerLogoFile: "",
  });

  const ReviewMdl = () => ({
    id: 0,
    rating: 0,
    desc: "",
  });

  const BankRecoMdl = () => ({
    id: 0,
    hoaId: "",
    txnId: "",
    payDate: "",
    txnType: "",
    bankId: "",
    amount: "",
  });

  const SubsHoldMdl = () => ({
    id: 0,
    subsId: "",
    userId: "",
    orgId: "",
    title: "",
    reason: "",
  });

  const PageMdl = () => ({
    id: 0,
    type: "",
    title: "",
    btnName: "",
    content: "",
  });

  const SubsSettingsMdl = () => ({
    id: 0,
    yearlyPayDis: "",
  });

  const SubsPkgMdl = () => ({
    id: 0,
    title: "",
    price: "",
    trialDuration: "",
    note: "",

    branch: "",
    unlimitedBranch: false,
    unit: "",
    unlimitedUnit: false,
    owner: "",
    unlimitedOwner: false,
    vendor: "",
    unlimitedVendor: false,
    user: "",
    unlimitedUser: false,
    createdUserId: "",

    photo: "",
    photoFile: "",
  });

  const NoticeMdl = () => ({
    id: 0,
    title: "",
    msg: "",
    userId: "",
    hoaId: "",
    files: [],
    noticeFiles: [],
  });

  const FeatureMdl = () => ({
    id: 0,
    title: "",
    subtitle: "",
    featureItems: [],
    img: "",
    imgFile: "",
  });

  const InvPlanMdl = () => ({
    id: 0,
    name: "",
    duration: "",
    periodic: "",
    hoaId: "",
    userId: "",
  });

  const ChgPassMdl = () => ({
    oldPass: "",
    password: "",
    confirmPass: "",
  });

  const OrgMdl = () => ({
    id: 0,
    orgName: "",
    email: "",
    phone: "",
    fax: "",
    logo: "",
    logoFile: "",
  });

  const HoaMdl = () => ({
    id: 0,
    hoaName: "",
    ein: "",
    email: "",
    phone: "",
    fax: "",
    country: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });

  const BudgetMdl = () => ({
    id: 0,
    userId: "",
    hoaId: "",
    catId: "",
    month: "",
    year: "",
    amount: "0",
  });

  const HoaCatMdl = () => ({
    id: 0,
    userId: "",
    hoaId: "",
    catType: "",
    name: "",
  });
  const InvNoteMdl = () => ({
    id: 0,
    invId: "",
    userId: "",
    note: "",
  });
  const ARMdl = () => ({
    id: 0,
    toDate: "",
    amount: "",
    categoryId: "",
    bankAccId: "",
    ownerId: "",
    units: [],
    memo: "",
    descr: "",
  });

  const APMdl = () => ({
    id: 0,
    dueDate: "",
    amount: "",
    categoryId: "",
    bankAccId: "",
    vendorId: "",
    memo: "",
    descr: "",
    files: [],
    apFiles: [],
  });

  const VendorNoteMdl = () => ({
    id: 0,
    note: "",
    vendorId: "",
    userId: "",
  });

  const FileMdl = () => ({
    id: 0,
    isPrivate: true,
    fileType: "",
    file: "",
    fileName: "",
  });

  const PayVendorMdl = () => ({
    id: 0,
    vendorId: "",
    bankAccId: "",
    amount: "",
    checkNum: "",
    memo: "",
    msg: "",
    createDate: "",
  });

  const VendorMdl = () => ({
    // General Information.
    id: 0,
    name: "",
    nickname: "",
    accNumber: "",
    vendorEin: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    createDate: "",
    updDate: "",
    // Additional Details.
    contactName: "",
    contactEamil: "",
    website: "",
    phone: "",
    fax: "",
    logo: "",
    logoFile: "",
  });

  const InvSettingsMdl = () => ({
    id: 0,
    earlyPayDis: "",
    lateFee: "",
  });

  const InvoiceMdl = () => ({
    id: 0,
    invType: "",
    title: "",
    descr: "",
    invPlanId: "",
    bankAccId: "",
    categoryId: "",
    amount: "",
    unitId: "",
    sendEmail: true,
    issueDate: new Date().toLocaleDateString(),
    todayDate: new Date().toLocaleDateString(),
    dueDate: "",
    earlyPayDis: false,
    isLateFee: false,
    createDate: new Date().toLocaleString(),
  });

  const ReqMdl = () => ({
    id: 0,
    rcdType: "",
    type: "",
    unitId: "",
    isComplete: "",
    title: "",
    msg: "",
    resDeadline: "",
    createUserId: "",
    cmpltUserId: "",
    files: [],
    reqFiles: [],
  });

  const OwnerMdl = () => ({
    id: 0,
    fullName: "",
    email: "",
    address1: "",
    address2: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    units: [],
    password: "",
    confirmPass: "",
    createDate: "",
    lastLogin: "",
    isActive: "",
  });

  const ForgotMdl = () => ({
    email: "",
    forgotStep: "",
    verCode: "",
    password: "",
    confirmPass: "",
  });

  const EmailMdl = () => ({
    id: 0,
    ownerId: "",
    email: "",
    subject: "",
    msg: "",
  });

  const PaymentMdl = () => ({
    id: 0,
    unitId: "",
    userId: "",
    hoaId: "",
    invId: "",
    ownerId: "",
    amount: "",
    method: "",
    payType: "",
    refe: "",
    payDate: new Date().toLocaleDateString(),
    emailReceipt: false,
    note: "",
    createDate: "",
    files: [],
  });

  const BankAccMdl = () => ({
    id: 0,
    userId: "",
    hoaId: "",
    accName: "",
    routingNum: "",
    accountNum: "",
    isDeposit: false,
  });

  const UserMdl = () => ({
    id: 0,
    fullName: "",
    email: "",
    hoaName: "",
    orgName: "",
    password: "",
    confirmPass: "",
    role: "",

    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    photo: "",
    photoFile: "",
  });

  const AdminMdl = () => ({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPass: "",
    role: "",

    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    photo: "",
    photoFile: "",
  });

  const UnitMdl = () => ({
    id: 0,
    title: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    unitImgs: [],
    imgsFile: [],
    owners: [],
    unitOwners: [],
  });

  const UnitCsvMdl = () => ({
    id: 0,
    hoaId: "",
    orgId: "",
    csvFile: "",
  });

  return {
    HoaCatMdl,
    LoginMdl,
    ForgotMdl,
    UserMdl,
    BankAccMdl,
    UnitMdl,
    OwnerMdl,
    PaymentMdl,
    EmailMdl,
    ReqMdl,
    InvoiceMdl,
    VendorMdl,
    PayVendorMdl,
    FileMdl,
    VendorNoteMdl,
    APMdl,
    BudgetMdl,
    InvSettingsMdl,
    ARMdl,
    HoaMdl,
    ChgPassMdl,
    OrgMdl,
    InvNoteMdl,
    BankRecoMdl,
    InvPlanMdl,
    UnitCsvMdl,
    NoticeMdl,
    AdminMdl,
    SubsPkgMdl,
    SubsHoldMdl,
    SubsSettingsMdl,
    PageMdl,
    LandingPgMdl,
    FeatureMdl,
    ReviewMdl,
    DeactiveInvMdl,
  };
}
