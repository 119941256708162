import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";

const Log = (props) => {
  const { _getById, _defHoa } = props;
  const [logs, setLogs] = useState([]);
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    if (_defHoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setLogs([...res.result]);
      };
      _getById(
        endpoints.Org + controller.LogsByHoa,
        _defHoa.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_defHoa]);

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={() => <></>}
        tblHead={[
          { id: "type", label: "Action On" },
          { id: "action", label: "Action Type" },
          { id: "userName", label: "User Name" },
          { id: "date", label: "Date" },
          { id: "desc", label: "Description" },
        ]}
        tblData={logs.map((j) => ({
          ...j,
          userName: `${j.user.fullName} (${j.user.id})`,
        }))}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Log);
