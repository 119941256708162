import { LinearProgress } from "@mui/material";
import React from "react";

const Loader = ({ show = false }) => {
  return (
    <div className={(show ? "d-block" : "d-none") + " pos-relative"}>
      <div className="pos-absolute m-auto top-0 bottom-0 left-0 right-0 z-index-top">
        <LinearProgress color="primary" className="h-5" />
      </div>
    </div>
  );
};

export default Loader;
