import { Grid } from "@mui/material";
import React from "react";
import { Button, Input, InputMultFile } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import { NestedFormData, RmvEmptyProp } from "../../../hooks/Method";

const AddEditReq = (props) => {
  const { setNtcs, _post, _hoa, _authUser, setShowForm, setAlert } = props;
  const { NoticeMdl } = Model();
  const [loading, setLoading] = useState(false);
  const { values, setValues, errors, setErrors, handleInpChg, handleFile } =
    UseForm(NoticeMdl);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : "Title is required.";
    temp.msg = values.msg !== "" ? "" : "Messages is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201) {
          setNtcs && setNtcs((x) => [res.result, ...x]);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        values.userId = _authUser.id;
        _post(
          endpoints.Notice + controller.Post,
          NestedFormData(RmvEmptyProp(values), "files"),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate className="p-15">
      <Grid container spacing={2}>
        <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
          <h2 className="h fw-5 mb-5 tx-upp">Add Notice</h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-60" />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Input
            label="Title"
            name="title"
            value={values.title}
            error={errors.title}
            onChange={handleInpChg}
            inputProps={{ maxLength: 290 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            multiline
            rows={7}
            label="Message"
            name="msg"
            value={values.msg}
            error={errors.msg}
            onChange={handleInpChg}
            inputProps={{ maxLength: 1900 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputMultFile
            label="Upload File"
            name="files"
            maxSize={6}
            values={values.files}
            onChange={handleFile}
            rmv={(file) => {
              setValues((x) => ({
                ...x,
                files: x.files.filter(
                  (j) => j.lastModified !== file.lastModified
                ),
                noticeFiles: x.noticeFiles.filter((j) => j.id !== file.id),
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="text" size="large" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button size="large" type="submit" loading={loading} fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditReq);
