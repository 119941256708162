import { LocationOn } from "@mui/icons-material";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import "../../../dashboard/Units/UnitWidget/UnitWidget.scss";

const UnitWidget = (props) => {
  const { unit } = props;
  const [defOwner, setDefOwner] = useState();

  useEffect(() => {
    if (unit) {
      var firstOwner = unit.unitOwners.find((j) => j.isContactOwner);
      firstOwner && setDefOwner(firstOwner.owner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  return (
    <Paper className="unit-widget">
      <div className="unit-widget-img">
        <img
          alt="property-img"
          className="img-fluid"
          src={
            unit.unitImgs.length > 0
              ? unit.unitImgs[0].imgUrl
              : `${process.env.PUBLIC_URL}/assets/no-img.png`
          }
        />
      </div>

      <div className="unit-widget-body">
        <div className="p-15">
          <h2 className="h fs-20 fw-5 mb-5">{unit.title}</h2>
          {defOwner && (
            <span className="fs-14 gray-color">{defOwner.fullName}</span>
          )}
        </div>

        <div className="unit-widget-deatil">
          <LocationOn className="mr-15" color="error" />
          <span className="tx-sm">
            {unit.address1 + (unit.address2 && " | " + unit.address2)}
          </span>
        </div>
      </div>
    </Paper>
  );
};

export default UnitWidget;
