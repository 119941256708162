import { Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { Model, UseForm } from "../../../hooks";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { Alert, Form } from "../../../layout";
import { Button, Input, InputImg } from "../../../controls";
import * as actions from "../../../api/actions/actions";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";

const OrgSettings = (props) => {
  const { _put, _authUser, _get } = props;
  const { OrgMdl } = Model();
  const navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInpChg, handleFile } =
    UseForm(OrgMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const validateForm = () => {
    let temp = {};
    temp.orgName = values.orgName !== "" ? "" : "Full Name is required.";
    temp.email =
      values.email !== ""
        ? /^$|.+@.+..+/.test(values.email)
          ? ""
          : "Email is not valid."
        : "Email is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // Create Action
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200)
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "You have successfuly updated the organization details.",
          });
      };
      _put(
        endpoints.Org + controller.Put,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200
        ? setValues({ ...res.result })
        : setAlert({
            isOpen: true,
            type: "error",
            title: "Opps!",
            subTitle: "Something is Wrong. Refresh the page and try again.",
            onClick: () => navigate("/my_account/"),
          });
    };
    _authUser &&
      _get(endpoints.Org + controller.GetAuthUserOrg, null, onComplete);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser]);

  return (
    <>
      <Container maxWidth="sm">
        <Paper className="p-15">
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="tx-center p-20 mb-10 w-100pr">
                <h2 className="fs-22 fw-5 mb-5 tx-upp gray-color">
                  Organization Details
                </h2>
                <div className="border-bottom w-60 m-auto" />
              </Grid>
              <Grid item xs={12}>
                <span className="fw-5 fs-20 gray-color ml-10">
                  ID: {values.id}
                </span>
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Organization Name"
                  name="orgName"
                  onChange={handleInpChg}
                  value={values.orgName}
                  error={errors.orgName}
                  fullWidth
                  inputProps={{ maxLength: 300 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Eamil"
                  name="email"
                  onChange={handleInpChg}
                  value={values.email}
                  error={errors.email}
                  fullWidth
                  inputProps={{ maxLength: 300 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Phone"
                  name="phone"
                  onChange={handleInpChg}
                  value={values.phone}
                  error={errors.phone}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Fax"
                  name="fax"
                  onChange={handleInpChg}
                  value={values.fax}
                  error={errors.fax}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputImg
                  label="Logo"
                  name="logoFile"
                  src={values.logo}
                  rmv={() => {
                    setValues({ ...values, logoFile: "", logo: "" });
                  }}
                  onChange={handleFile}
                  error={errors.logoile}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} className="tx-right mt-10">
                <Button loading={loading} type="submit">
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Container>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(OrgSettings);
