import { TableCell } from "@mui/material";
import React, { useContext } from "react";
import { Table } from "../../../layout";
import { connect } from "react-redux";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import moment from "moment";
import RepNav from "./RepNav";

const ARRep = (props) => {
  const { _getById, _hoa } = props;
  const [invs, setInvs] = useState([]);
  const [units, setUnits] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [filteredInvs, setFilteredInvs] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    setCsvHeader([
      { label: "Unit", key: "unitName" },
      { label: "Owner", key: "owner" },
      { label: "01 to 30 Days", key: "last30Days" },
      { label: "31 to 60 Days", key: "last31to60Days" },
      { label: "61 to 90 Days	", key: "last61to90Days" },
      { label: "Over 90 Days", key: "more90Days" },
      { label: "Total Due", key: "total" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setInvs([
            ...res.result.filter(
              (j) =>
                j.invType !== 3 &&
                j.invPayments.length > 0 &&
                j.invPayments.some((x) => x.status !== 2)
            ),
          ]);
      };
      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        onComplete
      );
      _getById(
        endpoints.Unit + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setUnits([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    if (filteredInvs && units) {
      setTblData([
        ...units
          .filter((j) => filteredInvs.some((x) => x.unitId === j.id))
          .map((unit) => {
            var unitInv = filteredInvs
              .filter((j) => j.unitId === unit.id)
              .map((j) => j.invPayments)
              .flat();

            var credits = unit.unitCreditDebits.filter(
              (j) => j.unitId === unit.id
            );

            var last30daysBal = credits
              .filter(
                (j) =>
                  moment().diff(moment(j.addDate, "MM/DD/YYYY"), "days") >= 0 &&
                  moment().diff(moment(j.addDate, "MM/DD/YYYY"), "days") <= 30
              )
              .map((j) => j.balance)
              .reduce((x, y) => x + y, 0);

            var last30Days =
              unitInv
                .filter(
                  (j) =>
                    moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >=
                      0 &&
                    moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") <=
                      30
                )
                .map(
                  (j) =>
                    j.amount -
                    j.paidAmt +
                    j.invLateFees
                      .map((x) => x.amount - x.paidAmt)
                      .reduce((x, y) => x + y, 0)
                )
                .reduce((x, y) => x + y, 0) - last30daysBal;

            var last31to60DaysBal = credits
              .filter(
                (j) =>
                  moment().diff(moment(j.addDate, "MM/DD/YYYY"), "days") >=
                    31 &&
                  moment().diff(moment(j.addDate, "MM/DD/YYYY"), "days") <= 60
              )
              .map((j) => j.balance)
              .reduce((x, y) => x + y, 0);

            var last31to60Days =
              unitInv
                .filter(
                  (j) =>
                    moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >=
                      31 &&
                    moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") <=
                      60
                )
                .map(
                  (j) =>
                    j.amount -
                    j.paidAmt +
                    j.invLateFees
                      .map((x) => x.amount - x.paidAmt)
                      .reduce((x, y) => x + y, 0)
                )
                .reduce((x, y) => x + y, 0) - last31to60DaysBal;

            var last61to90DaysBal = credits
              .filter(
                (j) =>
                  moment().diff(moment(j.addDate, "MM/DD/YYYY"), "days") >=
                    61 &&
                  moment().diff(moment(j.addDate, "MM/DD/YYYY"), "days") <= 90
              )
              .map((j) => j.balance)
              .reduce((x, y) => x + y, 0);
            var last61to90Days =
              unitInv
                .filter(
                  (j) =>
                    moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >=
                      61 &&
                    moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") <=
                      90
                )
                .map(
                  (j) =>
                    j.amount -
                    j.paidAmt +
                    j.invLateFees
                      .map((x) => x.amount - x.paidAmt)
                      .reduce((x, y) => x + y, 0)
                )
                .reduce((x, y) => x + y, 0) - last61to90DaysBal;

            var more90DaysBal = credits
              .filter(
                (j) =>
                  moment().diff(moment(j.addDate, "MM/DD/YYYY"), "days") >= 90
              )
              .map((j) => j.balance)
              .reduce((x, y) => x + y, 0);
            var more90Days =
              unitInv
                .filter(
                  (j) =>
                    moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >=
                    90
                )
                .map(
                  (j) =>
                    j.amount -
                    j.paidAmt +
                    j.invLateFees
                      .map((x) => x.amount - x.paidAmt)
                      .reduce((x, y) => x + y, 0)
                )
                .reduce((x, y) => x + y, 0) - more90DaysBal;

            var total =
              last30Days + last31to60Days + last61to90Days + more90Days;
            return {
              unitName: unit.title,
              owner: unit.unitOwners[0] && unit.unitOwners[0].owner.fullName,
              last30Days,
              last31to60Days,
              last61to90Days,
              more90Days,
              total,
            };
          }),
      ]);
    }
  }, [filteredInvs, units]);

  useEffect(() => {
    invs &&
      setFilteredInvs([
        ...invs.filter(
          (j) =>
            moment(j.issueDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.issueDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);

    return () => {
      setFilteredInvs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invs, sortDate]);

  const HeaderComp = () => (
    <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">A/R Aging</h2>
  );

  return (
    <>
      <div className="mt-20 mb-10">
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "A/R Aging",
            showTitle: false,
          }}
        />
      </div>
      <div className="mt-15">
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={HeaderComp}
          tblHead={[
            { id: "unitName", label: "Unit" },
            { id: "owner", label: "Owner" },
            { id: "last30Days", label: "01 to 30 Days" },
            { id: "last31to60Days", label: "31 to 60 Days" },
            { id: "last61to90Days", label: "61 to 90 Days" },
            { id: "more90Days", label: "Over 90 Days" },
            { id: "total", label: "Total Due" },
          ]}
          tblData={tblData.map((j) => {
            return {
              ...j,
              owner: j.owner,
              total: (
                <span className="gray-color fw-6 fs-italic">
                  ${j.total.toLocaleString()}
                </span>
              ),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total
              </TableCell>
              <TableCell></TableCell>

              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.last30Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.last31to60Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.last61to90Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.more90Days)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {tblData
                  .map((j) => j.total)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(ARRep);
