import React, { Fragment, useEffect } from "react";
import { Alert, Form, Tabs } from "../../../layout";
import { Divider, Grid, Paper } from "@mui/material";
import { Model, UseForm } from "../../../hooks";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import {
  BGNestedObjToFromData,
  RandomCode,
  RmvEmptyProp,
} from "../../../hooks/Method";
import { Button, Input, InputImg } from "../../../controls";
import { AddRounded } from "@mui/icons-material";

const LPForm = (props) => {
  const { _get, _put } = props;
  const { FeatureMdl } = Model();
  const { values, setValues, errors, setErrors, handleInpChg, handleFile } =
    UseForm(FeatureMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    _get(endpoints.Feature, null, (res) => {
      res.result && setValues((j) => ({ ...j, ...res.result }));
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : "Title is required.";
    temp.subtitle = values.subtitle !== "" ? "" : "Subtitle is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "You have successfully updated the landing page data.",
          });
        } else if (res.status === 409)
          setErrors((x) => ({ ...x, email: res.msg }));
        else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };

      values.id &&
        _put(
          endpoints.Feature + controller.Put,
          BGNestedObjToFromData(RmvEmptyProp(values)),
          null,
          onComplete
        );
    }
  };

  const addSocialMedia = () => {
    setValues((j) => ({
      ...j,
      featureItems: [
        ...j.featureItems,
        {
          id: 0,
          key: RandomCode(10),
          title: "",
          subtitle: "",
          link: "",
          img: "",
          imgFile: "",
        },
      ],
    }));
  };

  const handleRmvItem = (ind) => {
    setValues((j) => ({
      ...j,
      featureItems: [...j.featureItems.filter((item, i) => i !== ind)],
    }));
  };

  const handleMediaChg = (e, ind) => {
    const { value, name } = e.target;
    setValues((j) => ({
      ...j,
      featureItems: [
        ...j.featureItems.map((item, i) =>
          i === ind ? { ...item, [name]: value } : item
        ),
      ],
    }));
  };

  const handleImg = (e, ind) => {
    const { value } = e;
    setValues((j) => ({
      ...j,
      featureItems: [
        ...j.featureItems.map((x, i) =>
          i === ind ? { ...x, imgFile: value } : x
        ),
      ],
    }));
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-15 pl-15 pr-15">
            <div>
              <span className="fs-22 fw-5 tx-upp border-left gray-color">
                Features
              </span>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                Save Changes
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-15 pr-15 pb-15">
            <Tabs
              className="mt-30"
              header={[
                { label: "General Field", icon: "" },
                { label: "Options", icon: "" },
              ]}
            >
              {/* General Field */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label="Title"
                    name="title"
                    value={values.title}
                    error={errors.title}
                    required={true}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 300 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    multiline
                    rows={3}
                    label="Subtitle"
                    name="subtitle"
                    required={true}
                    value={values.subtitle}
                    error={errors.subtitle}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 500 }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputImg
                    label="img"
                    name="imgFile"
                    src={values.img}
                    value={values.imgFile}
                    rmv={() => {
                      setValues({ ...values, imgFile: "", img: "" });
                    }}
                    onChange={handleFile}
                    error={errors.imgFile}
                  />
                </Grid>
              </Grid>

              {/* Options */}
              <Grid container spacing={3}>
                {values.featureItems.map((item, ind) => {
                  return (
                    <Fragment key={ind}>
                      <Grid item xs={12} md={2}>
                        <InputImg
                          label="Image"
                          name={`img${ind}`}
                          onChange={(e) => handleImg(e, ind)}
                          src={item.img}
                          value={item.imgFile}
                          rmv={() => {
                            setValues((j) => ({
                              ...j,
                              featureItems: [
                                ...j.featureItems.map((x, i) =>
                                  i === ind ? { ...x, img: "", imgFile: "" } : x
                                ),
                              ],
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <div className="d-grid g-15">
                          <Input
                            label="Title"
                            name="title"
                            value={item.title}
                            inputProps={{ maxLength: 200 }}
                            onChange={(e) => handleMediaChg(e, ind)}
                            fullWidth
                          />
                          <Input
                            label="Subtitle"
                            name="subtitle"
                            value={item.subtitle}
                            onChange={(e) => handleMediaChg(e, ind)}
                            inputProps={{ maxLength: 500 }}
                            fullWidth
                          />
                          <Input
                            label="Link"
                            name="link"
                            value={item.link}
                            onChange={(e) => handleMediaChg(e, ind)}
                            inputProps={{ maxLength: 1000 }}
                            fullWidth
                          />
                          <div className="tx-right">
                            <Button
                              onClick={() => handleRmvItem(ind)}
                              variant="text"
                              size="small"
                              sx={{ minWidth: "100px" }}
                              className="bg-errorSM error-color"
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Fragment>
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    onClick={() => addSocialMedia()}
                    variant="text"
                    size="large"
                    sx={{ minWidth: "120px" }}
                    startIcon={<AddRounded />}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(LPForm);
