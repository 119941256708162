import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useNavigate, useParams } from "react-router-dom";
import { controller, endpoints } from "../../../api/actions/api";
import { useState } from "react";
import Tabs from "../../../layout/Tabs";
import InvDetails from "./InvDetails";
import PayHistory from "./PayHistory";
import InvCredits from "./InvCredits";
import { IconButton } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";

const ViewInv = (props) => {
  const { _getById } = props;
  const { invId } = useParams();
  const [inv, setInv] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    invId &&
      _getById(
        endpoints.Invoice + controller.GetDetailById,
        invId,
        null,
        (res) => res.status === 200 && setInv({ ...res.result })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invId]);

  return (
    <>
      <div className="d-flex j-between a-center">
        <div className="mr-20">
          <IconButton
            onClick={() => navigate(-1)}
            size="small"
            className="bs-secondary"
          >
            <ArrowBackRounded />
          </IconButton>
        </div>

        <div className="d-grid mb-15 mt-20">
          <span className="fs-12 gray-color mb-5">Invoice Title</span>
          <span className="tx-md fs-18 tx-upp gray-color">
            ({inv.id}) {inv.title}
          </span>
        </div>
      </div>

      <Tabs
        header={[
          { label: "Invoice Details" },
          { label: "Payment History" },
          { label: "Credit / Debit" },
        ]}
      >
        <InvDetails data={inv || []} />
        <PayHistory payments={inv.invPayments || []} />
        <InvCredits invId={inv.id} />
      </Tabs>
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewInv);
