import {
  ClearRounded,
  EditRounded,
  LandscapeRounded,
} from "@mui/icons-material";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Table } from "../../../layout";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { DropLi } from "../../../hooks";

const Users = (props) => {
  const { _getAll, _delete, _admin } = props;
  const { Roles } = DropLi();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setUsers([...res.result]);
    };
    _getAll(endpoints.Admin, undefined, onComplete);

    return () => {
      setUsers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEdit = (id) => {
    navigate(`user_form/${id || ""}`);
  };

  const handleDlt = (id) => {
    if (id) {
      if (id === _admin.id)
        setAlert({
          isOpen: true,
          type: "warning",
          title: "Warning",
          subTitle:
            "You can't delete this user. Because this is the logged in user right now.",
        });
      else
        setAlert({
          isOpen: true,
          type: "confirmation",
          title: "Are you sure!",
          subTitle: "You want to delete this user?",
          onConfirm: () => {
            const onComplete = (res) => {
              if (res.status === 200) {
                setAlert({
                  isOpen: true,
                  type: "success",
                  title: "Deleted Successfully",
                  subTitle: res.msg,
                });
                setUsers((x) => [...x.filter((j) => j.id !== res.result.id)]);
              } else {
                setAlert({
                  isOpen: true,
                  type: "warning",
                  title: "Warning",
                  subTitle: res.msg,
                });
              }
            };
            _delete(endpoints.Admin, id, null, onComplete);
          },
        });
    }
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="Edit">
        <IconButton
          onClick={() => handleAddEdit(props.id)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <Box sx={{ mb: { xs: "10px", md: "0px" } }}>
      <Button
        variant="outlined"
        onClick={() => handleAddEdit(null)}
        className="mr-10"
      >
        Add User
      </Button>
    </Box>
  );
  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "photo", label: "Photo" },
          { id: "fullName", label: "Full Name" },
          { id: "role", label: "Role" },
          { id: "email", label: "Email" },
          { id: "phone", label: "Phone" },
        ]}
        tblData={users.map((obj) => {
          var roleName = Roles.find((j) => j.id === obj.role);
          return {
            ...obj,
            role: roleName && roleName.label,
            fullName: obj.firstName + " " + obj.lastName,
            photo: (
              <Avatar
                src={obj.photo}
                alt="logo"
                className="bg-primary"
                sx={{ width: 36, height: 36 }}
              >
                {!obj.photo && <LandscapeRounded />}
              </Avatar>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _getAll: actions.get,
  _set: actions.set,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Users);
