import React from "react";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const InputDate = (props) => {
  const {
    className,
    label,
    variant,
    size,
    name,
    value,
    onChange,
    error,
    ...other
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        {...other}
        renderInput={(params) => (
          <TextField
            {...params}
            className={className}
            variant={variant || "filled"}
            size={size || "medium"}
            error={false}
            {...(error && { error: true, helperText: error })}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
