import React from "react";
import { Dashboard } from "../../layout";
import { Header, Home, Log, NavMenu, Notices, Units, Users } from "./";
import { Navigate, Route, Routes } from "react-router-dom";
import AddEditUnit from "./Units/AddEditUnit";
import ViewUnit from "./Units/ViewUnit/ViewUnit";
import Vio from "./Request/Violations";
import Invoices from "./Invoice/Invoices";
import AddEditInvoice from "./Invoice/AddEditInvoice";
import AddEditPay from "./Payment/AddEditPay";
import PayTbl from "./Payment/Payments";
import Vendors from "./Vendors/Vendors";
import AddEditVendor from "./Vendors/AddEditVendor";
import ViewVendor from "./Vendors/ViewVendor";
import Budgets from "./Budgets/Budgets";
import Requests from "./Request/Requests";
import Doc from "./Documents/Doc";
import Revenue from "./Revenue/Revenue";
import InvSettings from "./Invoice/InvSettings";
import Receivables from "./Transactions/Receivable/Receivables";
import Payables from "./Transactions/Payable/Payables";
import {
  Reports,
  APRep,
  ARRep,
  ProfitVsLoss,
  OwnerRep,
  BudgetVsActual,
  DelAcc,
  InvByCat,
  Ledger,
  OwnershipStatus,
  OwnerContact,
  VendorInfo,
  VendorOverview,
  ExpDtls,
  ExpByCat,
  DepositDtls,
  ReceiptDtls,
  CstmRep,
  BankReco,
  JournalEntries,
} from "./Report";
import UpdateUser from "./Profile/UpdateUser";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import Hoas from "../../components/dashboard/Manage-Hoa/Hoas";
import AddEditHoa from "./Manage-Hoa/AddEditHoa";
import OrgSettings from "./Profile/OrgSettings";
import AccountsTbl from "./Bank/AccountsTbl";
import Owners from "./Owner/Owners";
import AddEditOwner from "./Owner/AddEditOwner";
import BuildBudgets from "./Budgets/BuildBudgets";
import HoaCats from "./HoaCat/HoaCats";
import InvPlans from "./InvPlan/InvPlans";
import ViewInv from "./Invoice/ViewInv";
import PayPage from "./Transactions/Payable/PayPage";
import AddEditUser from "./User/AddEditUser";
import ViewReq from "./Request/ViewReq";
import MySubs from "./Subs/MySubs";
import Packages from "./Subs/Packages";
import CheckPayment from "./StripePay/CheckPayment";
import ReviewForm from "../adminPanel/Review/ReviewForm";
import { useContext } from "react";
import { AppContext } from "../../App";

const DashRoute = (props) => {
  const { _authUser, _subs } = props;
  const { org } = useContext(AppContext);

  return (
    <Dashboard
      menu={<NavMenu />}
      header={<Header />}
      photo={org.logo || ""}
      title={org.orgName || ""}
    >
      <Routes>
        <Route path="/" element={<Navigate to="home" />} />
        <Route path="/my_subscriptions" element={<MySubs />} />
        <Route path="/checkPayment" element={<CheckPayment />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/home" element={<Home />} />

        {_subs &&
          _subs.isActive &&
          (_subs.subsPeriod === 1 || _subs.subsPeriod === 3) && (
            <>
              <Route path="/units">
                <Route path="unit_form">
                  <Route path=":id" element={<AddEditUnit />} />
                  <Route path="" element={<AddEditUnit />} />
                </Route>
                <Route path="view_unit/:id" element={<ViewUnit />} />
                <Route path="" element={<Units />} />
              </Route>

              <Route path="/notices" element={<Notices />} />
              <Route path="/violation" element={<Vio />} />
              <Route path="/owner" element={<Owners />} />
              <Route path="/owner/owner_form">
                <Route path=":id" element={<AddEditOwner />} />
                <Route path="" element={<AddEditOwner />} />
              </Route>

              <Route path="/logs" element={<Log />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route
                path="/invoices/view_invoice_details/:invId"
                element={<ViewInv />}
              />
              <Route path="/invoices/invoice_form">
                <Route path=":id" element={<AddEditInvoice />} />
                <Route path=":unitId/:invType" element={<AddEditInvoice />} />
                <Route path="" element={<AddEditInvoice />} />
              </Route>

              <Route path="/payments" element={<PayTbl />} />
              <Route path="/payments/payment_form">
                <Route path=":id" element={<AddEditPay />} />
                <Route path="" element={<AddEditPay />} />
              </Route>
              {_authUser && _authUser.role !== 1 && (
                <>
                  <Route path="/vendors" element={<Vendors />} />
                  <Route
                    path="/vendors/view_vendor/:id"
                    element={<ViewVendor />}
                  />
                  <Route path="/vendors/vendor_form">
                    <Route path=":id" element={<AddEditVendor />} />
                    <Route path="" element={<AddEditVendor />} />
                  </Route>
                  <Route path="/receivables" element={<Receivables />} />
                  <Route path="/payables" element={<Payables />} />
                  <Route path="/budgets" element={<Budgets />} />
                  <Route path="/categories" element={<HoaCats />} />
                  <Route
                    path="/budgets/build_budget"
                    element={<BuildBudgets />}
                  />
                </>
              )}
              <Route path="/requests" element={<Requests />} />
              <Route
                path="/view_request/:id/:isViolation?"
                element={<ViewReq viewFrom="admin" />}
              />
              <Route path="/reports">
                <Route path="ar_report" element={<ARRep />} />
                <Route path="ap_report" element={<APRep />} />
                <Route path="profit_vs_loss" element={<ProfitVsLoss />} />
                <Route path="budget_vs_actual" element={<BudgetVsActual />} />
                <Route path="owner_reports" element={<OwnerRep />} />
                <Route path="delinquent_accounts" element={<DelAcc />} />
                <Route path="invoice_by_category" element={<InvByCat />} />
                <Route path="general_ledger" element={<Ledger />} />
                <Route path="ownership_status" element={<OwnershipStatus />} />
                <Route path="owner_contact_info" element={<OwnerContact />} />
                <Route path="vendor_info" element={<VendorInfo />} />
                <Route path="vendor_overview" element={<VendorOverview />} />
                <Route path="expenses_details" element={<ExpDtls />} />
                <Route path="expenses_by_category" element={<ExpByCat />} />
                <Route path="deposit_details" element={<DepositDtls />} />
                <Route path="receipts_detail" element={<ReceiptDtls />} />
                <Route path="custom_report" element={<CstmRep />} />
                <Route path="bank_reconciliation" element={<BankReco />} />
                <Route path="journal_entries" element={<JournalEntries />} />
                <Route index element={<Reports />} />
              </Route>
              <Route path="/documents" element={<Doc />} />
              <Route path="/bank-accounts" element={<AccountsTbl />} />
              {_authUser && _authUser.role === 3 && (
                <>
                  <Route path="/invoice-terms" element={<InvPlans />} />
                  <Route path="/invoice-option" element={<InvSettings />} />
                  <Route path="/manage-hoa" element={<Hoas />} />
                  <Route path="/manage-hoa/hoa_form">
                    <Route path=":id" element={<AddEditHoa />} />
                    <Route path="" element={<AddEditHoa />} />
                  </Route>
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/user_form">
                    <Route path=":id" element={<AddEditUser />} />
                    <Route path="" element={<AddEditUser />} />
                  </Route>
                </>
              )}

              <Route path="/pay_vendor_page" element={<PayPage />} />
              <Route path="/revenue" element={<Revenue />} />
              <Route path="/update-user" element={<UpdateUser />} />
            </>
          )}
        {org.reviewType !== 2 && (
          <Route path="/review_form" element={<ReviewForm />} />
        )}
        <Route path="/organization-settings" element={<OrgSettings />} />
      </Routes>
    </Dashboard>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _subs: state.storeData.subs,
});

const mapActiontoProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActiontoProps)(DashRoute);
