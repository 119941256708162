import { Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ReportWidget = (props) => {
  const { link, title, icon } = props;
  return (
    <Link to={link} className="tx-und-none">
      <Paper className="p-15 link">
        <div className="d-flex a-center mt-5 mb-5">
          <div className="mr-15">{icon}</div>
          <div>
            <Typography variant="subtitle1" className="tx-upp fs-18">
              {title}
            </Typography>
          </div>
        </div>
      </Paper>
    </Link>
  );
};

export default ReportWidget;
