import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../../controls";
import { Box, Grow, IconButton, InputAdornment } from "@mui/material";
import {
  AccountCircleRounded,
  AdminPanelSettingsRounded,
  SendRounded,
} from "@mui/icons-material";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import {
  RmvEmptyProp,
  ScrollToBottom,
  ToFormData,
} from "../../../hooks/Method";
import { Form } from "../../../layout";
import { Fragment } from "react";

const Reply = (props) => {
  const {
    _getById,
    _authUser,
    _authOwner,
    _post,
    data,
    setData,
    setAlert,
    viewFrom = "admin",
  } = props;
  const [reply, setReply] = useState("");
  const [msgs, setMsgs] = useState([]);
  const msgsRef = useRef();

  const handleInpChg = (e) => {
    setReply(e.target.value);
  };

  useEffect(() => {
    data &&
      _getById(
        endpoints.ReqMsg + controller.GetAllById,
        data.id,
        null,
        (res) => res.status === 200 && setMsgs([...res.result])
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    ScrollToBottom(msgsRef);
    return () => {};
  }, [msgs]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reply && data) {
      const onComplete = (res) => {
        if (res.status === 201) {
          setMsgs((x) => [...x, { ...res.result }]);
          setReply("");
        } else if (res.status === 208) {
          setData(res.result);
          setReply("");
          setAlert({
            isOpen: true,
            type: "warning",
            title: "Error",
            subTitle: res.msg,
          });
        } else console.error("Message Sending Error: ", res);
      };
      _post(
        endpoints.ReqMsg + controller.Post,
        ToFormData(
          RmvEmptyProp({
            reqId: data.id,
            userId: viewFrom === "admin" ? _authUser.id : "",
            ownerId: viewFrom === "owner" ? _authOwner.id : "",
            message: reply,
          })
        ),
        null,
        onComplete
      );
    }
  };

  const Viewer = (type, data) => {
    switch (type) {
      case "admin":
        return (
          <div className="d-flex a-center g-10">
            <AdminPanelSettingsRounded
              className={`fs-42 ${
                viewFrom === "admin" ? "primary-color" : "gray-color"
              }`}
            />

            <div className="d-grid">
              <span className="fw-5">
                Administrator {viewFrom === "admin" ? "(Me)" : ""}
              </span>
              <span className="fs-11 grayMD-color pt-2">{data.sendDate}</span>
            </div>
          </div>
        );
      case "owner":
        return (
          <div className="d-flex a-center g-10">
            <AccountCircleRounded
              className={`fs-42 ${
                viewFrom === "owner" ? "primary-color" : "gray-color"
              }`}
            />

            <div className="d-grid">
              <span className="fw-5">
                {viewFrom === "owner" ? "Me" : "Owner"}
              </span>
              <span className="fs-11 grayMD-color pt-2">{data.sendDate}</span>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className="h-100pr d-flex flex-column j-end">
      <Box
        ref={msgsRef}
        className="p-15"
        sx={{
          height: `calc(100vh - ${data.isComplete === 1 ? "300px" : "350px"})`,
          overflow: "auto",
        }}
      >
        {msgs.map((msg, i) => (
          <Fragment key={i}>
            <Grow in timeout={500}>
              <div className="mt-10 mb-20 p-10 br-20">
                {Viewer(msg.user ? "admin" : "owner", msg)}
                <div className="ml-50 mt-5 gray-color">
                  <span className="p pre-wrap" style={{ lineHeight: "1.5rem" }}>
                    {msg.message}
                  </span>
                </div>
              </div>
            </Grow>
          </Fragment>
        ))}
      </Box>

      {data && data.isComplete === 2 && (
        <Form onSubmit={handleSubmit}>
          <div className="pl-10 pr-15 pb-15">
            <Input
              placeholder="Reply..."
              name="title"
              value={reply}
              variant="outlined"
              style={{ borderRadius: "50px" }}
              multiline
              fullWidth
              onChange={handleInpChg}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSubmit}>
                      <SendRounded />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _authOwner: state.storeData.authOwner,
});

const mapActionToProps = {
  _post: actions.post,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Reply);
