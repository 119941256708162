import { Box, Divider, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Select } from "../../../controls";
import { DropLi } from "../../../hooks";
import moment from "moment";
import { connect } from "react-redux";
// import * as actions from "../../../../api/actions/actions";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";

const BankAcc = (props) => {
  const { _getById, _hoa, banks } = props;
  const { setLoader } = useContext(AppContext);
  const [accounts, setAccounts] = useState([]);
  const [periodic, setPeriodic] = useState(3);
  const [ap, setAp] = useState([]);
  const [ar, setAr] = useState([]);
  const [records, setRecords] = useState([]);
  const [fltRecords, setFltRecords] = useState([]);
  const { PeriodicType } = DropLi();

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      _getById(endpoints.AP + controller.GetByHoa, _hoa.id, null, (res) => {
        setLoader(false);
        res.status === 200 &&
          setAp([...res.result.filter((j) => j.isComplete)]);
      });

      _getById(
        endpoints.Payment + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 &&
            setAr((x) => [...res.result.filter((j) => j.method !== 3)]);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    setRecords([
      ...ap.map((j) => ({
        date: j.paidDate,
        amt: -j.amount,
        bankId: j.bankAcc.id,
        bank: j.bankAcc.accName,
      })),
      ...ar.map((j) => ({
        date: j.payDate,
        amt: j.isRev ? -j.amount : j.amount,
        bankId: j.inv && j.inv.bankAcc.id,
        bank: j.inv && j.inv.bankAcc.accName,
      })),
    ]);

    return () => {
      setRecords([]);
    };
  }, [ap, ar]);

  useEffect(() => {
    var _accs = [];
    banks.forEach((bank) => {
      var _records = fltRecords.filter((j) => j.bankId === bank.id);
      var _totalAmt = _records.map((x) => x.amt).reduce((a, b) => a + b, 0);

      _totalAmt && _accs.push({ name: bank.accName, amt: _totalAmt });
    });
    setAccounts([..._accs]);
    return () => {
      setAccounts([]);
    };
  }, [banks, fltRecords]);

  useEffect(() => {
    switch (periodic) {
      case 2:
        setFltRecords([
          ...records.filter(
            (x) =>
              moment(x.date, "MM/DD/YYYY") >= moment().startOf("week") &&
              moment(x.date, "MM/DD/YYYY") <= moment().endOf("week")
          ),
        ]);
        break;
      case 3:
        setFltRecords([
          ...records.filter(
            (x) =>
              moment(x.date, "MM/DD/YYYY") >= moment().startOf("month") &&
              moment(x.date, "MM/DD/YYYY") <= moment().endOf("month")
          ),
        ]);
        break;
      case 4:
        setFltRecords([
          ...records.filter(
            (x) =>
              moment(x.date, "MM/DD/YYYY") >=
                moment().subtract(1, "month").startOf("month") &&
              moment(x.date, "MM/DD/YYYY") <=
                moment().subtract(1, "month").endOf("month")
          ),
        ]);
        break;
      case 5:
        setFltRecords([
          ...records.filter(
            (x) =>
              moment(x.date, "MM/DD/YYYY") >= moment().startOf("year") &&
              moment(x.date, "MM/DD/YYYY") <= moment().endOf("year")
          ),
        ]);
        break;
      case 6:
        setFltRecords([
          ...records.filter(
            (x) =>
              moment(x.date, "MM/DD/YYYY") >=
                moment().subtract(1, "year").startOf("year") &&
              moment(x.date, "MM/DD/YYYY") <=
                moment().subtract(1, "year").endOf("year")
          ),
        ]);
        break;

      default:
        setRecords([
          ...records.filter(
            (x) =>
              moment(x.date, "MM/DD/YYYY").format("MM/DD/YYYY") ===
              moment().format("MM/DD/YYYY")
          ),
        ]);

        break;
    }
    return () => {
      setFltRecords([]);
    };
  }, [periodic, records]);

  const handleInpChange = (e) => {
    setPeriodic(e.target.value);
  };

  return (
    <Paper>
      <div className="p-10 d-flex a-center j-between">
        <span className="h d-flex a-center fs-18 fw-5 gray-color">
          Bank Balance
        </span>
        <Select
          size="small"
          variant="outlined"
          onChange={handleInpChange}
          value={periodic}
          options={PeriodicType}
        />
      </div>
      <Divider />
      <div className="d-flex j-between p-10">
        <span className="gray-color tx-upp">Total:</span>
        <span className="fw-5">
          $&nbsp;
          {accounts
            .map((j) => j.amt)
            .reduce((a, b) => a + b, 0)
            .toLocaleString()}
        </span>
      </div>
      {accounts.length > 0 && <Divider />}
      <Box sx={{ maxHeight: "470px", overflow: "auto" }}>
        {accounts.map((val, ind) => (
          <div key={ind} className="mb-15 bs-secondary m-10">
            <div className="d-flex j-between p-10">
              <span className="fs-18 fw-6 gray-color">{val.name} :</span>
              <span className="fs-18 fw-6 gray-color">
                $&nbsp;{val.amt.toLocaleString()}
              </span>
            </div>
          </div>
        ))}
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(BankAcc);
