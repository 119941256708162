import {
  Box,
  Divider,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import AmtInp from "./AmtInp";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { Alert, Snackbar } from "../../../layout";
import { CSVLink } from "react-csv";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";

const BudgetForm = (props) => {
  const {
    _getById,
    _getByObj,
    _post,
    _hoa,
    headerDate,
    budgetType = 1,
    title,
  } = props;
  const [cats, setCats] = useState([]);
  const [budgets, setBudgets] = useState();
  const [bdgts, setBdgts] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [bdgtsCSV, setBdgtsCSV] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });

  useEffect(() => {
    if (_hoa.id) {
      _getByObj(
        endpoints.HoaCat + controller.GetAllByCatType,
        {
          id: _hoa.id,
          value: budgetType.toString(),
        },
        null,
        (res) => res.status === 200 && setCats([...res.result])
      );
      _getById(
        endpoints.Budget + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setBdgts([...res.result])
      );
    }
    return () => {
      setBdgts([]);
      setCats([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa.id, budgetType, headerDate]);

  useEffect(() => {
    if (bdgts && headerDate) {
      setBudgets(
        bdgts.filter(
          (j) =>
            j.cat.catType === budgetType &&
            moment(`${j.month}/${j.year}`, "MM/YYYY") >=
              moment(
                `${headerDate[0].months[0].id}/${headerDate[0].year}`,
                "MM/YYYY"
              ) &&
            moment(`${j.month}/${j.year}`, "MM/YYYY") <=
              moment(
                `${headerDate.slice(-1).pop().months.slice(-1).pop().id}/${
                  headerDate.slice(-1).pop().year
                }`,
                "MM/YYYY"
              )
        )
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bdgts, headerDate]);

  useEffect(() => {
    headerDate &&
      setCsvHeader([
        { label: "Category", key: "cat" },
        { label: "Lock", key: "lock" },
        { label: "Year", key: "year" },
        ...headerDate[0].months.map((j, ind) => {
          return { label: j.name, key: `${ind}` };
        }),
      ]);
    return () => {
      setCsvHeader([]);
    };
  }, [headerDate]);

  const handleCSVGenerate = () => {
    if (budgets) {
      var bdg = cats.map((cat) => {
        return {
          cat: cat.name,
          years: headerDate.map((date) => {
            var lockBdg = budgets.find(
              (j) => j.year === parseInt(date.year) && j.catId === cat.id
            );
            return {
              year: date.year,
              lock: lockBdg ? lockBdg.lock : false,
              months: date.months.map((month) => {
                var bdg = budgets.find(
                  (j) =>
                    j.catId === cat.id &&
                    parseInt(j.year) === parseInt(date.year) &&
                    parseInt(j.month) === parseInt(month.id)
                );
                return {
                  month: month.id,
                  amount: bdg ? bdg.amount : 0,
                };
              }),
            };
          }),
        };
      });
      var _csvBdg = [];
      bdg.forEach((bg) => {
        bg.years.forEach((year) => {
          _csvBdg.push({
            cat: bg.cat,
            year: year.year,
            lock: year.lock,
            ...year.months.map((j) => j.amount),
          });
        });

        _csvBdg.push({});
        _csvBdg.push({});
      });
      setBdgtsCSV([..._csvBdg]);
    }
  };

  const handleLock = (catId, year) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure?",
      subTitle:
        "You want to lock this budget? Once you lock, You can't change/edit any more.",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "You have successfuly locked",
              subTitle: res.msg,
            });
            setBudgets((x) => [
              ...x.map((j) =>
                j.catId === catId && parseInt(year) === parseInt(j.year)
                  ? {
                      ...j,
                      lock: true,
                    }
                  : j
              ),
            ]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _post(
          endpoints.Budget + controller.Lock,
          {
            id: catId,
            value: year,
          },
          null,
          onComplete
        );
      },
    });
  };

  return (
    <>
      <Paper className="pb-10 mb-30">
        {/* Header  */}
        <div className="d-flex j-between flex-wrap p-10 a-center">
          <Box sx={{ mb: { md: "0px", xs: "10px" } }}>
            <h2 className="border-left h fw-5 fs-20 gray-color tx-upp">
              {title}
            </h2>
          </Box>

          <div className="d-flex flex-wrap">
            <CSVLink
              onClick={handleCSVGenerate}
              data={bdgtsCSV}
              headers={csvHeader}
              filename={`${title + " Budget " + new Date().toDateString()}.csv`}
              target="_blank"
              className="tx-upp tx-und-none primary-color bg-primaryXS p-10 br-7 mr-10 mb-10"
            >
              Download CSV
            </CSVLink>
          </div>
        </div>
        {/* Body  */}
        <Divider />

        {cats.map((cat, i) => (
          <TableContainer key={i} className="mb-30">
            <Typography className="pl-10 pt-10 fs-18 fw-5 gray-color">
              {cat.name}
            </Typography>
            <Table
              key={i}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow className="bg-primaryXS">
                  <TableCell className="tx-upp primary-color fs-12">
                    Lock
                  </TableCell>
                  <TableCell className="tx-upp primary-color fs-12">
                    Year
                  </TableCell>
                  {headerDate
                    .map((j) => j.months)[0]
                    .map((month, i) => (
                      <TableCell
                        key={i}
                        className="tx-upp primary-color  fs-12"
                      >
                        {month.name}
                      </TableCell>
                    ))}
                  <TableCell className="tx-upp primary-color  fs-12">
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {headerDate.map((date, i) => {
                  var bdg = budgets.find(
                    (j) =>
                      j.catId === cat.id &&
                      parseInt(j.year) === parseInt(date.year)
                  );
                  return (
                    <TableRow key={i}>
                      <TableCell className="fs-12 fs-italic fw-5 gray-color">
                        {bdg ? (
                          bdg.lock ? (
                            <Tooltip
                              arrow
                              title="This budget has been locked. You can't edit it any more."
                            >
                              <Icon sx={{ m: "0px 5px 0px 5px" }}>
                                <LockOutlined className="fs-18" />
                              </Icon>
                            </Tooltip>
                          ) : (
                            <Tooltip arrow title="Lock">
                              <IconButton
                                onClick={() => handleLock(cat.id, date.year)}
                                size="small"
                                color="primary"
                                className="mr-5"
                              >
                                <LockOpenOutlined className="fs-18 m-2" />
                              </IconButton>
                            </Tooltip>
                          )
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell className="fs-14 fw-5 gray-color">
                        {date.year}
                      </TableCell>
                      {date.months.map((month, i) => {
                        var val =
                          budgets &&
                          budgets.find(
                            (j) =>
                              j.catId === cat.id &&
                              parseInt(j.month) === parseInt(month.id) &&
                              parseInt(j.year) === parseInt(date.year)
                          );

                        return (
                          <TableCell key={i}>
                            {bdg && bdg.lock ? (
                              val && (
                                <span className="fs-12 gray-color fs-italic">
                                  {val.amount}
                                </span>
                              )
                            ) : (
                              <AmtInp
                                {...{
                                  catId: cat.id,
                                  date: { year: date.year, month: month.id },
                                  setSnackbar,
                                  setBudgets,
                                  budgets,
                                  dates: date,
                                  val,
                                }}
                              />
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell className="fw-5 fs-12">
                        $
                        {budgets &&
                          budgets
                            .filter(
                              (j) =>
                                j.catId === cat.id &&
                                parseInt(j.year) === parseInt(date.year)
                            )
                            .map((j) => j.amount)
                            .reduce((x, y) => x + y, 0)
                            .toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow className="bg-primaryXS">
                  <TableCell
                    component="th"
                    scope="row"
                    className="tx-upp gray-color"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        display: "block",
                        width: "110px",
                        fontWeight: "500",
                      }}
                    >
                      Period Totals
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  {headerDate[0].months.map((month, i) => (
                    <TableCell key={i} className="fw-5 fs-14">
                      $
                      {budgets &&
                        budgets
                          .filter(
                            (j) =>
                              j.cat.catType === budgetType &&
                              j.catId === cat.id &&
                              parseInt(j.month) === parseInt(month.id)
                          )
                          .map((j) => j.amount)
                          .reduce((x, y) => x + y, 0)
                          .toLocaleString()}
                    </TableCell>
                  ))}

                  <TableCell className="fw-5 fs-14">
                    $
                    {budgets &&
                      budgets
                        .filter(
                          (j) =>
                            j.cat.catType === budgetType && j.catId === cat.id
                        )
                        .map((j) => j.amount)
                        .reduce((x, y) => x + y, 0)
                        .toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))}
        <div
          className="bs-secondary gray-color tx-upp d-flex a-center"
          style={{ overflow: "auto" }}
        >
          <div
            style={{
              borderRight: "1px solid var(--grayXS-color)",
              padding: "10px",
            }}
          >
            <Typography
              className="fw-5"
              sx={{ width: "120px", display: "block" }}
            >
              net total
            </Typography>
          </div>

          {headerDate[0].months.map((month, i) => (
            <div
              key={i}
              style={{
                minWidth: "130px",
                borderRight: "1px solid var(--grayXS-color)",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <Typography variant="subtitle2" className="fw-4">
                {month.name}:&nbsp;
                <span className="fw-5 fs-18">
                  $
                  {budgets &&
                    budgets
                      .filter(
                        (j) =>
                          j.cat.catType === budgetType &&
                          parseInt(j.month) === parseInt(month.id)
                      )
                      .map((j) => j.amount)
                      .reduce((x, y) => x + y, 0)
                      .toLocaleString()}
                </span>
              </Typography>
            </div>
          ))}
          <div
            style={{
              minWidth: "130px",
              borderRight: "1px solid var(--grayXS-color)",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <Typography>
              <span className="fw-6 fs-18">
                $
                {budgets &&
                  budgets
                    .filter((j) => j.cat.catType === budgetType)
                    .map((j) => j.amount)
                    .reduce((x, y) => x + y, 0)
                    .toLocaleString()}
              </span>
            </Typography>
          </div>
        </div>
      </Paper>
      <Snackbar {...{ snackbar, setSnackbar }} />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(BudgetForm);
