import React from "react";
import { Table } from "../../../../layout";

const Owners = (props) => {
  const { owners = [] } = props;

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={() => <> </>}
        tblHead={[
          { id: "status", label: "Status" },
          { id: "fullName", label: "Full Name" },
          { id: "phone", label: "Phone" },
          { id: "email", label: "Email" },
          { id: "lastLogin", label: "Last Login" },
        ]}
        tblData={owners.map((owner) => {
          return {
            ...owner,
            status: (
              <div className="p-5">
                {owner.isActive === 1 ? (
                  <span className="success-color bg-successXS status">
                    Active
                  </span>
                ) : (
                  <span className="error-color bg-errorSM status">
                    Inactive
                  </span>
                )}
              </div>
            ),
          };
        })}
      />
    </>
  );
};

export default Owners;
