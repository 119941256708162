import { Grid } from "@mui/material";
import React from "react";
import { Button } from "../../../../controls";

const ViewAP = (props) => {
  const { setShowForm, viewData } = props;

  const handleClose = () => {
    setShowForm(false);
  };

  return viewData ? (
    <Grid container spacing={3} className="p-20 dark-color">
      <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
        <h2 className="h fw-5 dark-color mb-5 tx-upp">AR Details</h2>
        <div className="pos-relative d-flex j-center">
          <div className="border-bottom w-60" />
        </div>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Transection Date:</span>
        <span className=" fw-4">{viewData.txnDate}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Due Date:</span>
        <span className=" fw-4">{viewData.dueDate}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Amount:</span>
        <span className=" fw-4">{viewData.amt}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Vendor:</span>
        <span className=" fw-4">{viewData.vendorName}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Memo:</span>
        <span className=" fw-4">{viewData.memo}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Bank Account:</span>
        <span className=" fw-4">
          {viewData.bankAcc && viewData.bankAcc.accName}
        </span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Category:</span>
        <span className=" fw-4">
          {viewData.category && viewData.category.name}
        </span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Update Date:</span>
        <span className="fw-4">{viewData.updateDate}</span>
      </Grid>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Paid Date:</span>
        <span className="fw-4">{viewData.paidDate}</span>
      </Grid>
      <Grid item xs={12} md={8} className="d-grid">
        <span className="tx-xs tx-upp mb-5">Discription:</span>
        <span className="fw-4 pre-wrap">{viewData.descr}</span>
      </Grid>
      <Grid item xs={12} className="d-grid">
        <span className="tx-xs tx-upp mb-5 gray-color ">View File:</span>
        {viewData.apFiles.map((file, i) => (
          <a
            key={i}
            href={file.fileSrc}
            className="mb-10 link fs-14"
            target="_blank"
            rel="noreferrer"
          >
            {file.name}
          </a>
        ))}
      </Grid>

      <Grid item xs={12} className="tx-center mt-20">
        <Button size="large" onClick={handleClose}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  ) : (
    "Loading..."
  );
};

export default ViewAP;
