import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Select } from "../../../controls";
import { DropLi } from "../../../hooks";
import { Link } from "react-router-dom";
import moment from "moment";

const ExpenseDonut = ({ payables, cats }) => {
  const [periodic, setPeriodic] = useState(3);
  const { PeriodicType } = DropLi();
  const [fltPay, setFltPay] = useState([]);
  const [series, setSeries] = useState([]);

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    labels: cats.map((j) => j.name),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontWeight: "600",
      fontSize: "12px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const handleInpChange = (e) => {
    setPeriodic(e.target.value);
  };

  useEffect(() => {
    var _series = [];
    cats.forEach((cat) => {
      _series.push(
        fltPay
          .filter((j) => j.categoryId === cat.id)
          .map((j) => j.amount)
          .reduce((a, b) => a + b, 0)
      );
    });
    setSeries([..._series]);

    return () => {
      setSeries([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fltPay, cats]);

  useEffect(() => {
    switch (periodic) {
      case 2:
        setFltPay([
          ...payables.filter(
            (j) =>
              moment(j.paidDate, "MM/DD/YYYY") >= moment().startOf("week") &&
              moment(j.paidDate, "MM/DD/YYYY") <= moment().endOf("week")
          ),
        ]);
        break;
      case 3:
        setFltPay([
          ...payables.filter(
            (j) =>
              moment(j.paidDate, "MM/DD/YYYY") >= moment().startOf("month") &&
              moment(j.paidDate, "MM/DD/YYYY") <= moment().endOf("month")
          ),
        ]);
        break;
      case 4:
        setFltPay([
          ...payables.filter(
            (j) =>
              moment(j.paidDate, "MM/DD/YYYY") >=
                moment().subtract(1, "month").startOf("month") &&
              moment(j.paidDate, "MM/DD/YYYY") <=
                moment().subtract(1, "month").endOf("month")
          ),
        ]);
        break;
      case 5:
        setFltPay([
          ...payables.filter(
            (j) =>
              moment(j.paidDate, "MM/DD/YYYY") >= moment().startOf("year") &&
              moment(j.paidDate, "MM/DD/YYYY") <= moment().endOf("year")
          ),
        ]);
        break;
      case 6:
        setFltPay([
          ...payables.filter(
            (j) =>
              moment(j.paidDate, "MM/DD/YYYY") >=
                moment().subtract(1, "year").startOf("year") &&
              moment(j.paidDate, "MM/DD/YYYY") <=
                moment().subtract(1, "year").endOf("year")
          ),
        ]);
        break;

      default:
        setFltPay([
          ...payables.filter(
            (j) =>
              moment(j.paidDate, "MM/DD/YYYY").format("MM/DD/YYYY") ===
              moment().format("MM/DD/YYYY")
          ),
        ]);

        break;
    }
    return () => {
      setFltPay([]);
    };
  }, [periodic, payables]);

  return (
    <Paper>
      {/* Chart */}
      <div className="d-flex flex-wrap a-center j-between p-10 mb-10 g-10">
        <div>
          <span className="fs-18 fw-5 gray-color">Expenses</span>
        </div>

        <div className="d-flex flex-wrap g-10">
          <Link to="/my_account/payables">
            <Button variant="outlined">Manage</Button>
          </Link>

          <Select
            size="small"
            variant="outlined"
            onChange={handleInpChange}
            value={periodic}
            options={PeriodicType}
          />
        </div>
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={330}
      />
    </Paper>
  );
};

export default ExpenseDonut;
