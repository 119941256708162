import React, { useEffect, useState } from "react";
import { About, Footer, Header, SubsPlan, Testimonials } from "./";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Landing.scss";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import { endpoints } from "../../api/actions/api";
import { CalcAverageRating } from "../../hooks/Method";

const Landing = (props) => {
  const { _getAll } = props;
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState();

  useEffect(() => {
    AOS.init({ duration: 800 });

    _getAll(endpoints.Review, null, (res) => {
      res.result && setReviews((j) => [...res.result]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRating(CalcAverageRating(reviews.map((j) => j.rating)));

    return () => {};
  }, [reviews]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Header {...{ rating }} />
      <About />
      <SubsPlan />
      {reviews.length > 0 && <Testimonials {...{ reviews }} />}
      {/* <div data-aos="fade-up">
        <GetStart />
      </div> */}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _getAll: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Landing);
