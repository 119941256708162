import { VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Dialog, Table } from "../../../layout";
import VeweInvoices from "./VeweInvoices";

const RevTable = ({ catInvs, fltCrDr = [] }) => {
  const [viewDtls, setViewDtls] = useState(false);
  const [invs, setInvs] = useState([]);
  const [tblData, setTblData] = useState([]);

  const handleView = (props) => {
    setInvs(props.invs);
    setViewDtls(true);
  };

  useEffect(() => {
    setTblData([
      ...catInvs.map((j) => {
        var _invPayments = j.invs.map((x) => x.inv && x.inv.invPayments).flat();
        var _lateFee = _invPayments
          .map((x) =>
            x.invLateFees.map((y) => y.paidAmt).reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0);

        var _totalPayment = _invPayments
          .map((x) => x.paidAmt)
          .reduce((x, y) => x + y, 0);

        var _totalDis = fltCrDr
          .filter((x) => x.inv && x.inv.categoryId === j.catId)
          .map((x) => x.balance)
          .reduce((x, y) => x + y, 0);

        return {
          ...j,
          catName: j.catName,
          earlyPayDis: "$" + _totalDis,
          lateFee: "$" + _lateFee.toLocaleString(),
          invAmount: "$" + _totalPayment.toLocaleString(),
          subtotal:
            "$" + (_lateFee + _totalPayment - _totalDis).toLocaleString(),
        };
      }),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catInvs]);

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Details">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );

  const HeaderComp = () => (
    <h2 className="border-left h fw-5 fs-18 gray-color tx-upp">
      Revenue Details
    </h2>
  );

  return (
    <div className="mt-20">
      <Table
        shadow={false}
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "catName", label: "Category" },
          { id: "earlyPayDis", label: "Early Pay Credit" },
          { id: "lateFee", label: "Late Fee" },
          { id: "invAmount", label: "Invoice Amount" },
          {
            id: "subtotal",
            label: (
              <div className="d-grid">
                <span>Subtotal</span>
                <span className="gray-color tx-upp mt-0 tx-xs">
                  ((late-fee + invoice amount) - early pay discount)
                </span>
              </div>
            ),
          },
        ]}
        tblData={tblData}
      />

      {/* View Details */}
      <Dialog show={viewDtls} onClose={false} maxWidth="lg">
        <VeweInvoices {...{ setShowForm: setViewDtls, invs }} />
      </Dialog>
    </div>
  );
};

export default RevTable;
