import {
  CheckCircleRounded,
  RefreshRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { Box, Divider, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../../controls";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { controller, endpoints } from "../../../api/actions/api";
import { useParams } from "react-router-dom";
import { DropLi } from "../../../hooks";
import Reply from "./Reply";
import { Alert } from "../../../layout";

const ViewReq = (props) => {
  const { _getById, viewFrom = "" } = props;
  const { id, isViolation = false } = useParams();
  const { ReqType, VioType } = DropLi();
  const [data, setData] = useState();
  const [reqType, setReqType] = useState("");
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  console.log("data: ", data);

  useEffect(() => {
    id &&
      _getById(
        endpoints.Request + controller.GetById,
        id,
        null,
        (res) => res.status === 200 && setData(res.result)
      );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      var type = JSON.parse(isViolation)
        ? VioType.find((j) => j.id === data.type)
        : ReqType.find((j) => j.id === data.type);
      type && setReqType(type.label);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleViewLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const markAsCmplt = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: `You want to mark it as complete the ${
        JSON.parse(isViolation) ? "violation" : "request"
      }?`,
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: `"You have successfully completed the ${
                JSON.parse(isViolation) ? "violation" : "request"
              }"`,
              subTitle: res.msg,
            });
            setData(res.result);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _getById(
          endpoints.Request + controller.MarkComplete,
          data.id,
          null,
          onComplete
        );
      },
    });
  };

  return (
    <>
      {data ? (
        <Paper>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="d-flex flex-wrap g-15 a-center j-between p-15">
                <div>
                  <span className="fs-20 tx-upp border-left">
                    View {JSON.parse(isViolation) ? "Violation" : "Request"}
                  </span>
                </div>
                <div className="d-flex flex-wrap g-10">
                  {data.isComplete === 2 && viewFrom === "admin" && (
                    <Button
                      onClick={markAsCmplt}
                      variant="text"
                      startIcon={<CheckCircleRounded />}
                    >
                      Mark as Complete
                    </Button>
                  )}
                  <Button
                    onClick={() => window.location.reload()}
                    variant="text"
                    startIcon={<RefreshRounded />}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                borderRight: {
                  xs: "none",
                  md: "1px solid var(--grayXS-color)",
                },
              }}
            >
              <div className="d-grid g-20 dark-color ml-15">
                <div className="d-grid">
                  <span className="tx-xs tx-upp mb-5 gray-color">
                    Unit Name:
                  </span>
                  <span>{data.unit && data.unit.title}</span>
                </div>
                <div className="d-grid">
                  <span className="tx-xs tx-upp mb-5 gray-color">
                    Request Type:
                  </span>
                  {reqType}
                </div>
                <div className="d-grid a-center">
                  <span className="tx-xs tx-upp mb-5 gray-color ">Status:</span>
                  {data.isComplete === 2 ? (
                    <span className="bg-warningT warning-color status">
                      Pending
                    </span>
                  ) : (
                    <span className="bg-primarySM primary-color status">
                      Complete
                    </span>
                  )}
                </div>
                {data.fileSrc && (
                  <div className="d-grid">
                    <span className="tx-xs tx-upp mb-5 gray-color ">
                      View File:
                    </span>
                    <Button
                      onClick={() => handleViewLink(data.fileSrc)}
                      variant="text"
                      size="small"
                      className="ml-10 mr-10 p-10 br-20"
                      sx={{ maxWidth: "120px" }}
                      startIcon={<VisibilityRounded />}
                    >
                      Open File
                    </Button>
                  </div>
                )}
                <div className="d-grid">
                  <span className="tx-xs tx-upp mb-5 gray-color">
                    Request Created From:
                  </span>
                  <span>{data.owner ? "Owner" : "Administrator"}</span>
                </div>
                <div className="d-grid">
                  {data.rcdType === 1 ? (
                    <>
                      <span className="tx-xs tx-upp mb-5 gray-color">
                        Request Date:
                      </span>
                      <span>{data.createDate}</span>
                    </>
                  ) : (
                    <>
                      <span className="tx-xs tx-upp mb-5 gray-color">
                        Resolution Deadline:
                      </span>
                      <span>{data.resDeadline}</span>
                    </>
                  )}
                </div>
                <div className="d-grid">
                  <span className="tx-xs tx-upp mb-5 gray-color">
                    Request Complete User:
                  </span>
                  <span>{data.cmpltUser && data.cmpltUser.fullName}</span>
                </div>
                <div className="d-grid">
                  <span className="tx-xs tx-upp mb-5 gray-color">
                    Request Complete Date:
                  </span>
                  <span>{data.cmpltDate}</span>
                </div>
                <div className="d-grid">
                  <span className="tx-xs tx-upp mb-5 gray-color">Title:</span>
                  <span>{data.title}</span>
                </div>
                <div className="d-grid">
                  <span className="tx-xs tx-upp mb-5 gray-color ">
                    View File:
                  </span>
                  {data.reqFiles.map((file, i) => (
                    <a
                      key={i}
                      href={file.fileSrc}
                      className="mb-10 link fs-14"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {file.name}
                    </a>
                  ))}
                </div>
                <div>
                  <span className="tx-xs tx-upp gray-color">Message:</span>
                  <Divider />
                  <div style={{ height: "140px", overflow: "auto" }}>
                    <p className="p pre-wrap">{data.msg}</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="h-100pr" sx={{ m: { xs: "10px", md: "0px" } }}>
                <Reply {...{ viewFrom, data, setAlert, setData }} />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <span>Loading...</span>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewReq);
