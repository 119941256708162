import { Divider, Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { DropLi, Model, UseForm } from "../../../hooks";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { Alert, Dialog, Form } from "../../../layout";
import { Button, Input, InputAuto, InputImg } from "../../../controls";
import * as actions from "../../../api/actions/actions";
import { LockResetRounded } from "@mui/icons-material";
import ChangePass from "./ChangePass";

const UpdateUser = (props) => {
  const { _put, _authUser } = props;
  const { UserMdl } = Model();
  const { Countries } = DropLi();
  const [hideZip, setHideZip] = useState(true);
  const { values, setValues, errors, setErrors, handleInpChg, handleFile } =
    UseForm(UserMdl);
  const [loading, setLoading] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : "Full Name is required.";
    temp.email =
      values.email !== ""
        ? /^$|.+@.+..+/.test(values.email)
          ? ""
          : "Email is not valid."
        : "Email is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";
    temp.address1 = values.address1 !== "" ? "" : "Address 1 is required.";
    temp.city = values.city !== "" ? "" : "City is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // Create Action
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg,
          });
        } else {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "Your profile has been successfully updated.",
          });
        }
      };
      _put(
        endpoints.User + controller.Put,
        ToFormData(RmvEmptyProp(values)),
        actions.ActionType.Auth,
        onComplete
      );
    }
  };

  useEffect(() => {
    setValues({ ..._authUser });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.country === "JM") {
      setHideZip(false);
      setValues((x) => ({ ...x, zip: "" }));
    } else setHideZip(true);
    return () => {
      setHideZip(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country]);

  return (
    <>
      <Paper>
        <div className="d-flex j-between a-center p-15">
          <div>
            <h2 className="border-left h fw-5 fs-20 gray-color tx-upp">
              Update User
            </h2>
          </div>
          <div>
            <Button
              onClick={() => setChangePass(true)}
              variant="outlined"
              startIcon={<LockResetRounded />}
            >
              Change Password
            </Button>
          </div>
        </div>
        <Divider />

        <Form noValidate onSubmit={handleSubmit} className="mt-20 p-15">
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <InputImg
                label="Profile Picture"
                name="photoFile"
                src={values.photo}
                rmv={() => {
                  setValues({ ...values, photoFile: "", photo: "" });
                }}
                onChange={handleFile}
                error={errors.photoFile}
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    label="Full Name"
                    name="fullName"
                    onChange={handleInpChg}
                    value={values.fullName}
                    error={errors.fullName}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Eamil"
                    name="email"
                    onChange={handleInpChg}
                    value={values.email}
                    error={errors.email}
                    fullWidth
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Phone"
                    name="phone"
                    onChange={handleInpChg}
                    value={values.phone}
                    error={errors.phone}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputAuto
                    label="Country"
                    name="country"
                    value={values.country}
                    error={errors.country}
                    onChange={handleInpChg}
                    options={Countries}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="City"
                    name="city"
                    onChange={handleInpChg}
                    value={values.city}
                    error={errors.city}
                    fullWidth
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={!hideZip ? "Parish" : "State"}
                    name="state"
                    value={values.state}
                    error={errors.state}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                {hideZip && (
                  <Grid item xs={12} md={6}>
                    <Input
                      label="ZIP"
                      name="zip"
                      value={values.zip}
                      error={errors.zip}
                      onChange={handleInpChg}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Address 1"
                name="address1"
                multiline
                rows={3}
                onChange={handleInpChg}
                value={values.address1}
                error={errors.address1}
                fullWidth
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Address 2"
                name="address2"
                multiline
                rows={3}
                onChange={handleInpChg}
                value={values.address2}
                error={errors.address2}
                fullWidth
                inputProps={{ maxLength: 500 }}
              />
            </Grid>

            <Grid item xs={12} className="tx-right mt-10">
              <Button loading={loading} type="submit">
                Update Changes
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Change Password */}
      <Dialog show={changePass} onClose={false} maxWidth="xs">
        <ChangePass {...{ setShowForm: setChangePass, setAlert }} />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(UpdateUser);
