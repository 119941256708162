import React from "react";
import { Route, Routes } from "react-router-dom";
import { Forgot, Login } from "./";

const AuthOwner = () => {
  return (
    <div className="bg-primaryXS">
      <Routes>
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </div>
  );
};

export default AuthOwner;
