import React from "react";
import { Table } from "../../../../layout";

const Invoices = ({ invs }) => {
  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={() => <> </>}
        tblHead={[
          { id: "status", label: "Status" },
          { id: "amt", label: "Amount" },
          { id: "subsTitle", label: "Subs. Titlte" },
          { id: "isInitial", label: "Is Initial Invoice" },
          { id: "date", label: "Date" },
        ]}
        tblData={invs.map((j) => {
          return {
            ...j,
            amt: <span>$ {j.amount}</span>,
            subsTitle: j.subs && j.subs.title,
            userName: j.user && j.user.fullName,
            isInitial: j.isInitial ? "Yes" : "No",
            date: (
              <div className="d-grid fs-14">
                <span>Due Date: {j.dueDate}</span>
                <span>Paid Date: {j.paidDate}</span>
              </div>
            ),
            statusName:
              j.status === 1
                ? "Paid"
                : j.status === 1
                ? "Pending"
                : "Subs. Deactivated",
            status: (
              <div className="p-5">
                {j.status === 2 ? (
                  <span className="success-color bg-successXS status">
                    Paid
                  </span>
                ) : j.status === 1 ? (
                  <span className="error-color bg-errorSM status">Pending</span>
                ) : (
                  <span className="dark-color bg-grayXS status">
                    Subs. Deactivated
                  </span>
                )}
              </div>
            ),
          };
        })}
      />
    </>
  );
};

export default Invoices;
