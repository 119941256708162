import { Grid, InputAdornment, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputCheck, InputImg } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { AdminBaseUrl } from "../../../utils/utils";

const SubsPkgForm = (props) => {
  const { _getById, _post, _put, _admin, isTrial = false } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const { SubsPkgMdl } = Model();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    handleFile,
    resetFormControls,
  } = UseForm(SubsPkgMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200 &&
        setValues({ ...values, ...RmvEmptyProp(res.result) });
    };
    id &&
      _getById(endpoints.SubsPkg + controller.GetById, id, null, onComplete);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : "Title is required.";
    temp.trialDuration =
      values.trialDuration !== "" ? "" : "Trial duration is required.";
    temp.price = values.price !== "" ? "" : "Price is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res && (res.status === 201 || res.status === 200)) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Subscription package ${
              id ? "update" : "added"
            } successfully.`,
            onClick: () => navigate(`${AdminBaseUrl}/subs_pkg`),
          });
        } else if (res.status === 409)
          setErrors((x) => ({ ...x, email: res.msg }));
        else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };

      if (values.id === 0) {
        values.createdUserId = _admin.id;
        _post(
          endpoints.SubsPkg + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          undefined,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.SubsPkg + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          undefined,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    setValues((j) => ({
      ...j,
      branch: j.unlimitedBranch ? "" : j.branch,
      unit: j.unlimitedUnit ? "" : j.unit,
      vendor: j.unlimitedVendor ? "" : j.vendor,
      user: j.unlimitedUser ? "" : j.user,
      owner: j.unlimitedOwner ? "" : j.owner,
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.unlimitedBranch,
    values.unlimitedUnit,
    values.unlimitedVendor,
    values.unlimitedUser,
    values.unlimitedOwner,
  ]);

  return (
    <>
      <Paper className="p-20">
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <div className="mb-10 d-flex j-between a-center">
                <div>
                  <h2 className="h fs-20 fw-5 mb-5 tx-upp border-left">
                    {id ? "Edit" : "Add"}{" "}
                    {isTrial ? "Trial Plan" : "Subscription Package"}
                  </h2>
                </div>

                <div>
                  <Button type="submit" loading={loading}>
                    {id ? "Save Changes" : "Submit"}{" "}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Title"
                name="title"
                value={values.title}
                error={errors.title}
                inputProps={{ maxLength: 50 }}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Price"
                name="price"
                fullWidth
                value={values.price}
                error={errors.price}
                type="number"
                onChange={handleInpChg}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Month</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Trial Duration"
                name="trialDuration"
                fullWidth
                value={values.trialDuration}
                error={errors.trialDuration}
                type="number"
                onChange={handleInpChg}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Day</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Short Note"
                name="note"
                inputProps={{ maxLength: 100 }}
                value={values.note}
                multiline={true}
                rows={2.5}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Number of Branch/HOA"
                name="branch"
                value={values.branch}
                error={errors.branch}
                type="number"
                disabled={values.unlimitedBranch}
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 10))
                }
                onChange={handleInpChg}
                fullWidth
              />

              <InputCheck
                label="Unlimited Branch"
                name="unlimitedBranch"
                size="small"
                checked={values.unlimitedBranch}
                onChange={handleInpCheck}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Number of Unit"
                name="unit"
                value={values.unit}
                error={errors.unit}
                type="number"
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 10))
                }
                onChange={handleInpChg}
                fullWidth
                disabled={values.unlimitedUnit}
              />
              <InputCheck
                label="Unlimited Unit"
                name="unlimitedUnit"
                size="small"
                checked={values.unlimitedUnit}
                onChange={handleInpCheck}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Number of Owner"
                name="owner"
                value={values.owner}
                error={errors.owner}
                type="number"
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 10))
                }
                onChange={handleInpChg}
                fullWidth
                disabled={values.unlimitedOwner}
              />
              <InputCheck
                label="Unlimited Owner"
                name="unlimitedOwner"
                size="small"
                checked={values.unlimitedOwner}
                onChange={handleInpCheck}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Number of Vendor"
                name="vendor"
                value={values.vendor}
                error={errors.vendor}
                type="number"
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 10))
                }
                onChange={handleInpChg}
                fullWidth
                disabled={values.unlimitedVendor}
              />
              <InputCheck
                label="Unlimited Vendor"
                name="unlimitedVendor"
                size="small"
                checked={values.unlimitedVendor}
                onChange={handleInpCheck}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Number of User"
                name="user"
                value={values.user}
                error={errors.user}
                type="number"
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 10))
                }
                onChange={handleInpChg}
                fullWidth
                disabled={values.unlimitedUser}
              />
              <InputCheck
                label="Unlimited User"
                name="unlimitedUser"
                size="small"
                checked={values.unlimitedUser}
                onChange={handleInpCheck}
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ maxWidth: "300px" }}>
                <InputImg
                  label="Photo"
                  name="photoFile"
                  src={values.photo}
                  rmv={() => {
                    setValues({ ...values, photoFile: "", photo: "" });
                  }}
                  onChange={handleFile}
                  error={errors.photoFile}
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(SubsPkgForm);
