import React from "react";
import { Table } from "../../../../layout";
import { Divider } from "@mui/material";

const AccActivities = ({ stmts }) => {
  const HeaderComp = () => {
    var _acc = stmts.slice(-1).pop();
    return (
      <div className="gray-color d-grid tx-upp g-3 mb-5">
        <span className="fs-12 fw-6">Total </span>
        <Divider />
        <div className="d-flex flex-wrap g-20">
          <div>
            <span className="fs-14">Debit:&nbsp;</span>
            <span className="fw-6">$ {_acc.debit}</span>
          </div>
          <div>
            <span className="fs-14">Credit:&nbsp;</span>
            <span className="fw-6">$ {_acc.credit}</span>
          </div>
          <div>
            <span className="fs-14">Balance:&nbsp;</span>
            <span className="fw-6">$ {_acc.bal}</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Table
      ActionComp={() => <></>}
      HeaderComp={HeaderComp}
      tblHead={[
        { id: "date", label: "TRANS DATE" },
        { id: "par", label: "PARTICULARS" },
        { id: "no", label: "INSTRUMENT NO" },
        { id: "debit", label: "DEBIT" },
        { id: "credit", label: "CREDIT" },
        { id: "bal", label: "BALANCE" },
      ]}
      tblData={stmts.slice(0, -1)}
    />
  );
};

export default AccActivities;
