import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Input, InputAuto, InputPass } from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import { PassValidation } from "../../../utils";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { NestedFormData, RmvEmptyProp } from "../../../hooks/Method";

const OwnerForm = (props) => {
  const { _getById, _put } = props;
  const { id } = useParams();
  const { Countries } = DropLi();
  const { OwnerMdl } = Model();
  const [loading, setLoading] = useState(false);
  const [hideZip, setHideZip] = useState(true);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpPass,
    passValid,
    resetFormControls,
  } = UseForm(OwnerMdl);

  useEffect(() => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result) {
        var val = res.result;
        setValues((x) => ({
          ...x,
          ...val,
          units: val.unitOwners.map((j) => j.unitId),
        }));
      }
    };
    id && _getById(endpoints.Owner + controller.GetById, id, null, onComplete);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : "Full Name is required.";
    temp.address1 = values.address1 !== "" ? "" : "Address line is required.";
    temp.city = values.city !== "" ? "" : "City is required.";
    temp.country = values.country !== "" ? "" : "Country is required.";
    if (hideZip) temp.zip = values.zip !== "" ? "" : "Zip is required.";
    if (!values.id) {
      temp.password = values.password !== "" ? "" : "Password is required.";
      temp.confirmPass =
        values.confirmPass !== ""
          ? values.confirmPass === values.password
            ? ""
            : "Password and Confirm password not match"
          : "Confirm Password is required.";
    }

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "Your details updated successfully",
          });
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id) {
        if (values.units.length > 0) {
          values.unitOwners = values.units.map((j) => {
            return {
              unitId: j,
            };
          });
        }
        _put(
          endpoints.Owner + controller.Put,
          NestedFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    if (values.country === "JM") {
      setHideZip(false);
      setValues((x) => ({ ...x, zip: "" }));
    } else setHideZip(true);
    return () => {
      setHideZip(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country]);

  return (
    <>
      <Paper className="p-20">
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="mb-10 d-flex j-between a-center">
                <div>
                  <h2 className="h tx-md fw-5 mb-5 tx-upp border-left">
                    {id ? "Edit" : "Add"} Owner
                  </h2>
                </div>

                <div>
                  <Button type="submit" loading={loading}>
                    {id ? "Save Changes" : "Submit"}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex a-center pl-5">
                <span className="dark-color tx-upp" style={{ width: "120px" }}>
                  Owner Info
                </span>
                <div className="bs-secondary w-100pr" />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                label="Full Name"
                name="fullName"
                value={values.fullName}
                error={errors.fullName}
                onChange={handleInpChg}
                inputProps={{ maxLength: 200 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Phone"
                name="phone"
                value={values.phone}
                error={errors.phone}
                onChange={handleInpChg}
                inputProps={{ maxLength: 50 }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <InputAuto
                label="Country"
                name="country"
                value={values.country}
                error={errors.country}
                onChange={handleInpChg}
                options={Countries}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                label="City"
                name="city"
                value={values.city}
                error={errors.city}
                onChange={handleInpChg}
                inputProps={{ maxLength: 200 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                label={!hideZip ? "Parish" : "State"}
                name="state"
                value={values.state}
                error={errors.state}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            {hideZip && (
              <Grid item xs={12} md={3}>
                <Input
                  label="ZIP"
                  name="zip"
                  value={values.zip}
                  error={errors.zip}
                  onChange={handleInpChg}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Input
                label="Address Line - 1"
                name="address1"
                multiline
                rows={2}
                value={values.address1}
                error={errors.address1}
                onChange={handleInpChg}
                inputProps={{ maxLength: 500 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Address Line - 2"
                name="address2"
                multiline
                rows={2}
                value={values.address2}
                error={errors.address2}
                onChange={handleInpChg}
                inputProps={{ maxLength: 500 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="mt-40">
              <div className="d-flex a-center pl-5">
                <span className="w-80 dark-color tx-upp">Access</span>
                <div className="bs-secondary w-100pr" />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputPass
                label="Password"
                name="password"
                value={values.password}
                onChange={handleInpPass}
                error={errors.password}
                className="input-primary w-100pr"
              />
              <PassValidation {...{ password: values.password, passValid }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputPass
                label="Confirm Password"
                name="confirmPass"
                value={values.confirmPass}
                onChange={(e) => handleInpPass(e, true)}
                error={errors.confirmPass}
                className="input-primary w-100pr"
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(OwnerForm);
