import { VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import AddEditNotice from "./AddEditNotice";
import ViewNotice from "./ViewNotice";

const Notices = (props) => {
  const { _getById, _defHoa } = props;
  const [ntcs, setNtcs] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [viewData, setViewData] = useState();
  const [showView, setShowView] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_defHoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setNtcs(res.result);
      };
      _getById(
        endpoints.Notice + controller.GetByHoa,
        _defHoa.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_defHoa]);

  const handleView = (data) => {
    setViewData(data);
    setShowView(true);
  };
  const handleAdd = () => {
    setShowForm(true);
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Notice">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAdd()}>
        Add Notice
      </Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "posted", label: "Posted" },
          { id: "note", label: "Notice" },
        ]}
        tblData={ntcs.map((ntc) => {
          return {
            ...ntc,
            posted: (
              <div className="d-grid">
                <span>{ntc.user && ntc.user.fullName}</span>
                <span className="fs-12 gray-color">{ntc.createDate}</span>
              </div>
            ),
            note: (
              <div style={{ maxWidth: "600px" }}>
                <span>{ntc.title}</span>
                <div className="max-txt-400">
                  <span className="fs-12 gray-color">{ntc.msg}</span>
                </div>
              </div>
            ),
          };
        })}
      />

      {/* Add Notice Form */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditNotice {...{ setShowForm: setShowForm, setNtcs, setAlert }} />
        </Dialog>
      )}

      {/* View Notice */}
      {showView && (
        <Dialog show={showView} onClose={false} maxWidth="md">
          <ViewNotice {...{ setShowForm: setShowView, viewData }} />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Notices);
