import { VisibilityRounded, WarningAmberRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Alert, Dialog, Table } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import AddEditReq from "./AddEditReq";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { DropLi } from "../../../hooks";
import ViewReq from "./ViewReq";
import moment from "moment";

const Violations = (props) => {
  const { _getByObj, unit, _authOwner, fromHome = false, title } = props;
  const { VioType } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [viewData, setViewData] = useState();
  const [vios, setVios] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [viewVio, setViewVio] = useState(false);
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      if (res.status === 200) {
        fromHome
          ? setVios([
              ...res.result.filter(
                (j) =>
                  moment(j.createDate, "MM/DD/YYYY").format("MM/YYYY") ===
                  moment().format("MM/YYYY")
              ),
            ])
          : setVios([...res.result]);
      }
    };
    if (unit && unit.id) {
      _getByObj(
        endpoints.Request + controller.GetAllByUnit,
        { id: unit.id, value: "2" },
        null,
        onComplete
      );
    } else if (_authOwner.id) {
      setLoader(true);
      _getByObj(
        endpoints.Request + controller.GetAllByOwner,
        { id: _authOwner.id, value: "2" },
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authOwner, unit]);

  const handleView = (val) => {
    setViewData(val);
    setViewVio(true);
  };

  const HeaderComp = () => (
    <div className="d-flex a-center g-10">
      <WarningAmberRounded color="warning" />
      <span className="fs-20 gray-color fw-4 tx-upp">{title || "Notices"}</span>
    </div>
  );

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="btn-error"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );

  return (
    <div className="p-15">
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "unitName", label: "Unit" },
          { id: "reqType", label: "Request Type" },
          { id: "resDeadline", label: "Resolution Deadline" },
          { id: "cmpltDate", label: "Complete Date" },
        ]}
        tblData={vios.map((req, i) => {
          var type = VioType.find((j) => j.id === req.type);
          return {
            ...req,
            unitName: req.unit && req.unit.title,
            reqType: type && type.label,
            status:
              req.isComplete === 2 ? (
                <span className="bg-warningT warning-color status">
                  Pending
                </span>
              ) : (
                <span className="bg-primarySM primary-color status">
                  Complete
                </span>
              ),
          };
        })}
      />

      {/* Add Requests */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditReq
            {...{
              setShowForm: setShowForm,
              setAlert,
              setList: setVios,
              unit,
              rcdType: 2,
            }}
          />
        </Dialog>
      )}

      {/* View Requests */}
      {/* <Dialog show={viewVio} onClose={false} maxWidth="md">
        <ViewVio {...{ setShowForm: setViewVio, viewData }} />
      </Dialog> */}
      <Dialog show={viewVio} onClose={false} maxWidth="md">
        <ViewReq {...{ setShowForm: setViewVio, viewData, setList: setVios }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authOwner: state.storeData.authOwner,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Violations);
