import { SendRounded } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { ToFormData } from "../../../hooks/Method";

const EmailForm = (props) => {
  const { setShowForm, setAlert, unit, _post, setEmails } = props;
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState([]);
  const { EmailMdl } = Model();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(EmailMdl);

  useEffect(() => {
    if (unit && unit.unitOwners.length > 0)
      setOwners([
        ...unit.unitOwners.map((j) => {
          return {
            id: j.ownerId,
            label: j.owner.fullName,
            email: j.owner.email,
          };
        }),
      ]);
    return () => {
      resetFormControls();
      setOwners([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  useEffect(() => {
    values.ownerId &&
      setValues((x) => ({
        ...x,
        email: owners.find((j) => j.id === values.ownerId).email,
      }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.ownerId]);

  const validateForm = () => {
    let temp = {};
    temp.ownerId = values.ownerId !== "" ? "" : "Owner name is required.";
    temp.email = values.email !== "" ? "" : "Email number is required.";
    temp.subject = values.subject !== "" ? "" : "Subject number is required.";
    temp.msg = values.msg !== "" ? "" : "Message number is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201) {
          setEmails((x) => [res.result, ...x]);
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Sent Succeed",
            subTitle: res.msg,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      values.unitId = unit.id;
      _post(
        endpoints.OwnerEmail + controller.Post,
        ToFormData(values),
        null,
        onComplete
      );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate className="p-15">
      <Grid container spacing={3}>
        <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
          <h2 className="h fw-5 mb-5 tx-upp">Send Email</h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-60" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            label="Owner"
            name="ownerId"
            value={values.ownerId}
            error={errors.ownerId}
            onChange={handleInpChg}
            options={owners}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Email"
            name="email"
            value={values.email}
            error={errors.email}
            onChange={handleInpChg}
            inputProps={{ maxLength: 300 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Subject"
            name="subject"
            value={values.subject}
            error={errors.subject}
            onChange={handleInpChg}
            inputProps={{ maxLength: 300 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            multiline
            rows={5}
            label="Message"
            name="msg"
            value={values.msg}
            error={errors.msg}
            onChange={handleInpChg}
            inputProps={{ maxLength: 2000 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="text" size="large" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            size="large"
            type="submit"
            loading={loading}
            fullWidth
            endIcon={<SendRounded />}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(EmailForm);
