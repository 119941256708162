import { TouchAppRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import AddEditReq from "./AddEditReq";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { DropLi } from "../../../hooks";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Requests = (props) => {
  const { _getByObj, _hoa, unit, _authOwner, fromHome = false, title } = props;
  const { ReqType } = DropLi();
  const navigate = useNavigate();
  const [updData, setUpdData] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [reqs, setReqs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      if (res.status === 200) {
        fromHome
          ? setReqs([
              ...res.result.filter(
                (j) =>
                  moment(j.createDate, "MM/DD/YYYY").format("MM/YYYY") ===
                  moment().format("MM/YYYY")
              ),
            ])
          : setReqs([...res.result]);
      }
    };
    if (unit && unit.id) {
      _getByObj(
        endpoints.Request + controller.GetAllByUnit,
        { id: unit.id, value: "1" },
        null,
        onComplete
      );
    } else if (_authOwner.id) {
      setLoader(true);
      _getByObj(
        endpoints.Request + controller.GetAllByOwner,
        { id: _authOwner.id, value: "1" },
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa, unit]);

  const handleView = (val) => {
    navigate(`/owner_portal/view_request/${val.id}`);
  };

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Request">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="btn-error"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );

  const HeaderComp = () => (
    <div>
      {fromHome ? (
        <div className="d-flex a-center g-10">
          <TouchAppRounded color="primary" />
          <span className="fs-20 gray-color fw-4 tx-upp">{title}</span>
        </div>
      ) : (
        <Button variant="outlined" onClick={() => handleAddEdit()}>
          Add Request
        </Button>
      )}
    </div>
  );
  return (
    <div className="p-15">
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "unitName", label: "Unit" },
          { id: "reqType", label: "Request Type" },
          { id: "createDate", label: "Create Date" },
          { id: "cmpltDate", label: "Complete Date" },
        ]}
        tblData={reqs.map((req) => {
          var type = ReqType.find((j) => j.id === req.type);
          return {
            ...req,
            unitName: req.unit && req.unit.title,
            reqType: type && type.label,
            status:
              req.isComplete === 2 ? (
                <span className="bg-warningT warning-color status">
                  Pending
                </span>
              ) : (
                <span className="bg-primarySM primary-color status">
                  Complete
                </span>
              ),
          };
        })}
      />

      {/* Add Requests */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditReq
            {...{
              setShowForm: setShowForm,
              setAlert,
              setList: setReqs,
              unit,
              updData,
              rcdType: 1,
            }}
          />
        </Dialog>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _authOwner: state.storeData.authOwner,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Requests);
