import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Alert } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const AddEditNote = (props) => {
  const {
    setShowForm,
    _post,
    _put,
    _authUser,
    updData,
    alert,
    setAlert,
    setNotes,
    vendor,
  } = props;
  const { VendorNoteMdl } = Model();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    values,
    setValues,
    resetFormControls,
    errors,
    setErrors,
    handleInpChg,
  } = UseForm(VendorNoteMdl);

  useEffect(() => {
    updData && setValues({ ...updData });
    setValues((x) => ({ ...x, vendorId: vendor.id, userId: _authUser.id }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData, _authUser, vendor]);

  const validateForm = () => {
    let temp = {};
    temp.note = values.note ? "" : "Note is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          if (setNotes && res.result.id) {
            updData
              ? setNotes((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setNotes((x) => [res.result, ...x]);
          }
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Note ${updData ? "Update" : "Added"} Successfully.`,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Something is Wrong",
            subTitle: "Please refresh the page and try again.",
            onClick: () => navigate("/my_account/vendors/"),
          });
      };
      if (!values.id) {
        _post(
          endpoints.VendorNote + controller.Post,
          ToFormData(values),
          null,
          onComplete
        );
      } else {
        _put(
          endpoints.VendorNote + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box className="p-15" sx={{ minWidth: { xs: "280px", md: "450px" } }}>
        <div className="tx-center mb-30">
          <h2 className="h fw-5 mb-5 mt-10 fs-20 tx-upp gray-color">
            {updData ? "Update" : "Add"} Note
          </h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-40" />
          </div>
        </div>
        <div className="mb-15">
          <Input
            multiline
            rows={4}
            label="Note"
            placeholder="Write your note here..."
            name="note"
            value={values.note}
            error={errors.note}
            onChange={handleInpChg}
            fullWidth
          />
        </div>
        <Box className="d-flex">
          <Button
            onClick={handleClose}
            fullWidth
            className="mr-10"
            variant="text"
          >
            Close
          </Button>
          <Button
            fullWidth
            type="submit"
            loading={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditNote);
