import { Box, IconButton, Paper } from "@mui/material";
import React from "react";
import { InputDate } from "../../../controls";
import { ArrowBackRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { subDays } from "date-fns";
import moment from "moment";

const RepNav = (props) => {
  const {
    csvHeader,
    csvData,
    setSortDate,
    sortDate,
    title = "",
    showTitle = true,
  } = props;
  const navigate = useNavigate();

  const handleInpChange = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  return (
    <>
      {showTitle && (
        <div className="mb-10">
          <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
            {title}
          </h2>
        </div>
      )}
      <Paper className="p-10">
        <div className="d-flex j-between flex-wrap a-center">
          <div className="mr-20">
            <IconButton
              size="small"
              className="link"
              onClick={() => navigate(-1)}
            >
              <ArrowBackRounded className="fs-24" />
            </IconButton>
          </div>
          <div className="d-flex flex-wrap">
            <Box
              className="d-flex tx-center"
              sx={{
                m: { xs: "10px 0px 15px 0px", md: "0px 15px 0px 0px" },
                width: { xs: "100%", md: "auto" },
              }}
            >
              <CSVLink
                data={csvData}
                headers={csvHeader}
                filename={`${title} (${new Date().toDateString()}).csv`}
                target="_blank"
                className="w-100pr tx-upp tx-und-none primary-color bg-primaryXS p-10 fs-14 br-7"
              >
                Download CSV
              </CSVLink>
            </Box>

            <div className="d-flex a-center">
              <InputDate
                variant="outlined"
                sx={{ maxWidth: "150px" }}
                size="small"
                name="fromDate"
                value={sortDate.fromDate}
                views={["day", "month", "year"]}
                openTo="day"
                minDate={new Date("01/01/2000")}
                maxDate={subDays(new Date(sortDate.toDate), 1)}
                onChange={(date) =>
                  handleInpChange(
                    "fromDate",
                    new Date(date).toLocaleDateString()
                  )
                }
              />
              <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
              <InputDate
                variant="outlined"
                sx={{ maxWidth: "150px" }}
                size="small"
                name="toDate"
                value={sortDate.toDate}
                minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
                views={["day", "month", "year"]}
                openTo="day"
                onChange={(date) =>
                  handleInpChange("toDate", new Date(date).toLocaleDateString())
                }
              />
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default RepNav;
