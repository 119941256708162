import React from "react";

const StmtPdf = ({ data, unitTitle, unitId }) => {
  return (
    <>
      <style>
        {`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

      #notePDF {
        font-family: 'Roboto', sans-serif;
      }
      
      #notePdfTbl,
      #notePdfTbl td,
      #notePdfTbl th {
        border: 1px solid #ddd;
        text-align: left;
      }
      
      #notePdfTbl {
        margin-top: 20px;
        border-collapse: collapse;
        width: 100%;
      }
      
      #notePdfTbl th,
      #notePdfTbl td {
        padding: 15px;
        font-weight: 400;
        letter-spacing: 0.04rem;
      }
      
      #notePdfTbl th {
        text-transform: uppercase;
      }
      
      #notePdfTbl td {
        color: rgb(105, 105, 105);
        white-space: pre-wrap;
      }
      
      #title h3,
      #title h4 {
        color: rgb(105, 105, 105);
        font-weight: 500;
        margin-top: 7px;
        margin-bottom: 7px;
      }
    `}
      </style>
      <div id="notePDF">
        <div id="title">
          <h3>Unit Id: {unitId} </h3>
          <h3>Unit Title: {unitTitle} </h3>
        </div>
        <table id="notePdfTbl">
          <thead>
            <tr>
              <th>Trans Date</th>
              <th>Particulars</th>
              <th>Instrument No</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data.map((j, ind) => (
                <tr key={ind}>
                  <td>{j.date}</td>
                  <td>{j.par}</td>
                  <td>{j.no}</td>
                  <td>{j.debit}</td>
                  <td>{j.credit}</td>
                  <td>{j.bal}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StmtPdf;
