import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { Alert } from "../../../layout";
import { CircularProgress } from "@mui/material";

const CheckPayment = (props) => {
  const { _post } = props;
  const [searchParams] = useSearchParams();
  const [param, setParam] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const success = searchParams.get("success");
    const sessionId = searchParams.get("session_id");
    const subsId = searchParams.get("subsId");
    const pkgTitle = searchParams.get("pkgTitle");

    setParam({
      success,
      sessionId,
      subsId,
      pkgTitle,
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (param && param.sessionId) {
      setLoading(true);
      const onComplete = (res) => {
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
            onClick: () => {
              navigate("/my_account/home/");
              window.location.reload();
            },
          });
        } else if (res.status === 499)
          setAlert({
            isOpen: true,
            type: "error",
            title: "Cancelled",
            subTitle: res.msg,
            onClick: () => navigate("/my_account/my_subscriptions/"),
          });
        else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
            onClick: () => navigate("/my_account/my_subscriptions/"),
          });
        setLoading(false);
      };

      _post(
        endpoints.StripePay + controller.CheckPayment,
        ToFormData(RmvEmptyProp(param)),
        null,
        onComplete
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return (
    <>
      {loading && (
        <div
          style={{
            height: "calc(100vh - 100px)",
          }}
          className="d-flex a-center j-center"
        >
          <div className="d-grid g-20 tx-center">
            <div>
              <CircularProgress />
            </div>
            <span className="gray-color">Processing...</span>
          </div>
        </div>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(CheckPayment);
