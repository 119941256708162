import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import UnitWidget from "./UnitWidget";
import UnitHeader from "../../../OwnerPortal/Units/UnitHeader";

const Units = (props) => {
  const { units } = props;
  const [filterUnits, setFilterUnits] = useState([]);

  useEffect(() => {
    setFilterUnits([...units]);

    return () => {};
  }, [units]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <UnitHeader {...{ units, setFilterUnits }} />
      </Grid>
      {filterUnits &&
        filterUnits.map((unit, i) => (
          <Grid item xs={12} md={3} key={i}>
            <UnitWidget {...{ unit }} />
          </Grid>
        ))}
    </Grid>
  );
};

export default Units;
