import { Box, TableCell } from "@mui/material";
import React, { useContext } from "react";
import { Table } from "../../../layout";
import { connect } from "react-redux";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import RepNav from "./RepNav";
import moment from "moment";

const JournalEntries = (props) => {
  const { _getById, _hoa } = props;
  const [ap, setAp] = useState([]);
  const [ar, setAr] = useState([]);
  const [filteredAp, setFilteredAp] = useState([]);
  const [filteredAr, setFilteredAr] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [tblHead, setTblHead] = useState([]);
  const [cats, setCats] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    setTblHead([
      { id: "catName", label: "Category" },
      { id: "debit", label: "Debit" },
      { id: "credit", label: "Credit" },
    ]);
    return () => {
      setTblHead([]);
    };
  }, []);

  useEffect(() => {
    if (ap || ar) {
      setFilteredAp([
        ...ap.filter(
          (j) =>
            moment(j.paidDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.paidDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
      setFilteredAr([
        ...ar.filter(
          (j) =>
            moment(j.dueDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.dueDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ap, ar, sortDate]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);

      _getById(endpoints.AP + controller.GetByHoa, _hoa.id, null, (res) => {
        setLoader(false);
        res.status === 200 &&
          setAp([...res.result.filter((j) => j.isComplete)]);
      });

      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setAr([...res.result])
      );

      _getById(
        endpoints.HoaCat + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setCats([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    if (filteredAr || filteredAp) {
      var _ar = [];
      var _ap = [];
      cats.forEach((cat) => {
        // Set Credit Amount
        if (cat.catType === 1) {
          var credit = filteredAr
            .filter((j) => j.categoryId === cat.id)
            .map((j) =>
              j.invPayments
                .map(
                  (x) =>
                    x.paidAmt +
                    x.invLateFees
                      .map((y) => y.paidAmt)
                      .reduce((x, y) => x + y, 0)
                )
                .reduce((x, y) => x + y, 0)
            )
            .reduce((x, y) => x + y, 0);
          credit &&
            _ar.push({
              catName: cat.name,
              credit,
            });
        } // Set Debity Amount
        else if (cat.catType === 2) {
          var debit = filteredAp
            .filter((j) => j.categoryId === cat.id)
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          debit &&
            _ap.push({
              catName: cat.name,
              debit,
            });
        }
      });
      setTotalCredit(_ar.map((j) => j.credit).reduce((a, b) => a + b, 0));
      setTotalDebit(_ap.map((j) => j.debit).reduce((a, b) => a + b, 0));
      setTblData([..._ap, ..._ar]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAr, filteredAp, cats]);

  const IncHeader = () => (
    <div className="d-flex a-center flex-wrap">
      <Box>
        <h2 className="border-left h fw-4 fs-18 gray-color tx-upp">
          Journal Entries
        </h2>
      </Box>
    </div>
  );

  return (
    <>
      <div>
        <RepNav
          {...{
            csvHeader: tblHead.map((j) => ({ ...j, key: j.id })),
            csvData: [
              ...tblData,
              {
                catName: "Totals",
                debit: totalDebit.toLocaleString(),
                credit: totalCredit.toLocaleString(),
              },
            ],
            sortDate,
            setSortDate,
            title: "Journal Entries",
            showTitle: false,
          }}
        />
      </div>
      <div className="mt-20">
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={IncHeader}
          tblHead={tblHead}
          tblData={tblData.map((j) => ({
            ...j,
            debit: j.debit ? j.debit.toLocaleString() : "",
            credit: j.credit ? j.credit.toLocaleString() : "",
          }))}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Totals
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $ {totalDebit.toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $ {totalCredit.toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(JournalEntries);
