import axios from "axios";

// const BaseUrl = "http://localhost:31884/api/";
const BaseUrl = "https://backend.sm876.com/api/";
// const BaseUrl = "https://sm876backend.jhprog.com/api/";

export const endpoints = {
  User: "User",
  Auth: "Auth",
  Admin: "Admin",
  Hoa: "Hoa",
  Org: "Org",
  Vendor: "Vendor",
  VendorNote: "VendorNote",
  BankAcc: "BankAcc",
  Document: "Document",
  Owner: "Owner",
  Unit: "Unit",
  OwnerEmail: "OwnerEmail",
  Request: "Request",
  Payment: "Payment",
  Invoice: "Invoice",
  Category: "Category",
  HoaCat: "HoaCat",
  AR: "AR",
  AP: "AP",
  InvNote: "InvNote",
  Budget: "Budget",
  UserSettings: "UserSettings",
  BankReco: "BankReco",
  InvPlan: "InvPlan",
  Notice: "Notice",
  ReqMsg: "ReqMsg",
  AuthAdmin: "AuthAdmin",
  SubsPkg: "SubsPkg",
  Subs: "Subs",
  SubsHold: "SubsHold",
  Master: "Master",
  Page: "Page",
  LP: "LP",
  Feature: "Feature",

  // For owner portal
  AuthOwner: "AuthOwner",
  StripePay: "StripePay",
  SubsInv: "SubsInv",
  Review: "Review",
};

export const controller = {
  Get: "/Get",
  GetAll: "/GetAll",
  GetById: "/GetById",
  GetByType: "/GetByType",
  GetByHoa: "/GetByHoa",
  Post: "/Post",
  Put: "/Put",
  Delete: "/Delete",
  GetAuthUser: "/GetAuthUser",
  Login: "/Login",
  Logout: "/Logout",
  Forgot: "/Forgot",
  ChgPass: "/ChgPass",
  GetAllById: "/GetAllById",
  GetAllByHoa: "/GetAllByHoa",
  MarkComplete: "/MarkComplete",
  GetAllByUnit: "/GetAllByUnit",
  GetUsers: "/GetUsers",

  GetAllByOwner: "/GetAllByOwner",
  GetInvsByUnit: "/GetInvsByUnit",
  CsvImport: "/CsvImport",
  GetByOrg: "/GetByOrg",
  GetCrDrByHoa: "/GetCrDrByHoa",
  GetCredits: "/GetCredits",
  RevPayment: "/RevPayment",
  GetDetailById: "/GetDetailById",
  GetOwnershipsByHoa: "/GetOwnershipsByHoa",
  LogsByHoa: "/LogsByHoa",
  Lock: "/Lock",
  GetAllByVendor: "/GetAllByVendor",
  GetAllByCatType: "/GetAllByCatType",
  GetByInv: "/GetByInv",
  GetInvIssuedUnitsByHoa: "/GetInvIssuedUnitsByHoa",
  GetUnpaidInvsByUnit: "/GetUnpaidInvsByUnit",
  GetUnpaidInvs: "/GetUnpaidInvs",
  GetCatsByType: "/GetCatsByType",
  ContactOwner: "/ContactOwner",
  SendEmail: "/SendEmail",
  RmvOwnerFromUnit: "/RmvOwnerFromUnit",
  SetDefHoa: "/SetDefHoa",
  RenameDoc: "/RenameDoc",
  GetAuthUserOrg: "/GetAuthUserOrg",
  GetAuthUserHoas: "/GetAuthUserHoas",
  GetInvSett: "/GetInvSett",
  PostInvSett: "/PostInvSett",
  PutInvSett: "/PutInvSett",
  NoticeMarkAsSeen: "/NoticeMarkAsSeen",
  GetAllForOwners: "/GetAllForOwners",
  CreateSubs: "/CreateSubs",
  GetAllByOrg: "/GetAllByOrg",
  Deactivate: "/Deactivate",
  OrgSubs: "/OrgSubs",
  ActivePlan: "/ActivePlan",
  StartHoldSubs: "/StartHoldSubs",
  CheckoutSession: "/CheckoutSession",
  CheckPayment: "/CheckPayment",
  GetUnpaidInv: "/GetUnpaidInv",
  GetSubsSettings: "/GetSubsSettings",
  PutSubsSettings: "/PutSubsSettings",
  ShowHide: "/ShowHide",
  GetTodayRegOrg: "/GetTodayRegOrg",
  GetAllPayment: "/GetAllPayment",
  Total: "/Total",
  GetAllPaymentsByOrg: "/GetAllPaymentsByOrg",
  DeactiveInv: "/DeactiveInv",
  GetAllCreditDebit: "/GetAllCreditDebit",
};

export const api = (endpoint) => {
  const url = BaseUrl + endpoint + "/";
  return {
    get: () => axios.get(url, { withCredentials: true }),
    getById: (id) => axios.get(url + id, { withCredentials: true }),
    getByObj: (obj) => axios.post(url, obj, { withCredentials: true }),
    post: (data) => axios.post(url, data, { withCredentials: true }),
    put: (data) => axios.put(url, data, { withCredentials: true }),
    delete: (id) => axios.delete(url + id, { withCredentials: true }),
  };
};
