import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { InputSwitch } from "../../controls";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import { controller, endpoints } from "../../api/actions/api";
import { Alert } from "../../layout";
import SubsPkgComp from "./SubsPkgComp";

const SubsPlan = (props) => {
  const { _getAll, fromDashboard = false, _get } = props;
  const [pkgs, setPkgs] = useState([]);
  const [fltPkgs, setFltPkgs] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [isYearlyPay, setIsYearlyPay] = useState(false);
  const [yearlyPayDis, setYearlyPayDis] = useState(0);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setPkgs([...res.result]);
    };
    _getAll(endpoints.SubsPkg, undefined, onComplete);
    // Get Yearly Payment Discount
    _get(
      endpoints.Master + controller.GetSubsSettings,
      null,
      (res) => res.status === 200 && setYearlyPayDis(res.result.yearlyPayDis)
    );

    return () => {
      setPkgs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isYearlyPay) {
      var decimalPct = yearlyPayDis / 100;
      setFltPkgs(
        pkgs.map((x) => ({
          ...x,
          price: (
            parseFloat(x.price) -
            parseFloat(x.price) * decimalPct
          ).toFixed(2),
        }))
      );
    } else setFltPkgs([...pkgs]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYearlyPay, pkgs]);

  const handleSwitch = (e) => {
    setIsYearlyPay(e.target.checked);
  };

  return (
    <>
      <div
        id="subscriptions"
        className={!fromDashboard ? "bg-primaryXS pt-80 pb-80" : "pt-20 pb-20"}
      >
        <Container maxWidth="xl">
          <div className="d-flex flex-wrap j-between mb-60">
            <div className="border-left tx-upp mb-20">
              <h1 className="h h2 fw-5 mb-5">Subscription Packages</h1>
              <span className="p">
                Strata suits association needs of every size.
              </span>
            </div>

            <div className="d-flex a-center fs-18 flex-wrap">
              <span className="dark-color">Pay Monthly</span>
              <InputSwitch
                checked={isYearlyPay}
                onChange={handleSwitch}
                name="isYearlyPay"
                label="Pay Yearly"
              />
              <span className="primary-color">(Save {yearlyPayDis}%)</span>
            </div>
          </div>
          <Grid container spacing={5} justifyContent="center">
            {fltPkgs.map((pkg, i) => (
              <Grid key={i} item xs={12} md={6} lg={4}>
                <SubsPkgComp {...{ setAlert, pkg, isYearlyPay }} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getAll: actions.get,
  _getById: actions.getById,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(SubsPlan);
