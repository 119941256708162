import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, InputFile, InputSwitch } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const UplFileForm = (props) => {
  const {
    setShowForm,
    _post,
    _authUser,
    _hoa,
    alert,
    setAlert,
    vendorId = "",
    unitId = "",
    setDocs,
    makePrivate = true,
  } = props;
  const { FileMdl } = Model();
  const { values, setValues, errors, setErrors, handleFile, handleInpCheck } =
    UseForm(FileMdl);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setValues((x) => ({
      ...x,
      vendorId,
      unitId,
      userId: _authUser.id,
      hoaId: _hoa.id,
      fileType: vendorId ? 1 : unitId ? 2 : 3,
      isPrivate: makePrivate,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId, unitId, _hoa]);

  const validateForm = () => {
    let temp = {};
    temp.file = values.file ? "" : "Document is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          if (setDocs && res.result.id) setDocs((x) => [res.result, ...x]);
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Document Added Successfully.`,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Something is Wrong",
            subTitle: "Please refresh the page and try again.",
            onClick: () => navigate("/my_account/vendors/"),
          });
      };
      _post(
        endpoints.Document + controller.Post,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box className="p-15" sx={{ minWidth: { xs: "280px", md: "380px" } }}>
        <Form onSubmit={handleSubmit} noValidate>
          <div className="tx-center mb-30">
            <h2 className="h fw-5 mb-5 fs-20 tx-upp">Upload Document</h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-40" />
            </div>
          </div>
          <div className="mb-15">
            <InputFile
              label="Upload Documents"
              name="file"
              src={values.fileName}
              values={values.fileName}
              onChange={handleFile}
              error={errors.file}
              rmv={() => {
                setValues({ ...values, file: "", fileName: "" });
              }}
            />
          </div>
          {values.fileType !== 3 && (
            <div className="mb-20">
              <InputSwitch
                label="Make Private"
                name="isPrivate"
                fontSize="16px"
                checked={JSON.parse(values.isPrivate)}
                onChange={handleInpCheck}
              />
            </div>
          )}
          <Box className="d-flex">
            <Button
              onClick={handleClose}
              fullWidth
              className="mr-10"
              variant="text"
            >
              Close
            </Button>
            <Button fullWidth type="submit" loading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      </Box>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(UplFileForm);
