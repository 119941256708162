import { EventNoteRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Dialog, Table } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import ViewNotice from "../../dashboard/Notice/ViewNotice";
import moment from "moment";
import { ToFormData } from "../../../hooks/Method";
import { OwnerContext } from "../OwnerPortalRoute";

const Notices = (props) => {
  const { _authOwner, fromHome = false, title, _post } = props;
  const { notices, setNotices } = useContext(OwnerContext);
  const [ntcs, setNtcs] = useState([]);
  const [viewData, setViewData] = useState();
  const [showView, setShowView] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (notices.length > 0) {
      fromHome
        ? setNtcs([
            ...notices.filter(
              (j) =>
                moment(j.createDate, "MM/DD/YYYY").format("MM/YYYY") ===
                moment().format("MM/YYYY")
            ),
          ])
        : setNtcs(notices);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notices]);

  const handleView = (data) => {
    setViewData(data);
    setShowView(true);
    !data.isSeen &&
      _post(
        endpoints.Notice + controller.NoticeMarkAsSeen,
        ToFormData({
          id: data.id,
          ownerId: _authOwner.id,
        }),
        null,
        (res) =>
          res.status === 201 &&
          setNotices((x) =>
            x.map((j) => (j.id === data.id ? { ...j, isSeen: true } : { ...j }))
          )
      );
  };

  const ActionComp = (props) => (
    <>
      <div className="d-flex">
        <Tooltip arrow title="Not Seen">
          <div
            style={{
              display: `${!props.isSeen ? "block" : "none"}`,
              height: "8px",
              width: "8px",
              backgroundColor: "var(--error-color)",
              borderRadius: "10px",
              marginRight: "10px",
            }}
          />
        </Tooltip>

        <Tooltip arrow title="View Notice">
          <IconButton
            onClick={() => handleView(props)}
            size="small"
            color="primary"
            className="mr-5"
          >
            <VisibilityRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );

  const HeaderComp = () => (
    <div className="d-flex a-center g-10">
      <EventNoteRounded color="primary" />
      <span className="fs-20 gray-color fw-4 tx-upp">{title || "Notices"}</span>
    </div>
  );

  return (
    <div className="p-15">
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "posted", label: "Posted" },
          { id: "note", label: "Notice" },
        ]}
        tblData={ntcs.map((ntc) => {
          return {
            ...ntc,
            posted: (
              <div className="d-grid">
                <span>{ntc.user && ntc.user.fullName}</span>
                <span className="fs-12 gray-color">{ntc.createDate}</span>
              </div>
            ),
            note: (
              <div style={{ maxWidth: "600px" }}>
                <span>{ntc.title}</span>
                <div className="max-txt-400">
                  <span className="fs-12 gray-color">{ntc.msg}</span>
                </div>
              </div>
            ),
          };
        })}
      />
      {/* View Notice */}
      {showView && (
        <Dialog show={showView} onClose={false} maxWidth="md">
          <ViewNotice {...{ setShowForm: setShowView, viewData }} />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authOwner: state.storeData.authOwner,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(Notices);
