import React, { useState, useEffect } from "react";
import { Input, Typography, IconButton } from "@mui/material";
import { useRef } from "react";
import { ClearRounded, FileUploadRounded } from "@mui/icons-material";
import { Alert } from "../layout";

const InputImg = (props) => {
  const {
    label = "Attach Img",
    name,
    error = null,
    onChange,
    rmv,
    src,
    value,
    maxSize = 3,
    subtitle,
  } = props;

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [sizeErr, setSizeErr] = useState();
  const [photoSrc, setPhotoSrc] = useState(null);
  const fileRef = useRef(null);

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      if (imageFile.size / 1048576 < maxSize) {
        const reader = new FileReader();
        reader.onload = (x) => {
          setPhotoSrc(x.target.result);
          onChange({ name, value: imageFile });
        };
        reader.readAsDataURL(imageFile);
        setSizeErr();
      } else setSizeErr(`Maximum Allowed ${maxSize}MB`);
    }
  };
  const rmvImg = () => {
    setPhotoSrc(null);
    rmv !== undefined && rmv();
  };

  useEffect(() => {
    src && setPhotoSrc(src);
    return () => {
      setPhotoSrc(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    value && !photoSrc && setPhotoSrc(URL.createObjectURL(value));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pos-relative">
        <div
          className="w-100pr d-flex j-center bg-primaryXS"
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          {photoSrc ? (
            <div className="pos-relative">
              <img
                className="w-100pr obj-cover c-pointer br-8"
                src={photoSrc}
                alt={photoSrc}
                onClick={() => fileRef.current.click()}
              />
              <IconButton
                color="error"
                size="small"
                onClick={rmvImg}
                sx={{ margin: "10px", right: "0", position: "absolute" }}
              >
                <ClearRounded />
              </IconButton>
            </div>
          ) : (
            <>
              <FileUploadRounded
                className="obj-cover c-pointer"
                color="whitemd"
                onClick={() => fileRef.current.click()}
                sx={{
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid",
                }}
                alt="Attach a PP Pic"
              />
              <div className="pos-absolute bottom-0 p-5 gray-color d-grid tx-center">
                <span className="tx-upp">{label}</span>
                <span className="tx-xs fw-5 mt-5">{subtitle}</span>
              </div>
            </>
          )}
        </div>

        <label htmlFor={name}>
          <Input
            accept="image/png, image/gif, image/jpeg, image/jpg"
            className="d-none"
            name={name}
            id={name}
            onChange={showPreview}
            type="file"
            ref={fileRef}
            inputProps={{ multiple: true }}
          />
        </label>
      </div>
      <Typography color="error" className="mt-10 font-4">
        {error || sizeErr}
      </Typography>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default InputImg;
