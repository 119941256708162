import { Rating } from "@mui/material";
import React from "react";

const InputRate = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    error = null,
    required,
    size = "large",
    ...other
  } = props;

  return (
    <div className="d-contents">
      <span className="p-5 mb-10 fs-14 gray-color">{label}</span>
      <br />
      <Rating
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        size={size}
        {...other}
      />
      <br />
      {error && !value && <span className="fs-12 error-color">{error}</span>}
    </div>
  );
};

export default InputRate;
