import React from "react";
import {
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
  FormHelperText,
} from "@mui/material";

export default function InputCheck(props) {
  const {
    label,
    checked,
    defaultChecked,
    name,
    onChange,
    size = "medium",
    error = null,
    className,
    ...other
  } = props;
  return (
    <FormGroup className="d-contents">
      <FormControlLabel
        onChange={onChange}
        className={"text-select-disable " + className}
        name={name}
        control={
          <MuiCheckbox
            size={size}
            checked={checked}
            defaultChecked={defaultChecked}
            className="p-5"
          />
        }
        label={
          <span className={`gray-color ${size === "small" ? "fs-14" : ""}`}>
            {label}
          </span>
        }
        {...other}
      />
      {error && (
        <FormHelperText className="color-danger">{error}</FormHelperText>
      )}
    </FormGroup>
  );
}
