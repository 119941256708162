import React, { useContext, useState } from "react";
import { Alert, Dialog, Table } from "../../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { useEffect } from "react";
import { AppContext } from "../../../../App";
import { controller, endpoints } from "../../../../api/actions/api";
import moment from "moment";
import AddEditNote from "./AddEditNote";
import Notes from "./Notes";
import { Button } from "../../../../controls";
import RepNav from "../../Report/RepNav";

const Receivables = (props) => {
  const { _getById, _hoa } = props;
  const { setLoader } = useContext(AppContext);
  const [tblData, setTblData] = useState([]);
  const [inv, setInv] = useState();
  const [filteredInvs, setFilteredInvs] = useState([]);
  const [invs, setInvs] = useState([]);
  const [totalAmt, setTotalAmt] = useState();
  const [totalPaidAmt, setTotalPaidAmt] = useState();
  const [totalDue, setTotalDue] = useState();
  const [last30DaysDue, setLast30DaysDue] = useState();
  const [last31To60DaysDue, setLast31To60DaysDue] = useState();
  const [last61To90DaysDue, setLast61To90DaysDue] = useState();
  const [more90DaysDue, setMore90DaysDue] = useState();
  const [viewNotes, setViewNotes] = useState();
  const [csvHeader, setCsvHeader] = useState([]);
  const [unitCredits, setUnitCredits] = useState([]);
  const [fltCredits, setFltCredits] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setCsvHeader([
      { label: "Invoice Id", key: "invId" },
      { label: "Unit", key: "unitName" },
      { label: "Issue Date", key: "issueDate" },
      { label: "Due Date", key: "dueDate" },
      { label: "Days Over Due", key: "daysOver" },
      { label: "Amount", key: "invTotal" },
      { label: "Paid", key: "paid" },
      { label: "Remaining", key: "remaining" },
      { label: "1 - 30", key: "1to30" },
      { label: "31 - 60", key: "31to60" },
      { label: "61 - 90", key: "61to90" },
      { label: "> 90", key: "more90" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setInvs([
            ...res.result.filter(
              (j) =>
                j.invPayments.length > 0 &&
                j.invType !== 3 &&
                j.invPayments.slice(-1).pop() &&
                j.invPayments.slice(-1).pop().status !== 2
            ),
          ]);
      };
      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        onComplete
      );
      _getById(
        endpoints.Unit + controller.GetAllCreditDebit,
        _hoa.id,
        null,
        (res) => res.status === 200 && setUnitCredits([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    invs &&
      setFilteredInvs([
        ...invs.filter(
          (j) =>
            moment(j.issueDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.issueDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invs, sortDate]);

  useEffect(() => {
    setFltCredits([
      ...unitCredits.filter((y) =>
        y.unitCreditDebits.some(
          (j) =>
            moment(j.addDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.addDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        )
      ),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitCredits, sortDate]);

  useEffect(() => {
    setTblData([
      ...filteredInvs.map((inv) => {
        var issueDate = inv.invPayments[0].issueDate;
        var dueDate = inv.invPayments.slice(-1)[0].dueDate;
        var dueDays = moment().diff(moment(dueDate, "MM/DD/YYYY"), "days");

        var totalInvAmt = inv.invPayments
          .filter((j) => !j.isRevPayment)
          .map(
            (j) =>
              j.amount +
              j.invLateFees.map((x) => x.amount).reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        const paidAmt = inv.invPayments
          .map(
            (j) =>
              j.paidAmt +
              j.invLateFees.map((x) => x.paidAmt).reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        const dueAmt = inv.invPayments
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        var last30DaysDue = inv.invPayments
          .filter(
            (j) =>
              moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >= 0 &&
              moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") <= 30
          )
          .map((j) =>
            j.isRevPayment === true
              ? j.amount + j.paidAmt
              : j.amount -
                j.paidAmt +
                j.invLateFees
                  .map((x) => x.amount - x.paidAmt)
                  .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        var last31to60DaysDue = inv.invPayments
          .filter(
            (j) =>
              moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >= 31 &&
              moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") <= 60
          )
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        var last61to90DaysDue = inv.invPayments
          .filter(
            (j) =>
              moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >= 61 &&
              moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") <= 90
          )
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2);
        var more90DaysDue = inv.invPayments
          .filter(
            (j) =>
              moment().diff(moment(j.issueDate, "MM/DD/YYYY"), "days") >= 90
          )
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        return {
          ...inv,
          isCredit: false,
          invId: inv.id,
          unitName: inv.unit && inv.unit.title,
          issueDate: issueDate,
          dueDate: dueDate,
          daysOver: dueDays,
          invTotal: "$" + totalInvAmt,
          paid: "$" + paidAmt,
          remaining: "$" + dueAmt,
          "1to30": "$" + last30DaysDue,
          "31to60": "$" + last31to60DaysDue,
          "61to90": "$" + last61to90DaysDue,
          more90: "$" + more90DaysDue,
        };
      }),
      ...fltCredits.map((j) => {
        const total = j.unitCreditDebits
          .map((x) => x.balance)
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        const last30Days = j.unitCreditDebits
          .filter(
            (x) =>
              moment().diff(moment(x.addDate, "MM/DD/YYYY"), "days") >= 0 &&
              moment().diff(moment(x.addDate, "MM/DD/YYYY"), "days") <= 30
          )
          .map((x) => x.balance)
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        const last31to60Days = j.unitCreditDebits
          .filter(
            (x) =>
              moment().diff(moment(x.addDate, "MM/DD/YYYY"), "days") >= 31 &&
              moment().diff(moment(x.addDate, "MM/DD/YYYY"), "days") <= 60
          )
          .map((x) => x.balance)
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        const last61to90Days = j.unitCreditDebits
          .filter(
            (x) =>
              moment().diff(moment(x.addDate, "MM/DD/YYYY"), "days") >= 61 &&
              moment().diff(moment(x.addDate, "MM/DD/YYYY"), "days") <= 90
          )
          .map((x) => x.balance)
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        const more90Days = j.unitCreditDebits
          .filter(
            (x) => moment().diff(moment(x.addDate, "MM/DD/YYYY"), "days") >= 90
          )
          .map((x) => x.balance)
          .reduce((x, y) => x + y, 0)
          .toFixed(2);

        return {
          ...j,
          isCredit: true,
          invId: "",
          unitName: j.title,
          issueDate:
            j.unitCreditDebits.length > 0 &&
            j.unitCreditDebits.slice(-1).addDate,
          dueDate: "",
          daysOver: "",
          invTotal: "",
          paid: "",
          remaining: "- $" + total,
          "1to30": "- $" + last30Days,
          "31to60": "- $" + last31to60Days,
          "61to90": "- $" + last61to90Days,
          more90: "- $" + more90Days,
        };
      }),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredInvs, fltCredits]);

  useEffect(() => {
    setTotalAmt(
      tblData
        .map((j) => parseFloat(j["invTotal"].replace(/[^\d.]/g, "")) || 0)
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    setTotalPaidAmt(
      tblData
        .map((j) => parseFloat(j["paid"].replace(/[^\d.]/g, "")) || 0)
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    setTotalDue(
      tblData
        .map((j) => {
          const amt = parseFloat(j["remaining"].replace(/[^\d.]/g, "")) || 0;
          return j.isCredit ? -amt : amt;
        })
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    setLast30DaysDue(
      tblData
        .map((j) => {
          const amt = parseFloat(j["1to30"].replace(/[^\d.]/g, "")) || 0;
          return j.isCredit ? -amt : amt;
        })
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    setLast31To60DaysDue(
      tblData
        .map((j) => {
          const amt = parseFloat(j["31to60"].replace(/[^\d.]/g, "")) || 0;
          return j.isCredit ? -amt : amt;
        })
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    setLast61To90DaysDue(
      tblData
        .map((j) => {
          const amt = parseFloat(j["61to90"].replace(/[^\d.]/g, "")) || 0;
          return j.isCredit ? -amt : amt;
        })
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    setMore90DaysDue(
      tblData
        .map((j) => {
          const amt = parseFloat(j["more90"].replace(/[^\d.]/g, "")) || 0;
          return j.isCredit ? -amt : amt;
        })
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    return () => {};
  }, [tblData]);

  const handleViewNotes = (val) => {
    setInv(val);
    setViewNotes(true);
  };

  const ActionComp = (props) => (
    <>
      {props.isCredit ? (
        <span className="primary-color bg-primarySM fs-12 tx-upp pl-10 pr-10 pt-5 pb-5 br-15">
          Credit
        </span>
      ) : (
        <Button
          onClick={() => handleViewNotes(props)}
          size="small"
          variant="outlined"
          className="pr-5 pl-5"
        >
          Notes ({props && props.invNotes && props.invNotes.length})
        </Button>
      )}
    </>
  );

  return (
    <>
      <div className="mt-20 mb-10">
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "ACCOUNT RECEIVABLES",
            showTitle: false,
          }}
        />
      </div>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => (
          <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
            ACCOUNT RECEIVABLES
          </h2>
        )}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "unitName", label: "Unit" },
          { id: "invId", label: "Invoice No" },
          { id: "issueDate", label: "Issue Date" },
          { id: "dueDate", label: "Due Date" },
          {
            id: "daysOver",
            label: <span className="fs-14">Days Over Due</span>,
          },
          {
            id: "invTotal",
            label: (
              <div className="d-grid">
                <span>Amount</span>
                <span className="gray-color fs-14">${totalAmt}</span>
              </div>
            ),
          },
          {
            id: "paid",
            label: (
              <div className="d-grid">
                <span>Paid</span>
                <span className="gray-color fs-14">${totalPaidAmt}</span>
              </div>
            ),
          },
          {
            id: "remaining",
            label: (
              <div className="d-grid">
                <span>Remaining</span>
                <span className="gray-color fs-14">${totalDue}</span>
              </div>
            ),
          },
          {
            id: "1to30",
            label: (
              <div className="d-grid">
                <span>1 - 30</span>
                <span className="gray-color fs-14">${last30DaysDue}</span>
              </div>
            ),
          },
          {
            id: "31to60",
            label: (
              <div className="d-grid">
                <span>31 - 60</span>
                <span className="gray-color fs-14">${last31To60DaysDue}</span>
              </div>
            ),
          },
          {
            id: "61to90",
            label: (
              <div className="d-grid">
                <span>61 - 90</span>
                <span className="gray-color fs-14">${last61To90DaysDue}</span>
              </div>
            ),
          },
          {
            id: "more90",
            label: (
              <div className="d-grid">
                <span> {">"} 90</span>
                <span className="gray-color fs-14">${more90DaysDue}</span>
              </div>
            ),
          },
        ]}
        tblData={tblData.map((j) => {
          return {
            ...j,
            daysOver: (
              <span className={0 < j.daysOver ? "error-color" : "dark-color"}>
                {j.daysOver}
              </span>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Add Edit Form */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditNote
            {...{ setShowForm: setShowForm, setAlert, inv, setFilteredInvs }}
          />
        </Dialog>
      )}
      {/* View Notes */}
      {viewNotes && (
        <Dialog show={viewNotes} onClose={false} maxWidth="lg">
          <Notes
            {...{
              setShowForm: setViewNotes,
              setAlert,
              inv,
              setViewNotes,
              filteredInvs,
              setFilteredInvs,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Receivables);
