import {
  ClearRounded,
  SendRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import ViewEmail from "./ViewEmail";
import EmailForm from "./EmailForm";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";

const Emails = (props) => {
  const { _getById, unit, _delete } = props;

  const [emails, setEmails] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [viewData, setViewData] = useState();
  const [showDtls, setShowDtls] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (unit.id) {
      const onComplete = (res) => {
        res.status === 200 && setEmails(res.result);
      };
      _getById(
        endpoints.OwnerEmail + controller.GetAllByUnit,
        unit.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete this email sent record.",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setEmails((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.OwnerEmail, id, null, onComplete);
      },
    });
  };

  const handleView = (val) => {
    setViewData(val);
    setShowDtls(true);
  };

  const handleSendForm = () => {
    setShowForm(true);
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Email" className="mr-10">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="btn-error"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete Record">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
          className="btn-error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <div>
      <Button
        variant="outlined"
        onClick={handleSendForm}
        endIcon={<SendRounded />}
      >
        Send Email
      </Button>
    </div>
  );
  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "sentDate", label: "Sent Date" },
          { id: "name", label: "Owner Name" },
          { id: "email", label: "Sent Email" },
          { id: "subject", label: "Subject" },
        ]}
        tblData={emails.map((email, i) => {
          return {
            ...email,
            name: email.owner && email.owner.fullName,
            email: (
              <a href={`mailto:${email.email}`} className="link">
                {email.email}
              </a>
            ),
          };
        })}
      />

      {/* Send Eamil */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <EmailForm
            {...{ setShowForm: setShowForm, setAlert, setEmails, unit }}
          />
        </Dialog>
      )}
      {/* View Eamil */}
      <Dialog show={showDtls} onClose={false} maxWidth="md">
        <ViewEmail {...{ setShowForm: setShowDtls, viewData }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Emails);
