import React, { createContext } from "react";
import { Notices, Requests, Violations } from "..";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import * as actions from "../../../api/actions/actions";

export const OwnerContext = createContext({});

const Home = (props) => {
  const { _authOwner } = props;

  return (
    <div>
      {_authOwner && (
        <>
          <div
            className="g-10 p-20 d-grid j-center a-center tx-center bg-primary white-color"
            style={{ borderRadius: "0px 0px 30px 30px" }}
          >
            <span className="fw-3 fs-22">Hi, {_authOwner.fullName}</span>

            <div className="d-flex g-10 a-end">
              <span className="fs-24 fw-3">Welcome to</span>
              <span className="fs-28 fw-5">
                {_authOwner.hoa && _authOwner.hoa.org.orgName} 🎉
              </span>
            </div>
          </div>
          <div className="p-10">
            <Grid container spacing={2}>
              <Grid item xs={12} xl={6}>
                <div className="bg-white br-10 shadow-primary mt-20">
                  <Notices fromHome={true} title="This month notices" />
                </div>
              </Grid>
              <Grid item xs={12} xl={6}>
                <div className="bg-white br-10 shadow-primary mt-20">
                  <Requests fromHome={true} title="This month request" />
                </div>
              </Grid>
              <Grid item xs={12} xl={6}>
                <div className="bg-white br-10 shadow-primary mt-20">
                  <Violations fromHome={true} title="This month violations" />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authOwner: state.storeData.authOwner,
});

const mapActiontoProps = {
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActiontoProps)(Home);
