import {
  AdminPanelSettingsRounded,
  BarChartRounded,
  CorporateFareRounded,
  PagesRounded,
  PlaylistAddCheckCircleRounded,
  ReviewsRounded,
  SellRounded,
  WebRounded,
} from "@mui/icons-material";
import React from "react";
import { NavItem } from "../../layout";
import { connect } from "react-redux";

const NavMenu = () => {
  return (
    <>
      <NavItem title="Dashboard" path="home" icon={<BarChartRounded />} />
      <NavItem
        title="Organizations"
        path="orgs"
        icon={<CorporateFareRounded />}
      />
      <NavItem
        title="Subscriptions"
        icon={<SellRounded />}
        child={[
          { title: "Subscriptions", path: "subscriptions" },
          { title: "Packages", path: "subs_pkg" },
        ]}
      />
      <NavItem
        title="Master"
        icon={<AdminPanelSettingsRounded />}
        child={[
          { title: "Users", path: "users" },
          { title: "Subs. Settings", path: "subs_settings" },
        ]}
      />
      <NavItem title="Pages" path="pages" icon={<PagesRounded />} />
      <NavItem title="Landing Pg." path="landing_pg" icon={<WebRounded />} />
      <NavItem
        title="Features"
        path="features"
        icon={<PlaylistAddCheckCircleRounded />}
      />
      <NavItem title="Reviews" path="reviews" icon={<ReviewsRounded />} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(NavMenu);
