import React from "react";
import Orgs from "../Org/Orgs";

const TodayRegOrg = () => {
  return (
    <div>
      <Orgs title="Today Registared" today={true} />
    </div>
  );
};

export default TodayRegOrg;
