import { ActionType } from "../actions/actions";

export const initialState = {
  list: [],
  type: "",
  data: {},
  bool: false,
  authUser: {},
  authOwner: {},
  admin: {},
  ownerUnits: [],
  selUnit: {},
  selHoa: {},
  hoas: [],
  defHoa: {},
  subs: {},
};

export const storeData = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.List:
      return {
        ...state,
        list: [...action.payload.result],
        type: action.payload.type,
      };
    case ActionType.OwnerUnits:
      return {
        ...state,
        ownerUnits: [...action.payload.result],
      };
    case ActionType.SelUnit:
      return {
        ...state,
        selUnit: action.payload.result,
      };
    case ActionType.AddItem:
      return {
        ...state,
        list: [action.payload.result, ...state.list],
        type: action.payload.type,
      };
    case ActionType.UpdateItem:
      return {
        ...state,
        list: state.list.map((j) =>
          j.id === action.payload.result.id ? action.payload.result : j
        ),
      };
    case ActionType.Data:
      return {
        ...state,
        data: action.payload.result,
        type: action.payload.type,
      };
    case ActionType.RmvList:
      return {
        ...state,
        list: state.list.filter((j) => j.id !== action.payload.result.id),
      };
    case ActionType.Check:
      return {
        ...state,
        type: action.payload.type,
        bool: action.payload.result,
      };
    case ActionType.Auth:
      return {
        ...state,
        authUser: action.payload.result,
      };
    case ActionType.AuthOwner:
      return {
        ...state,
        authOwner: action.payload.result,
      };
    case ActionType.AuthAdmin:
      return {
        ...state,
        admin: action.payload.result,
      };
    case ActionType.ClearLi:
      return {
        ...state,
        list: [],
        type: "",
      };
    case ActionType.ClearData:
      return {
        ...state,
        data: {},
        type: "",
      };
    case ActionType.Hoa:
      return {
        ...state,
        hoas: action.payload.result,
      };
    case ActionType.DefHoa:
      return {
        ...state,
        defHoa: action.payload.result,
      };
    case ActionType.Subs:
      return {
        ...state,
        subs: action.payload.result,
      };
    case ActionType.RmvHoa:
      return {
        ...state,
        hoas: state.hoas.filter((j) => j.id !== action.payload.result.id),
      };
    default:
      return state;
  }
};
