import {
  CircularProgress,
  Divider,
  LinearProgress,
  Paper,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import moment from "moment";

const BudgetReport = (props) => {
  const {
    title,
    date,
    _getById,
    _getByObj,
    _hoa,
    budgetType = 1,
    revenue = [],
    expense = [],
  } = props;
  const [cats, setCats] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [filteredBdg, setFilteredBdg] = useState([]);
  const [totalBdg, setTotalBdg] = useState(0);
  const [totalColl, setTotalColl] = useState(0);

  useEffect(() => {
    if (_hoa.id) {
      _getByObj(
        endpoints.HoaCat + controller.GetAllByCatType,
        {
          id: _hoa.id,
          value: budgetType.toString(),
        },
        null,
        (res) => res.status === 200 && setCats([...res.result])
      );
      _getById(
        endpoints.Budget + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setBudgets([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa.id]);

  useEffect(() => {
    budgets &&
      setFilteredBdg([
        ...budgets.filter(
          (j) =>
            moment(`${j.month}/${j.year}`, "MM/YYYY") >=
              moment(
                moment(date.fromDate, "MM/DD/YYYY").format("MM/YYYY"),
                "MM/YYYY"
              ) &&
            moment(`${j.month}/${j.year}`, "MM/YYYY") <=
              moment(
                moment(date.toDate, "MM/DD/YYYY").format("MM/YYYY"),
                "MM/YYYY"
              )
        ),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, budgets]);

  useEffect(() => {
    if (budgetType === 1)
      setTotalColl(revenue.map((j) => j.amount).reduce((x, y) => x + y, 0));
    else if (budgetType === 2)
      setTotalColl(expense.map((j) => j.amount).reduce((x, y) => x + y, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenue, expense]);

  useEffect(() => {
    filteredBdg &&
      setTotalBdg(
        filteredBdg
          .filter((j) => j.cat.catType === budgetType)
          .map((j) => j.amount)
          .reduce((x, y) => x + y, 0)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredBdg]);

  return (
    <Paper className="p-15">
      <h2 className="h h2 fs-18 border-left tx-upp">{title}</h2>
      <div className="mt-40 mb-40 tx-center pos-relative">
        <div
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <h2 className="h mb-10 dark-color fw-5 fs-24">
              ${totalColl.toLocaleString()}
            </h2>
            <span className="gray-color fw-5">
              / ${totalBdg.toLocaleString()}
            </span>
            <span className="dark-color fw-5"> Budgeted</span>
          </div>
        </div>
        <CircularProgress
          variant="determinate"
          value={100}
          size="13rem"
          thickness={1.5}
          sx={{ position: "absolute", color: "var(--grayXS-color)" }}
        />
        <CircularProgress
          variant="determinate"
          value={
            totalColl > 0
              ? totalColl > totalBdg
                ? 100
                : (totalColl / totalBdg) * 100
              : 0
          }
          size="13rem"
          thickness={1.5}
          sx={{ strokeLinecap: "round" }}
        />
      </div>

      {cats &&
        cats.map((cat, i) => {
          var collAmt = 0;
          var bdgAmt = 0;
          if (budgetType === 1)
            collAmt = revenue
              .filter((j) => j.inv.categoryId === cat.id)
              .map((j) => j.amount)
              .reduce((x, y) => x + y, 0);
          else if (budgetType === 2)
            collAmt = expense
              .filter((j) => j.categoryId === cat.id)
              .map((j) => j.amount)
              .reduce((x, y) => x + y, 0);

          if (filteredBdg)
            bdgAmt = filteredBdg
              .filter((j) => j.catId === cat.id)
              .map((j) => j.amount)
              .reduce((x, y) => x + y, 0);

          return (
            <div key={i}>
              <Box
                className="d-flex a-center j-between flex-wrap mb-10 mt-10"
                sx={{ justifyContent: { md: "space-between" } }}
              >
                <div className="w-100pr">
                  <div className="d-flex j-between fs-14">
                    <span className="gray-color mr-5 mb-5">{cat.name}</span>
                    <div>
                      <span className="dark-color fw-5">
                        ${collAmt.toLocaleString()}&nbsp;/&nbsp;
                      </span>
                      <span className="gray-color">
                        ${bdgAmt.toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>

                <LinearProgress
                  variant="determinate"
                  value={
                    collAmt > 0
                      ? collAmt > bdgAmt
                        ? 100
                        : (collAmt / bdgAmt) * 100
                      : 0
                  }
                  sx={{
                    backgroundColor: "var(--grayXS-color)",
                    height: "8px",
                    borderRadius: "20px",
                    width: "100%",
                  }}
                />
              </Box>
              <Divider />
            </div>
          );
        })}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(BudgetReport);
