import React, { useEffect, useState } from "react";
import TextEditor from "../../../controls/TextEditor";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { DropLi, Model, UseForm } from "../../../hooks";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { Alert, Form } from "../../../layout";
import { Grid } from "@mui/material";
import { Button, Input, Select } from "../../../controls";
import { useNavigate, useParams } from "react-router-dom";

const PageForm = (props) => {
  const { _getById, _post, _put } = props;
  const { PageMdl } = Model();
  const { PageType } = DropLi();
  const navigate = useNavigate();
  const { id } = useParams();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(PageMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    id &&
      _getById(
        endpoints.Page + controller.GetById,
        id,
        null,
        (res) => res.status === 200 && setValues({ ...res.result })
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.type = values.type !== "" ? "" : "Page type is required.";
    temp.btnName = values.btnName !== "" ? "" : "Button name is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Page ${values.id ? "updated" : "created"} Successfully.`,
            onClick: () => navigate("/admin_panel/pages"),
          });
        } else if (res.status === 409)
          setErrors((x) => ({ ...x, email: res.msg }));
        else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };

      if (values.id === 0) {
        _post(
          endpoints.Page + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          undefined,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.Page + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          undefined,
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="mb-10 d-flex j-between a-center">
              <div>
                <h2 className="h tx-md fw-5 mb-5 tx-upp border-left">
                  {id ? "Update" : "Create"} Page
                </h2>
              </div>
              <div>
                <Button type="submit" size="large" loading={loading}>
                  {values.id ? "Save Changes" : "Submit"}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label="Page Type"
              name="type"
              value={values.type}
              error={errors.type}
              onChange={handleInpChg}
              fullWidth
              options={PageType}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Button Name"
              name="btnName"
              value={values.btnName}
              error={errors.btnName}
              onChange={handleInpChg}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>
          {values.type === 3 && (
            <Grid item xs={12}>
              <Input
                label="Title"
                name="title"
                value={values.title}
                error={errors.title}
                onChange={handleInpChg}
                inputProps={{ maxLength: 300 }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextEditor
              onChange={handleInpChg}
              name="content"
              value={values.content}
              error={errors.content}
            />
          </Grid>
        </Grid>
      </Form>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(PageForm);
