import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Button, Input, Select } from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const AddEditHoaCat = (props) => {
  const {
    _post,
    _put,
    _authUser,
    _hoa,
    setShowForm,
    setAlert,
    updData,
    setHoasCat,
  } = props;
  const { HoaCatMdl } = Model();
  const { CatType } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(HoaCatMdl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updData && setValues({ ...updData });
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  const validateForm = () => {
    let temp = {};
    temp.catType = values.catType !== "" ? "" : "Category type is required.";
    temp.catName = values.catName !== "" ? "" : "Category name is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          if (setHoasCat && res.result.id) {
            updData
              ? setHoasCat((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setHoasCat((x) => [res.result, ...x]);
          }
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        values.userId = _authUser.id;
        _post(
          endpoints.HoaCat + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.HoaCat + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      noValidate
      className="p-15"
      style={{ maxWidth: "450px" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
          <h2 className="h fw-5 mb-5 tx-upp">
            {updData ? "Update" : "Add"} Category
          </h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-60" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Category Type"
            name="catType"
            value={values.catType}
            error={errors.catType}
            onChange={handleInpChg}
            options={CatType}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Category Name"
            name="name"
            value={values.name}
            error={errors.name}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Button variant="text" size="large" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button loading={loading} size="large" type="submit" fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditHoaCat);
