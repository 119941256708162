import {
  ContactsRounded,
  EmailRounded,
  FaxRounded,
  LandscapeRounded,
  LanguageRounded,
  LocationOnRounded,
  PersonPinRounded,
  PhoneRounded,
  TagRounded,
} from "@mui/icons-material";
import { Avatar, Box, Divider, Grid, Paper, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Payables from "../Transactions/Payable/Payables";
import VendorNote from "./VendorNote";
import * as actions from "../../../api/actions/actions";
import { useNavigate, useParams } from "react-router-dom";
import { controller, endpoints } from "../../../api/actions/api";
import { Alert } from "../../../layout";
import { DropLi, Model } from "../../../hooks";
import Doc from "../Documents/Doc";

const ViewVendor = (props) => {
  const { _getById } = props;
  const { id } = useParams();
  const { Countries } = DropLi();
  const { VendorMdl } = Model();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState(VendorMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200 && res.result.id
        ? setVendor({ ...res.result })
        : setAlert({
            isOpen: true,
            type: "error",
            title: "Something is Wrong",
            subTitle: "Please refresh the page and try again.",
            onClick: () => navigate("/my_account/vendors/"),
          });
    };
    id && _getById(endpoints.Vendor + controller.GetById, id, null, onComplete);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Grid container spacing={3}>
      {/* Vendor Details */}
      <Grid item xs={12} md={4}>
        <Paper>
          <div className=" tx-center p-15">
            <div className="d-flex j-center">
              <Avatar
                src={vendor.logo}
                alt="logo"
                className="bg-primary"
                sx={{ width: 80, height: 80 }}
              >
                {!vendor.logo && <LandscapeRounded />}
              </Avatar>
            </div>
            <h2 className="h mt-10 fs-18 fw-5 dark-color ">
              {vendor.name} {vendor.nickname && `(${vendor.nickname})`}
            </h2>
          </div>
          <Divider />
          <div className="gray-color">
            <div className="d-flex a-center p-15 ">
              <Tooltip title="Vendor Address">
                <LocationOnRounded color="error" className="mr-15 fs-18" />
              </Tooltip>
              <span>
                {vendor.address1 +
                  ", " +
                  vendor.address2 +
                  ", " +
                  vendor.state +
                  ", " +
                  vendor.city +
                  ", " +
                  (vendor.country &&
                    Countries.find((j) => j.id === vendor.country).label) +
                  ", " +
                  vendor.zip}
              </span>
            </div>
            <Divider />
            <div className="d-flex a-center p-15 ">
              <Tooltip title="Account Number">
                <TagRounded className="mr-15 fs-18" />
              </Tooltip>
              <span>{vendor.accNumber}</span>
            </div>
            <Divider />
            <div className="d-flex a-center p-15 ">
              <Tooltip title="EIN">
                <PersonPinRounded className="mr-15 fs-18" />
              </Tooltip>
              <span>{vendor.vendorEin}</span>
            </div>
            <Divider />
            <div className="d-flex a-center p-15 ">
              <Tooltip title="Contact Name">
                <ContactsRounded className="mr-15 fs-18 fs-18" />
              </Tooltip>
              <span>{vendor.contactName}</span>
            </div>
            <Divider />
            <div className="d-flex a-center p-15 ">
              <Tooltip title="Contact Phone">
                <PhoneRounded className="mr-15 fs-18" />
              </Tooltip>
              <a className="gray-color hove-primary" href="tel:23456789">
                {vendor.phone}
              </a>
            </div>
            <Divider />
            <div className="d-flex a-center p-15 ">
              <Tooltip title="Fax Number">
                <FaxRounded className="mr-15 fs-18" />
              </Tooltip>
              <span> {vendor.fax}</span>
            </div>
            <Divider />
            <div className="d-flex a-center p-15 ">
              <Tooltip title="Contact Eamil">
                <EmailRounded className="mr-15 fs-18" />
              </Tooltip>
              <a
                className="gray-color hove-primary"
                href={`mailto:${vendor.contactEamil}`}
              >
                {vendor.contactEamil}
              </a>
            </div>
            <Divider />
            <div className="d-flex a-center p-15 ">
              <Tooltip title="Vendor Website">
                <LanguageRounded className="mr-15 fs-18" />
              </Tooltip>
              <a
                className="gray-color hove-primary"
                href={vendor.website}
                target="_blank"
                rel="noreferrer"
              >
                {vendor.website}
              </a>
            </div>
          </div>
        </Paper>
      </Grid>

      {/* Vendor Billing Table  */}
      <Grid item xs={12} md={8}>
        <Box sx={{ minHeight: { md: "550px" } }}>
          <Payables {...{ tblShadow: false, vendor }} />
        </Box>
      </Grid>

      {/* Vendor Documents */}
      <Grid item xs={12} md={6}>
        <Doc tblShadow={false} vendor={vendor} />
      </Grid>

      {/* Vendor Notes */}
      <Grid item xs={12} md={6}>
        <VendorNote tblShadow={false} vendor={vendor} />
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewVendor);
