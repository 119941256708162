import { Container, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const InvSettings = (props) => {
  const { _getById, _post, _put, _hoa } = props;
  const { InvSettingsMdl } = Model();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { values, setValues, handleInpChg, resetFormControls } =
    UseForm(InvSettingsMdl);

  useEffect(() => {
    _hoa.id &&
      _getById(
        endpoints.UserSettings + controller.GetInvSett,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 && setValues((x) => ({ ...x, ...res.result }))
      );

    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa.id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const onComplete = (res) => {
      setLoading(false);
      if (res.status === 201 || res.status === 200) {
        setValues((x) => ({ ...x, ...res.result }));
        setAlert({
          isOpen: true,
          type: "success",
          title: "Success",
          subTitle: res.msg,
        });
      } else
        setAlert({
          isOpen: true,
          type: "error",
          title: "Error",
          subTitle: res.msg,
        });
    };
    if (values.id === 0) {
      values.hoaId = _hoa.id;
      _post(
        endpoints.UserSettings + controller.PostInvSett,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    } else if (values.id !== 0) {
      _put(
        endpoints.UserSettings + controller.PutInvSett,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm" className="mt-30">
        <Paper className="p-15">
          <Form onSubmit={handleSubmit} noValidate>
            <div className="tx-center p-20 mt-20 mb-10 w-100pr">
              <h2 className="h fw-5 mb-5 mt-10 tx-upp">Invoice Settings</h2>
              <div className="pos-relative d-flex j-center">
                <div className="border-bottom w-60" />
              </div>
            </div>
            <div className="mb-15">
              <Input
                label="Early Payment Discount (%)"
                name="earlyPayDis"
                type="number"
                placeholder="0.00%"
                value={values.earlyPayDis}
                onChange={handleInpChg}
                fullWidth
              />
            </div>
            <div className="mb-15">
              <Input
                label="Late Fee (%)"
                name="lateFee"
                type="number"
                placeholder="0.00%"
                value={values.lateFee}
                onChange={handleInpChg}
                fullWidth
              />
            </div>

            <div className="tx-right">
              <Button size="large" type="submit" loading={loading}>
                Submit
              </Button>
            </div>
          </Form>
        </Paper>
      </Container>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(InvSettings);
