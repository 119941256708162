import { Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Widget = (props) => {
  const { url, icon, title, subtitle = "" } = props;
  return (
    <Paper className="p-20">
      <Link to={url} className="tx-und-none">
        <div className="d-flex a-center">
          <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
            {icon}
          </div>
          <div className="ml-15">
            <h2 className="h fs-22 fw-6">{title}</h2>
            {subtitle && <span className="tx-sm gray-color">{subtitle}</span>}
          </div>
        </div>
      </Link>
    </Paper>
  );
};

export default Widget;
