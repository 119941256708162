import { Grid, TableCell } from "@mui/material";
import React, { useContext } from "react";
import { Table } from "../../../layout";
import { connect } from "react-redux";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import RepNav from "./RepNav";
import moment from "moment";

const BudgetVsActual = (props) => {
  const { _getById, _hoa } = props;
  const [ap, setAp] = useState([]);
  const [ar, setAr] = useState([]);
  const [totalExp, setTotalExp] = useState(0);
  const [totalAct, setTotalAct] = useState(0);
  const [filteredAp, setFilteredAp] = useState([]);
  const [filteredAr, setFilteredAr] = useState([]);
  const [apTblData, setApTblData] = useState([]);
  const [arTblData, setArTblData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [cats, setCats] = useState([]);
  const [bdgts, setBdgts] = useState([]);
  const [filteredBdgts, setFilteredBdgts] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    setCsvHeader([
      { label: "Category", key: "catName" },
      { label: "Expected", key: "expected" },
      { label: "Actual", key: "actual" },
      { label: "Variance", key: "variance" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    if (apTblData || arTblData) {
      setCsvData((x) => [
        ...x,
        {
          catName: "Income",
          expected: "-----------",
          actual: "-----------",
          variance: "-----------",
        },
        ...arTblData,
      ]);
      setCsvData((x) => [
        ...x,
        {},
        {
          catName: "Expense",
          expected: "-----------",
          actual: "-----------",
          variance: "-----------",
        },
        ...apTblData,
        {},
        {
          catName: "Total",
          expected: totalExp,
          actual: totalAct,
          variance: totalExp - totalAct,
        },
      ]);

      // Totals
      setTotalExp(
        arTblData.map((j) => j.expected).reduce((x, y) => x + y, 0) +
          apTblData.map((j) => j.expected).reduce((x, y) => x + y, 0)
      );
      setTotalAct(
        arTblData.map((j) => j.actual).reduce((x, y) => x + y, 0) +
          apTblData.map((j) => j.actual).reduce((x, y) => x + y, 0)
      );
    }
    return () => {
      setCsvData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apTblData, arTblData]);

  useEffect(() => {
    if (ap || ar) {
      setFilteredAp([
        ...ap.filter(
          (j) =>
            moment(j.paidDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.paidDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
      setFilteredAr([
        ...ar.filter(
          (j) =>
            moment(j.dueDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.dueDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
      setFilteredBdgts([
        ...bdgts.filter(
          (j) =>
            moment(`${j.month}/${j.year}`, "MM/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(`${j.month}/${j.year}`, "MM/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ap, ar, sortDate]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);

      _getById(endpoints.AP + controller.GetByHoa, _hoa.id, null, (res) => {
        setLoader(false);
        res.status === 200 &&
          setAp([...res.result.filter((j) => j.isComplete)]);
      });

      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setAr([...res.result])
      );

      _getById(
        endpoints.HoaCat + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setCats([...res.result])
      );

      _getById(
        endpoints.Budget + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setBdgts([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  // console.log("ar: ", filteredAr);

  useEffect(() => {
    if (filteredAr) {
      var _arTblData = [];
      cats
        .filter((j) => j.catType === 1)
        .forEach((cat) => {
          var _exp = filteredBdgts
            .filter((j) => j.catId === cat.id)
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);

          var _act = filteredAr
            .filter((j) => j.categoryId === cat.id)
            .map((j) =>
              j.invPayments
                .map((x) =>
                  x.isRevPayment
                    ? x.paidAmt -
                      x.amount +
                      x.invLateFees
                        .map((y) => y.paidAmt)
                        .reduce((x, y) => x + y, 0)
                    : x.paidAmt +
                      x.invLateFees
                        .map((y) => y.paidAmt)
                        .reduce((x, y) => x + y, 0)
                )
                .reduce((x, y) => x + y, 0)
            )
            .reduce((x, y) => x + y, 0);

          _arTblData.push({
            catName: cat.name,
            expected: _exp,
            actual: _act,
            variance: _exp - _act,
          });
        });
      setArTblData([..._arTblData]);
    }

    return () => {
      setApTblData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAr, cats]);

  useEffect(() => {
    if (filteredAp) {
      var _apTblData = [];
      cats
        .filter((j) => j.catType === 2)
        .forEach((cat) => {
          var _exp = filteredBdgts
            .filter((j) => j.catId === cat.id)
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          var _act = filteredAp
            .filter((j) => j.categoryId === cat.id)
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          _apTblData.push({
            catName: cat.name,
            expected: _exp,
            actual: _act,
            variance: _exp - _act,
          });
        });
      setApTblData([..._apTblData]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAp, cats]);

  const IncHeader = () => (
    <div className="d-flex a-center flex-wrap">
      <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">Income</h2>
    </div>
  );
  const ExpHeader = () => (
    <div className="d-flex a-center flex-wrap">
      <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">Expenses</h2>
    </div>
  );
  return (
    <Grid container spacing={3} className="pt-15">
      <Grid item xs={12} className="pb-30">
        <RepNav
          {...{
            csvHeader,
            csvData,
            sortDate,
            setSortDate,
            title: "Budget Vs Actual",
          }}
        />
      </Grid>

      {/* Income  */}
      <Grid item xs={12} md={6}>
        <Table
          shadow={false}
          showSearch={false}
          ActionComp={() => <></>}
          HeaderComp={IncHeader}
          tblHead={[
            { id: "catName", label: "Category" },
            { id: "exp", label: "Expected" },
            { id: "act", label: "Actual" },
            { id: "var", label: "Variance" },
          ]}
          tblData={arTblData.map((x) => {
            return {
              ...x,
              exp: x.expected.toLocaleString(),
              act: x.actual.toLocaleString(),
              var: (x.expected - x.actual).toLocaleString(),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {arTblData
                  .map((j) => j.expected)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {arTblData
                  .map((j) => j.actual)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {arTblData
                  .map((j) => j.variance)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </Grid>

      {/* Expenses */}
      <Grid item xs={12} md={6}>
        <Table
          shadow={false}
          ActionComp={() => <></>}
          showSearch={false}
          HeaderComp={ExpHeader}
          tblHead={[
            { id: "catName", label: "Category" },
            { id: "exp", label: "Expected" },
            { id: "act", label: "Actual" },
            { id: "var", label: "Variance" },
          ]}
          tblData={apTblData.map((j) => {
            return {
              ...j,
              exp: j.expected.toLocaleString(),
              act: j.actual.toLocaleString(),
              var: j.variance.toLocaleString(),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {apTblData
                  .map((j) => j.expected)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {apTblData
                  .map((j) => j.actual)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $
                {apTblData
                  .map((j) => j.variance)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(BudgetVsActual);
