import {
  ForwardToInboxRounded,
  LocationOnRounded,
  PhoneRounded,
} from "@mui/icons-material";
import { Avatar, Grid, Paper } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext } from "react";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import { useEffect } from "react";
import { endpoints } from "../../api/actions/api";
import { useState } from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const { _getAll } = props;
  const { landingPg } = useContext(AppContext);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    _getAll(
      endpoints.Page,
      undefined,
      (res) => res.status === 200 && setPages([...res.result])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      className="d-flex flex-wrap pt-20"
      style={{ borderTop: "1px solid var(--primaryMD-color)" }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <img
              style={{ maxWidth: "200px", objectFit: "cover" }}
              src={landingPg.footerLogo}
              alt="footer-logo"
            />
            <p className="p mr-10">{landingPg.desc}</p>
          </Grid>
          <Grid item xs={12} md={3}>
            <h2 className="fs-22 fw-4 dark-color border-left tx-upp mb-30">
              Contact Us
            </h2>
            <div className="d-grid">
              <a
                className="pb-10 tx-und-none d-flex a-center fs-16 dark-color"
                href={`mailto:${landingPg.email}`}
              >
                <ForwardToInboxRounded className="mr-15 fs-20" />
                {landingPg.email}
              </a>
              <a
                className="pb-10 tx-und-none d-flex a-center fs-16 dark-color"
                href={`tel:${landingPg.phone}`}
              >
                <PhoneRounded className="mr-15 fs-20" /> {landingPg.phone}
              </a>
              <span className="pb-10 tx-und-none d-flex a-center fs-16 dark-color">
                <LocationOnRounded color="error" className="mr-15 fs-20" />
                {landingPg.address}
              </span>
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <h2 className="fs-22 fw-4 dark-color border-left tx-upp mb-30">
              Pages
            </h2>
            <div className="d-grid">
              {pages
                .filter((j) => j.type !== 4)
                .map((page, i) => (
                  <Link
                    key={i}
                    className="pb-10 tx-und-none d-flex a-center fs-16 dark-color hove-primary"
                    to={`/page/${page.id}`}
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    {page.btnName}
                  </Link>
                ))}
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <h2 className="fs-22 fw-4 dark-color border-left tx-upp mb-30">
              Social Media
            </h2>
            <div className="d-grid">
              {landingPg.socialMedias &&
                landingPg.socialMedias.map((item, i) => (
                  <Link
                    key={i}
                    className="pb-10 tx-und-none d-flex a-center fs-16 dark-color hove-primary"
                    to={item.link}
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    <div className="d-flex g-10 a-center">
                      <Avatar
                        src={item.icon}
                        sx={{ height: "20px", width: "20px" }}
                      />{" "}
                      <span>{item.title}</span>
                    </div>
                  </Link>
                ))}
            </div>
          </Grid>
        </Grid>
      </Container>
      <div
        className="w-100pr mt-20 pt-20 pb-20"
        style={{ borderTop: "1px solid var(--primarySM-color)" }}
      >
        <Container maxWidth="xl">
          <span className="tx-sm">{landingPg.siteName}</span>
        </Container>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _getAll: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Footer);
