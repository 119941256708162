import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { PowerSettingsNewRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DeactiveInvForm = (props) => {
  const { setShowForm, _put, setAlert, inv, setPays } = props;
  const { DeactiveInvMdl } = Model();
  const navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(DeactiveInvMdl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues((j) => ({ ...j, invoiceId: inv.id }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inv]);

  console.log("values: ", values);

  const validateForm = () => {
    let temp = {};
    temp.note = values.note ? "" : "Note is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200) {
          if (setPays && res.result.id)
            setPays((x) => [
              ...x.map((j) => (j.id === res.result.id ? res.result : j)),
            ]);
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
            onClick: navigate("/my_account/invoices"),
          });
        } else {
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "error",
            title: "Something is Wrong",
            subTitle: res.msg,
          });
        }
      };
      _put(
        endpoints.Invoice + controller.DeactiveInv,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box className="p-15" sx={{ minWidth: { xs: "280px", md: "380px" } }}>
        <Form onSubmit={handleSubmit} noValidate>
          <div className="tx-center mb-40 mt-10">
            <h2 className="h fw-5 mb-5 fs-24 tx-upp gray-color">
              Deactive Invoice
            </h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-40" />
            </div>
          </div>
          <div className="mb-15">
            <Input
              label="Note"
              name="note"
              value={values.note}
              error={errors.note}
              inputProps={{ maxLength: 500 }}
              multiline
              rows={3}
              onChange={handleInpChg}
              fullWidth
            />
          </div>

          <Box className="d-flex">
            <Button
              onClick={handleClose}
              fullWidth
              className="mr-10"
              variant="text"
            >
              Close
            </Button>
            <Button
              fullWidth
              startIcon={<PowerSettingsNewRounded />}
              type="submit"
              className="bg-errorSM error-color"
              loading={loading}
            >
              Deactive
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(DeactiveInvForm);
