import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function InputPassword(props) {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    error = null,
    ...other
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      variant={variant || "filled"}
      label={label || "Password"}
      type={showPassword ? "text" : "password"}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleShowPassword}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
        maxLength: 100,
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
