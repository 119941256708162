import React, { createContext, useEffect, useState } from "react";
import { Navbar } from "../../layout";
import {
  Home,
  Invoices,
  Notices,
  Requests,
  Units,
  ViewUnit,
  Violations,
  Header,
} from "./";
import { Navigate, Route, Routes } from "react-router-dom";
import ViewInv from "../dashboard/Invoice/ViewInv";
import OwnerForm from "./OwnerSettings/OwnerForm";
import {
  EventNoteRounded,
  HomeRounded,
  LocationCityRounded,
  ReceiptRounded,
  WarningAmberRounded,
} from "@mui/icons-material";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";
import ViewReq from "../dashboard/Request/ViewReq";

export const OwnerContext = createContext({});

const OwnerPortalRoute = (props) => {
  const { _authOwner, _getByObj } = props;
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    if (_authOwner.id) {
      _getByObj(
        endpoints.Notice + controller.GetAllForOwners,
        ToFormData({ id: _authOwner.hoaId, ownerId: _authOwner.id }),
        null,
        (res) => setNotices([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authOwner]);

  return (
    <OwnerContext.Provider value={{ setNotices, notices }}>
      <div>
        {/* ===== Header ===== */}
        <Header />
        {/* ===== Body ===== */}
        <div className="mb-60">
          <Routes>
            <Route path="/" element={<Navigate to="home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/units">
              <Route path="view_unit/:id" element={<ViewUnit />} />
              <Route path="" element={<Units />} />
            </Route>
            <Route path="/invoice">
              <Route path="view_invoice_details/:invId" element={<ViewInv />} />
              <Route path="" element={<Invoices />} />
            </Route>
            <Route path="/requests" element={<Requests />} />
            <Route
              path="/view_request/:id"
              element={
                <div className="p-20">
                  <ViewReq viewFrom="owner" />
                </div>
              }
            />
            <Route path="/violation" element={<Violations />} />
            <Route path="/notices" element={<Notices />} />
            <Route path="/my_account/:id" element={<OwnerForm />} />
          </Routes>
        </div>

        {/* ===== Navbar ===== */}
        <Navbar menu={menu} />
      </div>
    </OwnerContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  _authOwner: state.storeData.authOwner,
});

const mapActiontoProps = {
  _getByObj: actions.getByObj,
};
export default connect(mapStateToProps, mapActiontoProps)(OwnerPortalRoute);

const menu = [
  {
    name: "Home",
    icon: <HomeRounded />,
    url: "/owner_portal",
  },
  { name: "Units", icon: <LocationCityRounded />, url: "/owner_portal/units" },
  { name: "Invoice", icon: <ReceiptRounded />, url: "/owner_portal/invoice" },
  {
    name: "Requests",
    icon: <EventNoteRounded />,
    url: "/owner_portal/requests",
  },
  {
    name: "Violation",
    icon: <WarningAmberRounded />,
    url: "/owner_portal/violation",
  },
];
