import { Grid } from "@mui/material";
import {
  AccountBalanceRounded,
  AccountBalanceWalletRounded,
  BadgeRounded,
  ContactPhoneRounded,
  ContactsRounded,
  CreditScoreRounded,
  GroupRounded,
  LocalAtmRounded,
  PaidRounded,
  PaymentRounded,
  PersonRounded,
  PriceChangeRounded,
  ReceiptLongRounded,
  ReceiptRounded,
  RequestQuoteRounded,
  SsidChartRounded,
  SupervisedUserCircleRounded,
  TuneRounded,
} from "@mui/icons-material";
import ReportWidget from "./ReportWidget";

const Reports = () => {
  return (
    <Grid container spacing={3} className="mt-15">
      <Grid item xs={12}>
        <h2 className="border-left h fw-4 fs-20 gray-color tx-upp">Reports</h2>
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="A/R AGING"
          link="ar_report"
          icon={<AccountBalanceWalletRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Payable AGING"
          link="ap_report"
          icon={<PaymentRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Journal Entries"
          link="journal_entries"
          icon={<LocalAtmRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Bank Reconciliation"
          link="bank_reconciliation"
          icon={<AccountBalanceRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Profit Vs Loss"
          link="profit_vs_loss"
          icon={<SsidChartRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Owners Report"
          link="owner_reports"
          icon={<SupervisedUserCircleRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Budget Vs Actual"
          link="budget_vs_actual"
          icon={<PriceChangeRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Delinquent Accounts"
          link="delinquent_accounts"
          icon={<BadgeRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Invoices By Category"
          link="invoice_by_category"
          icon={<ReceiptRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="General Ledger"
          link="general_ledger"
          icon={<CreditScoreRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Owner Contact Info"
          link="owner_contact_info"
          icon={<ContactPhoneRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Ownership Status"
          link="ownership_status"
          icon={<GroupRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Vendor Info"
          link="vendor_info"
          icon={<ContactsRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Vendor Overview"
          link="vendor_overview"
          icon={<PersonRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Expenses Detail"
          link="expenses_details"
          icon={<RequestQuoteRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Deposit Details"
          link="deposit_details"
          icon={<PaidRounded className="primary-color fs-30" />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Receipts Detail"
          link="receipts_detail"
          icon={<ReceiptLongRounded className="primary-color fs-30" />}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <ReportWidget
          title="Custom Report"
          link="custom_report"
          icon={<TuneRounded className="primary-color fs-30" />}
        />
      </Grid>
    </Grid>
  );
};

export default Reports;
