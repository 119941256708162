import { Grid, Paper, Alert as MuiAlert, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputSwitch,
  Select,
} from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Alert, Dialog, Form } from "../../../layout";
import AddEditAccount from "../../dashboard/Bank/AddEditAccount";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { MaxLength, NestedFormData, RmvEmptyProp } from "../../../hooks/Method";
import moment from "moment";

const AddEditInvoice = (props) => {
  const { _getById, _post, _put, _hoa, _authUser } = props;
  const { id, unitId, invType } = useParams();
  const navigate = useNavigate();
  const { InvTypes, InvPeriod } = DropLi();
  const { InvoiceMdl } = Model();
  const [addBank, setAddBank] = useState(false);
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [banks, setBanks] = useState([]);
  const [cats, setCats] = useState([]);
  const [plans, setPlans] = useState([]);
  const [isEmptyUnits, setIsEmptyUnits] = useState(false);
  const [isEmptyBanks, setIsEmptyBanks] = useState(false);
  const [isEmptyCats, setIsEmptyCats] = useState(false);
  const [isEmptyPlans, setIsEmptyPlans] = useState(false);

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    handleInpDate,
  } = UseForm(InvoiceMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    unitId && setValues((x) => ({ ...x, unitId: parseInt(unitId) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  useEffect(() => {
    invType && setValues((x) => ({ ...x, invType: parseInt(invType) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invType]);

  useEffect(() => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result) {
        var val = res.result;
        setValues((x) => ({
          ...x,
          ...val,
          units: val.invoiceUnits && val.invoiceUnits.map((j) => j.unitId),
        }));
      }
    };
    id &&
      _getById(endpoints.Invoice + controller.GetById, id, null, onComplete);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cats]);

  useEffect(() => {
    if (_hoa.id) {
      _getById(endpoints.Unit + controller.GetByHoa, _hoa.id, null, (res) => {
        res.status === 200 &&
          setUnits([
            ...res.result.map((j) => {
              var defOwner = j.unitOwners[0] && j.unitOwners[0].owner;
              return {
                id: j.id,
                label: `${j.title} ${
                  defOwner ? `(${defOwner.fullName})` : ""
                } `,
              };
            }),
          ]);
        setIsEmptyUnits(!res.result.length);
      });

      // Get Invoice Plans
      _getById(
        endpoints.InvPlan + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 &&
            setPlans([
              ...res.result.map((j) => {
                var _periodic = InvPeriod.find((x) => x.id === j.periodic);
                return {
                  ...j,
                  label: j.name,
                  subtitle: `${j.duration} ${_periodic && _periodic.label}`,
                };
              }),
            ]);
          setIsEmptyPlans(!res.result.length);
        }
      );

      _getById(endpoints.HoaCat + controller.GetByHoa, _hoa.id, null, (res) => {
        res.status === 200 &&
          setCats([
            ...res.result
              .filter((j) => j.catType === 1)
              .map((j) => ({ label: j.name, ...j })),
          ]);
        setIsEmptyCats(!res.result.length);
      });

      _getById(
        endpoints.BankAcc + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 &&
            setBanks([
              ...res.result.map((j) => {
                return {
                  id: j.id,
                  label: j.accName,
                };
              }),
            ]);
          setIsEmptyBanks(!res.result.length);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa.id]);

  useEffect(() => {
    var plan = plans.find((j) => j.id === values.invPlanId);
    if (values.invPlanId && plan) {
      switch (plan.periodic) {
        case 1:
          setValues((x) => ({
            ...x,
            dueDate: moment(x.issueDate, "MM/DD/YYYY")
              .add(plan.duration, "days")
              .format("MM/DD/YYYY"),
          }));
          break;
        case 2:
          setValues((x) => ({
            ...x,
            dueDate: moment(x.issueDate, "MM/DD/YYYY")
              .add(plan.duration, "w")
              .format("MM/DD/YYYY"),
          }));
          break;
        case 3:
          setValues((x) => ({
            ...x,
            dueDate: moment(x.issueDate, "MM/DD/YYYY")
              .add(plan.duration, "M")
              .format("MM/DD/YYYY"),
          }));
          break;
        case 4:
          setValues((x) => ({
            ...x,
            dueDate: moment(x.issueDate, "MM/DD/YYYY")
              .add(plan.duration, "y")
              .format("MM/DD/YYYY"),
          }));
          break;
        default:
          break;
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.invPlanId, values.issueDate]);

  const validateForm = () => {
    let temp = {};
    temp.invType = values.invType !== "" ? "" : "Invoice type is required.";
    temp.title = values.title !== "" ? "" : "Charge title is required.";
    temp.bankAccId =
      values.bankAccId !== "" ? "" : "Deposit bank account is required.";
    temp.categoryId =
      values.categoryId !== "" ? "" : "Revenue category is required.";
    temp.amount = values.amount !== "" ? "" : "Amount is required.";
    temp.unitId = values.unitId ? "" : "Units is required.";
    temp.issueDate = values.issueDate !== "" ? "" : "Active date is required.";
    temp.dueDate = values.dueDate !== "" ? "" : "Due Date is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Invoice ${
              id ? "Details Updated" : "Added"
            } Successfully.`,
            onClick: () => navigate("/my_account/invoices"),
          });
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        values.userId = _authUser.id;

        _post(
          endpoints.Invoice + controller.Post,
          NestedFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else if (values.id) {
        _put(
          endpoints.Invoice + controller.Put,
          NestedFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Collapse
        in={
          isEmptyBanks || isEmptyCats || isEmptyPlans || isEmptyUnits
            ? true
            : false
        }
      >
        <MuiAlert severity="warning" className="mb-20">
          <div className="d-grid g-5">
            <span>You need to add these before creating a invoice —</span>
            {units.length === 0 && (
              <span className="fs-16">
                * <Link to="/my_account/units/unit_form">Unit</Link>
              </span>
            )}
            {plans.length === 0 && (
              <span className="fs-16">
                * <Link to="/my_account/invoice-terms">Invoice Term</Link>
              </span>
            )}
            {cats.length === 0 && (
              <span className="fs-16">
                * <Link to="/my_account/categories">Category</Link>
              </span>
            )}
            {banks.length === 0 && (
              <span className="fs-16">
                * <Link to="/my_account/bank-accounts">Banks</Link>
              </span>
            )}
          </div>
        </MuiAlert>
      </Collapse>

      <Paper className="p-20">
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="mb-10 d-flex j-between a-center">
                <div>
                  <h2 className="h tx-md fw-5 mb-5 tx-upp border-left">
                    {id ? "Edit" : "Add"} Invoice
                  </h2>
                </div>

                <div>
                  <Button type="submit" loading={loading}>
                    {id ? "Save Changes" : "Submit"}{" "}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label="Invoice Type"
                name="invType"
                value={values.invType}
                error={errors.invType}
                onChange={handleInpChg}
                className="mr-5"
                options={InvTypes}
                disabled={values.id || parseInt(invType) ? true : false}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <InputAuto
                label="Select Units"
                name="unitId"
                value={values.unitId}
                error={errors.unitId}
                disabled={values.id || unitId ? true : false}
                onChange={handleInpChg}
                fullWidth
                options={units}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Amount"
                name="amount"
                value={values.amount}
                error={errors.amount}
                disabled={values.id ? true : false}
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 18))
                }
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Input
                label="Invoice Title"
                name="title"
                value={values.title}
                error={errors.title}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                label="Issue Date"
                name="issueDate"
                className="w-100pr"
                value={values.issueDate}
                error={errors.issueDate}
                disabled={values.id ? true : false}
                views={["day", "month", "year"]}
                minDate={new Date()}
                onChange={(date) =>
                  handleInpDate(
                    "issueDate",
                    new Date(date).toLocaleDateString()
                  )
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="d-flex">
              <InputAuto
                label="Invoice Term"
                name="invPlanId"
                value={values.invPlanId}
                error={errors.invPlanId}
                onChange={handleInpChg}
                disabled={values.id ? true : false}
                className="mr-5"
                options={plans}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                label="Due Date"
                name="dueDate"
                className="w-100pr"
                value={values.dueDate}
                error={errors.dueDate}
                disabled={true}
                onChange={(date) =>
                  handleInpDate("dueDate", new Date(date).toLocaleDateString())
                }
              />
            </Grid>
            <Grid item xs={12} md={6} className="d-flex">
              <InputAuto
                label="Revenue Category"
                name="categoryId"
                value={values.categoryId}
                error={errors.categoryId}
                onChange={handleInpChg}
                disabled={values.id ? true : false}
                className="mr-5"
                options={cats}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} className="d-flex">
              <InputAuto
                label="Deposit Bank Account"
                name="bankAccId"
                value={values.bankAccId}
                error={errors.bankAccId}
                onChange={handleInpChg}
                disabled={values.id ? true : false}
                className="mr-5"
                options={banks}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                multiline
                rows={2}
                label="Invoice Description"
                name="descr"
                value={values.descr}
                error={errors.descr}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputSwitch
                label="Email Invoice on Issue Date."
                name="sendEmail"
                fontSize="16px"
                checked={JSON.parse(values.sendEmail)}
                onChange={handleInpCheck}
              />
            </Grid>
            <Grid item xs={12}>
              <InputSwitch
                label="Early Payment Discount"
                name="earlyPayDis"
                fontSize="16px"
                checked={JSON.parse(values.earlyPayDis)}
                onChange={handleInpCheck}
              />
            </Grid>

            <Grid item xs={12}>
              <InputSwitch
                label="Late Fee"
                name="isLateFee"
                fontSize="16px"
                checked={JSON.parse(values.isLateFee)}
                onChange={handleInpCheck}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>

      {/* Bank Account Form*/}
      <Dialog show={addBank} onClose={false} maxWidth="sm">
        <AddEditAccount {...{ setShowForm: setAddBank }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditInvoice);
