import { Box } from "@mui/material";
import React, { useState } from "react";
import { Model } from "../../../../hooks";
import ChangePass from "./ChangePass";
import SendFrgtCode from "./SendFrgtCode";
import VerifyFrgtCode from "./VerifyFrgtCode";

const Forgot = () => {
  const { ForgotMdl } = Model();
  const [forgotVal, setForgotVal] = useState(ForgotMdl());
  const [activeStep, setActiveStep] = useState();

  const Content = (step) => {
    switch (step) {
      case 1:
        return (
          <VerifyFrgtCode
            {...{
              setActiveStep,
              forgotVal,
              setForgotVal,
            }}
          />
        );
      case 2:
        return (
          <ChangePass
            {...{
              setActiveStep,
              forgotVal,
              setForgotVal,
            }}
          />
        );
      default:
        return (
          <SendFrgtCode
            {...{
              setActiveStep,
              forgotVal,
              setForgotVal,
            }}
          />
        );
    }
  };

  return (
    <div className="h-100vh d-grid a-center j-center">
      <Box
        sx={{ maxWidth: "380px" }}
        className="shadow-primary br-20 bs-primary bg-white"
      >
        {Content(activeStep)}
      </Box>
    </div>
  );
};

export default Forgot;
