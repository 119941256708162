import * as React from "react";
import PropTypes from "prop-types";
import { Tabs as MuiTabs, Tab, Box, Grow } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span>{children}</span>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tabs(props) {
  const { header = [], children, ...other } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          padding: { xs: "0px", md: "0px 8px" },
          borderRadius: "0.8rem",
          mb: "25px",
          bgcolor: "var(--white-color)",
          border: "1px solid var(--grayXS-color)",
        }}
        {...other}
      >
        <MuiTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {header.map((item, i) => (
            <Tab
              key={i}
              icon={item.icon}
              label={item.label}
              {...a11yProps(i)}
            />
          ))}
        </MuiTabs>
      </Box>
      <div>
        {children.length > 0 &&
          children.map((el, i) => (
            <TabPanel key={i} value={value} index={i}>
              <Grow timeout={900} in={true}>
                <div>{el}</div>
              </Grow>
            </TabPanel>
          ))}
      </div>
    </Box>
  );
}
