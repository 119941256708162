import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Alert, Form } from "../../../layout";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { ToFormData } from "../../../hooks/Method";

const VerifyFrgtCode = (props) => {
  const { setActiveStep, forgotVal, setForgotVal, _getByObj } = props;
  const { ForgotMdl } = Model();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [loading, setLoading] = useState(false);
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(ForgotMdl);

  const validateForm = () => {
    let temp = {};
    temp.verCode =
      values.verCode !== "" ? "" : "Verification code is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res !== undefined && res.status === 200) {
          setActiveStep(2);
        } else if (res !== undefined && res.status === 204) {
          setErrors({
            ...errors,
            verCode: res.msg,
          });
        }
      };
      values.forgotStep = 2;
      _getByObj(
        endpoints.Auth + controller.Forgot,
        ToFormData(values),
        actions.ActionType.Data,
        onComplete
      );
    }
  };

  const handleResend = () => {
    setLoading(true);
    const onComplete = (res) => {
      setLoading(false);
      res.status === 200
        ? setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "The verification code has been resent successfully.",
          })
        : setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
    };
    values.forgotStep = 1;
    _getByObj(
      endpoints.Auth + controller.Forgot,
      ToFormData(values),
      actions.ActionType.Data,
      onComplete
    );
  };

  useEffect(() => {
    setValues(forgotVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setForgotVal({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Form noValidate onSubmit={submitForm}>
        <Grid container className="p-15 pt-0" spacing={3}>
          <Grid item xs={12} className="tx-center mt-20 pb-30">
            <h2 className="fs-24 fw-5 tx-upp h gray-color">
              Verification Code
            </h2>
            <div className="pos-relative d-flex j-center mt-5">
              <div className="border-bottom w-60" />
            </div>
          </Grid>
          <Grid item xs={12} className="mb-20">
            <Typography variant="p" className="font-18 gray-color">
              6 digit verification code has been sent to this email{" "}
              <strong>{values.email}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} className="tx-right">
            <Input
              type="number"
              label="Enter Verification Code"
              name="verCode"
              value={values.verCode}
              onChange={handleInpChg}
              error={errors.verCode}
              className="input-primary w-100pr mb-10"
            />
            <span
              onClick={handleResend}
              className="font-18 gray-color hove-primary c-pointer"
            >
              Resend Code
            </span>
          </Grid>
          <Grid item xs={12} className="text-center">
            <Button
              loading={loading}
              type="submit"
              size="large"
              className="w-100pr mb-10"
            >
              Verify
            </Button>
            <Button
              onClick={() => setActiveStep(0)}
              size="large"
              variant="outlined"
              className="w-100pr"
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(VerifyFrgtCode);
