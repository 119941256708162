import { CancelRounded, CheckCircleRounded } from "@mui/icons-material";
import { Avatar, Paper } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "../../api/actions/actions";
import { controller, endpoints } from "../../api/actions/api";
import { Button } from "../../controls";
import { ToFormData } from "../../hooks/Method";

const SubsPkgComp = (props) => {
  const { pkg, setAlert, _authUser, isYearlyPay = false, _getByObj } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleTakeSubs = (pkgId = 0) => {
    if (_authUser && _authUser.id) {
      setLoading(true);
      const onComplete = (res) => {
        if (res && res.status === 201) {
          navigate("/my_account/home");
          window.location.reload();
        } else
          setAlert({
            isOpen: true,
            type: "warning",
            title: "Warning",
            subTitle: res.msg,
          });

        setLoading(false);
      };
      _getByObj(
        endpoints.Subs + controller.CreateSubs,
        ToFormData({
          id: pkgId,
          boolVal: isYearlyPay,
        }),
        null,
        onComplete
      );
    } else {
      localStorage.setItem(
        "subsParam",
        JSON.stringify({
          pkgId: pkgId,
          isYearlyPay: isYearlyPay,
        })
      );
      navigate("/my_account/register");
    }
  };

  return (
    <Paper>
      {/* Titlte */}
      <div className="p-30 d-grid g-15">
        <div className="tx-center">
          <h1 className="h fs-26 fw-5 tx-upp mb-10">{pkg.title}</h1>
          <div className="d-flex j-center">
            <div className="border-bottom w-40" />
          </div>
        </div>
        <span className="p">{pkg.note}</span>
      </div>

      {/* Price  */}
      <div className="d-flex j-between mb-40 mt-30">
        <Avatar
          sx={{ width: 56, height: 56, borderRadius: "15px" }}
          className="ml-20"
          src={pkg.photo}
          alt="subscription"
        />
        <div
          className="d-flex j-center"
          style={{
            backgroundColor: "var(--primary-color)",
            boxShadow: "var(--shadow-primary)",
            minWidth: "50%",
            padding: "10px",
            borderRadius: "30px 0px 0px 30px",
          }}
        >
          <span className="fw-5 white-color mr-5">$</span>
          <div className="d-flex a-end white-color">
            <span style={{ lineHeight: 0.9 }} className="fs-40">
              {pkg.price}
            </span>
            <span className="fw-3"> / month</span>
          </div>
        </div>
      </div>
      <div className="p-30">
        <div className="d-flex a-center mb-15">
          {!pkg.unlimitedBranch && pkg.branch === 0 ? (
            <CancelRounded className="mr-15 error-color" />
          ) : (
            <CheckCircleRounded className="mr-15 success-color" />
          )}
          <span className="gray-color">
            <span className="fs-16">Number of HOA:&nbsp;&nbsp;</span>
            <span className="fw-5 fs-18">
              {pkg.unlimitedBranch ? "Unlimited" : pkg.branch}
            </span>
          </span>
        </div>
        <div className="d-flex a-center mb-15">
          {!pkg.unlimitedUnit && pkg.unit === 0 ? (
            <CancelRounded className="mr-15 error-color" />
          ) : (
            <CheckCircleRounded className="mr-15 success-color" />
          )}
          <span className="gray-color">
            <span className="fs-16">Number of Unit:&nbsp;&nbsp;</span>
            <span className="fw-5 fs-18">
              {pkg.unlimitedUnit ? "Unlimited" : pkg.unit}
            </span>
          </span>
        </div>
        <div className="d-flex a-center mb-15">
          {!pkg.unlimitedOwner && pkg.owner === 0 ? (
            <CancelRounded className="mr-15 error-color" />
          ) : (
            <CheckCircleRounded className="mr-15 success-color" />
          )}
          <span className="gray-color">
            <span className="fs-16">Number of Owner:&nbsp;&nbsp;</span>
            <span className="fw-5 fs-18">
              {pkg.unlimitedOwner ? "Unlimited" : pkg.owner}
            </span>
          </span>
        </div>
        <div className="d-flex a-center mb-15">
          {!pkg.unlimitedVendor && pkg.vendor === 0 ? (
            <CancelRounded className="mr-15 error-color" />
          ) : (
            <CheckCircleRounded className="mr-15 success-color" />
          )}
          <span className="gray-color">
            <span className="fs-16">Number of Vendor:&nbsp;&nbsp;</span>
            <span className="fw-5 fs-18">
              {pkg.unlimitedVendor ? "Unlimited" : pkg.vendor}
            </span>
          </span>
        </div>
        <div className="d-flex a-center mb-15">
          {!pkg.unlimitedUser && pkg.user === 0 ? (
            <CancelRounded className="mr-15 error-color" />
          ) : (
            <CheckCircleRounded className="mr-15 success-color" />
          )}
          <span className="gray-color">
            <span className="fs-16">Number of User:&nbsp;&nbsp;</span>
            <span className="fw-5 fs-18">
              {pkg.unlimitedUser ? "Unlimited" : pkg.user}
            </span>
          </span>
        </div>

        <div className="tx-center mt-50 d-flex g-15 j-center">
          <Button
            className="br-20 fs-16 fw-5"
            sx={{ p: "8px 25px 8px 25px" }}
            onClick={() => handleTakeSubs(pkg.id)}
            loading={loading}
          >
            {_authUser.id ? "Active Now" : `Try ${pkg.trialDuration} Days Free`}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(SubsPkgComp);
