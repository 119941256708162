import {
  CalendarTodayRounded,
  DeleteForeverRounded,
  EditRounded,
  LocationOnRounded,
  PaymentRounded,
} from "@mui/icons-material";
import { Grid, IconButton, Paper, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../../layout";
import { DropLi } from "../../../../hooks";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { endpoints } from "../../../../api/actions/api";
import { PrintDiv } from "../../../../hooks/Method";
import StmtPdf from "./StmtPdf";
import { Button } from "../../../../controls";

const UnitDetails = (props) => {
  const { unit, _delete, osBal, recBal, _authUser, stmts } = props;
  const { Countries } = DropLi();
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleEdit = () => {
    navigate(`/my_account/units/unit_form/${unit.id}`);
  };

  const handleDlt = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete this unit?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            navigate("/my_account/units");
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Unit, unit.id, null, onComplete);
      },
    });
  };

  const DownloadStmt = () => {
    PrintDiv("StmtPdf");
  };

  const handleAddInv = (unit, invType = 0) => {
    navigate(`/my_account/invoices/invoice_form/${unit.id}/${invType}`);
  };

  return (
    <>
      {unit && (
        <>
          <div id="StmtPdf" className="d-none">
            <StmtPdf data={stmts} unitId={unit.id} unitTitle={unit.title} />
          </div>
          <Paper className="p-10">
            <Grid container spacing={3}>
              <Grid item xs={12} className="d-flex j-between">
                <div>
                  <h2 className="h border-left fs-20 tx-upp fw-5 gray-color">
                    {unit.title}
                  </h2>
                </div>

                <div className="d-flex flex-wrap a-center">
                  {_authUser && _authUser.role !== 1 && (
                    <div>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={handleEdit}
                          size="small"
                          className="mr-15"
                          color="primary"
                        >
                          <EditRounded className="fs-20" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip onClick={handleDlt} title="Delete">
                        <IconButton color="error" size="small">
                          <DeleteForeverRounded className="fs-20" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  <div className="ml-20">
                    <Button onClick={DownloadStmt} size="small" variant="text">
                      Download Statement
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="d-flex a-center">
                <LocationOnRounded className="mr-10" color="error" />
                <span className="p fw-4 fs-16">
                  {unit.address1}
                  {unit.address1 && ", " + unit.address2}, {unit.city}
                  {unit.state && ", " + unit.state},{" "}
                  {unit.country &&
                    Countries.find((j) => j.id === unit.country).name}
                  , {unit.zip}
                </span>
              </Grid>
              <Grid item xs={12} md={4} className="d-flex a-center">
                <PaymentRounded className="mr-15" color="primary" />
                <div className="d-grid">
                  <Tooltip title="Unit ownre's total account balance">
                    <span className="tx-sm fw-5 mb-5">${osBal}</span>
                  </Tooltip>
                  <span className="tx-xs">Outstanding Balance</span>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="d-flex a-center">
                <CalendarTodayRounded className="mr-15" color="primary" />
                <div className="d-grid">
                  <Tooltip title="Total due recurring-invoice amount">
                    <span className="tx-sm fw-5 mb-5">${recBal}</span>
                  </Tooltip>
                  <span
                    onClick={() => handleAddInv(unit, 2)}
                    className="tx-xs c-pointer hove-primary tx-und"
                  >
                    Add Recurring Charge
                  </span>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _delete: actions.Delete,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(UnitDetails);
