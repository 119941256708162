import { AdminPanelSettingsRounded, Logout } from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, MenuItem } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { MenuPopup } from "../../layout";
import * as actions from "../../api/actions/actions";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { Box } from "@mui/system";
import { AdminBaseUrl } from "../../utils/utils";

const Header = (props) => {
  const { _get, _admin } = props;
  const [profileEl, setProfileEl] = useState(null);
  const { setLoader } = useContext(AppContext);
  const navigate = useNavigate();

  const handleProfile = (e) => {
    setProfileEl(e.currentTarget);
  };

  const handleLogout = () => {
    setLoader(true);
    const onComplete = (res) => {
      navigate("/");
      setLoader(false);
    };
    _get(
      endpoints.AuthAdmin + controller.Logout,
      actions.ActionType.AuthAdmin,
      onComplete
    );
  };

  return (
    <Box
      className="d-flex a-center w-100pr"
      sx={{ justifyContent: { md: "space-between", xs: "end" } }}
    >
      <Box
        className="ml-10 gray-color"
        sx={{
          display: { md: "block", xs: "none" },
        }}
      >
        <span className="fw-5">Admin Panel</span>
      </Box>
      <div className="d-flex a-center">
        <div>
          <IconButton
            onClick={handleProfile}
            size="small"
            aria-controls="account-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <Avatar
              alt="profile-pic"
              src={_admin.photo}
              className="bg-primary"
            />
          </IconButton>
          <MenuPopup
            anchorEl={profileEl}
            setAnchorEl={setProfileEl}
            id="account-menu"
          >
            <MenuItem
              onClick={() =>
                navigate(`${AdminBaseUrl}/users/user_form/${_admin.id}`)
              }
            >
              <ListItemIcon>
                <AdminPanelSettingsRounded fontSize="small" />
              </ListItemIcon>
              My Account
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </MenuPopup>
        </div>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActiontoProps = {
  _get: actions.get,
  _set: actions.set,
};

export default connect(mapStateToProps, mapActiontoProps)(Header);
