import { Grid } from "@mui/material";
import { ExpenseDonut, InvoiceVsPaidChart, Widgets } from "../";
import BankAcc from "../Bank/BankAcc";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useContext, useEffect, useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import Payables from "../Transactions/Payable/Payables";
import SubsWarning from "./SubsWarning";
import { AppContext } from "../../../App";
import { Button } from "../../../controls";
import moment from "moment";
import { Alert } from "../../../layout";

const Home = (props) => {
  const { _getById, _hoa, _subs } = props;
  const { subsWarning } = useContext(AppContext);
  const [invs, setInvs] = useState([]);
  const [reqs, setReqs] = useState([]);
  const [payables, setPayables] = useState([]);
  const [cats, setCats] = useState([]);
  const [banks, setBanks] = useState([]);
  const [invWarningMsg, setInvWarningMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_hoa.id) {
      // Get requests and violations
      _getById(
        endpoints.Request + controller.GetAllByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 && setReqs([...res.result]);
        }
      );

      // Get Invoices
      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setInvs([...res.result.filter((j) => j.invPayments.length > 0)])
      );

      // Get Payables
      _getById(
        endpoints.AP + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setPayables([...res.result])
      );

      // Get Categories
      _getById(
        endpoints.HoaCat + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setCats([...res.result])
      );

      // Get Banks
      _getById(
        endpoints.BankAcc + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setBanks([...res.result])
      );
    }

    return () => {
      setReqs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  const handleActivePlan = (id) => {
    setLoading(true);
    const onComplete = (res) => {
      if (res.status === 303) window.open(res.url, "_self");
      else {
        setAlert({
          isOpen: true,
          type: "warning",
          title: "Warning",
          subTitle: res.msg,
        });
      }
      setLoading(false);
    };
    _getById(
      endpoints.StripePay + controller.CheckoutSession,
      id,
      null,
      onComplete
    );
  };

  useEffect(() => {
    if (_subs && _subs.id) {
      // Check is there any unpaid invoice has, If have then get that invoice.
      _getById(
        endpoints.SubsInv + controller.GetUnpaidInv,
        _subs.id,
        null,
        (res) => {
          if (res.status === 200) {
            var inv = res.result;
            var _daysOver = moment(inv.dueDate, "MM/DD/YYYY")
              .add(1, "days")
              .diff(moment(), "days");
            var _actionBtn = (
              <Button
                loading={loading}
                size="large"
                color="primary"
                className="h-100pr fw-5 fs-18"
                onClick={() => handleActivePlan(_subs.id)}
              >
                Renew
              </Button>
            );

            _daysOver > 0
              ? setInvWarningMsg({
                  type: "warning",
                  title: `Your subscription will be expire with in ${_daysOver} days`,
                  msg: "Please renew your subscription. Otherwise your organization will goes on holding period and you can't access.",
                  Action: _actionBtn,
                })
              : setInvWarningMsg({
                  type: "error",
                  title: `Your subscription has expired ${Math.abs(
                    _daysOver
                  )} days ago`,
                  msg: "Please renew your subscription and run your organization smoothly :)",
                  Action: _actionBtn,
                });
          }
        }
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_subs]);

  return (
    <>
      {invWarningMsg && (
        <div className="mb-30">
          <SubsWarning
            title={invWarningMsg.title}
            msg={invWarningMsg.msg}
            type={invWarningMsg.type}
            Action={invWarningMsg.Action}
          />
        </div>
      )}
      {_subs && _subs.subsPeriod !== 3 && subsWarning && (
        <div className="mb-30">
          <SubsWarning
            title={subsWarning.title}
            msg={subsWarning.msg}
            type={subsWarning.type}
            Action={subsWarning.Action}
          />
        </div>
      )}
      <Grid container spacing={3}>
        {_subs &&
          _subs.isActive &&
          (_subs.subsPeriod === 1 || _subs.subsPeriod === 3) && (
            <>
              <Grid item xs={12}>
                <Widgets
                  totalReq={
                    reqs.filter((j) => j.rcdType === 1 && j.isComplete === 2)
                      .length
                  }
                  totalVio={
                    reqs.filter((j) => j.rcdType === 2 && j.isComplete === 2)
                      .length
                  }
                  totalInv={
                    invs.filter((j) =>
                      j.invPayments.some((x) => x.status === 1)
                    ).length
                  }
                  totalPay={payables.filter((j) => !j.isComplete).length}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <InvoiceVsPaidChart {...{ invs }} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ExpenseDonut
                  {...{ payables, cats: cats.filter((j) => j.catType === 2) }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <BankAcc {...{ banks, invs }} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Payables fromHome={true} />
              </Grid>
            </>
          )}
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _subs: state.storeData.subs,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _getById: actions.getById,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Home);
