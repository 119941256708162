import { ClearRounded, EditRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import AddEditAccount from "./AddEditAccount";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";

const headerLabel = [
  { id: "actions", label: "Actions" },
  {
    id: "accName",
    label: "Account Name",
  },
  { id: "routingNum", label: "Routing No." },
  { id: "accountNum", label: "Account No." },
  { id: "isDeposit", label: "Is Deposit" },
  { id: "createDate", label: "Create Date" },
];

const AccountsTbl = (props) => {
  const { _getById, _defHoa, _delete, _authUser } = props;
  const [accounts, setAccounts] = useState([]);
  const [updData, setUpdData] = useState();
  const { setLoader } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_defHoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setAccounts(res.result);
      };
      _getById(
        endpoints.BankAcc + controller.GetByHoa,
        _defHoa.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_defHoa]);

  const handleAddEdit = (props) => {
    setUpdData(props);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure❓",
      subTitle:
        "If you delete this bank account, all related data will also be deleted like payment history, vendor payment, and so on.",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setAccounts((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.BankAcc, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="Edit Account">
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete Account">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAddEdit()}>
        Add Account
      </Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={
          _authUser && _authUser.role === 3
            ? headerLabel
            : headerLabel.filter((j) => j.id !== "actions")
        }
        tblData={accounts.map((obj) => {
          return {
            ...obj,
            isDeposit: `${obj.isDeposit}`,
          };
        })}
      />

      {/* Send Eamil */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditAccount
            {...{ setShowForm: setShowForm, updData, setAccounts }}
          />
        </Dialog>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(AccountsTbl);
