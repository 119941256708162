import { Box, Grid, Paper, Rating } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Carousel } from "../../layout";
import { connect } from "react-redux";
import * as actions from "../../api/actions/actions";
import { endpoints } from "../../api/actions/api";
import { no_img } from "../../assets";

const Testimonials = (props) => {
  const { _get } = props;
  const [values, setValues] = useState([]);

  useEffect(() => {
    _get(endpoints.Review, null, (res) => {
      res.result && setValues((j) => [...res.result]);
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl" className="pt-80 pb-80">
      {/* Title */}
      <div className="border-left tx-upp mb-80" data-aos="fade-right">
        <h1 className="h h2 fw-5 mb-5">Our client says 💁‍♂️</h1>
      </div>
      {/* Carousel  */}
      <div className="d-flex j-center">
        <Box data-aos="fade-up" sx={{ width: { md: "50%", xs: "100%" } }}>
          <Carousel>
            {values.slice(0, 10).map((item, i) => (
              <div className="d-flex j-center" key={i}>
                <Paper className="p-25 border-right">
                  <Grid container>
                    <Grid item xs={12} lg={3} className="tx-center">
                      <img
                        className="img-fluid"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          objectFit: "contain",
                          border: "1px solid  var(--primary-color)",
                          padding: "3px",
                        }}
                        src={(item.org && item.org.logo) || no_img}
                        alt="testimonial"
                      />
                      <h2 className="h fs-18 fw-5 mt-5">
                        {item.org && item.org.orgName}
                      </h2>
                      <span className="p fs-14">
                        {item.user && item.user.fullName}
                      </span>
                      <div>
                        <Rating
                          size="medium"
                          value={item.rating}
                          className="mt-5"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <div
                        style={{
                          padding: { lg: "0px 20px 0px 20px", xs: "0px" },
                        }}
                      >
                        <p
                          className="p fs-18 fs-italic pre-wrap"
                          style={{
                            lineHeight: "30px",
                            padding: "0px 50px 0px 20px",
                          }}
                        >
                          {item.desc}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            ))}

            {/* <div className="d-flex j-center">
              <Paper className="p-25 border-right">
                <Grid container>
                  <Grid item xs={12} lg={3} className="tx-center">
                    <img
                      className="img-fluid"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "2px solid  var(--primary-color)",
                        padding: "3px",
                      }}
                      src={process.env.PUBLIC_URL + "/assets/no-img.png"}
                      alt="testimonial"
                    />
                    <h2 className="h fs-18 fw-5 mt-5">Name1</h2>
                    <span className="p fs-14">Job Title</span>
                    <div>
                      <Rating value={5} className="primary-color fs-18 mt-5" />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <div
                      style={{
                        padding: { lg: "0px 20px 0px 20px", xs: "0px" },
                      }}
                    >
                      <p
                        className="p fs-18 fs-italic pre-wrap"
                        style={{
                          lineHeight: "30px",
                          padding: "0px 50px 0px 20px",
                        }}
                      >
                        My overall experience has been Amazing.
                        Strata-Management offers great value, wonderful customer
                        service, and support, and the overall ease of use makes
                        this a no-brainer. I did vast amounts of research for
                        Community Management software and we are very pleased we
                        selected Strata-Management. We plan to use them for a
                        long time to come.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
            <div className="d-flex j-center">
              <Paper className="p-25 border-right">
                <Grid container>
                  <Grid item xs={12} lg={3} className="tx-center">
                    <img
                      className="img-fluid"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "2px solid  var(--primary-color)",
                        padding: "3px",
                      }}
                      src={process.env.PUBLIC_URL + "/assets/no-img.png"}
                      alt="testimonial"
                    />
                    <h2 className="h fs-18 fw-5 mt-5">Name2</h2>
                    <span className="p fs-14">Job Title</span>
                    <div>
                      <Rating value={5} className="primary-color fs-18 mt-5" />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <div
                      style={{
                        padding: { lg: "0px 20px 0px 20px", xs: "0px" },
                      }}
                    >
                      <p
                        className="p fs-18 fs-italic pre-wrap"
                        style={{
                          lineHeight: "30px",
                          padding: "0px 50px 0px 20px",
                        }}
                      >
                        My overall experience has been Amazing.
                        Strata-Management offers great value, wonderful customer
                        service, and support, and the overall ease of use makes
                        this a no-brainer. I did vast amounts of research for
                        Community Management software and we are very pleased we
                        selected Strata-Management. We plan to use them for a
                        long time to come.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div> */}
          </Carousel>
        </Box>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Testimonials);
