import React, { useContext, useEffect, useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { AppContext } from "../../../App";
import RepNav from "./RepNav";
import { Table } from "../../../layout";
import { TableCell } from "@mui/material";
import moment from "moment";

const tblHeader = () => (
  <div className="d-flex a-center flex-wrap">
    <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
      Invoices by Revenue Category
    </h2>
  </div>
);

const InvByCat = (props) => {
  const { _getById, _hoa } = props;
  const { setLoader } = useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [invs, setInvs] = useState([]);
  const [filteredInvs, setFilteredInvs] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });

  useEffect(() => {
    setCsvHeader([
      { label: "Unit", key: "unit" },
      { label: "Invoice", key: "invoice" },
      { label: "Is Paid", key: "isPaid" },
      { label: "Active Date", key: "activeDate" },
      { label: "Due Date", key: "dueDate" },
      { label: "Amount", key: "amount" },
      { label: "Remaining", key: "remaining" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          setLoader(false);
          res.status === 200 &&
            setInvs(res.result.filter((j) => j.invType !== 3));
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    invs &&
      setFilteredInvs([
        ...invs.filter(
          (j) =>
            moment(j.issueDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.issueDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);

    return () => {
      setFilteredInvs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invs, sortDate]);

  useEffect(() => {
    filteredInvs &&
      setTblData([
        ...filteredInvs.map((inv) => {
          var _firstPayment = inv.invPayments[0];
          var _lastPayment = inv.invPayments.slice(-1).pop();
          return {
            unit: inv.unit.title,
            invoice: `${inv.title} (${inv.id})`,
            isPaid: inv.invPayments.some((j) => j.status === 2),
            activeDate: _firstPayment && _firstPayment.issueDate,
            dueDate: _lastPayment && _lastPayment.dueDate,
            depositAcct: inv.bankAcc.accName,
            amount: inv.invPayments
              .map(
                (j) =>
                  j.amount +
                  j.invLateFees.map((x) => x.amount).reduce((x, y) => x + y, 0)
              )
              .reduce((x, y) => x + y, 0),
            remaining: inv.invPayments
              .map(
                (j) =>
                  j.amount -
                  j.paidAmt +
                  j.invLateFees
                    .map((x) => x.amount - x.paidAmt)
                    .reduce((x, y) => x + y, 0)
              )
              .reduce((x, y) => x + y, 0),
          };
        }),
      ]);

    return () => {
      setTblData([]);
    };
  }, [filteredInvs]);

  return (
    <>
      <div className="mt-20 mb-10">
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "Invoices by Revenue Category",
            showTitle: false,
          }}
        />
      </div>
      <div>
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={tblHeader}
          tblHead={[
            { id: "unit", label: "Unit" },
            { id: "invoice", label: "Invoice" },
            { id: "isPaid", label: "Status" },
            { id: "activeDate", label: "Active Date" },
            { id: "dueDate", label: "Due Date" },
            { id: "depositAcct", label: "Deposit Acct" },
            { id: "amount", label: "Amount" },
            { id: "remaining", label: "Remaining" },
          ]}
          tblData={tblData.map((data) => {
            return {
              ...data,
              isPaid: data.isPaid ? "Paid" : "Due",
              amount: "$ " + data.amount.toLocaleString(),
              remaining: "$ " + data.remaining.toLocaleString(),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total Invoice
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                ${" "}
                {tblData
                  .map((j) => j.amount)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                ${" "}
                {tblData
                  .map((j) => j.remaining)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(InvByCat);
