import { Grid, IconButton, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputMultImg,
  MultiSelect,
} from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Dialog, Form, Snackbar } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useNavigate, useParams } from "react-router-dom";
import { controller, endpoints } from "../../../api/actions/api";
import { NestedFormData, RmvEmptyProp } from "../../../hooks/Method";
import { PersonAddRounded } from "@mui/icons-material";
import AddEditOwner from "../Owner/AddEditOwner";

const AddEditUnit = (props) => {
  const { _getById, _post, _put, _hoa, _authUser } = props;
  const { UnitMdl } = Model();
  const { Countries } = DropLi();
  const { id } = useParams();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState([]);
  const [hideZip, setHideZip] = useState(true);
  const [showAddOwner, setShowAddOwner] = useState(false);
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    resetFormControls,
  } = UseForm(UnitMdl);

  useEffect(() => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result) {
        var val = res.result;
        setValues((x) => ({
          ...x,
          ...val,
          owners: val.unitOwners.map((j) => j.ownerId),
          imgsFile: [],
        }));
        setImgSrc(val.unitImgs.map((j) => ({ src: j.imgUrl, ...j })));
      }
    };
    id && _getById(endpoints.Unit + controller.GetById, id, null, onComplete);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (_hoa.id) {
      _getById(
        endpoints.Owner + controller.GetByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setOwners([
            ...res.result.map((j) => {
              return {
                ...j,
                id: j.id,
                label: j.fullName,
              };
            }),
          ])
      );

      // Set Hoa Address.
      setValues((j) => ({
        ...j,
        address1: _hoa.address1,
        address2: _hoa.address2,
        city: _hoa.city,
        state: _hoa.state,
        zip: _hoa.zip,
        country: _hoa.country,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : "Title is required.";
    temp.address1 = values.address1 !== "" ? "" : "Address is required.";
    temp.country = values.country !== "" ? "" : "Country is required.";
    temp.city = values.city !== "" ? "" : "City is required.";

    if (hideZip) temp.zip = values.zip !== "" ? "" : "Zip is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setSnackbar({
            show: true,
            type: "success",
            msg: `Unit ${id ? "Details Updated" : "Added"} Successfully.`,
          });
          res.status === 200 && navigate("/my_account/units/");
          resetFormControls();
          setImgSrc([]);
          setValues((j) => ({
            ...j,
            address1: _hoa.address1,
            address2: _hoa.address2,
            city: _hoa.city,
            state: _hoa.state,
            zip: _hoa.zip,
            country: _hoa.country,
          }));
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg,
          });
        } else
          setSnackbar({
            show: true,
            type: "error",
            msg: res.msg,
          });
      };
      if (values.owners.length > 0) {
        values.unitOwners = values.owners.map((j) => {
          return {
            ownerId: j,
          };
        });
      } else {
        values.owners = [];
        values.unitOwners = [];
      }
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        values.userId = _authUser.id;
        _post(
          endpoints.Unit + controller.Post,
          NestedFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.Unit + controller.Put,
          NestedFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    navigate("/my_account/units");
  };

  const handleAddOwner = () => {
    setShowAddOwner(!showAddOwner);
  };

  const handleNewOwner = (val) => {
    setOwners((x) => [{ id: val.id, label: val.fullName, ...val }, ...x]);
    setValues((j) => ({ ...j, owners: [val.id, ...j.owners] }));
  };

  useEffect(() => {
    if (values.country === "JM") {
      setHideZip(false);
      setValues((x) => ({ ...x, zip: "" }));
    } else setHideZip(true);
    return () => {
      setHideZip(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country]);

  return (
    <>
      <Paper className="p-15 mt-30">
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="mb-10 d-flex j-between a-center">
                <div>
                  <h2 className="h tx-md fw-5 mb-5 tx-upp border-left">
                    {id ? "Edit" : "Add"} Unit
                  </h2>
                </div>
                <div>
                  <Button
                    onClick={handleClose}
                    variant="text"
                    loading={loading}
                    sx={{
                      mr: { sm: "10px", xs: "0px" },
                      mb: { sm: "0px", xs: "10px" },
                      width: { sm: "auto", xs: "100%" },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    sx={{ width: { sm: "auto", xs: "100%" } }}
                    loading={loading}
                  >
                    {id ? "Save Changes" : "Submit"}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Unit Title"
                name="title"
                value={values.title}
                error={errors.title}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex a-center">
                <MultiSelect
                  label="Select Owners"
                  name="owners"
                  value={values.owners}
                  error={errors.owners}
                  onChange={handleInpChg}
                  fullWidth
                  options={owners}
                />

                <div className="d-grid j-center a-center tx-center pl-5">
                  <div>
                    <IconButton
                      sx={{ pb: "5px" }}
                      onClick={() => handleAddOwner()}
                    >
                      <PersonAddRounded />
                    </IconButton>
                  </div>

                  <span
                    style={{
                      fontSize: "10px",
                      minWidth: "60px",
                      color: "var(--gray-color)",
                      cursor: "pointer",
                    }}
                  >
                    Add Owner
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputAuto
                label="Country"
                name="country"
                value={values.country}
                error={errors.country}
                options={Countries}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Address 1"
                name="address1"
                value={values.address1}
                error={errors.address1}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Address 2"
                name="address2"
                value={values.address2}
                error={errors.address2}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="City"
                name="city"
                value={values.city}
                error={errors.city}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={!hideZip ? "Parish" : "State"}
                name="state"
                value={values.state}
                error={errors.state}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            {hideZip && (
              <Grid item xs={12} md={6}>
                <Input
                  label="ZIP"
                  name="zip"
                  value={values.zip}
                  error={errors.zip}
                  onChange={handleInpChg}
                  fullWidth
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <InputMultImg
                label="Property Images"
                name="imgsFile"
                src={imgSrc}
                values={values.imgsFile}
                onChange={handleFile}
                rmv={(file) => {
                  setValues((x) => ({
                    ...x,
                    imgsFile: x.imgsFile.filter(
                      (j) => j.lastModified !== file.lastModified
                    ),
                    unitImgs: x.unitImgs.filter((j) => j.id !== file.id),
                  }));
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Snackbar {...{ snackbar, setSnackbar }} />
      {showAddOwner && (
        <Dialog show={showAddOwner} onClose={false} maxWidth="lg">
          <AddEditOwner
            {...{
              fromAddUnit: true,
              setOpenFromUnit: setShowAddOwner,
              handleNewOwner,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditUnit);
