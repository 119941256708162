import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../controls";
import { DropLi } from "../../../hooks";
import { useEffect } from "react";

const ViewOwner = (props) => {
  const { setShowForm, viewData } = props;
  const [country, setCountry] = useState();
  const { Countries } = DropLi();
  const handleClose = () => {
    setShowForm(false);
  };
  useEffect(() => {
    viewData && setCountry(Countries.find((j) => j.id === viewData.country));
    return () => setCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  return (
    <>
      {viewData && (
        <Grid container spacing={3} className="p-20 dark-color">
          <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
            <h2 className="h fw-5 dark-color mb-5 tx-upp">Owner Details</h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-60" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Create Date:</span>
            <span className="fw-4">{viewData.createDate}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Update Date:</span>
            <span className="fw-4">{viewData.updateDate}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Last Login:</span>
            <span className=" fw-4">{viewData.lastLogin}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 ">Account Status:</span>
            {viewData.isActive === 1 ? (
              <span className="success-color bg-successXS status">Active</span>
            ) : (
              <span className="error-color bg-errorSM status">Inactive</span>
            )}
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Full Name:</span>
            <span className="fw-4">{viewData.fullName}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Eamil:</span>
            <a className="link" href={`mailto:${viewData.email}`}>
              {viewData.email}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Phone:</span>
            <a
              className="dark-color hove-primary tx-und-none"
              href={`tel:${viewData.phone}`}
            >
              {viewData.phone}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">City:</span>
            <span className="fw-4">{viewData.city}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">State:</span>
            <span className="fw-4">{viewData.state}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Country:</span>
            <span className="fw-4">{country && country.label}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">ZIP:</span>
            <span className="fw-4">{viewData.zip}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Address Line - 2:</span>
            <span className="fw-4">{viewData.address1}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5">Address Line - 2:</span>
            <span className="fw-4">{viewData.address2}</span>
          </Grid>
          {viewData.unitOwners && viewData.unitOwners[0] && (
            <Grid item xs={12} md={4} className="d-grid">
              <span className="tx-xs tx-upp mb-5">Units:</span>
              <div className="d-flex flex-wrap">
                {viewData.unitOwners.map((val, i) => (
                  <span
                    key={i}
                    className="primary-color bg-primarySM status mr-10 mb-5"
                  >
                    {val.unit.title}
                  </span>
                ))}
              </div>
            </Grid>
          )}

          <Grid item xs={12} className="tx-center mt-30">
            <Button size="large" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ViewOwner;
