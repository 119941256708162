import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { Forgot, Login } from "./";
import { connect } from "react-redux";
import { GetStart } from "../landingPage";

const MyAccount = (props) => {
  const { redirect } = useParams();
  return (
    <Routes>
      <Route path="/register" element={<GetStart {...{ redirect }} />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/" element={<Login {...{ redirect }} />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActiontoProps = {};

export default connect(mapStateToProps, mapActiontoProps)(MyAccount);
