import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import {
  PlayCircleFilledRounded,
  StopCircleRounded,
} from "@mui/icons-material";
import SubsHoldForm from "./SubsHoldForm";
import { useNavigate } from "react-router-dom";

const MySubs = (props) => {
  const { _getAll, _getById } = props;
  const naviage = useNavigate();
  const [subs, setSubs] = useState([]);
  const [holdOrg, setHoldOrg] = useState();
  const { setLoader } = useContext(AppContext);
  const [showHoldForm, setShowHoldForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setSubs([...res.result]);
    };
    _getAll(endpoints.Subs + controller.GetAll, undefined, onComplete);

    return () => {
      setSubs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHold = (val) => {
    setHoldOrg(val);
    setShowHoldForm(true);
  };

  const handleStart = (val) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure?",
      subTitle: `You want to active ${val.org.orgName} organization.`,
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: res.msg,
            });
            setSubs((x) => [
              ...x.map((j) => (j.id === res.result.id ? res.result : j)),
            ]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _getById(
          endpoints.SubsHold + controller.StartHoldSubs,
          val.id,
          null,
          onComplete
        );
      },
    });
  };

  const handleViewOrg = (id) => {
    naviage(`/admin_panel/view_org/${id}`);
  };

  const ActionComp = (props) => (
    <>{props.isActive && <SubsPeriodAct val={props} />}</>
  );

  const SubsPeriodAct = ({ val }) => {
    switch (val.subsPeriod) {
      case 1:
      case 3:
        return (
          <Button
            size="small"
            color="error"
            onClick={() => handleHold(val)}
            startIcon={<StopCircleRounded />}
          >
            Hold Org.
          </Button>
        );
      case 5:
        return (
          <Button
            size="small"
            color="success"
            onClick={() => handleStart(val)}
            startIcon={<PlayCircleFilledRounded />}
          >
            Start Org.
          </Button>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => <></>}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "orgDtl", label: "Org" },
          { id: "pkg", label: "Package" },
          { id: "access", label: "Access" },
          { id: "date", label: "Date" },
        ]}
        tblData={subs.map((j) => {
          return {
            ...j,
            searchByOrgId: `org ${j.org.id}`,
            orgName: j.org.orgName,
            subsUser: j.subsUser.fullName,
            orgDtl: (
              <div className="d-grid g-5">
                <span
                  className="fs-14 d-flex link tx-und"
                  onClick={() => handleViewOrg(j.orgId)}
                >
                  <span className="tx-upp">Organization: </span>&nbsp;
                  <span className="fw-5">{`(${j.orgId}) ${
                    j.org && j.org.orgName
                  }`}</span>
                </span>

                <span className="fs-14 d-flex">
                  <span className="tx-upp">Suscribed User: </span>&nbsp;
                  <span className="fw-5 ">{`(${j.subsUserId}) ${j.subsUser.fullName}`}</span>
                </span>
              </div>
            ),
            status: (
              <div className="d-grid g-10">
                <div>
                  {!j.isActive ? (
                    <span
                      className="fs-12 fw-5 bg-grayXS white-gray br-8 tx-upp"
                      style={{ padding: "5px 10px 5px 10px" }}
                    >
                      Deactivated
                    </span>
                  ) : (
                    <div>
                      <PkgStatusComp status={j.subsPeriod} />
                    </div>
                  )}
                </div>
              </div>
            ),
            pkgSearchTerm: `pkg ${j.id}`,
            pkg: (
              <div className="d-grid g-5">
                <div className="d-flex g-5">
                  <span className="fw-5">Pkg Id: {j.id}</span> |
                  <span className="fw-5">{j.title}</span>
                </div>

                <span>$ {j.price}</span>
                <span className="fs-14">
                  {j.isYearlyPay
                    ? `Yearly Base (${j.yearlyPayDis}%)`
                    : "Monthly Base"}
                </span>
              </div>
            ),
            access: (
              <div className="d-flex g-10">
                <div
                  className="d-grid g-5 fs-14 pr-10"
                  style={{ borderRight: "1px solid var(--grayXS-color)" }}
                >
                  <span>
                    Branch/Hoa:&nbsp;
                    <span className="fw-5">
                      {j.unlimitedBranch ? "Unlimited" : j.branch}
                    </span>
                  </span>
                  <span>
                    Unit:&nbsp;
                    <span className="fw-5">
                      {j.unlimitedUnit ? "Unlimited" : j.unit}
                    </span>
                  </span>
                  <span>
                    User:&nbsp;
                    <span className="fw-5">
                      {j.unlimitedUser ? "Unlimited" : j.user}
                    </span>
                  </span>
                </div>
                <div>
                  <div className="d-grid g-5 fs-14">
                    <span>
                      Owner:&nbsp;
                      <span className="fw-5">
                        {j.unlimitedOwner ? "Unlimited" : j.owner}
                      </span>
                    </span>
                    <span>
                      Vendor:&nbsp;
                      <span className="fw-5">
                        {j.unlimitedVendor ? "Unlimited" : j.vendor}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ),
            date: (
              <div className="d-grid">
                {j.trialStartDate && (
                  <span className="fs-14">
                    Trial Start:{" "}
                    <span className="fw-5">
                      {new Date(j.trialStartDate).toDateString()}
                    </span>
                  </span>
                )}

                {j.trialEndDate && (
                  <span className="fs-14">
                    Trial End:{" "}
                    <span className="fw-5">
                      {new Date(j.trialEndDate).toDateString()}
                    </span>
                  </span>
                )}
                {j.pkgStartDate && (
                  <span className="fs-14">
                    Package Start:{" "}
                    <span className="fw-5">
                      {new Date(j.pkgStartDate).toDateString()}
                    </span>
                  </span>
                )}

                {j.pkgEndDate && (
                  <span className="fs-14">
                    Package End :{" "}
                    <span className="fw-5">
                      {new Date(j.pkgEndDate).toDateString()}
                    </span>
                  </span>
                )}
              </div>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Organization Hold Form */}
      {showHoldForm && (
        <Dialog show={showHoldForm} onClose={false} maxWidth="sm">
          <SubsHoldForm
            {...{ setShowForm: setShowHoldForm, setAlert, holdOrg, setSubs }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getAll: actions.get,
  _delete: actions.Delete,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(MySubs);

export const PkgStatusComp = ({ status }) => {
  switch (status) {
    case 1:
      return (
        <span
          className="fs-12 fw-5 bg-primarySM primary-color br-8 tx-upp"
          style={{ padding: "5px 10px 5px 10px" }}
        >
          Running On Trial Period
        </span>
      );
    case 2:
      return (
        <span
          className="fs-12 fw-5 bg-warningT warning-color br-8 tx-upp"
          style={{ padding: "5px 10px 5px 10px" }}
        >
          Finished Trial Period
        </span>
      );
    case 3:
      return (
        <span
          className="fs-12 fw-5 bg-successXS success-color br-8 tx-upp"
          style={{ padding: "5px 10px 5px 10px" }}
        >
          Plan Active
        </span>
      );
    case 4:
      return (
        <span
          className="fs-12 fw-5 bg-errorSM error-color br-8 tx-upp"
          style={{ padding: "5px 10px 5px 10px" }}
        >
          Have Some Issues
        </span>
      );
    case 5:
      return (
        <span
          className="fs-12 fw-5 bg-errorSM error-color br-8 tx-upp"
          style={{ padding: "5px 10px 5px 10px" }}
        >
          On Hold
        </span>
      );
    default:
      break;
  }
};
