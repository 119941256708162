import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Input } from "../../../../controls";
import { Model, UseForm } from "../../../../hooks";
import { Alert } from "../../../../layout";
import * as actions from "../../../../api/actions/actions";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../../../api/actions/api";
import { ToFormData } from "../../../../hooks/Method";

const AddEditNote = (props) => {
  const {
    setShowForm,
    _post,
    _authUser,
    updData,
    alert,
    setAlert,
    setNotes,
    setTblData,
    inv,
  } = props;
  const { InvNoteMdl } = Model();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(InvNoteMdl);

  useEffect(() => {
    updData && setValues({ ...updData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  const validateForm = () => {
    let temp = {};
    temp.note = values.note ? "" : "Note is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201) {
          if (setNotes && res.result.id) {
            setNotes((x) => [res.result, ...x]);
            setTblData &&
              setTblData((j) => [
                ...j.map((x) =>
                  x.invId === inv.id
                    ? {
                        ...x,
                        invNotes: [...x.invNotes, { ...res.result }],
                      }
                    : x
                ),
              ]);
          }
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "Note Added Successfully.",
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Something is Wrong",
            subTitle: "Please refresh the page and try again.",
            onClick: () => navigate("/my_account/receivables/"),
          });
      };

      values.invId = inv.invId;
      values.userId = _authUser.id;
      _post(
        endpoints.InvNote + controller.Post,
        ToFormData(values),
        null,
        onComplete
      );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box className="p-15" sx={{ minWidth: { xs: "280px", md: "450px" } }}>
        <div className="tx-center mb-30">
          <h2 className="h fw-5 mb-5 mt-10 fs-20 tx-upp gray-color">
            {updData ? "Update" : "Add"} Note
          </h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-40" />
          </div>
        </div>
        <div className="mb-15">
          <Input
            multiline
            rows={4}
            label="Note"
            placeholder="Write your note here..."
            name="note"
            value={values.note}
            error={errors.note}
            inputProps={{ maxLength: 1500 }}
            onChange={handleInpChg}
            fullWidth
          />
        </div>
        <Box className="d-flex">
          <Button
            onClick={handleClose}
            fullWidth
            className="mr-10"
            variant="text"
          >
            Close
          </Button>
          <Button
            fullWidth
            type="submit"
            loading={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditNote);
