import { ThemeProvider } from "@mui/system";
import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import MyAccount from "./components/auth/MyAccount";
import { DashRoute } from "./components/dashboard";
import { Landing } from "./components/landingPage";
import { Button, Loader } from "./controls";
import "./styles/App.scss";
import "./styles/JHScss.scss";
import { CstmMui } from "./utils";
import * as actions from "./api/actions/actions";
import { controller, endpoints } from "./api/actions/api";
import { OwnerPortalRoute } from "./components/OwnerPortal";
import { AuthOwner } from "./components/OwnerPortal/AuthOwner";
import AdminPanelRoute from "./components/adminPanel/AdminPanelRoute";
import { AuthAdmin } from "./components/adminPanel/AuthAdmin";
import moment from "moment";
import ViewPage from "./components/landingPage/ViewPage";

export const AppContext = createContext({});

function App(props) {
  const { _get, _authUser, _authOwner, _admin, _subs, _getById } = props;
  const { theme } = CstmMui();
  const [loader, setLoader] = useState(false);
  const [subsWarning, setSubsWarning] = useState();
  const [org, setOrg] = useState({});
  const [subsNeedToDeactive, setSubsNeedToDeactive] = useState(false);
  const [landingPg, setLandingPg] = useState({});
  const [openSidebar, setOpenSidebar] = useState(true);

  useEffect(() => {
    _authUser &&
      _authUser.id &&
      // Get Auth User Organization
      _get(
        endpoints.Org + controller.GetAuthUserOrg,
        null,
        (res) => res.status === 200 && setOrg(res.result)
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser]);

  useEffect(() => {
    // Get Organization Subscription
    org &&
      org.id &&
      _get(endpoints.Subs + controller.OrgSubs, actions.ActionType.Subs);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  useEffect(() => {
    setLoader(true);
    _get(endpoints.Auth + controller.GetAuthUser, actions.ActionType.Auth, () =>
      setLoader(false)
    );
    _get(
      endpoints.AuthOwner + controller.GetAuthUser,
      actions.ActionType.AuthOwner
    );
    _get(
      endpoints.AuthAdmin + controller.GetAuthUser,
      actions.ActionType.AuthAdmin
    );
    _get(endpoints.LP, null, (res) => {
      res.result && setLandingPg((j) => ({ ...j, ...res.result }));
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActivePlan = (id) => {
    setLoader(true);
    const onComplete = (res) => {
      if (res.status === 303) window.open(res.url, "_self");
      setLoader(false);
    };
    _getById(
      endpoints.StripePay + controller.CheckoutSession,
      id,
      null,
      onComplete
    );
  };

  useEffect(() => {
    if (_subs && _subs.isActive) {
      switch (_subs.subsPeriod) {
        case 1:
          setSubsWarning({
            title: "Trial Period",
            type: "warning",
            msg: `You are in the trial period. You have ${
              _subs.trialDuration -
              moment().diff(moment(_subs.trialStartDate, "MM/DD/YYYY"), "days")
            }
             days of trial left.`,
            Action: (
              <Button
                size="large"
                color="warning"
                className="h-100pr fw-5 fs-18"
                onClick={() => handleActivePlan(_subs.id)}
              >
                Active Now
              </Button>
            ),
          });
          break;
        case 2:
          setSubsWarning({
            title: "Trial Finished",
            msg: "Your trial period has been finished. You have to active your plan.",
            type: "error",
            Action: (
              <Button
                size="large"
                color="primary"
                className="h-100pr fw-5 fs-18"
                onClick={() => handleActivePlan(_subs.id)}
              >
                Active
              </Button>
            ),
          });
          break;
        case 4:
          setSubsWarning({
            title: "Issue on Subscrition",
            msg: "Your subscription has some issues. Please contact to the support",
            type: "error",
          });
          break;
        case 5:
          _getById(
            endpoints.SubsHold + controller.GetByOrg,
            _subs.orgId,
            null,
            (res) => {
              if (res.status === 200 && res.result) {
                setSubsWarning({
                  title: res.result.title,
                  msg: res.result.reason,
                  type: "error",
                });
              } else setSubsNeedToDeactive(true);
            }
          );

          break;
        case 6:
          setSubsWarning({
            title: "Pending for the Payment",
            msg: "We're waiting to activate your subscription. Please complete the payment.",
            type: "info",
            Action: (
              <Button
                size="large"
                color="primary"
                className="h-100pr fw-5 fs-18"
                onClick={() => handleActivePlan(_subs.id)}
              >
                Active
              </Button>
            ),
          });

          break;
        default:
          break;
      }
    } else if (_subs && _subs.id && !_subs.isActive)
      setSubsWarning({
        title: "No Plans",
        msg: "You don't have any active subscription plan. Please take a subscription for actively manage your organization.",
        type: "error",
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_subs]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          loader,
          setLoader,
          subsWarning,
          org,
          setOrg,
          landingPg,
          setLandingPg,
          openSidebar,
          setOpenSidebar,
          subsNeedToDeactive,
          setSubsNeedToDeactive,
        }}
      >
        <Loader show={loader} />
        <Routes>
          <Route
            path="/my_account/*"
            element={_authUser.id ? <DashRoute /> : <MyAccount />}
          />
          <Route
            path="/owner_portal/*"
            element={_authOwner.id ? <OwnerPortalRoute /> : <AuthOwner />}
          />
          <Route
            path="/admin_panel/*"
            element={_admin.id ? <AdminPanelRoute /> : <AuthAdmin />}
          />
          <Route path="/page/:id" element={<ViewPage />} />
          <Route path="/*" element={<Landing />} />
        </Routes>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _authOwner: state.storeData.authOwner,
  _admin: state.storeData.admin,
  _subs: state.storeData.subs,
});

const mapActiontoProps = {
  _get: actions.get,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActiontoProps)(App);
