import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import BudgetReport from "./BudgetReport";
import FilterBudget from "./FilterBudget";
import { useState } from "react";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { connect } from "react-redux";
import moment from "moment";

const Budgets = (props) => {
  const { _getById, _hoa } = props;
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const [expLi, setExpLi] = useState([]);
  const [revLi, setRevLi] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [expense, setExpense] = useState([]);

  useEffect(() => {
    if (_hoa.id) {
      _getById(
        endpoints.Payment + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setRevLi([...res.result])
      );
      _getById(
        endpoints.AP + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setExpLi([...res.result])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa.id]);

  useEffect(() => {
    revLi &&
      setRevenue([
        ...revLi.filter(
          (j) =>
            moment(j.payDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.payDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
    expLi &&
      setExpense([
        ...expLi.filter(
          (j) =>
            moment(j.paidDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.paidDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
  }, [sortDate, revLi, expLi]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FilterBudget {...{ sortDate, setSortDate }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <BudgetReport
          {...{ title: "Revenue Overview", date: sortDate, revenue }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BudgetReport
          {...{
            title: "Expense Overview",
            date: sortDate,
            budgetType: 2,
            expense,
          }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Budgets);
