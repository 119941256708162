import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { Model, UseForm } from "../../../hooks";

const AmtInp = (props) => {
  const {
    catId,
    date,
    dates,
    val,
    _post,
    _put,
    _hoa,
    _authUser,
    setBudgets,
    budgets,
    setSnackbar,
  } = props;
  const { BudgetMdl } = Model();
  const { values, setValues, handleInpChg, resetFormControls } =
    UseForm(BudgetMdl);
  const style = {
    border: "none",
    minWidth: "70px",
    width: "100%",
    backgroundColor: "transparent",
    padding: "5px",
    fontSize: "14px",
  };

  useEffect(() => {
    val
      ? setValues((x) => ({ ...x, ...val }))
      : setValues((x) => ({ ...x, amount: "" }));

    return () => resetFormControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  const handleAddOrUpd = (e) => {
    const onComplete = (res) => {
      if (res.status === 201) {
        setSnackbar({
          show: true,
          type: "success",
          msg: "Added Successfully.",
        });
        setValues((x) => ({ ...x, ...res.result }));
        setBudgets((x) => [res.result, ...x]);
      } else if (res.status === 200) {
        setSnackbar({
          show: true,
          type: "success",
          msg: "Updated Successfully.",
        });
        setValues((x) => ({ ...x, ...res.result }));
        setBudgets((x) => [
          ...x.map((j) => (j.id === res.result.id ? res.result : j)),
        ]);
      } else
        setSnackbar({
          show: true,
          type: "error",
          msg: res.msg,
        });
    };

    if (values.id) {
      _put(
        endpoints.Budget + controller.Put,
        ToFormData(
          RmvEmptyProp({
            id: values.id,
            catId: catId,
            month: date.month,
            year: date.year,
            hoaId: _hoa.id,
            userId: _authUser.id,
            amount: e.target.value,
          })
        ),
        null,
        onComplete
      );
    } else if (parseInt(e.target.value)) {
      _post(
        endpoints.Budget + controller.Post,
        ToFormData(
          RmvEmptyProp({
            catId: catId,
            month: date.month,
            year: date.year,
            hoaId: _hoa.id,
            userId: _authUser.id,
            amount: e.target.value,
          })
        ),
        null,
        onComplete
      );
    }
  };

  const handleAmtChange = (e) => {
    if (parseInt(e.target.value)) {
      if (!values.id && date.month === "01") {
        dates.months.forEach((month) => {
          const onComplete = (res) => {
            if (res.status === 201) {
              setValues((x) => ({ ...x, ...res.result }));
              setBudgets((x) => [res.result, ...x]);
            } else
              setSnackbar({
                show: true,
                type: "error",
                msg: res.msg,
              });
          };
          !budgets.some(
            (j) =>
              j.catId === catId &&
              j.year === parseInt(dates.year) &&
              j.month === parseInt(month.id)
          ) &&
            _post(
              endpoints.Budget + controller.Post,
              ToFormData(
                RmvEmptyProp({
                  catId: catId,
                  month: month.id,
                  year: dates.year,
                  hoaId: _hoa.id,
                  userId: _authUser.id,
                  amount: e.target.value,
                })
              ),
              null,
              onComplete
            );
        });
      } else handleAddOrUpd(e);
    }
  };

  return (
    <input
      onBlur={handleAmtChange}
      value={values.amount}
      name="amount"
      placeholder="0.00"
      onInput={(e) => (e.target.value = MaxLength(e.target.value, 22))}
      onChange={handleInpChg}
      style={style}
    />
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(AmtInp);
