import { DangerousRounded } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Table } from "../../../layout";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { PkgStatusComp } from "../../adminPanel/Subs/Subs";

const MySubs = (props) => {
  const { _getAll, _authUser, _getById } = props;
  const navigate = useNavigate();
  const [subs, setSubs] = useState([]);
  const { setLoader, subsNeedToDeactive, setSubsNeedToDeactive } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_authUser && _authUser.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setSubs([...res.result]);
      };
      _getAll(endpoints.Subs + controller.GetAllByOrg, undefined, onComplete);
    }
    return () => {
      setSubs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser]);

  const handleDeactive = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to deactivate this plan?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setSubsNeedToDeactive(false);
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deactivated",
              subTitle: res.msg,
              onClick: () => window.location.reload(),
            });
            setSubs((x) => [
              ...x.map((j) => (j.id === res.result.id ? res.result : j)),
            ]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _getById(endpoints.Subs + controller.Deactivate, id, null, onComplete);
      },
    });
  };

  const handleActivePlan = (id) => {
    setLoading(true);
    const onComplete = (res) => {
      if (res.status === 303) window.open(res.url, "_self");
      else if (res.status === 200) {
        setSubs((x) => [
          ...x.map((j) => (j.id === res.result.id ? res.result : j)),
        ]);
        setAlert({
          isOpen: true,
          type: "success",
          title: "Success",
          subTitle: res.msg,
          onClick: () => window.location.reload(),
        });
      } else {
        setAlert({
          isOpen: true,
          type: "warning",
          title: "Warning",
          subTitle: res.msg,
        });
      }
      setLoading(false);
    };
    _getById(
      endpoints.StripePay + controller.CheckoutSession,
      id,
      null,
      onComplete
    );
  };

  const ActionComp = (props) => (
    <>
      {props.isActive && (
        <>
          {(props.subsPeriod === 1 ||
            props.subsPeriod === 3 ||
            subsNeedToDeactive) && (
            <Tooltip arrow title="Deactive Package">
              <IconButton
                onClick={() => handleDeactive(props.id)}
                color="error"
              >
                <DangerousRounded />
              </IconButton>
            </Tooltip>
          )}
          {props.subsPeriod === 1 && (
            <Button
              size="small"
              color="success"
              loading={loading}
              onClick={() => handleActivePlan(props.id)}
            >
              Active Plan
            </Button>
          )}
        </>
      )}
    </>
  );
  const HeaderComp = () => (
    <Box sx={{ mb: { xs: "10px", md: "0px" } }}>
      <Button
        variant="outlined"
        onClick={() => navigate("/my_account/packages")}
        className="mr-10"
      >
        Subscriptions
      </Button>
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          { id: "pkg", label: "Package" },
          { id: "access", label: "Access" },
          { id: "user", label: "User" },
          { id: "date", label: "Date" },
        ]}
        tblData={subs.map((j) => {
          return {
            ...j,
            status: (
              <div className="d-grid g-10">
                <div>
                  {!j.isActive ? (
                    <span
                      className="fs-12 fw-5 bg-grayXS white-gray br-8 tx-upp"
                      style={{ padding: "5px 10px 5px 10px" }}
                    >
                      Deactivated
                    </span>
                  ) : (
                    <div>
                      <PkgStatusComp status={j.subsPeriod} />
                    </div>
                  )}
                </div>
              </div>
            ),
            pkgSearchTerm: `pkg ${j.id}`,
            pkg: (
              <div className="d-grid g-5">
                <div className="d-flex g-5">
                  <span className="fw-5">Pkg Id: {j.id}</span> |
                  <span className="fw-5">{j.title}</span>
                </div>
                <span>$ {j.price}</span>
                <span className="fs-14">
                  {j.isYearlyPay
                    ? `Yearly Base (${j.yearlyPayDis}%)`
                    : "Monthly Base"}
                </span>
              </div>
            ),
            access: (
              <div className="d-flex g-10">
                <div
                  className="d-grid g-5 fs-14 pr-10"
                  style={{ borderRight: "1px solid var(--grayXS-color)" }}
                >
                  <span>
                    Branch/Hoa:&nbsp;
                    <span className="fw-5">
                      {j.unlimitedBranch ? "Unlimited" : j.branch}
                    </span>
                  </span>
                  <span>
                    Unit:&nbsp;
                    <span className="fw-5">
                      {j.unlimitedUnit ? "Unlimited" : j.unit}
                    </span>
                  </span>
                  <span>
                    User:&nbsp;
                    <span className="fw-5">
                      {j.unlimitedUser ? "Unlimited" : j.user}
                    </span>
                  </span>
                </div>
                <div>
                  <div className="d-grid g-5 fs-14">
                    <span>
                      Owner:&nbsp;
                      <span className="fw-5">
                        {j.unlimitedOwner ? "Unlimited" : j.owner}
                      </span>
                    </span>
                    <span>
                      Vendor:&nbsp;
                      <span className="fw-5">
                        {j.unlimitedVendor ? "Unlimited" : j.vendor}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ),
            date: (
              <div className="d-grid">
                {j.trialStartDate && (
                  <span className="fs-14">
                    Trial Start:{" "}
                    <span className="fw-5">
                      {new Date(j.trialStartDate).toDateString()}
                    </span>
                  </span>
                )}
                {j.trialEndDate && (
                  <span className="fs-14">
                    Trial End:{" "}
                    <span className="fw-5">
                      {new Date(j.trialEndDate).toDateString()}
                    </span>
                  </span>
                )}

                {j.pkgStartDate && (
                  <span className="fs-14">
                    Package Start:{" "}
                    <span className="fw-5">
                      {new Date(j.pkgStartDate).toDateString()}
                    </span>
                  </span>
                )}

                {j.pkgEndDate && (
                  <span className="fs-14">
                    Package End :{" "}
                    <span className="fw-5">
                      {new Date(j.pkgEndDate).toDateString()}
                    </span>
                  </span>
                )}
                {j.nextPayDate && (
                  <span className="fs-14">
                    Next Payment Date :{" "}
                    <span className="fw-5">
                      {new Date(j.nextPayDate).toDateString()}
                    </span>
                  </span>
                )}
              </div>
            ),
            user: (
              <div className="d-grid g-5">
                <span className="fw-5">{j.subsUser.fullName}</span>
                <span className="fs-14">ID: {j.subsUserId}</span>
              </div>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getAll: actions.get,
  _delete: actions.Delete,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(MySubs);
