import { IconButton } from "@mui/material";

export default function IconButtonWithText(props) {
  const {
    name,
    onClick,
    variant,
    icon,
    text = "",
    className,
    ...other
  } = props;
  return (
    <IconButton
      className="p-0"
      onClick={onClick}
      {...other}
      sx={{
        color: "#e9e9e9cc",
        "&:hover": {
          backgroundColor: "transparent",
          color: "var(--white-color)",
        },
      }}
    >
      <div className={`c-pointer d-flex flex-column a-center ${className}`}>
        {icon}
        <span className="fs-11">{text}</span>
      </div>
    </IconButton>
  );
}
