import {
  CalendarTodayRounded,
  LocationOn,
  PaymentRounded,
} from "@mui/icons-material";
import { Divider, Paper, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../controls";
import "../../../dashboard/Units/UnitWidget/UnitWidget.scss";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { controller, endpoints } from "../../../../api/actions/api";

const UnitWidget = (props) => {
  const { unit, _getById } = props;
  const [defOwner, setDefOwner] = useState();
  const [osBal, setOsBal] = useState(0);
  const [recBal, setRecBal] = useState(0);
  const [invs, setInvs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (unit) {
      _getById(
        endpoints.Invoice + controller.GetInvsByUnit,
        unit.id,
        null,
        (res) => res.status === 200 && setInvs([...res.result])
      );

      var firstOwner = unit.unitOwners.find((j) => j.isContactOwner);
      firstOwner && setDefOwner(firstOwner.owner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  useEffect(() => {
    // Get Outstanding  Balance
    setOsBal(
      invs
        .map((j) => j.invPayments)
        .flat(Infinity)
        .filter((j) => j.status !== 2)
        .map(
          (j) =>
            j.amount -
            j.paidAmt +
            j.invLateFees
              .map((x) => x.amount - x.paidAmt)
              .reduce((x, y) => x + y, 0)
        )
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    // Get Monthly Recurring Balance
    setRecBal(
      invs
        .filter((j) => j.invType === 2)
        .map((j) => j.amount)
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );
    return () => {};
  }, [invs]);

  const handleViewUnit = () => {
    navigate(`view_unit/${unit.id}`);
  };

  return (
    <Paper className="unit-widget">
      <div className="unit-widget-img">
        <img
          alt="property-img"
          className="img-fluid"
          src={
            unit.unitImgs.length > 0
              ? unit.unitImgs[0].imgUrl
              : `${process.env.PUBLIC_URL}/assets/no-img.png`
          }
        />
        <div className="unit-hover-action">
          <div className="view-unit-btn">
            <Button
              variant="outlined"
              color="white"
              className="pos-absolute"
              onClick={handleViewUnit}
            >
              View Unit
            </Button>
          </div>
        </div>
      </div>

      <div className="unit-widget-body">
        <div className="p-15">
          <h2 className="h fs-20 fw-5 mb-5">{unit.title}</h2>
          {defOwner && (
            <span className="fs-14 gray-color">{defOwner.fullName}</span>
          )}
        </div>

        <div className="unit-widget-deatil">
          <LocationOn className="mr-15" color="error" />
          <span className="tx-sm">{unit.address1 + " | " + unit.address2}</span>
        </div>
        <Divider />
        <div className="unit-widget-deatil">
          <PaymentRounded className="mr-15" color="primary" />
          <div className="d-grid">
            <Tooltip title="Unit ownre's total account balance">
              <span className="tx-sm fw-5 mb-5">${osBal}</span>
            </Tooltip>
            <span className="tx-xs gray-color tx-upp">Outstanding Balance</span>
          </div>
        </div>
        <Divider />
        <div className="unit-widget-deatil">
          <CalendarTodayRounded className="mr-15" color="primary" />
          <div className="d-grid">
            <Tooltip title="Total due recurring-invoice amount">
              <span className="tx-sm fw-5 mb-5">${recBal}</span>
            </Tooltip>
            <span className="tx-xs gray-color tx-upp">Recurring Charge</span>
          </div>
        </div>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _delete: actions.Delete,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(UnitWidget);
