import { LandscapeRounded } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React from "react";
import { DropLi } from "../../../../hooks";
import { Table } from "../../../../layout";

const Users = (props) => {
  const { users = [] } = props;
  const { Roles } = DropLi();

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={() => <></>}
        tblHead={[
          { id: "photo", label: "Photo" },
          { id: "fullName", label: "Full Name" },
          { id: "role", label: "Role" },
          { id: "email", label: "Email" },
          { id: "phone", label: "Phone" },
        ]}
        tblData={users.map((obj) => {
          var roleName = Roles.find((j) => j.id === obj.role);
          return {
            ...obj,
            role: roleName && roleName.label,
            photo: (
              <Avatar
                src={obj.photo}
                alt="logo"
                className="bg-primary"
                sx={{ width: 36, height: 36 }}
              >
                {!obj.photo && <LandscapeRounded />}
              </Avatar>
            ),
          };
        })}
      />
    </>
  );
};

export default Users;
