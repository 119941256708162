import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Button, Input, InputDate, Select } from "../../../../controls";
import { DropLi, Model, UseForm } from "../../../../hooks";
import { Form } from "../../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../../hooks/Method";

const AddEditRcd = (props) => {
  const { banks, setList, _post, _put, _hoa, setShowForm, setAlert, updData } =
    props;
  const { BankRecoMdl } = Model();
  const { BankTxnType } = DropLi();
  const [loading, setLoading] = useState(false);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpDate,
    resetFormControls,
  } = UseForm(BankRecoMdl);

  useEffect(() => {
    // Set Update Value
    banks && updData && setValues((x) => ({ ...x, ...updData }));

    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData, banks]);

  const validateForm = () => {
    let temp = {};
    temp.txnId = values.txnId !== "" ? "" : "Transection Id is required.";
    temp.payDate = values.payDate !== "" ? "" : "Payment Date is required.";
    temp.txnType = values.txnType !== "" ? "" : "Transection Type is required.";
    temp.bankId = values.bankId !== "" ? "" : "Bank is required.";
    temp.amount = values.amount !== "" ? "" : "Amount is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          var data = res.result;
          updData
            ? setList((x) => [
                ...x.map((j) =>
                  j.id === data.id
                    ? {
                        ...data,
                        date: data.payDate,
                        amt: data.txnType === 1 ? data.amount : -data.amount,
                        bank: data.bank && data.bank.accName,
                      }
                    : j
                ),
              ])
            : setList((x) => [
                {
                  ...data,
                  date: data.payDate,
                  amt: data.txnType === 1 ? data.amount : -data.amount,
                  bank: data.bank && data.bank.accName,
                },
                ...x,
              ]);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Bank Record ${
              updData ? "Updated" : "Added"
            } Successfully.`,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        _post(
          endpoints.BankReco + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.BankReco + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate className="p-15">
      <Grid container spacing={3}>
        <Grid item xs={12} className="tx-center p-20 mt-20 mb-10 w-100pr">
          <h2 className="h fw-5 mb-5 tx-upp">
            {updData ? "Update" : "Add"} Bank Record
          </h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-60" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Transection Id"
            name="txnId"
            value={values.txnId}
            error={errors.txnId}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputDate
            label="Payment Date"
            name="payDate"
            value={values.payDate}
            error={errors.payDate}
            className="w-100pr"
            onChange={(date) =>
              handleInpDate("payDate", new Date(date).toLocaleDateString())
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Select
            label="Transection Type"
            name="txnType"
            value={values.txnType}
            error={errors.txnType}
            onChange={handleInpChg}
            options={BankTxnType}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            label="Bank"
            name="bankId"
            value={values.bankId}
            error={errors.bankId}
            onChange={handleInpChg}
            options={banks}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Amount"
            name="amount"
            type="number"
            value={values.amount}
            error={errors.amount}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="text" size="large" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button size="large" type="submit" loading={loading} fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditRcd);
