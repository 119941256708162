import {
  AdminPanelSettings,
  AutoGraphRounded,
  BarChartRounded,
  ContactsRounded,
  CurrencyExchangeOutlined,
  EventNoteRounded,
  FolderCopyRounded,
  LocationCityRounded,
  PaymentRounded,
  PaymentsRounded,
  PeopleRounded,
  RateReviewRounded,
  ReceiptRounded,
  SettingsSuggestRounded,
  StackedBarChartRounded,
  SubscriptionsRounded,
  TouchAppRounded,
  VideoLibraryRounded,
  WarningAmberRounded,
} from "@mui/icons-material";
import React from "react";
import { NavItem } from "../../layout";
import { connect } from "react-redux";
import { useContext } from "react";
import { AppContext } from "../../App";

const NavMenu = ({ _authUser, _subs }) => {
  const { org } = useContext(AppContext);
  return (
    <div>
      <NavItem title="Dashboard" path="home" icon={<BarChartRounded />} />

      {_subs &&
        _subs.isActive &&
        (_subs.subsPeriod === 1 || _subs.subsPeriod === 3) && (
          <>
            <NavItem
              title="Cash Application"
              path="payments"
              icon={<PaymentRounded />}
            />
            <NavItem
              title="Pay Vendor"
              path="pay_vendor_page"
              icon={<PaymentsRounded />}
            />
            {_authUser && _authUser.role !== 1 && (
              <NavItem
                title="Transactions"
                icon={<CurrencyExchangeOutlined />}
                child={[
                  { title: "Receivable", path: "receivables" },
                  { title: "Payable", path: "payables" },
                ]}
              />
            )}

            <NavItem
              title="Invoices"
              path="invoices"
              icon={<ReceiptRounded />}
            />
            <NavItem
              title="Notices"
              path="notices"
              icon={<EventNoteRounded />}
            />
            <NavItem
              title="Units"
              path="units"
              icon={<LocationCityRounded />}
            />
            <NavItem
              title="Reports"
              path="reports"
              icon={<StackedBarChartRounded />}
            />

            <NavItem
              title="Revenue"
              path="revenue"
              icon={<AutoGraphRounded />}
            />
            <NavItem
              title="Requests"
              path="requests"
              icon={<TouchAppRounded />}
            />
            <NavItem
              title="Violation"
              path="violation"
              icon={<WarningAmberRounded />}
            />
            <NavItem title="Owner" path="owner" icon={<PeopleRounded />} />
            {_authUser && _authUser.role !== 1 && (
              <NavItem
                title="Vendors"
                path="vendors"
                icon={<ContactsRounded />}
              />
            )}
            <NavItem
              title="Documents"
              path="documents"
              icon={<FolderCopyRounded />}
            />

            {_authUser && _authUser.role === 3 && (
              <>
                <NavItem
                  title="Settings"
                  icon={<SettingsSuggestRounded />}
                  child={[
                    { title: "Budgets", path: "budgets" },
                    { title: "Categories", path: "categories" },
                    { title: "Bank Accounts", path: "bank-accounts" },
                  ]}
                />
                <NavItem
                  title="Master"
                  icon={<AdminPanelSettings />}
                  child={[
                    { title: "Manage HOA", path: "manage-hoa" },
                    { title: "Invoice Option", path: "invoice-option" },
                    { title: "Invoice Term", path: "invoice-terms" },
                    { title: "Log", path: "logs" },
                    { title: "Users", path: "users" },
                  ]}
                />
              </>
            )}
          </>
        )}

      <NavItem
        title="Subscription"
        icon={<SubscriptionsRounded />}
        child={[
          { title: "My Subscriptions", path: "my_subscriptions" },
          { title: "Packages", path: "packages" },
        ]}
      />
      {org.reviewType !== 2 && (
        <NavItem
          title="Review"
          path="review_form"
          icon={<RateReviewRounded />}
        />
      )}
      <NavItem
        title="Tutorials"
        path="https://youtube.com/playlist?list=PL7idGPQfsIfLc9BqgjSCYGjHfd1w8r61f&si=g0wwZiX8h9as1faH"
        newTab={true}
        icon={<VideoLibraryRounded />}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _subs: state.storeData.subs,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(NavMenu);
