import { CheckCircleRounded } from "@mui/icons-material";
import { Box, Grid, Paper } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Signup } from "../auth";

const GetStart = () => {
  return (
    <Box
      id="getstart"
      className="d-grid a-center bg-primaryXS"
      sx={{
        height: { lg: "100vh", xs: "100%" },
        padding: { xs: "30px 0px 30px 0px", lg: "0px" },
      }}
    >
      <div>
        <Container maxWidth="xl">
          <Paper className="bg-primary" sx={{ borderRadius: "40px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="p-30">
                  <h1
                    className="white-color h2 fw-4"
                    style={{
                      borderLeft: "4px double var(--white-color)",
                      paddingLeft: "10px",
                    }}
                  >
                    Keep Your Community Running Smoothly
                  </h1>
                </div>
                <div className="d-flex j-between">
                  <div className="p-30">
                    <div className="d-flex a-center mb-20 white-color">
                      <CheckCircleRounded className="mr-15 fs-22" />
                      <span className="fs-18 fw-3">Manage Units</span>
                    </div>
                    <div className="d-flex a-center mb-20 white-color">
                      <CheckCircleRounded className="mr-15 fs-22" />
                      <span className="fs-18 fw-3">Manage People</span>
                    </div>
                    <div className="d-flex a-center mb-20 white-color">
                      <CheckCircleRounded className="mr-15 fs-22" />
                      <span className="fs-18 fw-3">
                        Invoice (Single invoice, Bulk Invoice etc. )
                      </span>
                    </div>
                    <div className="d-flex a-center mb-20 white-color">
                      <CheckCircleRounded className="mr-15 fs-22" />
                      <span className="fs-18 fw-3">Manage Payments</span>
                    </div>
                    <div className="d-flex a-center mb-20 white-color">
                      <CheckCircleRounded className="mr-15 fs-22" />
                      <span className="fs-18 fw-3">
                        Tickets (violations and requests)
                      </span>
                    </div>
                    <div className="d-flex a-center mb-20 white-color">
                      <CheckCircleRounded className="mr-15 fs-22" />
                      <span className="fs-18 fw-3">
                        Document (Private, Public Folder)
                      </span>
                    </div>
                    <div className="d-flex a-center mb-20 white-color">
                      <CheckCircleRounded className="mr-15 fs-22" />
                      <span className="fs-18 fw-3">Accounting</span>
                    </div>
                  </div>
                  <Box
                    sx={{
                      display: { xs: "none", lg: "block" },
                      marginRight: "-5px",
                    }}
                  >
                    <img
                      alt="singup-bg"
                      className="img-fluid"
                      style={{
                        width: "300px",
                      }}
                      src={process.env.PUBLIC_URL + "/assets/signup.png"}
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  className="d-flex j-center pb-50 pt-50"
                  style={{
                    borderRadius: "35px",
                    backgroundColor: "rgba(255 255 255 / 95%)",
                    backdropFilter: "blur(50px)",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: { lg: "60%", xs: "100%" },
                    }}
                  >
                    <Signup />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    </Box>
  );
};

export default GetStart;
