import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useState } from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { AppContext } from "../App";

const NavItem = (props) => {
  const { icon, path, title, child = [], newTab = false } = props;
  const [open, setOpen] = useState(false);
  const { setOpenSidebar } = useContext(AppContext);
  const navigate = useNavigate();

  const handlePage = (e, url) => {
    if (newTab) {
      e.preventDefault();
      window.open(url, "_blank");
    } else navigate(url);

    if (window.innerWidth < 900) setOpenSidebar(false);
  };

  const sxActive = {
    backgroundColor: "var(--primarySM-color)",
    // borderLeft: "4px solid var(--primary-color)",
    "& .MuiListItemIcon-root": {
      color: "var(--primary-color) !important",
    },
    "& .MuiTypography-root": {
      color: "var(--primary-color)",
      fontWeight: "500",
    },
  };

  return (
    <Grid className={`${open && "bg-deepWhite"} br-6 mb-10`}>
      {child.length > 0 ? (
        <>
          <ListItemButton
            onClick={() => setOpen(!open)}
            sx={open ? sxActive : {}}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
            {/* Child Menu */}
            {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </ListItemButton>
          <Collapse in={open}>
            <List className="p-0">
              {child.map((item, i) => (
                <ListItemButton
                  key={i}
                  onClick={(e) => handlePage(e, item.path)}
                  component={NavLink}
                  to={item.path}
                  sx={{
                    mt: 1,
                    pl: 4,
                    "&.active": sxActive,
                  }}
                >
                  <ListItemIcon>
                    <ArrowRightAltRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <ListItemButton
          onClick={(e) => handlePage(e, path)}
          component={NavLink}
          to={path}
          sx={{
            "&.active": sxActive,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      )}
    </Grid>
  );
};

export default NavItem;
