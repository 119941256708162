import {
  AdminPanelSettingsRounded,
  Logout,
  RoomPreferencesRounded,
} from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { MenuPopup } from "../../layout";
import * as actions from "../../api/actions/actions";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { Select } from "../../controls";
import { Box } from "@mui/system";

const Header = (props) => {
  const { _get, _authUser, _hoas, _set, _defHoa } = props;
  const [profileEl, setProfileEl] = useState(null);
  const [defHoa, setDefHoa] = useState("");
  const [hoas, setHoas] = useState([]);
  const { setLoader, org } = useContext(AppContext);
  const navigate = useNavigate();

  const handleProfile = (e) => {
    setProfileEl(e.currentTarget);
  };

  const handleLogout = () => {
    setLoader(true);
    const onComplete = (res) => {
      navigate("/");
      setLoader(false);
    };
    _get(
      endpoints.Auth + controller.Logout,
      actions.ActionType.Auth,
      onComplete
    );
  };

  const handleHoa = (e) => {
    _set(
      { result: _hoas.find((j) => j.id === e.target.value) },
      actions.ActionType.DefHoa
    );
  };

  useEffect(() => {
    if (_authUser.id !== undefined) {
      const onComplete = (res) => {};
      _get(
        endpoints.Hoa + controller.GetAuthUserHoas,
        actions.ActionType.Hoa,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser]);

  useEffect(() => {
    if (_hoas.length > 0) {
      // Set Hoas
      setHoas([
        ..._hoas.map((j) => {
          return {
            id: j.id,
            label: j.hoaName,
          };
        }),
      ]);
      // Set Default Hoa
      var findDefHoa = _hoas.find((j) => j.defHoa);
      findDefHoa && _set({ result: findDefHoa }, actions.ActionType.DefHoa);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoas]);

  useEffect(() => {
    _hoas.length > 0 && _defHoa && setDefHoa(_defHoa.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_defHoa]);

  return (
    <Box
      className="d-flex a-center w-100pr"
      sx={{ justifyContent: { md: "space-between", xs: "end" } }}
    >
      <Box
        className="ml-10 gray-color"
        sx={{
          display: { md: "block", xs: "none" },
        }}
      >
        <div className="d-grid fs-14">
          <span className="fw-5">
            {org.orgName}&nbsp;|&nbsp;Id: {org.id}
          </span>
          <span className="fw-5">{_authUser.fullName}</span>
        </div>
      </Box>
      <div className="d-flex a-center">
        <div className="mr-10">
          <Select
            className="min-w-150"
            variant="outlined"
            size="small"
            name="hoa"
            fullWidth
            value={defHoa}
            onChange={handleHoa}
            options={hoas}
          />
        </div>
        <div>
          <IconButton
            onClick={handleProfile}
            size="small"
            aria-controls="account-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <Avatar
              alt="profile-pic"
              src={_authUser.photo}
              className="bg-primary"
            />
          </IconButton>
          <MenuPopup
            anchorEl={profileEl}
            setAnchorEl={setProfileEl}
            id="account-menu"
          >
            <MenuItem onClick={() => navigate("update-user")}>
              <ListItemIcon>
                <AdminPanelSettingsRounded fontSize="small" />
              </ListItemIcon>
              My Account
            </MenuItem>
            {_authUser && _authUser.role === 3 && (
              <MenuItem onClick={() => navigate("organization-settings")}>
                <ListItemIcon>
                  <RoomPreferencesRounded fontSize="small" />
                </ListItemIcon>
                Organization
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </MenuPopup>
        </div>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoas: state.storeData.hoas,
  _defHoa: state.storeData.defHoa,
});

const mapActiontoProps = {
  _get: actions.get,
  _set: actions.set,
};

export default connect(mapStateToProps, mapActiontoProps)(Header);
