import { Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import DropLi from "../../../hooks/DropLi";
import { Button } from "../../../controls";
import { PowerSettingsNewRounded } from "@mui/icons-material";
import { Alert, Dialog } from "../../../layout";
import DeactiveInvForm from "./DeactiveInvForm";

const InvDetails = ({ data }) => {
  const { InvTypes, InvPeriod } = DropLi();
  const [unitOwner, setUnitOwner] = useState();
  const [durationType, setDurationType] = useState();
  const [invTypeName, setInvTypeName] = useState();
  const [showForm, setShowForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (
      data &&
      data.unit &&
      data.unit.unitOwners[0] &&
      data.unit.unitOwners[0].owner
    ) {
      setUnitOwner(data.unit.unitOwners[0].owner);
    }

    if (data) {
      var _type = InvTypes.find((j) => j.id === data.invType);
      _type && setInvTypeName(_type.label);

      if (data.invPlan) {
        var _durationType = InvPeriod.find(
          (j) => j.id === data.invPlan.periodic
        );
        _durationType && setDurationType(_durationType.label);
      }
    }

    return () => {
      setUnitOwner();
      setInvTypeName();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDeactive = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure?",
      subTitle: "You want to deactive this invoice",
      onConfirm: () => {
        setAlert({ isOpen: false, type: "", title: "", subTitle: "" });
        setShowForm(!showForm);
      },
    });
  };

  return (
    <>
      <Grid container spacing={3} className="p-15 dark-color mb-10">
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
            Invoice Id:
          </span>
          <span className="fs-18 fw-4 gray-color">{data.id}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Title:</span>
          <span className="fs-18 fw-4 gray-color gray-color">{data.title}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Amount:</span>
          <span className="fs-18 fw-4 gray-color">$ {data.amount}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Issued Date:</span>
          <span className="fs-18 fw-4 gray-color">{data.issueDate}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Due Date:</span>
          <span className="fs-18 fw-4 gray-color">{data.dueDate}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Category:</span>
          <span className="fs-18 fw-4 gray-color">
            {data.category && data.category.name}
          </span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Unit:</span>
          <span className="fs-18 fw-4 gray-color">
            {data.unit && data.unit.title}
            {unitOwner && `(${unitOwner.fullName})`}
          </span>
        </Grid>

        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Invoice Type:</span>
          <span className="fs-18 fw-4 gray-color">{invTypeName}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Bank:</span>
          <span className="fs-18 fw-4 gray-color">
            {data.bankAcc && data.bankAcc.accName}
          </span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">
            Early Pay Discount:
          </span>
          <span className="fs-18 fw-4 gray-color">
            {data.earlyPayDis ? "Yes" : "No"}
          </span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Late Fee:</span>
          <span className="fs-18 fw-4 gray-color">
            {data.isLateFee ? "Yes" : "No"}
          </span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Send Email:</span>
          <span className="fs-18 fw-4 gray-color">
            {data.sendEmail ? "Yes" : "No"}
          </span>
        </Grid>
        <Grid item xs={12} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Description:</span>
          <span className="fs-18 fw-4 gray-color">{data.descr}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Created User</span>
          <span className="fs-18 fw-4 gray-color">
            {data.user && data.user.fullName}
          </span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">
            Invoice Create Date:
          </span>
          <span className="fs-18 fw-4 gray-color">{data.createDate}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">
            Last Update Date:
          </span>
          <span className="fs-18 fw-4 gray-color">{data.updateDate}</span>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} className="d-grid">
          <span className="fs-14 gray-color tx-upp mb-10">Invoice Plan</span>
          <span className="fs-18 fw-4 gray-color">
            {data.invPlan && data.invPlan.name}
          </span>
          <span className="tx-xs tx-upp mt-5 grayMD-color">
            {data.invPlan && data.invPlan.duration} {durationType}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Divider className="mb-10" />
          {data.invType !== 3 ? (
            <div className="d-flex j-end">
              <Button
                onClick={handleDeactive}
                startIcon={<PowerSettingsNewRounded />}
                className="bg-errorSM error-color"
                size="large"
              >
                Deactive Invoice
              </Button>
            </div>
          ) : (
            <div className="d-grid">
              <span className="fs-14 gray-color tx-upp mb-10 error-color">
                Deactive Note:
              </span>
              <span className="fs-18 fw-4 gray-color">{data.deactiveNote}</span>
            </div>
          )}
        </Grid>
      </Grid>

      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <DeactiveInvForm
            {...{
              setShowForm,
              inv: data,
              setAlert,
            }}
          />
        </Dialog>
      )}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default InvDetails;
