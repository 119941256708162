import { AdminPanelSettingsRounded } from "@mui/icons-material";
import { Grid, Grow, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Model, UseForm } from "../../../hooks";
import { controller, endpoints } from "../../../api/actions/api";
import { ToFormData } from "../../../hooks/Method";
import { ActionType } from "../../../api/actions/actions";
import * as actions from "../../../api/actions/actions";
import { Form } from "../../../layout";
import { Button, Input, InputPass } from "../../../controls";

const Login = (props) => {
  const { _getByObj } = props;
  const { LoginMdl } = Model();
  const [loading, setLoading] = useState(false);
  const { values, errors, setErrors, handleInpChg } = UseForm(LoginMdl);
  const [errMsg, setErrMsg] = useState("");

  const validateForm = () => {
    let temp = {};
    temp.email = values.email !== "" ? "" : "Email is required.";
    temp.password = values.password !== "" ? "" : "Password is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        if (res !== undefined && res.status === 403) {
          setErrors({
            ...errors,
            [res.error.key]: res.error.msg,
          });
        } else if (res.status === 401) {
          setErrMsg(res.msg);
        }
        setLoading(false);
      };
      _getByObj(
        endpoints.AuthAdmin + controller.Login,
        ToFormData(values),
        ActionType.AuthAdmin,
        onComplete
      );
    }
  };

  return (
    <Grow in timeout={1000}>
      <Container maxWidth="xl">
        <div className="d-flex j-center a-center h-100vh">
          <div style={{ maxWidth: "400px" }}>
            <div className="p-15 br-30 bs-primary bg-white">
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="tx-center mb-30 mt-20 d-flex j-center">
                      <div
                        title="Admin Panel"
                        className="w-fit p-5"
                        style={{
                          backgroundColor: "var(--primarySM-color)",
                          borderRadius: "50%",
                        }}
                      >
                        <AdminPanelSettingsRounded
                          className="primary-color"
                          sx={{ fontSize: "100px" }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="tx-center">
                    <Typography color="error">{errMsg}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInpChg}
                      error={errors.email}
                      className="input-primary w-100pr"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputPass
                      label="Password"
                      name="password"
                      value={values.password}
                      onChange={handleInpChg}
                      error={errors.password}
                      className="input-primary w-100pr"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="tx-right"
                    sx={{
                      pt: "15px !important",
                      pb: "15px",
                      fontSize: "15px",
                    }}
                  >
                    <Link
                      to="/owner_portal/forgot"
                      className="gray-color tx-und-none tx-upp hove-primary"
                    >
                      Forgot Password?
                    </Link>
                  </Grid>
                  <Grid item xs={12} className="text-center">
                    <Button
                      loading={loading}
                      type="submit"
                      className="mb-10 w-100pr br-20 shadow-primary"
                      size="large"
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </Grow>
  );
};
const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(Login);
