import { Grid } from "@mui/material";
import React from "react";
import OrgWidgets from "./OrgWidgets";
import YearlyRev from "./YearlyRev";
import TodayRegOrg from "./TodayRegOrg";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { useEffect } from "react";
import { controller, endpoints } from "../../../api/actions/api";

const Home = (props) => {
  const { _getAll } = props;
  const [payments, setPayments] = useState([]);
  const [subsInvs, setSubsInvs] = useState([]);

  useEffect(() => {
    _getAll(endpoints.Subs + controller.GetAllPayment, null, (res) =>
      setPayments([...res.result])
    );
    _getAll(endpoints.SubsInv + controller.GetAll, null, (res) =>
      setSubsInvs([...res.result])
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-20">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OrgWidgets />
        </Grid>
        <Grid item xs={12} className="mt-30">
          <YearlyRev {...{ payments, subsInvs }} />
        </Grid>
        <Grid item xs={12} className="mt-30">
          <TodayRegOrg />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _getAll: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Home);
