import React from "react";
import { SubsPlan } from "../../landingPage";

const Packages = () => {
  return (
    <div>
      <SubsPlan fromDashboard={true} />
    </div>
  );
};

export default Packages;
