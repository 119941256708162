import { Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import Widget from "../Org/Widget";
import {
  AdminPanelSettingsRounded,
  ApartmentRounded,
  ContactsRounded,
  GroupRounded,
} from "@mui/icons-material";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useEffect } from "react";
import { controller, endpoints } from "../../../api/actions/api";

const OrgWidgets = (props) => {
  const { _getAll } = props;
  const [units, setUnits] = useState([]);
  const [owners, setOwners] = useState([]);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    _getAll(endpoints.Unit + controller.Total, null, (res) =>
      setUnits(res.result)
    );
    _getAll(endpoints.Owner + controller.Total, null, (res) =>
      setOwners(res.result)
    );
    _getAll(endpoints.User + controller.Total, null, (res) =>
      setUsers(res.result)
    );
    _getAll(endpoints.Vendor + controller.Total, null, (res) =>
      setVendors(res.result)
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={3}>
        <Widget
          url=""
          icon={<ApartmentRounded className="fs-32" />}
          title={units}
          subtitle="Units"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Widget
          url=""
          icon={<GroupRounded className="fs-32" />}
          title={owners}
          subtitle="Owner"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Widget
          url=""
          icon={<AdminPanelSettingsRounded className="fs-32" />}
          title={users}
          subtitle="User"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Widget
          url=""
          icon={<ContactsRounded className="fs-28" />}
          title={vendors}
          subtitle="Vendor"
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _admin: state.storeData.admin,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _getAll: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(OrgWidgets);
