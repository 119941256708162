import { Paper, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Doc from "../../Documents/Doc";
import Emails from "../../Email/Emails";
import Requests from "../../Request/Requests";
import Vio from "../../Request/Violations";
import Home from "./Home/Home";
import UnitDetails from "./UnitDetails";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { controller, endpoints } from "../../../../api/actions/api";
import CreditDebit from "./CreditDebit";
import AccActivities from "./AccActivities";

const ViewUnit = (props) => {
  const { _getById } = props;
  const { id } = useParams();
  const [activeOn, setActiveOn] = useState("home");
  const [unit, setUnit] = useState();
  const [invs, setInvs] = useState([]);
  const [osBal, setOsBal] = useState();
  const [recBal, setRecBal] = useState();
  const [crdr, setCrdr] = useState([]);
  const [payments, setPayments] = useState([]);
  const [stmts, setStmts] = useState([]);

  useEffect(() => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result)
        setUnit((x) => ({
          ...x,
          ...res.result,
        }));
    };
    id && _getById(endpoints.Unit + controller.GetById, id, null, onComplete);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (unit) {
      _getById(
        endpoints.Invoice + controller.GetInvsByUnit,
        unit.id,
        null,
        (res) => res.status === 200 && setInvs([...res.result])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  useEffect(() => {
    // Get Outstanding  Balance
    setOsBal(
      invs
        .map((j) => j.invPayments)
        .flat(Infinity)
        .filter((j) => j.status !== 2)
        .map(
          (j) =>
            j.amount -
            j.paidAmt +
            j.invLateFees
              .map((x) => x.amount - x.paidAmt)
              .reduce((x, y) => x + y, 0)
        )
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    // Get Monthly Recurring Balance
    setRecBal(
      invs
        .filter((j) => j.invType === 2)
        .map((j) => j.amount)
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );
    return () => {};
  }, [invs]);

  // Get Account activities
  useEffect(() => {
    if (unit && unit.id) {
      _getById(
        endpoints.Unit + controller.GetCredits,
        unit.id,
        null,
        (res) => res.status === 200 && setCrdr([...res.result])
      );
      _getById(
        endpoints.Invoice + controller.GetInvsByUnit,
        unit.id,
        null,
        (res) =>
          res.status === 200 &&
          setPayments([
            ...res.result
              .map((j) => j.invPayments)
              .filter((j) => j.paidAmt > 0)
              .flat(),
          ])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  console.log("payments: ", payments);

  useEffect(() => {
    if (crdr || payments) {
      var _statement = [
        ...crdr.map((j) => ({
          date: new Date(j.addDate).toLocaleDateString(),
          par: j.note,
          debit: j.txnType === 2 ? j.balance : 0,
          credit: j.txnType !== 2 ? j.balance : 0,
          bal: 0,
        })),
        ...payments.map((j) =>
          j.isRevPayment
            ? {
                date: j.issueDate,
                par: `This payment has been reversed. Reverse Note: ${j.note}`,
                no: j.refe,
                debit: j.amount,
                credit: 0,
                bal: 0,
              }
            : {
                date: j.paidDate,
                par: `Note: ${j.note}`,
                no: j.refe,
                debit: 0,
                credit: j.paidAmt,
                bal: 0,
              }
        ),
      ];
      var prevBal = 0;

      var _finalStatement = _statement
        .sort((x, y) => new Date(y.date) - new Date(x.date))
        .map((j) => {
          prevBal = prevBal + j.credit - Math.abs(j.debit);
          return {
            ...j,
            bal: prevBal,
          };
        });
      var lastStatement = _finalStatement.slice(-1).pop();
      setStmts([
        ..._finalStatement,
        {
          date: "",
          par: "",
          no: "Total",
          debit: _finalStatement
            .map((j) => j.debit)
            .reduce((x, y) => x + y, 0)
            .toLocaleString(),
          credit: _finalStatement
            .map((j) => j.credit)
            .reduce((x, y) => x + y, 0)
            .toLocaleString(),
          bal: lastStatement && lastStatement.bal.toLocaleString(),
        },
      ]);
    }
    return () => {};
  }, [crdr, payments]);

  const Content = (active) => {
    switch (active) {
      case "home":
        return (
          <>
            <Home {...{ unit }} />
          </>
        );
      case "email":
        return (
          <>
            <Emails {...{ unit }} />
          </>
        );
      case "doc":
        return (
          <>
            <Doc {...{ unit }} />
          </>
        );
      case "requests":
        return (
          <>
            <Requests {...{ unit }} />
          </>
        );
      case "violations":
        return (
          <>
            <Vio {...{ unit }} />
          </>
        );
      case "credit_debit":
        return (
          <>
            <CreditDebit unitId={unit.id} />
          </>
        );
      case "acc_activities":
        return (
          <>
            <AccActivities stmts={stmts} />
          </>
        );
      default:
        break;
    }
  };

  const handleActive = (val, newVal) => {
    setActiveOn(newVal);
  };

  return (
    <Box>
      {/* Unit Details Bar  */}
      <div className="mb-20">
        <UnitDetails {...{ unit, osBal, recBal, stmts }} />
      </div>
      <Paper
        className="mb-20"
        sx={{ padding: { md: "0px 10px 0px 10px", xs: "0px" } }}
      >
        <Tabs
          value={activeOn}
          onChange={handleActive}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          <Tab label="Home" value="home" />
          <Tab label="Email" value="email" />
          <Tab label="Documents" value="doc" />
          <Tab label="Requests" value="requests" />
          <Tab label="Violations" value="violations" />
          <Tab label="Credit / Debit" value="credit_debit" />
          <Tab label="Account Activities" value="acc_activities" />
        </Tabs>
      </Paper>
      {Content(activeOn)}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewUnit);
