import { Divider, Grid, IconButton } from "@mui/material";
import React from "react";
import { CloseRounded } from "@mui/icons-material";

const ViewNotice = (props) => {
  const { setShowForm, viewData } = props;
  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      {viewData ? (
        <Grid container spacing={3} className="p-15 dark-color">
          <Grid item xs={12}>
            <div className="d-flex a-center j-between mb-20">
              <div>
                <span className="fs-20 tx-upp border-left">View Notice</span>
              </div>
              <div>
                <IconButton size="small" onClick={handleClose}>
                  <CloseRounded />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">Date:</span>
            <span>{viewData.createDate}</span>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">Posted User:</span>
            <span>{viewData.user && viewData.user.fullName}</span>
          </Grid>
          <Grid item xs={12} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">Title:</span>
            <span>{viewData.title}</span>
          </Grid>

          <Grid item xs={12}>
            <span className="tx-xs tx-upp mb-5 gray-color">Message:</span>
            <Divider />
            <p className="p pre-wrap">{viewData.msg}</p>
          </Grid>
          <Grid item xs={12} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color fw-6">
              View Files:
            </span>
            {viewData.noticeFiles.map((file, i) => (
              <a
                key={i}
                href={file.fileSrc}
                className="mb-10 link fs-14 tx-und"
                target="_blank"
                rel="noreferrer"
              >
                {file.name}
              </a>
            ))}
          </Grid>
        </Grid>
      ) : (
        <span>Loading...</span>
      )}
    </>
  );
};

export default ViewNotice;
