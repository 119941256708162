import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import RevNav from "./RevNav";
import RevChart from "./RevChart";
import RevTable from "./RevTable";
import RevAmountWidget from "./RevAmountWidget";
import { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import moment from "moment";

const Revenue = (props) => {
  const { _getById, _getByObj, _hoa } = props;
  const [rev, setRev] = useState([]);
  const [crDr, setCrDr] = useState([]);
  const [fltCrDr, setFltCrDr] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [catInvs, setCatInvs] = useState([]);
  const [cats, setCats] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });

  useEffect(() => {
    if (_hoa.id) {
      _getById(
        endpoints.Payment + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setRev([...res.result])
      );

      _getById(
        endpoints.Payment + controller.GetCrDrByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setCrDr([...res.result.filter((j) => j.txnType === 3)])
      );

      _getByObj(
        endpoints.HoaCat + controller.GetAllByCatType,
        {
          id: _hoa.id,
          value: "1",
        },
        null,
        (res) => res.status === 200 && setCats([...res.result])
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa.id]);

  useEffect(() => {
    rev &&
      setRevenue([
        ...rev.filter(
          (j) =>
            moment(
              moment(j.payDate, "MM/DD/YYYY").format("MM/YYYY"),
              "MM/YYYY"
            ) >=
              moment(
                moment(sortDate.fromDate, "MM/DD/YYYY").format("MM/YYYY"),
                "MM/YYYY"
              ) &&
            moment(
              moment(j.payDate, "MM/DD/YYYY").format("MM/YYYY"),
              "MM/YYYY"
            ) <=
              moment(
                moment(sortDate.toDate, "MM/DD/YYYY").format("MM/YYYY"),
                "MM/YYYY"
              )
        ),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDate, rev]);

  useEffect(() => {
    crDr &&
      setFltCrDr([
        ...crDr.filter(
          (j) =>
            moment(
              moment(j.addDate, "MM/DD/YYYY").format("MM/YYYY"),
              "MM/YYYY"
            ) >=
              moment(
                moment(sortDate.fromDate, "MM/DD/YYYY").format("MM/YYYY"),
                "MM/YYYY"
              ) &&
            moment(
              moment(j.addDate, "MM/DD/YYYY").format("MM/YYYY"),
              "MM/YYYY"
            ) <=
              moment(
                moment(sortDate.toDate, "MM/DD/YYYY").format("MM/YYYY"),
                "MM/YYYY"
              )
        ),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDate, crDr]);

  useEffect(() => {
    revenue &&
      setCatInvs(
        cats.map((j) => {
          return {
            catId: j.id,
            catName: j.name,
            invs: revenue.filter((x) => x.inv.categoryId === j.id),
          };
        })
      );
    return () => {
      setCatInvs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenue, cats]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RevNav {...{ sortDate, setSortDate }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <RevAmountWidget {...{ revenue, fltCrDr }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <RevChart {...{ catInvs }} />
      </Grid>
      <Grid item xs={12}>
        <RevTable {...{ catInvs, fltCrDr }} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(Revenue);
