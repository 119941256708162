import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { Alert } from "../../../layout";
import UnitHeader from "./UnitHeader";
import UnitWidget from "./UnitWidget/UnitWidget";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { useEffect } from "react";

const Units = (props) => {
  const { _getById, _authOwner } = props;
  const [units, setUnits] = useState([]);
  const [filterUnits, setFilterUnits] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_authOwner.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res.status === 200) {
          setUnits(res.result);
          setFilterUnits(res.result);
        }
      };
      _getById(
        endpoints.Unit + controller.GetAllByOwner,
        _authOwner.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authOwner]);

  return (
    <div className="p-15">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UnitHeader {...{ units, setFilterUnits, _authOwner, setAlert }} />
        </Grid>
        {filterUnits.map((unit, i) => (
          <Grid item xs={12} md={3} key={i}>
            <UnitWidget {...{ unit, setUnits: setFilterUnits, setAlert }} />
          </Grid>
        ))}
        {!filterUnits.length && (
          <span className="p-20 mt-40 fs-24 gray-color">No Units</span>
        )}
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authOwner: state.storeData.authOwner,
});

const mapActionToProps = {
  _getById: actions.getById,
  _set: actions.set,
};

export default connect(mapStateToProps, mapActionToProps)(Units);
