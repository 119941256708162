import {
  DiscountRounded,
  ReceiptRounded,
  WatchLaterRounded,
} from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const RevAmountWidget = ({ revenue, fltCrDr }) => {
  const [totalInv, setTotalInv] = useState(0);
  const [totalLateFee, setTotalLateFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [miscAmt, setMiscAmt] = useState(0);

  useEffect(() => {
    if (revenue || fltCrDr) {
      var _invPayments = revenue.map((j) => j.inv && j.inv.invPayments).flat();

      var _totalInv = _invPayments
        .map((j) => j.paidAmt)
        .reduce((x, y) => x + y, 0);
      setTotalInv(_totalInv);

      var _totalLateFee = _invPayments
        .map((x) =>
          x.invLateFees.map((y) => y.paidAmt).reduce((x, y) => x + y, 0)
        )
        .reduce((x, y) => x + y, 0);

      setTotalLateFee(_totalLateFee);

      var _totalDis = fltCrDr.map((j) => j.balance).reduce((x, y) => x + y, 0);
      setTotal(_totalInv + _totalLateFee - _totalDis);

      setMiscAmt(
        revenue
          .filter(
            (j) =>
              j.inv &&
              j.inv.category &&
              (j.inv.category.name.toLowerCase() === "miscellaneous" ||
                j.inv.category.name.toLowerCase() === "misc")
          )
          .map((j) => j.inv && j.inv.invPayments)
          .flat()
          .map((j) => j.paidAmt)
          .reduce((x, y) => x + y, 0)
      );
    }

    return () => {
      setTotalInv(0);
      setTotalLateFee(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenue, fltCrDr]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper className="p-20 border-bottom">
          <div className="d-flex a-center h-100">
            <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
              <ReceiptRounded className="fs-30" />
            </div>
            <div className="ml-15">
              <h2 className="h fs-22 fw-6 mb-5">
                ${totalInv.toLocaleString()}
              </h2>
              <span className="tx-sm gray-color">Total Invoice Amount</span>
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper className="p-20 border-bottom">
          <div className="d-flex a-center h-100">
            <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
              <WatchLaterRounded className="fs-30" />
            </div>
            <div className="ml-15">
              <h2 className="h fs-22 fw-6 mb-5">
                ${totalLateFee.toLocaleString()}
              </h2>
              <span className="tx-sm gray-color">Total Late Fee</span>
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper className="p-20 border-bottom">
          <div className="d-flex a-center h-100">
            <div className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center">
              <DiscountRounded className="fs-30" />
            </div>
            <div className="ml-15">
              <h2 className="h fs-22 fw-6 mb-5">${miscAmt}</h2>
              <span className="tx-sm gray-color">Miscellaneous</span>
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper className="p-20 border-bottom">
          <div className="d-flex a-center h-100">
            <div
              className="primary-color bg-primaryT br-50pr h-50 w-50 d-flex a-center j-center"
              style={{ minWidth: "50px" }}
            >
              <DiscountRounded className="fs-30" />
            </div>
            <div className="ml-15">
              <h2 className="h fs-22 fw-6 mb-5">$ {total.toLocaleString()}</h2>
              <span className="tx-sm gray-color">Total</span>
              <br />
              <span className="tx-xs tx-upp fw-5 gray-color">
                ((late-fee + invoice amount) - early pay discount)
              </span>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RevAmountWidget;
