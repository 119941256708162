import React, { useContext, useEffect, useState } from "react";
import { Table } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { Box, IconButton } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";

const OwnerRep = (props) => {
  const { _getById, _getByObj, _hoa } = props;
  const { setLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [vio, setVio] = useState([]);
  const [req, setReq] = useState([]);
  const [units, setUnits] = useState([]);
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    setCsvHeader([
      { label: "Owner", key: "owner" },
      { label: "Unit", key: "unit" },
      { label: "Contact", key: "contact" },
      { label: "Email", key: "email" },
      { label: "Address", key: "address" },
      { label: "IsActive", key: "isActive" },
      { label: "Violation", key: "vio" },
      { label: "Request", key: "req" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    owners &&
      setCsvData([
        ...owners.map((owner) => {
          return {
            ...owner,
            isActive: owner.status === 1 ? "Active" : "Inactive",
          };
        }),
      ]);

    return () => {
      setCsvData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owners]);

  useEffect(() => {
    if (_hoa && _hoa.id) {
      setLoader(true);
      _getById(endpoints.Unit + controller.GetByHoa, _hoa.id, null, (res) => {
        setLoader(false);
        setUnits(res.result);
      });

      _getByObj(
        endpoints.Request + controller.GetByHoa,
        { id: _hoa.id, value: "1" },
        null,
        (res) =>
          res.status === 200 &&
          setReq(res.result.filter((j) => j.isComplete === 2))
      );

      _getByObj(
        endpoints.Request + controller.GetByHoa,
        { id: _hoa.id, value: "2" },
        null,
        (res) =>
          res.status === 200 &&
          setVio(res.result.filter((j) => j.isComplete === 2))
      );
    }

    return () => {
      setUnits([]);
      setReq([]);
      setVio([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);
  useEffect(() => {
    if (units) {
      var _owners = [];
      units.forEach((unit) => {
        var ownr = unit.unitOwners;
        var _vio = vio.filter((y) => y.unitId === unit.id).length;
        var _req = req.filter((y) => y.unitId === unit.id).length;
        _owners.push(
          ownr.map((j) => {
            return {
              unit: unit.title,
              owner: j.owner.fullName,
              email: j.owner.email,
              contact: j.owner.phone,
              address: j.owner.address1 + " || " + j.owner.address2,
              status: j.owner.isActive,
              vio: _vio,
              req: _req,
            };
          })
        );
      });
      setOwners([..._owners.flat()]);
    }
    return () => {
      setOwners([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, vio, req]);

  const HeaderComp = () => (
    <div className="d-flex a-center flex-wrap">
      <IconButton size="small" className="link" onClick={() => navigate(-1)}>
        <ArrowBackRounded className="fs-24" />
      </IconButton>
      <div className="ml-20">
        <CSVLink
          data={csvData}
          headers={csvHeader}
          filename={`Owner Reports (${new Date().toDateString()}).csv`}
          target="_blank"
          className="tx-upp tx-und-none primary-color bg-primaryXS p-10 fs-14 br-7"
        >
          Download CSV
        </CSVLink>
      </div>

      <Box sx={{ m: { xs: "10px 0px 0px 0px", md: "0px 0px 0px 15px" } }}>
        <h2 className="h fs-20 gray-color tx-upp">Owner Reports</h2>
      </Box>
    </div>
  );

  return (
    <div className="pt-15">
      <Table
        shadow={false}
        ActionComp={() => <></>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "owner", label: "Owner" },
          { id: "unit", label: "Unit" },
          { id: "contact", label: "Contact" },
          { id: "email", label: "Email" },
          { id: "address", label: "Address" },
          { id: "status", label: "Status" },
          { id: "vio", label: "Violation" },
          { id: "req", label: "Request" },
        ]}
        tblData={owners.map((owner) => {
          return {
            ...owner,
            email: (
              <a className="link" href={`mailto:${owner.email}`}>
                {owner.email}
              </a>
            ),
            balance: "$" + owner.balance,
            status: (
              <Box sx={{ m: "5px" }}>
                {owner.status === 1 ? (
                  <span className="success-color bg-successXS status">
                    Active
                  </span>
                ) : (
                  <span className="error-color bg-errorSM status">
                    Inactive
                  </span>
                )}
              </Box>
            ),
          };
        })}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(OwnerRep);
