import { Grid } from "@mui/material";
import React from "react";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const SubsHoldForm = (props) => {
  const { _post, _authUser, setShowForm, setAlert, holdOrg, setSubs } = props;
  const { SubsHoldMdl } = Model();
  const { values, errors, setErrors, handleInpChg } = UseForm(SubsHoldMdl);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : "Title is required.";
    temp.desc = values.desc !== "" ? "" : "Note is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && _authUser.id) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201) {
          setShowForm(false);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
          setSubs((x) => [
            ...x.map((j) => (j.id === res.result.id ? res.result : j)),
          ]);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };

      values.userId = _authUser.id;
      values.subsId = holdOrg.id;
      values.orgId = holdOrg.orgId;

      _post(
        endpoints.SubsHold + controller.Post,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate className="p-15">
      <Grid container spacing={3}>
        <Grid item xs={12} className="tx-center p-20 mt-10 mb-10 w-100pr">
          <h2 className="fw-5 mb-5 fs-22">
            Hold '{holdOrg && holdOrg.orgName}'
          </h2>
          <div className="pos-relative d-flex j-center">
            <div className="border-bottom w-60" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Title"
            name="title"
            value={values.title}
            error={errors.title}
            required={true}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Reason"
            name="reason"
            value={values.reason}
            error={errors.reason}
            required={true}
            multiline
            rows={4}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Button variant="text" size="large" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button loading={loading} size="large" type="submit" fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.admin,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(SubsHoldForm);
