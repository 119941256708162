import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = (props) => {
  const { name, label, value, onChange, error } = props;

  const handleChg = (newVal) => {
    onChange &&
      onChange({
        target: {
          value: newVal,
          name: name,
        },
      });
  };

  return (
    <div className="d-grid">
      {label && <span className="p-10 fw-5 fs-18 gray-color">{label}</span>}
      <Editor
        name={name}
        apiKey="7krwibryd2mkdlxamyevn5wpewwmhk1kdplo90skl4h9mdu0"
        onEditorChange={handleChg}
        value={value}
        oninti
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {error && (
        <div className="pl-10 pr-10 pb-10">
          <span className="error-color fs-14">{error}</span>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
