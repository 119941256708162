import { CloseRounded } from "@mui/icons-material";
import { Divider, Grid, IconButton } from "@mui/material";
import React from "react";

const ViewReq = (props) => {
  const { setShowForm, viewData } = props;
  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      {viewData ? (
        <Grid container spacing={3} className="p-15 dark-color">
          <Grid item xs={12}>
            <div className="d-flex j-between a-center mb-20">
              <div>
                <h2 className="h fw-5 mb-5 border-left tx-upp fs-20 gray-color">
                  View Request
                </h2>
              </div>
              <div>
                <IconButton onClick={handleClose} size="small">
                  <CloseRounded />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">Unit Name:</span>
            <span>{viewData.unitName}</span>
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">Request Type:</span>
            {viewData.reqType}
          </Grid>
          <Grid item xs={12} md={3} className="d-grid a-center">
            <span className="tx-xs tx-upp mb-5 gray-color ">Status:</span>
            {viewData.status}
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">Request User:</span>
            <span>{viewData.createUser && viewData.createUser.fullName}</span>
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            {viewData.rcdType === 1 ? (
              <>
                <span className="tx-xs tx-upp mb-5 gray-color">
                  Request Date:
                </span>
                <span>{viewData.createDate}</span>
              </>
            ) : (
              <>
                <span className="tx-xs tx-upp mb-5 gray-color">
                  Resolution Deadline:
                </span>
                <span>{viewData.resDeadline}</span>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              Request Complete User:
            </span>
            <span>{viewData.cmpltUser && viewData.cmpltUser.fullName}</span>
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              Request Complete Date:
            </span>
            <span>{viewData.cmpltDate}</span>
          </Grid>
          <Grid item xs={12} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">Title:</span>
            <span>{viewData.title}</span>
          </Grid>

          <Grid item xs={12}>
            <span className="tx-xs tx-upp mb-5 gray-color">Message:</span>
            <Divider />
            <p className="p pre-wrap">{viewData.msg}</p>
          </Grid>
          <Grid item xs={12} className="d-grid">
            <Divider />
            <span className="tx-xs tx-upp mb-5 mt-10 gray-color fw-6 fs-italic">
              View File:
            </span>
            {viewData.reqFiles.map((file, i) => (
              <a
                key={i}
                href={file.fileSrc}
                className="mb-10 link fs-14"
                target="_blank"
                rel="noreferrer"
              >
                {file.name}
              </a>
            ))}
          </Grid>
        </Grid>
      ) : (
        <span>Loading...</span>
      )}
    </>
  );
};

export default ViewReq;
