import { PersonRemoveRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../../../layout";
import ViewOwner from "../../../Owner/ViewOwner";
import { connect } from "react-redux";
import * as actions from "../../../../../api/actions/actions";
import { controller, endpoints } from "../../../../../api/actions/api";
import { InputCheck } from "../../../../../controls";

const Owners = (props) => {
  const { _post, unit, _getById, _authUser } = props;
  const [viewDtls, setViewDtls] = useState();
  const [showDtls, setShowDtls] = useState(false);
  const [owners, setOwners] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    unit && setOwners([...unit.unitOwners]);

    return () => {
      setOwners([]);
    };
  }, [unit]);

  const handleDlt = (val) => {
    if (val && owners.length > 1) {
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "Are you sure!",
        subTitle: "You want to remove this owner from this unit?",
        onConfirm: () => {
          const onComplete = (res) => {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Removed Successfully",
                subTitle: res.msg,
              });
              setOwners((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else {
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
            }
          };
          _post(
            endpoints.Unit + controller.RmvOwnerFromUnit,
            { id: unit.id, userId: val.ownerId },
            null,
            onComplete
          );
        },
      });
    } else {
      setAlert({
        isOpen: true,
        type: "warning",
        title: "Warning",
        subTitle:
          "You can't delete this owner. Because this is the only one owner of this unit.",
      });
    }
  };

  const handleView = (props) => {
    setViewDtls(props.owner);
    setShowDtls(true);
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View Details">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>

      {_authUser && _authUser.role !== 1 && (
        <Tooltip arrow title="Remove Ownership">
          <IconButton
            onClick={() => handleDlt(props)}
            size="small"
            color="error"
          >
            <PersonRemoveRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const Header = () => (
    <div className="mb-5">
      <h2 className="h fs-18 fw-5 tx-upp gray-color">Owners of this Unit</h2>
    </div>
  );

  const handleContact = (e, id) => {
    const onComplete = (res) => {
      res.status === 200 &&
        setOwners((x) => [
          ...x.map((j) =>
            j.id === res.result.id
              ? { ...j, isContactOwner: res.result.isContactOwner }
              : j
          ),
        ]);
    };

    _getById(endpoints.Unit + controller.ContactOwner, id, null, onComplete);
  };

  const ContactOwner = (val) => (
    <InputCheck
      disabled={_authUser && _authUser.role === 1 ? true : false}
      onChange={(e) => handleContact(e, val.id)}
      checked={val.isContactOwner}
    />
  );

  return (
    <>
      <Table
        shadow={false}
        ActionComp={ActionComp}
        HeaderComp={Header}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          {
            id: "contactOwner",
            label: "Contact Owner",
          },
          { id: "name", label: "Owner" },
          { id: "email", label: "Email" },
        ]}
        tblData={owners.map((owner) => {
          return {
            ...owner,
            contactOwner: owner.id && ContactOwner(owner),
            status:
              owner.owner.isActive === 1 ? (
                <span className="success-color bg-successXS status">
                  Active
                </span>
              ) : (
                <span className="error-color bg-errorSM status">Inactive</span>
              ),
            name: owner.owner.fullName,
            email: (
              <a className="link" href={`mailto:${owner.owner.email}`}>
                {owner.owner.email}
              </a>
            ),
          };
        })}
      />

      {/* View Details */}
      <Dialog show={showDtls} onClose={false} maxWidth="md">
        <ViewOwner {...{ setShowForm: setShowDtls, viewData: viewDtls }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Owners);
