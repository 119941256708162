import { Container, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, InputRadio } from "../../../controls";
import { Alert, Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";

const DefHoa = (props) => {
  const { _hoas, _getById, _defHoa } = props;
  const [hoas, setHoas] = useState([]);
  const [defHoa, setDefHoa] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    // Set Hoa list in the state.
    if (_hoas.length > 0) {
      setHoas([
        ..._hoas.map((j) => {
          return {
            id: j.id,
            name: j.hoaName,
          };
        }),
      ]);
      // Set Default Hoa
      setDefHoa(_defHoa ? _defHoa.id : "");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoas]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const onComplete = (res) => {
      setLoading(false);
      if (res.status === 200) {
        setAlert({
          isOpen: true,
          type: "success",
          title: "Success",
          subTitle: "Successfully set as a default HOA",
          onClick: () => window.location.reload(),
        });
      } else
        setAlert({
          isOpen: true,
          type: "error",
          title: "Error",
          subTitle: "Something is wrong. Please try again.",
        });
    };

    _getById(
      endpoints.Hoa + controller.SetDefHoa,
      defHoa,
      actions.ActionType.DefHoa,
      onComplete
    );
  };

  const handleInpChange = (e) => {
    setDefHoa(parseInt(e.target.value));
  };
  return (
    <Container maxWidth="md">
      <Form noValidate onSubmit={handleSubmit}>
        <Paper className="br-8 shadow-xs p-20">
          <Box className="tx-center mb-20">
            <Typography variant="subtitle1" className="fs-22 dark-color fw-5">
              Set Default HOA
            </Typography>
            <div className="border-bottom w-60 m-auto" />
          </Box>
          <Box
            sx={{
              ml: { md: "80px" },
              maxHeight: "calc(100vh - 290px)",
              overflow: "auto",
            }}
          >
            <InputRadio
              options={hoas}
              value={defHoa}
              row={false}
              onChange={handleInpChange}
            />
          </Box>
          <Box className="tx-center mt-30">
            <Button loading={loading} type="submit" variant="text" size="large">
              Submit as a Default HOA
            </Button>
          </Box>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoas: state.storeData.hoas,
  _defHoa: state.storeData.defHoa,
});

const mapActiontoProps = {
  _get: actions.get,
  _getById: actions.getById,
  _set: actions.set,
};

export default connect(mapStateToProps, mapActiontoProps)(DefHoa);
