import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Input, InputAuto } from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { useNavigate, useParams } from "react-router-dom";

const AddEditHoa = (props) => {
  const { _getById, _post, _put, _get } = props;
  const { HoaMdl } = Model();
  const navigate = useNavigate();
  const { id } = useParams();
  const [hideZip, setHideZip] = useState(true);
  const [loading, setLoading] = useState(false);
  const { Countries } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(HoaMdl);

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200 && setValues({ ...res.result });
    };
    id && _getById(endpoints.Hoa + controller.GetById, id, null, onComplete);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.hoaName = values.hoaName !== "" ? "" : "Hoa Name is required.";
    temp.email = values.email !== "" ? "" : "Email is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";
    temp.address1 = values.address1 !== "" ? "" : "Address 1 is required.";
    temp.city = values.city !== "" ? "" : "City is required.";
    temp.country = values.country !== "" ? "" : "Country is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `HOA ${id ? "Edited" : "Added"} Successfully.`,
            onClick: () => navigate("/my_account/manage-hoa/"),
          });
          // Get Updated List
          _get(
            endpoints.Hoa + controller.GetAuthUserHoas,
            actions.ActionType.Hoa
          );
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        _post(
          endpoints.Hoa + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.Hoa + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    if (values.country === "JM") {
      setHideZip(false);
      setValues((x) => ({ ...x, zip: "" }));
    } else setHideZip(true);
    return () => {
      setHideZip(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country]);

  return (
    <>
      <Paper className="p-15">
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <div className="tx-center p-20 mt-20 mb-10 w-100pr dark-color">
              <h2 className="h fw-5 mb-5 tx-upp">{id ? "Edit" : "Add"} Hoa</h2>
              <div className="border-bottom w-60 m-auto" />
            </div>
            <Grid item xs={12} md={8}>
              <Input
                label="HOA Name"
                name="hoaName"
                value={values.hoaName}
                error={errors.hoaName}
                onChange={handleInpChg}
                inputProps={{ maxLength: 200 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="EIN"
                name="ein"
                value={values.ein}
                error={errors.ein}
                onChange={handleInpChg}
                inputProps={{ maxLength: 50 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Email"
                name="email"
                value={values.email}
                error={errors.email}
                onChange={handleInpChg}
                inputProps={{ maxLength: 300 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Phone"
                name="phone"
                value={values.phone}
                error={errors.phone}
                onChange={handleInpChg}
                inputProps={{ maxLength: 50 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Fax"
                name="fax"
                value={values.fax}
                error={errors.fax}
                onChange={handleInpChg}
                inputProps={{ maxLength: 50 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputAuto
                label="Country"
                name="country"
                onChange={handleInpChg}
                value={values.country}
                error={errors.country}
                fullWidth
                options={Countries}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                label="City"
                name="city"
                onChange={handleInpChg}
                value={values.city}
                error={errors.city}
                fullWidth
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                label={!hideZip ? "Parish" : "State"}
                name="state"
                value={values.state}
                error={errors.state}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            {hideZip && (
              <Grid item xs={12} md={3}>
                <Input
                  label="ZIP"
                  name="zip"
                  value={values.zip}
                  error={errors.zip}
                  onChange={handleInpChg}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Input
                label="Address 1"
                name="address1"
                multiline
                rows={2}
                onChange={handleInpChg}
                value={values.address1}
                error={errors.address1}
                fullWidth
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Address 2"
                name="address2"
                multiline
                rows={2}
                onChange={handleInpChg}
                value={values.address2}
                error={errors.address2}
                fullWidth
                inputProps={{ maxLength: 500 }}
              />
            </Grid>

            <Grid item xs={12} className="tx-right">
              <Button loading={loading} size="large" type="submit">
                {values.id !== 0 ? "Save Changes" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _get: actions.get,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditHoa);
