import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputMultFile,
  InputSwitch,
  Select,
} from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../../api/actions/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const AddEditPay = (props) => {
  const {
    _post,
    _put,
    _authUser,
    _getById,
    _hoa,
    setShowForm,
    setAlert,
    updData,
    setPays,
    unit,
    invDtls,
  } = props;
  const navigate = useNavigate();
  const [invs, setInvs] = useState([]);
  const [units, setUnits] = useState([]);
  const [owners, setOwners] = useState([]);
  const [invAmt, setInvAmt] = useState(0.0);
  const [onwerAccBal, setOnwerAccBal] = useState();
  const [loading, setLoading] = useState(false);
  const { PaymentMdl } = Model();
  const { PayMethods, PayType } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    handleInpDate,
    handleFile,
  } = UseForm(PaymentMdl);

  useEffect(() => {
    invDtls &&
      setValues((x) => ({
        ...x,
        unitId: invDtls.unitId,
        invId: invDtls.id,
      }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invDtls, units]);

  useEffect(() => {
    unit && setValues((x) => ({ ...x, unitId: unit.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  useEffect(() => {
    if (values.payType === 1 && values.unitId) {
      _getById(
        endpoints.Unit + controller.GetCredits,
        values.unitId,
        null,
        (res) => onComplete(res)
      );

      const onComplete = (res) => {
        if (res.status === 200) {
          var totalCredit = res.result
            .map((j) => j.balance)
            .reduce((x, y) => x + y, 0);

          if (totalCredit > 0) {
            setValues((x) => ({
              ...x,
              amount: totalCredit > invAmt ? invAmt : totalCredit,
            }));
            setErrors((x) => ({ ...x, payType: "", amount: "" }));
          } else setErrors((x) => ({ ...x, payType: "No Credit", amount: "" }));
          setOnwerAccBal(totalCredit);
        }
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payType, unit]);

  useEffect(() => {
    if (_hoa.id) {
      // Get Unit List
      _getById(
        endpoints.Unit + controller.GetInvIssuedUnitsByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 &&
            setUnits([
              ...res.result.map((j) => {
                var defOwner = j.unitOwners[0] && j.unitOwners[0].owner;
                return {
                  ...j,
                  id: j.id,
                  label: `${j.title} ${
                    defOwner ? `(${defOwner.fullName})` : ""
                  } `,
                };
              }),
            ]);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    // Get Invoice List
    if (values.unitId) {
      _getById(
        endpoints.Invoice +
          (updData ? controller.GetByHoa : controller.GetUnpaidInvsByUnit),
        updData ? _hoa.id : values.unitId,
        null,
        (res) =>
          res.status === 200 &&
          setInvs([
            ...res.result
              .filter((j) => j.invType !== 3)
              .map((j) => {
                var firstPayment = j.invPayments
                  .map(
                    (j) =>
                      j.amount -
                      j.paidAmt +
                      j.invLateFees
                        .map((y) => y.amount - y.paidAmt)
                        .reduce((x, y) => x + y, 0)
                  )
                  .reduce((x, y) => x + y, 0);
                return {
                  ...j,
                  id: j.id,
                  label: `(${j.id}) ${j.title}`,
                  subtitle: `${
                    j.category && j.category.name
                  } || $${firstPayment}`,
                };
              }),
          ])
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.unitId]);

  useEffect(() => {
    if (values.invId && invs.length > 0) {
      var inv = invs.find((j) => j.id === values.invId);
      if (inv) {
        setInvAmt(
          inv.invPayments
            .map(
              (j) =>
                j.amount -
                j.paidAmt +
                j.invLateFees
                  .map((x) => x.amount - x.paidAmt)
                  .reduce((x, y) => x + y, 0)
            )
            .reduce((x, y) => x + y, 0)
        );
        inv.unit.unitOwners &&
          setOwners([
            ...inv.unit.unitOwners.map((j) => {
              return {
                id: j.owner.id,
                label: j.owner.fullName,
                totalAccBal: j.owner.totalAccBal,
              };
            }),
          ]);
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.invId, invs]);

  useEffect(() => {
    updData && setValues((x) => ({ ...x, ...updData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  const validateForm = () => {
    let temp = {};
    temp.unitId = values.unitId !== "" ? "" : "Unit is required.";
    temp.invId = values.invId !== "" ? "" : "Invoice is required.";
    if (values.payType === 2) {
      temp.method = values.method !== "" ? "" : "Method is required.";
      temp.ownerId = values.ownerId !== "" ? "" : "Owners is required.";
      temp.amount = values.amount !== "" ? "" : "Amount is required.";
    }
    temp.payDate = values.payDate !== "" ? "" : "Pay date is required.";
    temp.payType =
      values.payType !== ""
        ? (values.payType === 1 && onwerAccBal > 0) || values.payType === 2
          ? ""
          : "No Credit"
        : "Pay type is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          if (setPays && res.result.id) {
            updData
              ? setPays((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setPays((x) => [res.result, ...x]);
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Cash ${updData ? "Update" : "Added"} Successfully.`,
            onClick: () => !unit && navigate("/my_account/payments"),
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
        setShowForm(false);
      };
      if (values.id === 0) {
        values.hoaId = _hoa.id;
        values.userId = _authUser.id;
        _post(
          endpoints.Payment + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.Payment + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const handleInpUnit = (e) => {
    handleInpChg(e);
    setOnwerAccBal();
    setValues((x) => ({
      ...x,
      invId: "",
      ownerId: "",
      method: "",
      amount: "",
      payType: "",
    }));
  };

  const handleInpInv = (e) => {
    handleInpChg(e);
    setValues((x) => ({
      ...x,
      ownerId: "",
      method: "",
      amount: "",
      payType: "",
    }));
    setOnwerAccBal();
  };

  const handleInpPayType = (e) => {
    handleInpChg(e);
    setValues((x) => ({
      ...x,
      ownerId: "",
      method: "",
    }));
    setOnwerAccBal();
  };

  const handleInpOwner = (e) => {
    handleInpChg(e);
    setValues((x) => ({ ...x, method: "", amount: "" }));
    setOnwerAccBal();
  };

  const handleInpPayment = (e) => {
    if (onwerAccBal !== undefined) {
      var amt = parseInt(e.target.value);
      onwerAccBal < amt
        ? setErrors((x) => ({
            ...x,
            amount: "You can't enter more then your account balance.",
          }))
        : handleInpChg(e);
    } else {
      handleInpChg(e);
    }
  };

  return (
    <div className="p-15 bg-white">
      <Form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <div className="tx-center p-20 mt-30 mb-10 w-100pr dark-color">
            <h2 className="h fw-5 mb-5 tx-upp">
              {updData ? "Update" : "Add"} Cash
            </h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-60" />
            </div>
          </div>

          {onwerAccBal !== undefined && (
            <Grid item xs={12}>
              <span className="gray-color">Credit Balance: ${onwerAccBal}</span>
            </Grid>
          )}
          <Grid item xs={12} md={values.payType === 2 ? 6 : 12}>
            <InputAuto
              label="Unit"
              name="unitId"
              value={values.unitId}
              error={errors.unitId}
              options={units}
              disabled={values.id || unit || invDtls ? true : false}
              onChange={handleInpUnit}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputAuto
              label="Invoice"
              name="invId"
              value={values.invId}
              error={errors.invId}
              options={invs}
              disabled={values.id || !values.unitId || invDtls ? true : false}
              onChange={handleInpInv}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label="Pay From"
              name="payType"
              value={values.payType}
              error={errors.payType}
              options={PayType}
              disabled={values.id || !values.invId ? true : false}
              onChange={handleInpPayType}
              fullWidth
            />
          </Grid>

          {values.payType && values.payType === 2 && (
            <>
              <Grid item xs={12} md={6}>
                <Select
                  label="Owner"
                  name="ownerId"
                  disabled={
                    (owners.length <= 0 ? true : false) || values.id
                      ? true
                      : false
                  }
                  value={values.ownerId}
                  error={errors.ownerId}
                  options={owners}
                  onChange={handleInpOwner}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label="Pay Method"
                  name="method"
                  disabled={!values.ownerId || updData ? true : false}
                  value={values.method}
                  error={errors.method}
                  options={PayMethods}
                  onChange={handleInpChg}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Reference"
                  name="refe"
                  value={values.refe}
                  error={errors.refe}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleInpChg}
                  fullWidth
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <Input
              label="Amount"
              name="amount"
              value={values.amount}
              error={errors.amount}
              disabled={values.id || values.payType !== 2 ? true : false}
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 14))}
              onChange={handleInpPayment}
              fullWidth
            />
            <span className="gray-color fs-12">Due Amount: ${invAmt}</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputDate
              label="Pay Date"
              name="payDate"
              value={values.payDate}
              error={errors.payDate}
              className="w-100pr"
              onChange={(date) =>
                handleInpDate("payDate", new Date(date).toLocaleDateString())
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              multiline
              rows={3}
              label="Note"
              name="note"
              value={values.note}
              error={errors.note}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          {!values.id && (
            <Grid item xs={12} md={6}>
              <InputMultFile
                label="Upload File"
                name="files"
                src={values.files}
                values={values.files}
                onChange={handleFile}
                rmv={(file) =>
                  setValues((x) => ({
                    ...x,
                    files: x.files.filter(
                      (j) => j.lastModified !== file.lastModified
                    ),
                  }))
                }
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <InputSwitch
              label="Email Recept"
              fontSize="15px"
              name="emailReceipt"
              checked={values.emailReceipt}
              onChange={handleInpCheck}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: { md: "flex", xs: "grid" } }}>
            <Button
              variant="text"
              size="large"
              onClick={handleClose}
              sx={{
                mr: { md: "10px", xs: "0px" },
                mb: { md: "0px", xs: "10px" },
              }}
              fullWidth
            >
              Cancel
            </Button>
            <Button loading={loading} size="large" type="submit" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditPay);
