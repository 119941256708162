import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";

import OrgDtlComp from "./OrgDtlComp";
import { Grid, Tab, Tabs } from "@mui/material";
import Widget from "./Widget";
import { Select } from "../../../controls";
import {
  AdminPanelSettingsRounded,
  ApartmentRounded,
  ContactsRounded,
  GroupRounded,
} from "@mui/icons-material";
import Units from "./Units/Units";
import Owners from "./Owners/Owners";
import Vendors from "./Vendors/Vendors";
import Users from "./Users/Users";
import Payments from "./Payments";
import Invoices from "./Invoice/Invoices";

const ViewOrg = (props) => {
  const { _getById } = props;
  const [activeTab, setActiveTab] = useState("payments");
  const { id } = useParams();
  const [org, setOrg] = useState();
  const [hoas, setHoas] = useState([]);
  const [selHoa, setSelHoa] = useState("");
  const [units, setUnits] = useState([]);
  const [hoasUnits, setHoasUnits] = useState([]);
  const [owners, setOwners] = useState([]);
  const [hoasOwners, setHoasOwners] = useState([]);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [hoasVendors, setHoasVendors] = useState([]);
  const [payments, setPayments] = useState([]);
  const [invs, setInvs] = useState([]);

  useEffect(() => {
    if (id) {
      // Get Org's
      _getById(
        endpoints.Org + controller.GetById,
        id,
        null,
        (res) => res.status === 200 && setOrg(res.result)
      );
      // Get HOA's
      _getById(
        endpoints.Hoa + controller.GetAllById,
        id,
        null,
        (res) =>
          res.status === 200 &&
          setHoas(res.result.map((j) => ({ ...j, label: j.hoaName })))
      );

      // Get Units
      _getById(
        endpoints.Unit + controller.GetAllByOrg,
        id,
        null,
        (res) => res.status === 200 && setUnits([...res.result])
      );

      // Get Owners
      _getById(
        endpoints.Owner + controller.GetAllByOrg,
        id,
        null,
        (res) => res.status === 200 && setOwners([...res.result])
      );

      // Get Users
      _getById(
        endpoints.User + controller.GetByOrg,
        id,
        null,
        (res) => res.status === 200 && setUsers([...res.result])
      );

      // Get Vendors
      _getById(
        endpoints.Vendor + controller.GetAllByOrg,
        id,
        null,
        (res) => res.status === 200 && setVendors([...res.result])
      );

      // Get Payments
      _getById(
        endpoints.Subs + controller.GetAllPaymentsByOrg,
        id,
        null,
        (res) => res.status === 200 && setPayments([...res.result])
      );

      _getById(
        endpoints.Subs + controller.GetAllByOrg,
        id,
        null,
        (res) => res.status === 200 && setInvs([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Get Default HOA
  useEffect(() => {
    if (hoas && hoas.length > 0) {
      var defHoa = hoas.find((j) => j.defHoa);
     defHoa ? setSelHoa(defHoa.id) : setSelHoa(hoas[0].id);
    } else setSelHoa("");
    return () => {};
  }, [hoas]);

  useEffect(() => {
    setHoasUnits([...units.filter((j) => j.hoaId === selHoa)]);
    setHoasOwners([...owners.filter((j) => j.hoaId === selHoa)]);
    setHoasVendors([...vendors.filter((j) => j.hoaId === selHoa)]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selHoa]);

  const handleHoa = (e) => {
    setSelHoa(e.target.value);
  };

  const handleActive = (val, newVal) => {
    setActiveTab(newVal);
  };

  const DetailsContent = (active) => {
    switch (active) {
      case "payments":
        return <Payments {...{ payments }} />;
      case "invoices":
        return <Invoices {...{ invs }} />;
      case "units":
        return <Units units={hoasUnits} />;
      case "owners":
        return <Owners owners={hoasOwners} />;
      case "vendors":
        return <Vendors vendors={hoasVendors} />;
      case "users":
        return <Users users={users} />;
      default:
        break;
    }
  };

  return (
    <div>
      {org && <OrgDtlComp {...{ org }} />}
      <Grid container spacing={3} className="mt-20">
        <Grid item xs={12}>
          <span className="border-left fs-20 fw-5 gray-color  tx-upp">
            Total in this Organization
          </span>
        </Grid>
        <Grid item xs={12} md={3}>
          <Widget
            url=""
            icon={<ApartmentRounded className="fs-32" />}
            title={units.length}
            subtitle="Units"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Widget
            url=""
            icon={<GroupRounded className="fs-32" />}
            title={owners.length}
            subtitle="Owner"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Widget
            url=""
            icon={<AdminPanelSettingsRounded className="fs-32" />}
            title={users.length}
            subtitle="User"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Widget
            url=""
            icon={<ContactsRounded className="fs-28" />}
            title={vendors.length}
            subtitle="Vendor"
          />
        </Grid>
        <Grid item xs={12} className="mt-30">
          <div className="d-flex flex-wrap g-20 a-center j-between">
            <div className="d-grid g-5 border-left ">
              <span className="fs-20 fw-5 gray-color  tx-upp">Details</span>
              <span className="fs-12 tx-upp gray-color fw-5">
                Total HOA: {hoas.length}
              </span>
            </div>
            <div>
              <Select
                sx={{ minWidth: "200px" }}
                variant="outlined"
                name="hoa"
                fullWidth
                value={selHoa}
                onChange={handleHoa}
                options={hoas}
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            className="mb-20"
            style={{ borderBottom: "1px solid var(--grayXS-color)" }}
          >
            <Tabs
              value={activeTab}
              onChange={handleActive}
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                label={`Payments (Paid: $${payments
                  .filter((j) => j.status === 1)
                  .map((j) => j.amount)
                  .reduce((x, y) => x + y, 0)})`}
                value="payments"
              />
              <Tab
                label={`Invoices (Due: $${invs
                  .filter((j) => j.status === 1)
                  .map((j) => j.amount)
                  .reduce((x, y) => x + y, 0)})`}
                value="invoices"
              />
              <Tab label={`Units (${hoasUnits.length})`} value="units" />
              <Tab label={`Owner (${hoasOwners.length})`} value="owners" />
              <Tab label={`Vendors (${hoasVendors.length})`} value="vendors" />
              <Tab label="Org. Users" value="users" />
            </Tabs>
          </div>
          {DetailsContent(activeTab)}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewOrg);
