import React, { Fragment, useState } from "react";
import { IconButtonWithText } from "../../controls";
import { useNavigate } from "react-router-dom";
import "./Navbar.scss";

const Navbar = ({ menu = [] }) => {
  const [active, setActive] = useState("Home");
  const navigate = useNavigate();

  const handleNavigate = (val) => {
    navigate(`${val.url}`);
    setActive(val.name);
  };

  return (
    <>
      <div className="navbar_container">
        <div className="navbar">
          {menu.map((item, i) => (
            <Fragment key={i}>
              <IconButtonWithText
                onClick={() => handleNavigate(item)}
                className={active === item.name ? "active" : ""}
                text={item.name}
                icon={item.icon}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default Navbar;
