import { VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog, Snackbar, Table } from "../../../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../../../api/actions/actions";
import { AppContext } from "../../../../../App";
import { controller, endpoints } from "../../../../../api/actions/api";
import { DropLi } from "../../../../../hooks";
import ViewPayment from "../../../../dashboard/Payment/ViewPayment";

const Payments = (props) => {
  const { _getById, unitId } = props;
  const { PayType } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [viewReq, setViewReq] = useState(false);
  const [viewData, setViewData] = useState();
  const [pays, setPays] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState({
    show: false,
    type: "success",
    msg: "",
  });

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setPays([...res.result]);
    };
    if (unitId) {
      _getById(
        endpoints.Payment + controller.GetAllByUnit,
        unitId,
        null,
        onComplete
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  const handleView = (val) => {
    setViewData(val);
    setViewReq(true);
  };

  const ActionComp = (props) => (
    <div className="d-flex a-center">
      <Tooltip arrow title="View Payment">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {props.isRev && (
        <span className="bg-errorSM error-color status">Reversed</span>
      )}
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => <></>}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "id", label: "Payment Id" },
          { id: "invId", label: "Invoice Id" },
          { id: "payFrom", label: "Pay From" },
          { id: "ownerName", label: "Owner" },
          { id: "amt", label: "Amount" },
          { id: "refe", label: "Reference" },
          { id: "payDate", label: "Pay Date" },
        ]}
        tblData={pays.map((pay, i) => {
          var payType = PayType.find((j) => j.id === pay.payType);
          return {
            ...pay,
            amt: "$" + pay.amount.toLocaleString(),
            payFrom: payType && payType.label,
            ownerName: pay.owner && pay.owner.fullName,
            payor: pay.payor && pay.payor.title,
            receipt: <span className="tx-upp">{`${pay.emailReceipt}`}</span>,
          };
        })}
      />

      {/* View Payments */}
      <Dialog show={viewReq} onClose={false} maxWidth="md">
        <ViewPayment {...{ setShowForm: setViewReq, viewData }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      <Snackbar {...{ snackbar, setSnackbar }} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Payments);
