import { ClearRounded, EditRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../../controls";
import { Alert, Dialog, Table } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import AddEditPlan from "./AddEditPlan";
import { DropLi } from "../../../hooks";

const InvPlans = (props) => {
  const { _getById, _defHoa, _delete } = props;
  const { InvPeriod } = DropLi();
  const [plans, setPlans] = useState([]);
  const [updData, setUpdData] = useState();
  const { setLoader } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_defHoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 && setPlans(res.result);
      };
      _getById(
        endpoints.InvPlan + controller.GetByHoa,
        _defHoa.id,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_defHoa]);

  const handleAddEdit = (props) => {
    setUpdData(props);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure?",
      subTitle: "You want to delete this plan",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setPlans((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.InvPlan, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="Edit Plan">
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete Plan">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );
  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAddEdit()}>
        Add Term
      </Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "name", label: "Title" },
          { id: "dura", label: "Duration" },
          { id: "createDate", label: "Create Date" },
        ]}
        tblData={plans.map((obj) => {
          var period = InvPeriod.find((j) => j.id === obj.periodic);
          return {
            ...obj,
            dura: `${obj.duration} ${period && period.label}`,
          };
        })}
      />

      {/* Send Eamil */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditPlan {...{ setShowForm: setShowForm, updData, setPlans }} />
        </Dialog>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _defHoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(InvPlans);
