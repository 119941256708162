import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { Alert, Dialog } from "../../../layout";
import AddEditPay from "../Payment/AddEditPay";
import UnitHeader from "./UnitHeader";
import UnitWidget from "./UnitWidget/UnitWidget";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { useEffect } from "react";

const Units = (props) => {
  const { _getById, _hoa, _authUser } = props;
  const [showPay, setShowPay] = useState(false);
  const [units, setUnits] = useState([]);
  const [filterUnits, setFilterUnits] = useState([]);
  const { setLoader } = useContext(AppContext);
  const [unit, setUnit] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res.status === 200) {
          setUnits(res.result);
          setFilterUnits(res.result);
        }
      };
      _getById(endpoints.Unit + controller.GetByHoa, _hoa.id, null, onComplete);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  const handlePayment = (val) => {
    setUnit(val);
    setShowPay(!showPay);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UnitHeader
            {...{
              units,
              filterUnits,
              setFilterUnits,
              _authUser,
              _hoa,
              setAlert,
            }}
          />
        </Grid>
        {filterUnits.map((unit, i) => (
          <Grid item xs={12} md={3} key={i}>
            <UnitWidget
              {...{ handlePayment, unit, setUnits: setFilterUnits, setAlert }}
            />
          </Grid>
        ))}
        {!filterUnits.length && (
          <span className="p-20 mt-40 fs-24 gray-color">No Units</span>
        )}
      </Grid>

      {showPay && (
        <Dialog show={showPay} onClose={false} maxWidth="sm">
          <AddEditPay {...{ setShowForm: setShowPay, setAlert, alert, unit }} />
        </Dialog>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _set: actions.set,
};

export default connect(mapStateToProps, mapActionToProps)(Units);
