import React, { useState, useEffect } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { Input } from "../controls";
import { Box } from "@mui/system";

export default function Table(props) {
  const {
    tblHead = [],
    tblData = [],
    FooterRow,
    showSearch = true,
    HeaderComp = <div></div>,
    ActionComp = <div></div>,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [_tblData, _setTblData] = useState(tblData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSerach = (e) => {
    _setTblData([
      ...tblData.filter((j) =>
        Object.keys(j).some((x) =>
          String(j[x]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      ),
    ]);
  };

  useEffect(() => {
    _setTblData(tblData);
    return () => {};
  }, [tblData]);

  return (
    <>
      <Box className="d-flex j-between a-center flex-wrap mb-10">
        <Box
          className="d-flex"
          sx={{
            minWidth: { xs: "100%", lg: "250px" },
            mb: { xs: "10px", lg: "0px" },
          }}
        >
          <HeaderComp />
        </Box>
        {showSearch && (
          <Grid sx={{ width: { xs: "100%", lg: "250px" } }}>
            <Input
              label="Search"
              name="search"
              size="small"
              variant="outlined"
              onChange={handleSerach}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <SearchRoundedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Box>

      {/* Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 184px)" }}>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                {tblHead.map((item, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      fontSize: "16px",
                      backgroundColor: "var(--white-color)",
                      color: "var(--primary-color)",
                      minWidth: "80px",
                      padding: "16px 8px 16px 16px",
                    }}
                  >
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_tblData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, i) => {
                  return (
                    <TableRow hover key={i}>
                      {tblHead.map((headItem, index) => {
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              fontSize: "16px",
                              padding: "6px 16px 6px 16px",
                              whiteSpace: "nowrap",
                              color: "var(--gray-color)",
                            }}
                          >
                            {headItem.id === "actions" ? (
                              <ActionComp {...item} />
                            ) : (
                              item[headItem.id]
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              <TableRow>{FooterRow && <FooterRow />}</TableRow>
            </TableBody>
          </MuiTable>
          {_tblData.length <= 0 && (
            <Typography
              variant="h6"
              className="tx-center p-30 tx-upp gray-color"
            >
              Data not avaiable
            </Typography>
          )}
        </TableContainer>
        {_tblData.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            labelRowsPerPage="Row"
            count={_tblData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
}
