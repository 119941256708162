import React, { useContext, useEffect, useState } from "react";
import { Table } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { Box, IconButton } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";

const OwnerRep = (props) => {
  const { _getById, _hoa } = props;
  const { setLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const [tblData, setTblData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [invs, setInvs] = useState([]);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    setCsvHeader([
      { label: "Unit", key: "unit" },
      { label: "Owner", key: "owner" },
      { label: "Address", key: "address" },
      { label: "Past Due", key: "pastDue" },
      { label: "Unit Balance", key: "unitBal" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      _getById(endpoints.Unit + controller.GetByHoa, _hoa.id, null, (res) => {
        setLoader(false);
        setUnits(res.result);
      });

      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 &&
            setInvs(res.result.filter((j) => j.invType !== 3));
        }
      );
    }

    return () => {
      setUnits([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    if (units && invs) {
      setTblData([
        ...units.map((unit) => {
          var _unitInvs = invs.filter((j) => j.unitId === unit.id);
          var _pastDue = _unitInvs
            .map((j) => j.invPayments)
            .flat()
            .map(
              (j) =>
                j.amount -
                j.paidAmt +
                j.invLateFees
                  .map((x) => x.amount - x.paidAmt)
                  .reduce((x, y) => x + y, 0)
            )
            .reduce((x, y) => x + y, 0);
          var _unitInvAmt = _unitInvs
            .map((j) => j.amount)
            .reduce((x, y) => x + y, 0);
          var _defOwner = unit.unitOwners[0] && unit.unitOwners[0].owner;
          return {
            unit: unit.title,
            owners: _defOwner && _defOwner.fullName,
            phone: _defOwner && _defOwner.phone,
            address:
              _defOwner && _defOwner.address1 + " | " + _defOwner.address2,
            pastDue: _pastDue,
            unitBal: _pastDue + _unitInvAmt,
          };
        }),
      ]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units.length, invs.length]);

  const HeaderComp = () => (
    <div className="d-flex a-center flex-wrap">
      <IconButton size="small" className="link" onClick={() => navigate(-1)}>
        <ArrowBackRounded className="fs-24" />
      </IconButton>
      <div className="ml-20">
        <CSVLink
          data={tblData || []}
          headers={csvHeader}
          filename={`Delinquent Accounts (${new Date().toDateString()}).csv`}
          target="_blank"
          className="tx-upp tx-und-none primary-color bg-primaryXS p-10 fs-14 br-7"
        >
          Download CSV
        </CSVLink>
      </div>

      <Box sx={{ m: { xs: "10px 0px 0px 0px", md: "0px 0px 0px 15px" } }}>
        <h2 className="h fs-20 gray-color tx-upp">Delinquent Accounts</h2>
      </Box>
    </div>
  );

  return (
    <div className="pt-15">
      <Table
        shadow={false}
        ActionComp={() => <></>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "unit", label: "Unit" },
          { id: "owners", label: "Owners" },
          { id: "phone", label: "Phone" },
          { id: "address", label: "Address" },
          { id: "pastDue", label: "Past Due" },
          { id: "unitBal", label: "Unit Balance" },
        ]}
        tblData={tblData.map((data) => {
          return {
            ...data,
            unitBal: "$ " + data.unitBal.toLocaleString(),
            pastDue: "$ " + data.pastDue.toLocaleString(),
          };
        })}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(OwnerRep);
