import React, { useContext, useEffect, useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { AppContext } from "../../../App";
import RepNav from "./RepNav";
import { Table, Tabs } from "../../../layout";
import moment from "moment";
import { Grid } from "@mui/material";
import { InputCheck } from "../../../controls";
import { DropLi } from "../../../hooks";

const headerKey = [
  { id: "unitId", label: "Unit Id" },
  { id: "unitTitle", label: "Unit Title" },
  { id: "unitAddress1", label: "Unit Address1" },
  { id: "unitAddress2", label: "Unit Address2" },
  { id: "unitCity", label: "Unit City" },
  { id: "unitState", label: "Unit State" },
  { id: "unitZip", label: "Unit ZIP" },
  { id: "outstandingBal", label: "Outstanding Balance" },
  { id: "pastDueBal", label: "Past Due Balance" },
  { id: "bal", label: "Current Balance" },
  { id: "openViolations", label: "Violations" },
  { id: "req", label: "Requests" },
  { id: "fullName", label: "Owner Full Name" },
  { id: "ownerEmail", label: "Owner Email" },
  { id: "ownerPhone", label: "Owner Phone" },
  { id: "ownerAdd1", label: "Owner Address 1" },
  { id: "ownerAdd2", label: "Owner Address 2" },
  { id: "ownerCity", label: "Owner City" },
  { id: "ownerState", label: "Owner State" },
  { id: "ownerZip", label: "Owner ZIP" },
  { id: "ownerCountry", label: "Owner Country" },
  { id: "lastLogin", label: "Last Login" },
];

const OwnerContact = (props) => {
  const { _getById, _getByObj, _hoa } = props;
  const { Countries } = DropLi();
  const { setLoader } = useContext(AppContext);
  const [invs, setInvs] = useState([]);
  const [req, setReq] = useState([]);
  const [vio, setVio] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [units, setUnits] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [tblHead, setTblHead] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const [fields, setFields] = useState({
    unitId: false,
    unitTitle: false,
    unitAddress1: false,
    unitAddress2: false,
    unitCity: false,
    unitState: false,
    unitZip: false,
    outstandingBal: false,
    pastDueBal: false,
    bal: false,
    openViolations: false,
    req: false,
    fullName: false,
    ownerEmail: false,
    ownerPhone: false,
    ownerAdd1: false,
    ownerAdd2: false,
    ownerCity: false,
    ownerState: false,
    ownerZip: false,
    ownerCountry: false,
    lastLogin: false,
  });

  useEffect(() => {
    setCsvHeader([...tblHead.map((j) => ({ key: j.id, ...j }))]);
    return () => {
      setCsvHeader([]);
    };
  }, [tblHead]);

  useEffect(() => {
    setSelectedKey([...Object.keys(fields).filter((j) => fields[j])]);
    return () => {
      setSelectedKey([]);
    };
  }, [fields]);

  useEffect(() => {
    selectedKey &&
      setTblHead([
        ...headerKey.filter((j) => selectedKey.some((x) => x === j.id)),
      ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  useEffect(() => {
    setTblData([
      ...filteredData.map((j) => {
        var unitOwner = j.unitOwners.find((j) => j.isContactOwner);
        var owner = unitOwner && unitOwner.owner;
        var ownerCountry =
          owner && Countries.find((x) => x.id === owner.country);
        var _vio = vio.filter((y) => y.unitId === j.id).length;
        var _req = req.filter((y) => y.unitId === j.id).length;

        var payments = invs
          .filter((x) => x.unitId === j.id)
          .map((x) => x.invPayments)
          .flat();

        var osBal = payments
          .filter((j) => j.status !== 2)
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toLocaleString();

        var pastDue = payments
          .filter(
            (j) =>
              moment(j.issueDate, "MM/DD/YYYY").format("MM/YYYY") ===
                moment().subtract(1, "months").format("MM/YYYY") &&
              j.status !== 2
          )
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toLocaleString();

        var currentBal = payments
          .filter(
            (j) =>
              moment(j.issueDate, "MM/DD/YYYY").format("MM/YYYY") ===
                moment().format("MM/YYYY") && j.status !== 2
          )
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toLocaleString();

        return {
          ...j,
          unitId: j.id,
          unitTitle: j.title,
          unitAddress1: j.address1,
          unitAddress2: j.address2,
          unitCity: j.city,
          unitState: j.state,
          unitZip: j.zip,
          outstandingBal: osBal,
          pastDueBal: pastDue,
          bal: currentBal.toLocaleString(),
          openViolations: _vio,
          req: _req,
          fullName: owner && owner.fullName,
          ownerEmail: owner && owner.email,
          ownerPhone: owner && owner.phone,
          ownerAdd1: owner && owner.address1,
          ownerAdd2: owner && owner.address2,
          ownerCity: owner && owner.city,
          ownerState: owner && owner.state,
          ownerZip: owner && owner.zip,
          ownerCountry: ownerCountry && ownerCountry.name,
          lastLogin: owner && owner.lastLogin,
        };
      }),
    ]);
    return () => {
      setTblData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res.status === 200) {
          setUnits(res.result);
          setFilteredData(res.result);
        }
      };
      _getById(endpoints.Unit + controller.GetByHoa, _hoa.id, null, onComplete);

      // Get Invoices
      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setInvs([
            ...res.result.filter(
              (j) =>
                j.invType !== 3 && j.invPayments.some((x) => x.status === 1)
            ),
          ])
      );

      _getByObj(
        endpoints.Request + controller.GetByHoa,
        { id: _hoa.id, value: "1" },
        null,
        (res) =>
          res.status === 200 &&
          setReq(res.result.filter((j) => j.isComplete === 2))
      );

      _getByObj(
        endpoints.Request + controller.GetByHoa,
        { id: _hoa.id, value: "2" },
        null,
        (res) =>
          res.status === 200 &&
          setVio(res.result.filter((j) => j.isComplete === 2))
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    units &&
      setFilteredData([
        ...units.filter(
          (j) =>
            moment(j.createDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.createDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);

    return () => {
      setFilteredData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, sortDate]);

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFields((x) => ({ ...x, [name]: checked }));
  };

  return (
    <>
      <div className="mt-20 mb-10">
        <div className="mb-20">
          <Tabs header={[{ label: "Unit Fields" }, { label: "Owner Fields" }]}>
            {/* Unit Fields  */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="Unit ID"
                  name="unitId"
                  onChange={handleCheck}
                  checked={fields.unitId}
                />
                <InputCheck
                  label="Title"
                  name="unitTitle"
                  onChange={handleCheck}
                  checked={fields.unitTitle}
                />
                <InputCheck
                  label="Address 1"
                  name="unitAddress1"
                  onChange={handleCheck}
                  checked={fields.unitAddress1}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="Address 2"
                  name="unitAddress2"
                  onChange={handleCheck}
                  checked={fields.unitAddress2}
                />
                <InputCheck
                  label="City"
                  name="unitCity"
                  onChange={handleCheck}
                  checked={fields.unitCity}
                />
                <InputCheck
                  label="State"
                  name="unitState"
                  onChange={handleCheck}
                  checked={fields.unitState}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="Zip"
                  name="unitZip"
                  onChange={handleCheck}
                  checked={fields.unitZip}
                />
                <InputCheck
                  label="Open Violations"
                  name="openViolations"
                  onChange={handleCheck}
                  checked={fields.openViolations}
                />
                <InputCheck
                  label="Requests"
                  name="req"
                  onChange={handleCheck}
                  checked={fields.req}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="Outstanding Balance"
                  name="outstandingBal"
                  onChange={handleCheck}
                  checked={fields.outstandingBal}
                />
                <InputCheck
                  label="Past Due Balance"
                  name="pastDueBal"
                  onChange={handleCheck}
                  checked={fields.pastDueBal}
                />
                <InputCheck
                  label="Balance"
                  name="bal"
                  onChange={handleCheck}
                  checked={fields.bal}
                />
              </Grid>
            </Grid>
            {/* Owner Fields  */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="Full Name"
                  name="fullName"
                  onChange={handleCheck}
                  checked={fields.fullName}
                />
                <InputCheck
                  label="Email"
                  name="ownerEmail"
                  onChange={handleCheck}
                  checked={fields.ownerEmail}
                />
                <InputCheck
                  label="Phone"
                  name="ownerPhone"
                  onChange={handleCheck}
                  checked={fields.ownerPhone}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="Address1"
                  name="ownerAdd1"
                  onChange={handleCheck}
                  checked={fields.ownerAdd1}
                />
                <InputCheck
                  label="Address2"
                  name="ownerAdd2"
                  onChange={handleCheck}
                  checked={fields.ownerAdd2}
                />
                <InputCheck
                  label="City"
                  name="ownerCity"
                  onChange={handleCheck}
                  checked={fields.ownerCity}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="State"
                  name="ownerState"
                  onChange={handleCheck}
                  checked={fields.ownerState}
                />
                <InputCheck
                  label="ZIP"
                  name="ownerZip"
                  onChange={handleCheck}
                  checked={fields.ownerZip}
                />
                <InputCheck
                  label="Country"
                  name="ownerCountry"
                  onChange={handleCheck}
                  checked={fields.ownerCountry}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputCheck
                  label="Last Login"
                  name="lastLogin"
                  onChange={handleCheck}
                  checked={fields.lastLogin}
                />
              </Grid>
            </Grid>
          </Tabs>
        </div>
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "Custom Report",
            showTitle: false,
          }}
        />
      </div>
      <div>
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={() => (
            <div className="d-flex a-center flex-wrap">
              <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
                Custom Report
              </h2>
            </div>
          )}
          tblHead={tblHead}
          tblData={tblData}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(OwnerContact);
