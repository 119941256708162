import { createTheme } from "@mui/material/styles";

export default function CstmMui() {
  const prop = {
    primary: "#007bff",
    dark: "#3d4e5f",
    success: "#2dbf64",
    error: "#ff0000",
    warning: "#FFCC00",
    info: "#00e1ff",
    white: "#fff",
    whitemd: "#D1D5DB",
    black: "#000",
    grayXS: "#ebebeb",
    gray: "#7a7a7a",
    radius: "0.6rem",
  };
  const theme = createTheme({
    status: {
      danger: prop.danger,
    },
    palette: {
      default: {
        contrastText: prop.black,
      },
      primary: {
        main: prop.primary,
        contrastText: prop.white,
      },
      secondary: {
        main: prop.grayXS,
        contrastText: prop.gray,
      },
      white: {
        main: prop.white,
        contrastText: prop.gray,
      },
      whitemd: {
        main: prop.whitemd,
        contrastText: prop.gray,
      },
      error: {
        main: prop.error,
        contrastText: prop.white,
      },
      warning: {
        main: prop.warning,
        contrastText: prop.white,
      },
      info: {
        main: prop.info,
        contrastText: prop.white,
      },
      success: {
        main: prop.success,
        contrastText: prop.white,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "15px",
            border: "1px solid var(--grayXS-color)",
            boxShadow: "none",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "var(--primarySM-color)",
            color: "var(--primary-color)",
            backdropFilter: "blur(10px)",
          },
          arrow: {
            color: "var(--primarySM-color)",
            backdropFilter: "blur(10px)",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "var(--primarySM-color)",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              color: "var(--primary-color)",
              backgroundColor: "var(--primaryXS-color)",
              ".MuiListItemIcon-root": {
                color: "var(--primary-color)",
              },
            },
            "&.Mui-selected": {
              color: "var(--primaryDark-color)",
              backgroundColor: "var(--grayXS-color)",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "inherit",
            color: "var(--dark-color)",
            boxShadow: "none",
            backdropFilter: "0",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            "&:hover fieldset": {
              borderColor: `${prop.primary} !important`,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            fontWeight: "normal",
            letterSpacing: "0.8px",
            ":disabled": {
              backgroundColor: "var(--primarySM-color)",
              color: "var(--primary-color)",
            },
          },
        },
        variants: [
          {
            props: { variant: "text" },
            style: {
              backgroundColor: "var(--primarySM-color)",
              color: "var(--primary-color)",
              "&:hover": {
                backgroundColor: "var(--primaryMD-color)",
              },
            },
          },
        ],
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&, &:hover, &.MuiFilledInput-root.Mui-focused": {
              backgroundColor: "var(--primaryXS-color)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            },
            " &.MuiFilledInput-root:before": {
              borderBottom: "1px solid #D1D5DB",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--primaryXS-color)",
            },
          },
        },
      },
    },
  });
  return {
    theme,
  };
}
