import { Box } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, InputFile } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import * as actions from "../../../api/actions/actions";
import { controller, endpoints } from "../../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { Typography } from "@mui/material";

const MultUnitUpl = (props) => {
  const { setShowForm, _post, _hoa, setFilterUnits, alert, setAlert } = props;
  const { UnitCsvMdl } = Model();
  const { values, setValues, errors, setErrors, handleFile } =
    UseForm(UnitCsvMdl);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let temp = {};
    temp.csvFile = values.csvFile ? "" : "CSV File is requird.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200) {
          res.result &&
            res.result.length > 0 &&
            setFilterUnits((x) => [...x, ...res.result]);
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
        setShowForm(false);
      };
      values.hoaId = _hoa.id;
      values.orgId = _hoa.orgId;
      _post(
        endpoints.Unit + controller.CsvImport,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box className="p-15" sx={{ maxWidth: { xs: "280px", md: "450px" } }}>
        <Form onSubmit={handleSubmit} noValidate>
          <div className="tx-center mb-30">
            <h2 className="h fw-5 mb-5 fs-20 tx-upp">Import XLSX</h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-40" />
            </div>
          </div>
          <div className="tx-center mb-30">
            <Typography className="fs-14">
              <span className="fw-5">Note: </span>
              <span>
                Your first row will countable as a header. And only your first
                column data will countable as a unit title. Addresses will
                integrate from hoa's address.
              </span>
            </Typography>
          </div>
          <div className="mb-15">
            <InputFile
              label="Upload"
              name="csvFile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              src={values.csvFile}
              values={values.csvFile}
              onChange={handleFile}
              error={errors.csvFile}
              rmv={() => {
                setValues((x) => ({ ...x, csvFile: "" }));
              }}
            />
          </div>

          <Box className="d-flex">
            <Button
              onClick={handleClose}
              fullWidth
              className="mr-10"
              variant="text"
            >
              Close
            </Button>
            <Button fullWidth type="submit" loading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      </Box>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.storeData.authUser,
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(MultUnitUpl);
