import { CloseRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../../../controls";
import { Alert, Dialog, Table } from "../../../../layout";
import AddEditNote from "./AddEditNote";
import * as actions from "../../../../api/actions/actions";
import NotePdf from "./NotePdf";
import { PrintDiv } from "../../../../hooks/Method";

const Notes = (props) => {
  const { tblShadow = true, inv, setViewNotes, setTblData } = props;
  const [notes, setNotes] = useState([]);
  const [updData, setUpdData] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const [showForm, setShowForm] = useState(false);
  const handleAddEdit = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  const handleClose = () => {
    setViewNotes(false);
  };

  useEffect(() => {
    inv && setNotes([...inv.invNotes.sort((x, y) => y.id - x.id)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inv]);

  const DownloadPDF = () => {
    PrintDiv("NotePDF");
  };

  const HeaderComp = () => (
    <>
      <Button
        variant="outlined"
        className="mr-10"
        sx={{ mb: { xs: "10px", md: "0px" } }}
        onClick={() => handleAddEdit()}
      >
        Add Note
      </Button>
      <Button onClick={DownloadPDF} variant="outlined" className="mr-20">
        Download PDF
      </Button>
    </>
  );

  return (
    <>
      <div id="NotePDF" className="d-none">
        <NotePdf
          data={notes}
          unitTitle={inv && inv.unit.title}
          invTitle={inv.title}
          invId={inv.id}
        />
      </div>
      <div className="p-15">
        <div className="mb-20 d-flex j-between">
          <div>
            <h2 className="h tx-md fs-20 mb-5 tx-upp border-left gray-color">
              <span className="d-block">{inv && inv.unit.title}</span>
              <span className="fs-16">{inv && inv.title}</span>
            </h2>
          </div>
          <div>
            <IconButton onClick={() => handleClose()}>
              <CloseRounded />
            </IconButton>
          </div>
        </div>

        <Table
          id="tbl123"
          ActionComp={() => <></>}
          HeaderComp={HeaderComp}
          shadow={tblShadow}
          tblHead={[
            { id: "note", label: "Note" },
            { id: "createDate", label: "Added Date" },
          ]}
          tblData={notes.map((note) => {
            return {
              ...note,
              note: <div className="pre-wrap">{note.note}</div>,
            };
          })}
        />
      </div>

      {/* Note Form */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditNote
            {...{
              setShowForm: setShowForm,
              setAlert,
              inv,
              updData,
              setNotes,
              setTblData,
            }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Notes);
