import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { controller, endpoints } from "../../../api/actions/api";
import { Model, UseForm } from "../../../hooks";
import { ToFormData } from "../../../hooks/Method";
import * as actions from "../../../api/actions/actions";
import { Form } from "../../../layout";
import { Button, InputPass } from "../../../controls";
import { PassValidation } from "../../../utils";

const ChangePass = (props) => {
  const { _post, setShowForm, setAlert } = props;
  const { ChgPassMdl } = Model();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const { values, errors, setErrors, handleInpPass, handleInpChg, passValid } =
    UseForm(ChgPassMdl);

  const validateForm = () => {
    let temp = {};
    temp.oldPass = values.oldPass !== "" ? "" : "Old password is required.";
    temp.password = values.password !== "" ? "" : "Password is required.";
    temp.confirmPass =
      values.confirmPass !== ""
        ? values.confirmPass === values.password
          ? ""
          : "Password and Confirm password not match"
        : "Confirm Password is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "You have successfully changed the password.",
          });
          setShowForm(false);
        } else setErr(res.msg);
      };
      _post(
        endpoints.User + controller.ChgPass,
        ToFormData(values),
        null,
        onComplete
      );
    }
  };

  const handleCls = () => {
    setShowForm(false);
  };

  return (
    <>
      <Form noValidate onSubmit={submitForm}>
        <Grid container className="p-15 pt-0" spacing={2}>
          <Grid item xs={12} className="tx-center mt-20 pb-30">
            <h2 className="fs-24 fw-5 tx-upp h gray-color">Change Password</h2>
            <div className="pos-relative d-flex j-center mt-5">
              <div className="border-bottom w-60" />
            </div>
          </Grid>
          {err && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="error">
                <span className="fw-6 tx-upp"> Error:&nbsp;</span> {err}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <InputPass
              label="Old Password"
              name="oldPass"
              value={values.oldPass}
              error={errors.oldPass}
              onChange={handleInpChg}
              className="w-100pr"
            />
          </Grid>
          <Grid item xs={12}>
            <InputPass
              label="Password"
              name="password"
              value={values.password}
              onChange={handleInpPass}
              error={errors.password}
              className="w-100pr"
            />
            <PassValidation {...{ password: values.password, passValid }} />
          </Grid>
          <Grid item xs={12} className="mb-10">
            <InputPass
              label="Confirm Password"
              name="confirmPass"
              value={values.confirmPass}
              onChange={(e) => handleInpPass(e, true)}
              error={errors.confirmPass}
              className="w-100pr"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={handleCls}
              variant="text"
              size="large"
              className="w-100pr"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              loading={loading}
              size="large"
              className="w-100pr"
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(ChangePass);
