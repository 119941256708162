import { Box, Grid, Paper, TableCell, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Table } from "../../../layout";
import { connect } from "react-redux";
import { useEffect } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { useState } from "react";
import RepNav from "./RepNav";
import moment from "moment";

const ProfitVsLoss = (props) => {
  const { _getById, _hoa } = props;
  const [ap, setAp] = useState([]);
  const [ar, setAr] = useState([]);
  const [totalAr, setTotalAr] = useState(0);
  const [totalAp, setTotalAp] = useState(0);
  const [filteredAp, setFilteredAp] = useState([]);
  const [filteredAr, setFilteredAr] = useState([]);
  const [apTblData, setApTblData] = useState([]);
  const [arTblData, setArTblData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [cats, setCats] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    setCsvHeader([
      { label: "Category", key: "catName" },
      { label: "Total", key: "total" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    if (apTblData || arTblData) {
      setCsvData((x) => [
        ...x,
        { catName: "Income", total: "-----------" },
        ...arTblData,
      ]);
      setCsvData((x) => [
        ...x,
        {},
        { catName: "Expense", total: "-----------" },
        ...apTblData,
        {},
        { catName: "Net Total (Income - Expense)", total: totalAr - totalAp },
      ]);
    }
    return () => {
      setCsvData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apTblData, arTblData]);

  useEffect(() => {
    if (ap || ar) {
      setFilteredAp([
        ...ap.filter(
          (j) =>
            moment(j.paidDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.paidDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
      setFilteredAr([
        ...ar.filter(
          (j) =>
            moment(j.dueDate, "MM/DD/YYYY") >=
              moment(sortDate.fromDate, "MM/DD/YYYY") &&
            moment(j.dueDate, "MM/DD/YYYY") <=
              moment(sortDate.toDate, "MM/DD/YYYY")
        ),
      ]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ap, ar, sortDate]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);

      _getById(endpoints.AP + controller.GetByHoa, _hoa.id, null, (res) => {
        setLoader(false);
        res.status === 200 &&
          setAp([...res.result.filter((j) => j.isComplete)]);
      });

      _getById(
        endpoints.Invoice + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setAr([...res.result])
      );

      _getById(
        endpoints.HoaCat + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => res.status === 200 && setCats([...res.result])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    if (filteredAr) {
      var _arTblData = [];
      cats
        .filter((j) => j.catType === 1)
        .forEach((cat) => {
          _arTblData.push({
            catName: cat.name,
            total: filteredAr
              .filter((j) => j.categoryId === cat.id)
              .map((j) =>
                j.invPayments
                  .map(
                    (x) =>
                      x.paidAmt +
                      x.invLateFees
                        .map((y) => y.paidAmt)
                        .reduce((x, y) => x + y, 0)
                  )
                  .reduce((x, y) => x + y, 0)
              )
              .reduce((x, y) => x + y, 0),
          });
        });
      setArTblData([..._arTblData]);
      setTotalAr(_arTblData.map((j) => j.total).reduce((x, y) => x + y, 0));
    }

    return () => {
      setApTblData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAr, cats]);

  useEffect(() => {
    if (filteredAp) {
      var _apTblData = [];
      cats
        .filter((j) => j.catType === 2)
        .forEach((cat) => {
          _apTblData.push({
            catName: cat.name,
            total: filteredAp
              .filter((j) => j.categoryId === cat.id)
              .map((j) => j.amount)
              .reduce((x, y) => x + y, 0),
          });
        });
      setApTblData([..._apTblData]);
      setTotalAp(_apTblData.map((j) => j.total).reduce((x, y) => x + y, 0));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAp, cats]);

  const IncHeader = () => (
    <div className="d-flex a-center flex-wrap">
      <Box>
        <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">Income</h2>
      </Box>
    </div>
  );
  const ExpHeader = () => (
    <div className="d-flex a-center flex-wrap">
      <Box>
        <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">Expenses</h2>
      </Box>
    </div>
  );
  return (
    <Grid container spacing={3} className="pt-15">
      <Grid item xs={12} className="pb-30">
        <RepNav
          {...{
            csvHeader,
            csvData,
            sortDate,
            setSortDate,
            title: "Profit Vs Loss",
          }}
        />
      </Grid>

      {/* Income  */}
      <Grid item xs={12} md={6}>
        <Table
          shadow={false}
          showSearch={false}
          ActionComp={() => <></>}
          HeaderComp={IncHeader}
          tblHead={[
            { id: "catName", label: "Category" },
            { id: "total", label: "Total" },
          ]}
          tblData={arTblData.map((x) => {
            return {
              ...x,
              total: "$" + x.total.toLocaleString(),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                ${totalAr.toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </Grid>

      {/* Expenses */}
      <Grid item xs={12} md={6}>
        <Table
          shadow={false}
          ActionComp={() => <></>}
          showSearch={false}
          HeaderComp={ExpHeader}
          tblHead={[
            { id: "catName", label: "Category" },
            { id: "total", label: "Total" },
          ]}
          tblData={apTblData.map((j) => {
            return {
              ...j,
              total: "$" + j.total.toLocaleString(),
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total
              </TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                ${totalAp.toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </Grid>

      {/* Accounts */}
      <Grid item xs={12}>
        <Paper className="gray-color d-flex flex-wrap j-evenly a-center">
          <Grid container>
            <Grid item xs={12} md={4} className="br-right-sec p-15 tx-center">
              <Typography variant="h5">{totalAr.toLocaleString()}</Typography>
              <Typography className="fs-12 tx-upp">(Income)</Typography>
            </Grid>
            <Grid item xs={12} md={4} className="br-right-sec p-15 tx-center">
              <Typography variant="h5">{totalAp.toLocaleString()}</Typography>
              <Typography className="fs-12 tx-upp">(Expenses)</Typography>
            </Grid>
            <Grid item xs={12} md={4} className="p-15 tx-center">
              <Typography variant="h5">
                {(totalAr - totalAp).toLocaleString()}
              </Typography>
              <Typography className="fs-12 tx-upp">
                (Income - Expenses = Net Total)
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ProfitVsLoss);
