import {
  ClearRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { IconButton, Rating, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Alert, Table } from "../../../layout";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { useEffect } from "react";
import { controller, endpoints } from "../../../api/actions/api";

const Reviews = (props) => {
  const { _getAll, _getById, _delete } = props;
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { setLoader } = useContext(AppContext);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setReviews([...res.result]);
    };
    _getAll(endpoints.Review, undefined, onComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this review?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setReviews((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Review, id, null, onComplete);
      },
    });
  };

  const handleShowHide = (val) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: `You want to ${val.show ? "hide" : "show"} this review?`,
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: res.msg,
            });
            setReviews((x) => [
              ...x.map((j) => (j.id === res.result.id ? res.result : j)),
            ]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _getById(
          endpoints.Review + controller.ShowHide,
          val.id,
          null,
          onComplete
        );
      },
    });
  };

  const ActionComp = (props) => (
    <div className="d-grid g-10">
      <div>
        <Tooltip arrow title="Delete Category">
          <IconButton
            onClick={() => handleDlt(props.id)}
            size="small"
            color="error"
          >
            <ClearRounded className="fs-25 m-2" />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <IconButton
          onClick={() => handleShowHide(props)}
          color={props.show ? "primary" : "error"}
        >
          {props.show ? (
            <VisibilityRounded className="fs-25 m-2" />
          ) : (
            <VisibilityOffRounded className="fs-25 m-2" />
          )}
        </IconButton>
      </div>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => <></>}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "rat", label: "Rating" },
          { id: "review", label: "Review" },
          { id: "createDate", label: "Date" },
        ]}
        tblData={reviews.map((obj) => {
          return {
            ...obj,
            rat: (
              <div>
                <Rating value={obj.rating} readOnly />
                <div className="">
                  <span className="tx-upp fs-12 mr-10">organization:</span>
                  <span className="fs-18">{obj.org && obj.org.orgName}</span>
                </div>
                <div className="">
                  <span className="tx-upp fs-12 mr-10">user:</span>
                  <span className="fs-18">{obj.user && obj.user.fullName}</span>
                </div>
              </div>
            ),
            review: (
              <div style={{ maxWidth: "400px" }}>
                <div className="pre-wrap">{obj.desc}</div>
              </div>
            ),
            createDate: new Date(obj.createDate).toDateString(),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getAll: actions.get,
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Reviews);
